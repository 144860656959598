import { makeStyles } from "@mui/styles";

const clientStyles = makeStyles((theme) => ({
  sticky_filters: {
    position: "-webkit-sticky",
    position: "sticky",
    top: "62px",
    zIndex: "99",
    ["@media (max-width:780px)"]: {
      position: "initial",
    },
  },
  filter_content: {
    padding: "12px 24px !important",
  },
  main_container: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    alignItems: "center",
    borderRadius: 8,
  },
  text_container: {
    padding: 2,
    borderRadius: 1,
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    alignItems: "center",
  },
  com_container: {
    borderRadius: 8,
    backgroundColor: "white",
    margin: 16,
  },
  user_pic: {
    border: "1px solid #CCD3D5",
    borderRadius: "10px",
    width: 100,
    height: 100,
  },
  user_logo: {
    border: "1px solid #CCD3D5",
    borderRadius: "25px",
    width: 50,
    height: 50,
  },
  companyLogo: {
    width: 100,
    height: 100,
    borderRadius: "8px",
    borderTopLeftRadius: 10,
    backgroundColor: "#CCD5FC",
  },
  companyLogoText: {
    textAlign: "center",
    paddingTop: "24px",
    backgroundColor: "#CCD5FC",
    borderRadius: "8px",
  },
}));

export { clientStyles };
