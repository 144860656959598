import { makeStyles } from "@mui/styles";
//@ts-ignore
const useStyles = makeStyles((theme) => ({
  tbl_header: {
    textTransform: "capitalize !important",
    fontSize: "14px !important",
    padding: "12px",
  },
}));

export { useStyles };
