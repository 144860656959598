import { Box, Modal } from "@mui/material";
import { useStyles } from "./styles";
import ImmediateActionFeedbackContent from "./viewFeedback/ImmediateActionFeedbackContent";
import MonthlyFeedbackContent from "./viewFeedback/MonthlyFeedbackContent";
import RewardFeedbackContent from "./viewFeedback/RewardFeedbackContent";

const FeedbackViewModal = ({ details, showModal, setShowModal }) => {
  const classes = useStyles();
  
  if (!details) return null;

  let content;
  
  switch (details.feedbackType) {
    case "Monthly":
      content = <MonthlyFeedbackContent details={details} onClose={() => setShowModal(false)} />;
      break;
    case "Reward":
      content = <RewardFeedbackContent details={details} onClose={() => setShowModal(false)}/>;
      break;
    case "ImmedicateAction":
      content = <ImmediateActionFeedbackContent details={details} onClose={() => setShowModal(false)} />;
      break;
    default:
      content = <Box>Loading...</Box>;
  }
  
  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <Box className={classes.modal}>
        {content}
      </Box>
    </Modal>
  );
};

export default FeedbackViewModal;
