export const LABELS = {
  name: "Name",
  login: "Login",
  resend: "Resend",
  submit: "Submit",
  save: "Save",
  cancel: "Cancel",
  companyName: "Company Name",
  email: "Email",
  country: "Country",
  actions: "Actions",
};
