/**
 * Migrations manage schema changes in Redux store. They ensure data compatibility
 * and integrity across application updates by transforming existing state to match
 * new state structures, preventing data loss and maintaining a seamless user experience.
 */

const migrations = {
  2: (state) => {
    const employee = state.performance?.employee || {};
    if (!employee.annualFeedbackDetails) {
      employee.annualFeedbackDetails = {};
    }
    if (!employee.annualSubmitFeedbackInfo) {
      employee.annualSubmitFeedbackInfo = {};
    }

    if (!employee.test) {
        employee.test = {}
    }

    return {
      ...state,
      performance: {
        ...state.performance,
        employee: employee,
      },
    };
  },
};

export default migrations;