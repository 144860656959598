import { rowPerPage } from "../Constants";
import api from "../services/BaseApi";
import { userAccess } from "../utils/CommonFunction";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const getFilteredAttendance = BASE_URL + "getFilteredAttendance";
const addCheckInCheckOut = BASE_URL + "addCheckInCheckOut";

export const getAttendance = async (payload) => {
  try {
    const res = await api.post(getFilteredAttendance, payload);
    return res;
  } catch (error) {
    console.log(error, "===== getAttendance list weekly API Error");
    //return error;
  }
};

export const addAttendanceInOutTime = async (payload) => {
  try {
    const res = await api.post(addCheckInCheckOut, payload);
    return res;
  } catch (error) {
    console.log(error, "===== addAttendanceInOutTime weekly API Error");
    //return error;
  }
};
