import { Button, Grid, Skeleton, Typography } from "@mui/material"
import { memo, useEffect, useState } from "react"
import { STRINGS } from "../../Strings"
import { Link } from "react-router-dom"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import JobDetails from "./JobDetails";
import { useDispatch, useSelector } from "react-redux";
import { getJobDetailByJobRequestId } from "./Hire.helper";
import { styles } from "./HireStyle";


const CandidateListHeader = ({ jobId }) => {
    const [openDetails, setOpenDetails] = useState(false)
    const {
        isHireDetailLoading,
        hireJobsDetail,
    } = useSelector(
        //@ts-ignore
        (state) => state.hire
    )

    const dispatch = useDispatch()
    const classes = styles()

    useEffect(() => {
        if (jobId)
            getJobDetailByJobRequestId(jobId, dispatch);

    }, [jobId])
    return (
        <>
            <JobDetails
                hireJobsDetail={hireJobsDetail}
                showModal={openDetails}
                closeModal={() => setOpenDetails(false)}
                isHireDetailLoading={isHireDetailLoading}
            />
            <Grid container xs={12} md={12} direction={'row'} alignItems={'center'} mb={2}>
                <Grid md={8} container item direction={'row'} alignItems={'center'}>
                    <Link className={classes.back_icon2} to="/hire">
                        <ArrowBackIosNewIcon height={20} />
                    </Link>

                    {isHireDetailLoading ? <Skeleton
                        variant="text"
                        width={"150px"}
                        sx={{ fontSize: "1rem" }}
                    ></Skeleton> : (<><Typography
                        variant="body1"
                        component="div"
                        sx={{
                            display: {
                                xs: "none",
                                md: "block",
                            },
                        }}>

                        {hireJobsDetail.title}
                    </Typography>
                        <Typography
                            variant="body2"
                            component="div"
                            sx={{
                                ml: '1rem',
                                display: {
                                    xs: "none",
                                    md: "block",
                                },
                            }}>

                            {`(${STRINGS.RequestNumber.params({
                                number: hireJobsDetail.reference,
                            })})`}
                        </Typography></>)}
                </Grid>
                <Grid container item md={4} justifyContent={'flex-end'} flex={1}>
                    {isHireDetailLoading ? <Skeleton
                        variant="text"
                        width={"150px"}
                        sx={{ fontSize: "1rem" }}
                    ></Skeleton> : <Button variant="text" className="header-button" onClick={() => {
                        setOpenDetails(true)
                    }}>
                        Request Details
                    </Button>}
                </Grid>
            </Grid>
        </>
    )
}

export default memo(CandidateListHeader)