const HireIcon = ({ active }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.60025 13.2005C8.71078 13.2005 9.74593 12.8743 10.6174 12.3145L13.952 15.6492C14.186 15.8831 14.4932 16 14.8006 16C15.1078 16 15.4152 15.883 15.6492 15.6491C16.1169 15.1812 16.1169 14.4199 15.6491 13.952L12.3145 10.6174C12.8743 9.74591 13.2005 8.71078 13.2005 7.60025C13.2005 4.51222 10.6883 2 7.60025 2C4.51222 2 2 4.51222 2 7.60025C2 10.6883 4.51222 13.2005 7.60025 13.2005ZM5.02515 11.6482C5.19726 10.3712 6.28745 9.40033 7.60025 9.40033C8.91313 9.40033 10.0032 10.3712 10.1753 11.6482C9.43062 12.1236 8.54743 12.4005 7.60025 12.4005C6.65306 12.4005 5.76987 12.1236 5.02515 11.6482ZM6.4002 7.40024C6.4002 6.73849 6.9385 6.20019 7.60025 6.20019C8.262 6.20019 8.8003 6.73849 8.8003 7.40024C8.8003 8.06199 8.262 8.60029 7.60025 8.60029C6.9385 8.60029 6.4002 8.06199 6.4002 7.40024ZM15.0834 14.5176C15.2394 14.6736 15.2394 14.9275 15.0835 15.0834C14.9274 15.2395 14.6737 15.2394 14.5176 15.0835L11.2643 11.8301C11.4661 11.6551 11.6551 11.4661 11.8301 11.2644L15.0834 14.5176ZM7.60025 2.80004C10.2471 2.80004 12.4005 4.95335 12.4005 7.60025C12.4005 8.9806 11.814 10.2259 10.8782 11.1024C10.6042 10.0957 9.88314 9.28162 8.94351 8.87783C9.34586 8.51173 9.60034 7.98581 9.60034 7.40024C9.60034 6.29736 8.70313 5.40015 7.60025 5.40015C6.49737 5.40015 5.60016 6.29736 5.60016 7.40024C5.60016 7.98581 5.85465 8.51173 6.25699 8.87783C5.31735 9.28161 4.59627 10.0957 4.32226 11.1023C3.38645 10.2258 2.80004 8.98057 2.80004 7.60025C2.80004 4.95335 4.95335 2.80004 7.60025 2.80004Z"
        className="fill-color stroke-color"
        strokeWidth="0.2"
      />
    </svg>
  );
};

export default HireIcon;
