import { Alert, Box, Snackbar } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { sagaActions } from "../../redux/sagaActions";
import { userAccess } from "../../utils/CommonFunction";
import AnnaulFeedbackTableMobile from "./annual/AnnualFeedbackTableMobile";
import Layout from "./common/Layout";
import PerformanceTab from "./common/tabs/PerformanceTab";
import PerformanceTabPanel from "./common/tabs/PerformanceTabPanel";
import SubmissionModal from "./emailMultipleFeedback/SubmissionModal";
import PerformanceHeader from "./header/PerformanceHeader";
import PerformanceSubHeaderMobile from "./header/PerformanceSubHeaderMobile";
import PerformanceOverviewMobile from "./overview/PerformanceOverviewMobile";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const MobileView = () => {
  let query = useQuery();
  const tabName = query.get("tab");

  const dispatch = useDispatch();
  const [selectedEmploymentType, setSelectedEmploymentType] = useState("All");
  const [searchString, setSearchString] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  const [tabValue, setTabValue] = useState<number>(() =>
    tabName === "annual-feedback" ? 0 : 1
  );
  const [feedback, setFeedback] = useState({});
  const [ratingMissingRows, setRatingMissingRows] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const { clientCode } = useSelector(
    //@ts-ignore
    (state) => state.client
  );

  const { data = [] } = useSelector(
    //@ts-ignore
    (state) => state.performance.allEmployees
  );

  const { submitBulkFeedbackInfo } = useSelector(
    //@ts-ignore
    (state) => state.performance.multipleEmployees
  );

  const fetchEmployeePerformance = (pageNumber: number = 1) => {
    dispatch({
      type: sagaActions.FETCH_ALL_EMPLOYEES_PERFORMANCE_MOBILE,
      payload: {
        employeeType:
          selectedEmploymentType === "All" ? "" : selectedEmploymentType,
        pageNumber: pageNumber,
        searchValue: searchString.trim(),
        forAnualFeedback: tabValue === 0,
      },
    });
  };

  useEffect(() => {
    fetchEmployeePerformance();
  }, [selectedEmploymentType, clientCode, searchString, tabValue]);

  const handlePageChange = () => {
    fetchEmployeePerformance(pageNumber + 1);
    setPageNumber(pageNumber + 1);
  };

  const onBulkRatingChange = (event) => {
    let newFeedback = { ...feedback };
    const pendingRows = data.filter((row: any) => {
      return !row.isLastMonthFeedbackSubmitted && row.clientId !== 0;
    });
    pendingRows.forEach((row: any) => {
      newFeedback[row.id] = {
        ...newFeedback[row.id],
        rating: event.target.value,
      };
    });

    setFeedback(newFeedback);
  };

  const onSubmit = () => {
    let _ratingMissingRows: any = [];

    for (const feedbackEntry of Object.entries(feedback)) {
      const [employeeId, feedbackValue] = feedbackEntry as any;
      if (!feedbackValue.rating && feedbackValue.comment) {
        _ratingMissingRows.push(employeeId);
      }
    }

    if (_ratingMissingRows.length > 0) {
      setRatingMissingRows(_ratingMissingRows);
      return;
    }

    setShowModal(true);
    const { userId, firstName, lastName } = userAccess();
    const previousMonthStartDate = moment()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    const payload = Object.entries(feedback)?.map(
      ([employeeId, feedbackValue]: any) => {
        const employee = data.find(
          (e) => e.employeeId.toString() === employeeId
        );
        return {
          clientId: employee.clientId,
          userId,
          employeeId: parseInt(employeeId, 10),
          employeeName: employee.name,
          score: feedbackValue.rating,
          date: previousMonthStartDate,
          from: `${firstName} ${lastName}`,
          comment: feedbackValue.comment,
        };
      }
    );

    dispatch({ type: sagaActions.SUBMIT_BULK_FEEDBACK, payload });
  };

  const onModalClose = () => {
    fetchEmployeePerformance(1);
    setPageNumber(1);
    setShowModal(false);
    setFeedback({});
  };

  const handleTabChange = (
    event: React.SyntheticEvent,
    newTabValue: number
  ) => {
    setTabValue(newTabValue);
  };

  const onRefresh = () => {
    fetchEmployeePerformance();
  };

  return (
    <>
      <Box sx={{ margin: "0 0 12px" }}>
        <PerformanceTab handleTabChange={handleTabChange} tabValue={tabValue} />
      </Box>
      <Layout
        layoutClassName="performance-page-layout"
        header={
          <PerformanceHeader
            selectedEmploymentType={selectedEmploymentType}
            setSelectedEmploymentType={setSelectedEmploymentType}
            tabValue={tabValue}
            setSearchString={setSearchString}
            refresh={onRefresh}
          />
        }
        subHeader={
          <>
            {tabValue === 0 ? (
              <Alert
                icon={false}
                severity="warning"
                className="performance-alert-bg"
              >
                It's time for annual performance reviews! <br />
                The purpose of annual reviews is to evaluate your team's
                performance, provide feedback, set goals, and discuss career
                development.
              </Alert>
            ) : tabValue === 1 ? (
              <PerformanceSubHeaderMobile
                onBulkRatingChange={onBulkRatingChange}
                onSubmit={onSubmit}
                feedback={feedback}
              />
            ) : null}
          </>
        }
        body={
          <>
            <PerformanceTabPanel tabValue={tabValue} index={0}>
              <AnnaulFeedbackTableMobile
                loadMoreData={handlePageChange}
                pageNumber={pageNumber}
                setFeedback={setFeedback}
                feedback={feedback}
              />
            </PerformanceTabPanel>
            <PerformanceTabPanel tabValue={tabValue} index={1}>
              <PerformanceOverviewMobile
                loadMoreData={handlePageChange}
                pageNumber={pageNumber}
                setFeedback={setFeedback}
                feedback={feedback}
              />
            </PerformanceTabPanel>
          </>
        }
      />

      <SubmissionModal
        showModal={showModal}
        onSuccessClose={onModalClose}
        onCancel={() => setShowModal(false)}
        submitBulkFeedbackInfo={submitBulkFeedbackInfo}
      />

      {ratingMissingRows.length > 0 && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={true}
          autoHideDuration={6000}
          message="Please rate all the commented employees"
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: "red",
            },
          }}
        />
      )}
    </>
  );
};

export default MobileView;
