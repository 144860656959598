import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IconButton, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { sagaActions } from "../../../../redux/sagaActions";
import Layout from "../../common/Layout";
import SkeletonLoader from "../../common/loader/SkeletonLoader";
import SubmissionModal from "../../common/modal/SubmissionModal";
import AnnualFeedbackDetailsFilledForm from "./AnnualFeedbackDetailsFilledForm";
import AnnualFeedbackDetailsForm from "./AnnualFeedbackDetailsForm";

const AnnualFeedbackDetails = () => {
  const dispatch = useDispatch();
  const navigate: any = useNavigate();
  const location: any = useLocation();
  const params: any = useParams();
  const [showErrorMessage] = useState(
    () => !(params.year == new Date().getFullYear() - 1)
  );

  const [showModal, setShowModal] = useState(false);

  const { isLoading, data = [] } = useSelector(
    //@ts-ignore
    (state) => state.performance.annualQuestions
  );

  const {
    annualSubmitFeedbackInfo,
    annualFeedbackDetails,
    isLoading: annualFeedbackDetailsLoading,
  } = useSelector(
    //@ts-ignore
    (state) => state.performance.employee
  );

  const getEmployeeFeedbackDetails = () => {
    dispatch({
      type: sagaActions.FETCH_ANNUAL_FEEDBACK_DETAILS,
      payload: {
        employeeId: params.id,
        year: params.year,
      },
    });
  };
  const onModalClose = () => {
    setShowModal(false);
    let customState: any = null;
    if (location.state !== null && location.state.pageSize !== undefined) {
      customState = {
        pageSize: location.state.pageSize,
        pageNumber: location.state.pageNumber,
      };
    }
    navigate("/performance?tab=annual-feedback", { state: customState });
  };

  useEffect(() => {
    dispatch({
      type: sagaActions.FETCH_ALL_ANNUAL_QUESIONS_PERFORMANCE,
    });
    getEmployeeFeedbackDetails();
  }, []);

  const handleRedirectBack = () => {
    let customState: any = null;
    if (location.state !== null && location.state.pageSize !== undefined) {
      customState = {
        pageSize: location.state.pageSize,
        pageNumber: location.state.pageNumber,
      };
    }

    if (location.state.prevRoute) {
      navigate(location.state.prevRoute.pathname, { state: customState });
    } else {
      navigate("/performance?tab=annual-feedback", { state: customState });
    }
  };

  return (
    <Layout
      layoutClassName="annaul-feedback-details-layout"
      header={
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleRedirectBack}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography
            className="annual-feedback-details-header"
            variant="h5"
            color="inherit"
            noWrap
            fontWeight={400}
          >
            Team member feedback
          </Typography>
        </Toolbar>
      }
      headerClassName="annaul-feedback-details-header"
      body={
        showErrorMessage ? (
          <>
            <h1 className="error-message-annaul-feedback">
              Sorry you can't submit annual feedback this year {params.year}!
            </h1>
          </>
        ) : (
          <>
            {annualSubmitFeedbackInfo !== undefined && (
              <SubmissionModal
                showModal={showModal}
                onSuccessClose={onModalClose}
                onCancel={() => setShowModal(false)}
                apiProcessInfo={annualSubmitFeedbackInfo}
              />
            )}

            {isLoading || annualFeedbackDetailsLoading ? (
              <SkeletonLoader />
            ) : annualFeedbackDetails.isAnnualFeedbackSubmitted ? (
              <AnnualFeedbackDetailsFilledForm
                handleRedirectBack={handleRedirectBack}
                employeeDetails={annualFeedbackDetails}
              />
            ) : (
              <AnnualFeedbackDetailsForm
                allQuestions={data}
                handleRedirectBack={handleRedirectBack}
                employeeDetails={annualFeedbackDetails}
                setShowModal={setShowModal}
              />
            )}
          </>
        )
      }
      bodyClassName="annual-feedback-details-body"
    />
  );
};

export default AnnualFeedbackDetails;
