import {
  getUserRef,
  getAuthToken,
  userLogin,
  userRegistration,
} from "../../../services/OnBoarding";

import {
  registrationPending,
  registrationSuccess,
  registrationError,
} from "../../../redux/Registration/userRegistrationSlice";
import {
  loginPending,
  loginSuccess,
  loginFail,
} from "../../../redux/Login/loginSlice";
//jwt decoder
import jwt_decode from "jwt-decode";
import { Status } from "../../../services/ApiStatus";
import { Dispatch } from "@reduxjs/toolkit";
import { AlertContainer } from "react-alert";
import { Login, registerProps } from "../../../Types/Users/User";
import {
  setAuthorizationToken,
  setClientHeader,
} from "../../../services/BaseApi";
import { setClientLoginFirstTime } from "../../../redux/User/userSlice";
import { clientAdmin } from "../../../Constants";
import {
  setClientCode,
  setClientName,
  setClientRole,
} from "../../../redux/Client/clientSlice";

export const loggedUserIn = async (
  creds: Login,
  dispatch: Dispatch<any>,
  history: any,
  alert: AlertContainer
) => {
  dispatch(loginPending());
  try {
    const response = await getAuthToken();
    if (!!response && response.status === Status.Success) {
      let { token } = response.data;
      setAuthorizationToken(token);
      const loginResponse: any = await userLogin(creds);
      if (!!loginResponse && loginResponse?.status === Status.Success) {
        setAuthorizationToken(loginResponse?.data?.accessTokens?.token);
        dispatch(loginSuccess(loginResponse?.data));
        const { userInfo } = loginResponse?.data;
        if (userInfo.role?.toLowerCase() === "tmf_admin") {
          setClientHeader(0);
          dispatch(setClientCode(0));
          dispatch(setClientName("All"));
        } else {
          setClientHeader(userInfo?.companyId);
          dispatch(setClientCode(userInfo?.companyId));
          dispatch(setClientName(userInfo?.companyName));
        }
        dispatch(setClientRole(userInfo?.role));
        if (userInfo.role === clientAdmin) {
          dispatch(setClientLoginFirstTime());
          history("/client-onboarding");
        } else {
          history("/dashboard");
        }
      } else {
        if (!!loginResponse) {
          dispatch(loginFail(loginResponse.data.message));
          alert.error(loginResponse.data.message);
        }
      }
    } else {
      if (response) {
        alert.error(response.data.errorMessage);
      }
    }
  } catch (error: any) {
    if (error) {
      dispatch(loginFail(error.message));
    }
  }
};

export const registerUserAction = async (
  creds: registerProps,
  dispatch: Dispatch<any>,
  history: any,
  alert: AlertContainer
) => {
  dispatch(registrationPending());
  const responseAuth = await getAuthToken();
  if (!!responseAuth && responseAuth?.status === Status.Success) {
    let { token } = responseAuth.data;
    setAuthorizationToken(token);
    const response = await userRegistration(creds);
    if (!!response && response.data) {
      if (response.status === Status.Success) {
        alert.success("User created successfully.");
        dispatch(registrationSuccess(response.data));
        setAuthorizationToken(response?.data?.accessTokens?.token);
        dispatch(loginSuccess(response?.data));
        const { userInfo } = response?.data;
        if (userInfo.role?.toLowerCase() === "tmf_admin") {
          setClientHeader(0);
          dispatch(setClientCode(0));
          dispatch(setClientName("All"));
        } else {
          setClientHeader(userInfo?.companyId);
          dispatch(setClientCode(userInfo?.companyId));
          dispatch(setClientName(userInfo?.companyName));
        }
        dispatch(setClientRole(userInfo?.role));
        if (userInfo.role === clientAdmin) {
          dispatch(setClientLoginFirstTime());
          history("/client-onboarding");
        } else {
          history("/dashboard");
        }

        //Logged user in after successfully registered
        // let dataObj = { Email: creds.email, Password: creds.Password };
        // loggedUserIn(dataObj, dispatch, history, alert);
      } else {
        dispatch(registrationError(response.data.errorMessage));
        alert.error(response.data.errorMessage);
      }
    }
  } else {
    setAuthorizationToken("");
  }
};

export const getUserInfo = async (
  guid: string | null,
  token: string | null,
  history: any,
  alert: AlertContainer
) => {
  const dataObj = { refId: guid, operation: "SIGNUP", routeName: "" };
  const response = await getUserRef(dataObj, token);
  if (!!response && response.status === Status.Success) {
    let { data, isRg, message } = response.data.data;
    let decodedToken = jwt_decode(data);

    // return decoded;
    if (!isRg && message === "Link has Expired") {
      //if Link has been Expired
      history("/info", {
        state: {
          title: "The Email verification link has expired!",
          subTitle1:
            "Look like the verification link has expired. Not to worry we can send the verification link.",
          subTitle2: "",
          source: "Link Expired",
        },
      });
    } else if (isRg === true && message == null) {
      //if user is already Registered
      history("/info", {
        state: {
          title: "Already Registered",
          // @ts-ignore
          subTitle1: `The account with email-id ${decodedToken.email} already exists.`,
          subTitle2: "",
          source: "Already Registered",
        },
      });
    } else if (isRg === false && message == null) {
      //if user new go to verify account
      let decoded = jwt_decode(data);
      return decoded;
    }
  } else {
    if (response) {
      alert.error(response.data.errorMessage);
    }
  }
};

export const getToken = async (
  guid: string | null,
  alert: AlertContainer,
  history: any
) => {
  const response = await getAuthToken();
  if (!!response && response.status === Status.Success) {
    let { token } = response.data;
    return getUserInfo(guid, token, history, alert);
  } else {
    if (response) {
      alert.error(response.data.errorMessage);
    }
  }
};
