import { Box, Skeleton, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { getProfile } from "../../../services/Users";

const AssigneeUser = ({ assigneeUser, handleReporterClick = () => {} }) => {
    const [profile, setProfile] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);
    const alert = useAlert()
    const getProfileFromApi = async (userId) => {

        try {
            const res = await getProfile({
                payload: {
                    userId
                }
            })
            setProfile(res?.data)
        } catch (e) {
            alert.error('Error fetching user ' + e)
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (assigneeUser) {
            getProfileFromApi(assigneeUser)
        }
    }, [assigneeUser])
    return (
        <Box>


            {assigneeUser && isLoading && <Skeleton variant="rectangular" sx={{
                width: '100px',
                height: '20px',
            }}></Skeleton>}
            {assigneeUser && !isLoading && <Typography>{profile?.firstName} {profile?.lastName}</Typography>}
            {!assigneeUser && <Typography sx={{
                fontWeight: 500,
                backgroundColor: '#F7EBFC',
                borderRadius: '10px',
                ":hover": {
                    backgroundColor: '#F7EBFC',
                },
                padding: 1,
                lineHeight: '20px',
                fontSize: '14px',
                textTransform: 'none',
                color: '#8026A1',
                wordSpacing: '2px',

            }}>Yet to assign</Typography>
           }

        </Box>)
}

export default memo(AssigneeUser,(prevProps, nextProps) => {
    return prevProps.assigneeUser === nextProps.assigneeUser
})