import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import Search from "../../../shared/components/Search/Search";
import { userAccess } from "../../../utils/CommonFunction";
import FeedbackButtons from "../common/FeedbackButtons";
import FeedbackMenu from "../common/menu/FeedbackMenu";
import SubmitFeedbackModal from "../common/modal/SubmitFeedbackModal";

const PerformanceHeader = ({
  selectedEmploymentType,
  setSelectedEmploymentType,
  setSearchString,
  tabValue,
  refresh,
}) => {
  const isMobile = useMediaQuery("(max-width:1300px)");
  const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const backgroundColor = !userAccess().client ? "#CCD5FC" : "#F7EBFC";
  const fetchEmployee = (searchString: string) => {
    setSearchString(searchString);
  };

  const handleSubmissionModalClose = (e: any, status: string | undefined) => {
    refresh();
    setShowModal(false);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Toolbar
        sx={
          isMobile
            ? {
                backgroundColor: backgroundColor,
                borderRadius: "10px 10px 0 0",
              }
            : { flexWrap: "nowrap" }
        }
      >
        {!isMobile && (
          <FormControl sx={{ marginRight: 2 }}>
            <Search
              placeHolder="Search team member"
              getSearch={fetchEmployee}
            />
          </FormControl>
        )}
        <FormControl sx={{ flexGrow: 1 }}>
          <InputLabel>Team member type</InputLabel>
          <Select
            id="employeeType"
            name="employeeType"
            label="Team member type"
            value={selectedEmploymentType}
            disabled={tabValue === 0}
            onChange={(e) => {
              setSelectedEmploymentType(e.target.value);
            }}
            sx={{
              width: 190,
              "&:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000000 !important",
              },
            }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Contract">On Contract</MenuItem>
            <MenuItem value="Permanent">Permanent</MenuItem>
            <MenuItem value="Probation">Probation</MenuItem>
          </Select>
        </FormControl>

        {isMobile && (
          <>
            <Box>
              <IconButton onClick={handleClick}>
                <SearchIcon />
              </IconButton>
              <Popover
                id="search-performance-mobile"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Search
                  placeHolder="Search team member"
                  getSearch={fetchEmployee}
                />
              </Popover>
            </Box>
            <FeedbackMenu
              searchable
              onSubmit={() => setShowModal(true)}
              tabValue={tabValue}
            />
          </>
        )}
        {!isMobile && (
          <FeedbackButtons
            searchable
            onSubmit={() => setShowModal(true)}
            tabValue={tabValue}
          />
        )}
      </Toolbar>

      <SubmitFeedbackModal
        open={showModal}
        onClose={handleSubmissionModalClose}
      />
    </>
  );
};

export default PerformanceHeader;
