import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IconButton, Toolbar, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const navigate: any = useNavigate();

  const goBack = () => {
    let customState: any = null;
    if (location.state !== null && location.state.pageSize !== undefined) {
      customState = {
        pageSize: location.state.pageSize,
        pageNumber: location.state.pageNumber,
      };
    }

    navigate("/hire", {
      state: customState,
    });
  };

  return (
    <Toolbar style={{ padding: 0, minHeight: 40 }}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="back"
        onClick={goBack}
      >
        <ArrowBackIosNewIcon />
      </IconButton>

      <Typography variant="h6" color="inherit" noWrap>
        New job request
      </Typography>
    </Toolbar>
  );
};

export default Header;
