import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  company_panel: {
    backgroundColor: "#F7EBFC",
    borderRadius: "10px",
    padding: "20px 40px",
    color: "#000000",
  },
  back_icon: {
    cursor: "pointer",
    marginRight: "15px",
    color: "#000000",
    verticalAlign: "middle",
  },
  display_card: {
    backgroundColor: "#FFFFFF",
    borderRadius: "0 0 10px 10px",
    padding: "2rem",
    marginBottom: '1rem'
  },
  company_title_text: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: '20px',
    textTransform: "capitalize",
  },
  company_title_Heading: {
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: "2rem",
  },
  admin_users_card: {
    border: "1px solid #CCD3D5",
    borderRadius: "10px",
  },
  user_pic: {
    border: "1px solid #CCD3D5",
    borderRadius: "10px",
    width: "174px",
    height: "174px",
  },
  user_logo: {
    border: "1px solid #CCD3D5",
    borderRadius: "10px",
    width: 100,
    height: 100,
  },
}));

export { useStyles };
