import {
  Button,
  CircularProgress,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STRINGS } from "../../../Strings";
import { imageUpload } from "../../../modules/Client/UserDetails/User.helper";
import {
  moveCandidateStage,
  postComment,
} from "../../../modules/Hire/Hire.helper";
import { addTimelineComment } from "../../../schemas/timeline";
import { userAccess } from "../../../utils/CommonFunction";

let initialValues = {
  apiid: "",
  comment: "",
  fileName: "",
  file: "",
  userName: "",
  role: "",
  email: "",
};

const MoveCandidate = ({
  anchorEl,
  handleClose,
  selectedStage = { code: '', name: '', count: 0 },
  selectedCandidate,
  setSelectedStage = (() => undefined) as Dispatch<any>,
  showCommentBox,
  setShowCommentBox,
}) => {
  const open = Boolean(anchorEl);
  const { hireCandidateStaging, isCommentAdded } = useSelector(
    //@ts-ignore
    (state) => state.hire
  );
  const [commentPostLoading, setCommentPostLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState<any>("");
  const [uploadfile, setUploadfile] = useState<any>("");
  const [selectFile, setFile] = useState<any>("");
  const [isPicUpdated, setIsPicUpdated] = useState<boolean>(false);
  const [formAction, setFormAction] = useState<any>();
  const {
    values,
    errors,
    touched,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addTimelineComment,
    onSubmit: (values, action) => {
      const payload = {
        noteFrom: {
          id: '',
          email: userAccess().email
        },
        noteTo: {
          id: '',
          email: selectedCandidate.candidate.email
        },
        activityType: 'a1086f15-7616-4a55-9c4b-08ec2322bb62',
        notes: values.comment

      }

      setCommentPostLoading(true);
      postComment(payload, dispatch);
      action.resetForm();
      setFileName("");
    },
  });

  useEffect(() => {
    if (isCommentAdded) {
      setCommentPostLoading(false);
    }
  }, [isCommentAdded]);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: -1,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <MenuItem>
          <Typography variant="body1">{"Move candidate to"}</Typography>
        </MenuItem>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={selectedStage?.code}
          name="radio-buttons-group">
          {!!hireCandidateStaging &&
            hireCandidateStaging?.length > 0 &&
            hireCandidateStaging.map((action, index) => {
              return (
                <MenuItem
                  key={Math.floor(Math.random() * 1000000) + index}
                  onClick={() => {
                    const payload = {
                      CandidateId: selectedCandidate.candidate.id,
                      JobId: selectedCandidate.job.id,
                      Code: action?.code

                    };
                    moveCandidateStage(payload, dispatch);
                    setSelectedStage(action);
                  }}
                  sx={{ mt: -2 }}>
                  <FormControlLabel
                    value={action?.code}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">
                        {action?.name}
                      </Typography>
                    }
                  />
                </MenuItem>
              );
            })}
        </RadioGroup>
      </Menu>
      {showCommentBox && (
        <Box
          sx={{
            alignContent: "center",
            backgroundColor: "#ffffff",
            p: 2,
          }}>
          <Box
            mt={1}
            mb={1}
            sx={{
              pointerEvents: commentPostLoading ? "none" : "auto",
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Typography variant="h6">{STRINGS.AddComment}</Typography>
            <Box
              sx={{
                mt: 2,
              }}>
              <TextField
                error={Boolean(touched.comment && errors.comment)}
                helperText={touched.comment && errors.comment}
                value={values.comment}
                multiline
                maxRows={4}
                inputProps={{
                  style: {
                    height: 80,
                  },
                }}
                fullWidth
                label="Comment"
                placeholder="Use @ to address the respective person"
                id="comment"
                name="comment"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Box
                display={"flex"}
                justifyContent="space-between"
                alignContent={"center"}
                alignItems={"center"}>
                <Box display={"flex"} className="upload-btn-wrapper">
                  {/* <button className="profile_btn">
                    <Box display={"flex"}>
                      <img
                        style={{
                          width: "22px",
                          height: "20px",
                          marginRight: "11px",
                        }}
                        src="/images/attachment.png"
                        alt="icon"
                      />
                      <Typography variant="body2">
                        {STRINGS.Attachment}
                      </Typography>
                    </Box>
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    onChange={(e) =>
                      imageUpload(
                        e,
                        setFileName,
                        setFile,
                        alert,
                        setIsPicUpdated,
                        setUploadfile
                      )
                    }
                    disabled={commentPostLoading}
                  /> */}
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="text"
                    sx={{ mt: 2 }}
                    //onReset={(e) => handleReset(e)}
                    onClick={(e) => {
                      setShowCommentBox(false);
                      setFileName("");
                      setFieldError("comment", "");
                      setFieldValue("comment", "");
                      if (formAction) {
                        formAction.resetForm();
                      }
                      //setShowComment(false);
                    }}>
                    {STRINGS.Cancel}
                  </Button>
                  <Button
                    disabled={!dirty}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2 }}>
                    {commentPostLoading && (
                      <CircularProgress size={18} color="warning" />
                    )}
                    {!commentPostLoading && STRINGS.Save}
                  </Button>
                </Box>
              </Box>
              {fileName && <Typography variant="body2">{fileName}</Typography>}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MoveCandidate;
