import React from "react";
import Box from "@mui/material/Box";
import RowSkeleton from "./RowSkeleton";
import { Card, Divider } from "@mui/material";

const MobileSkeleton: React.FunctionComponent = () => {
  return (
    <Box className="responsive-tbl">
      <Card className="tbl-card-skeleton">
        <RowSkeleton rowHeight={150} />
      </Card>
      <Card className="tbl-card-skeleton">
        <RowSkeleton rowHeight={150} />
      </Card>
      <Card className="tbl-card-skeleton">
        <RowSkeleton rowHeight={150} />
      </Card>
    </Box>
  );
};

export default MobileSkeleton;

export const DasktopMobileSkeleton: React.FunctionComponent = () => {
  return (
    <Box>
      <Card className="tbl-card-skeleton">
        <RowSkeleton rowHeight={150} />
      </Card>
      <Divider />
      <Card className="tbl-card-skeleton">
        <RowSkeleton rowHeight={150} />
      </Card>
      <Divider />
      <Card className="tbl-card-skeleton">
        <RowSkeleton rowHeight={150} />
      </Card>
    </Box>
  );
};

export const GraphSkeleton: React.FunctionComponent = () => {
  return (
    <Box>
      <Card className="tbl-card-skeleton">
        <RowSkeleton rowHeight={200} />
      </Card>
    </Box>
  );
};
