import api from "../services/BaseApi";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const getClientById = BASE_URL + "getClientById";
const updateClient = BASE_URL + "updateClient";

export const getCompanyInfo = async (payload: any = "") => {
  try {
    const res = await api.post(getClientById, payload.payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const editCompanyInfo = async (payload) => {
  try {
    const res = await api.post(updateClient, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};
