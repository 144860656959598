import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Button, FormHelperText, Paper, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import moment from "moment";
import { getAuthToken, getEmailRef } from "../../../services/OnBoarding";
import {
  getMultipleEmployeesPerformance,
  submitBulkFeedbackWithToken,
} from "../../../services/Performance";
import SkeletonLoader from "../common/loader/SkeletonLoader";
import EmployeeFeedback from "./EmployeeFeedback";
import SubmissionModal from "./SubmissionModal";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const MultipleFeedbackList = () => {
  let query = useQuery();
  const refId = query.get("refId");

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [employees, setEmployees] = useState<any[]>([]);
  const [isTokenExpired, setIsTokenExpired] = useState<any>(false);
  const [submitBulkFeedbackInfo, setSubmitBulkFeedbackInfo] = useState<any>({
    isLoading: false,
    submitted: false,
    error: null,
  });
  const [reference, setReference] = useState<any>(null);

  useEffect(() => {
    fetchPendingEmployees();
  }, [refId]);

  const fetchPendingEmployees = async () => {
    try {
      setShowModal(false);
      setSubmitBulkFeedbackInfo({
        isLoading: false,
        submitted: false,
        error: null,
      });
      setIsLoading(true);

      const tokenResult: any = await getAuthToken();
      const payloadInfo = await getEmailRef(refId, tokenResult?.data.token);

      if (!payloadInfo?.isRg) {
        setIsTokenExpired(true);
        setIsLoading(false);
        return;
      }

      const jwtToken = payloadInfo?.data;
      const decodedToken: any = jwt_decode(jwtToken);

      let payload: any = {
        clientId: decodedToken?.clientId || 0,
        zohoId: decodedToken?.zohoId,
        monthId: decodedToken?.monthId,
      };

      const results = await getMultipleEmployeesPerformance(
        payload,
        tokenResult?.data.token
      );
      setEmployees(results?.data.data || []);

      setReference({
        ...payload,
        from: decodedToken?.userName,
        token: tokenResult?.data.token,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const feedbackSchema = Yup.object().shape(
    employees.reduce((acc, employee) => {
      acc[employee.employeeId] = Yup.object().shape({
        score: Yup.number()
          .required("Rating is required")
          .min(1, "Rating is required"),
        comment: Yup.string(),
      });
      return acc;
    }, {})
  );

  const initialValues = employees.reduce((values, employee) => {
    values[employee.employeeId] = {
      score: 0,
      comment: "",
      shareFeedback: false,
    };
    return values;
  }, {});

  const handleSubmit = async (values: any) => {
    try {
      setShowModal(true);
      setSubmitBulkFeedbackInfo({
        isLoading: true,
        submitted: false,
        error: null,
      });
      const currentYear = moment().year();
      const currentMonthIndex = moment().month(); // 0-indexed month (0 for January, 11 for December)
      const referenceMonthIndex = parseInt(reference.monthId, 10) - 1; // Convert to 0-indexed
      const isJanuary = currentMonthIndex === 0;
      const previousMonthYear = isJanuary ? currentYear - 1 : currentYear;

      const previousMonthStartDate = moment()
        .year(previousMonthYear)
        .month(referenceMonthIndex)
        .startOf("month")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      const payload = Object.entries(values)?.map(
        ([employeeId, feedback]: any) => {
          const employee = employees.find(
            (e) => e.employeeId.toString() === employeeId
          );
          return {
            clientId: employee.clientId || 0,
            from: reference.from,
            employeeId: parseInt(employeeId, 10),
            employeeName: employee.name,
            score: feedback.score,
            date: previousMonthStartDate,
            comment: feedback.comment,
            isShareOn: feedback.shareFeedback || false,
          };
        }
      );

      await submitBulkFeedbackWithToken(payload, reference.token);
      setSubmitBulkFeedbackInfo({
        isLoading: false,
        submitted: true,
        error: null,
      });
    } catch (error: any) {
      console.log(error);
      setSubmitBulkFeedbackInfo({
        isLoading: false,
        submitted: false,
        error: error?.data?.message || "Something went wrong",
      });
    }
  };

  const employeeLength = employees.length;
  const month = moment()
    .month(reference?.monthId || 1)
    .subtract(1, "month")
    .format("MMMM");

  if (isTokenExpired) {
    return (
      <Typography color="#CE45FF" fontWeight={700} mb={2}>
        Token has expired
      </Typography>
    );
  }

  if (isLoading) return <SkeletonLoader />;

  if (employeeLength === 0) {
    return (
      <Typography color="#CE45FF" fontWeight={700} mb={2}>
        No employees found
      </Typography>
    );
  }

  return (
    <div>
      <Typography color="#CE45FF" fontWeight={700} mb={2}>
        Feedback for {month} month
      </Typography>

      {!isLoading && employeeLength > 0 && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={feedbackSchema}
        >
          {({ values, handleBlur, setFieldValue, errors, isValid }) => (
            <Form>
              {employees?.map((employee) => (
                <EmployeeFeedback
                  key={employee.employeeId}
                  employee={employee}
                  value={values[employee.employeeId]}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  errors={errors[employee.employeeId]}
                  clientId={employee.clientId}
                />
              ))}

              <Paper
                sx={{
                  p: 2,
                  textAlign: "right",
                  mt: -2,
                  borderRadius: 0,
                  "@media (max-width:600px)": {
                    background: "none",
                  },
                }}
              >
                {!isValid && (
                  <FormHelperText sx={{ textAlign: "right" }} error>
                    *Please score all employees
                  </FormHelperText>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitBulkFeedbackInfo?.isLoading}
                >
                  Submit
                </Button>
              </Paper>
            </Form>
          )}
        </Formik>
      )}

      <SubmissionModal
        showModal={showModal}
        submitBulkFeedbackInfo={submitBulkFeedbackInfo}
        onCancel={() => setShowModal(false)}
      />
    </div>
  );
};

export default MultipleFeedbackList;
