import { clientAdmin, patonaAdmin, teamMember } from "../Constants";
import { userAccess } from "../utils/CommonFunction";
import api from "./BaseApi";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const addTaskUrl = BASE_URL + 'addTask';
export const addTask = (values) => {
    try {
        const result = api.post(addTaskUrl, values)
        return result;
    } catch (e) {
        return e
    }
}

const addTaskCommentUrl = BASE_URL + 'addTaskComment'
export const addTaskComment = (values) => {
    return api.post(addTaskCommentUrl, values)
}

const getTaskUrl = BASE_URL + 'GetAllTasksByRole'
export const getTasks = () => {
    try {
        const result = api.post(getTaskUrl, {
            role: userAccess().role === clientAdmin || userAccess().role === teamMember ? clientAdmin : patonaAdmin
        })
        return result;
    } catch (e) {
        return e
    }
}

const updateTaskUrl = BASE_URL + 'updateTask'
export const updateTask = (task) => {
    return api.post(updateTaskUrl, task)
}

const getTaskByIdUrl = BASE_URL + 'GetTaskById'
export const getTaskById = (taskId) => {
    return api.post(getTaskByIdUrl, {
        taskId
    })
}

const deleteTaskUrl = BASE_URL + 'deleteTask'
export const deleteTaskApi = (taskId) => {
    return api.post(deleteTaskUrl, {
        taskId
    })
}