import * as Yup from "yup";
import { clientAdmin } from "../Constants";
import { checkUserEmailExist } from "../services/Users";
import { userAccess } from "../utils/CommonFunction";

export const inviteAdminInviteSchema = Yup.object({
  firstName: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your first name"),
  lastName: Yup.string().min(2).max(25).required("Please enter your last name"),
  clientID: Yup.string().when(['role'], {

    is: clientAdmin,
    then: Yup.string().required("Please select company"),
    otherwise: Yup.string().notRequired(),
  }),
  email: Yup.string()
    .email()
    .test(
      "Enter your company domian email ID only",
      "Enter your company domian email ID only",
      (value) => containsDeviceId(value)
    )
    .test("Unique Email", "Email already in use", function (value) {
      if (value) {
        return new Promise((resolve, reject) => {
          checkUserEmailExist({ emailAddress: value }).then((res) => {
            resolve(!res?.data);
          });
        });
      } else {
        return true;
      }
    })
    .required("Please enter your email"),
  role: Yup.string().notRequired(),
});

// const containsDeviceId = async (value) => {
//   const domain = userAccess().email.split("@").pop();
//   var regex = new RegExp(domain, "g");
//   if (domain === "teamified.com") {
//     return true;
//   } else if (!regex.test(value)) {
//     return false;
//   }
//   return true;
// };

const containsDeviceId = async (value) => {
  if (
    userAccess().client &&
    (/@teamified.com.au\s*$/.test(value) || /@teamified.com\s*$/.test(value))
  ) {
    return false;
  }
  return true;
};
