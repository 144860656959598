import { Dispatch } from "@reduxjs/toolkit";
import {
  loginFail,
  loginPending,
  loginSuccess,
} from "../../../redux/Login/loginSlice";
import { sagaActions } from "../../../redux/sagaActions";
import { Login } from "../../../Types/Users/User";

export const loginAction = async (creds: Login, dispatch: Dispatch<any>) => {
  dispatch(loginPending());
  try {
    dispatch({ type: sagaActions.FETCH_DATA_SAGA, payload: creds });
    // dispatch({ type: sagaActions.GET_ALL_WORKABLE_CLIENTS });
  } catch (error: any) {
    if (error) {
      dispatch(loginFail(error.message));
    }
  }
};

export const onGoogleSuccess = (
  res: any,
  dispatch: Dispatch<any>,
  history: any
) => {
  dispatch(loginSuccess(res.profileObj));
  sessionStorage.setItem("isAuth", "1");
  sessionStorage.setItem("userProfile", JSON.stringify(res.profileObj));
  //history.push("/dashboard");
};

export const onGoogleFailure = (err: any) => {
  console.log("failed", err);
};
