import { all, put, takeLatest } from "redux-saga/effects";
import { Status } from "../../services/ApiStatus";
import { setAuthorizationToken, setClientHeader } from "../../services/BaseApi";
import { getAllWorkClients } from "../../services/Hire";
import {
  forgotPasswordService,
  getAuthToken,
  userLogin,
} from "../../services/OnBoarding";
import {
  setClientCode,
  setClientName,
  setClientRole,
} from "../Client/clientSlice";
import { getAllWorkableClients } from "../Hire/hireSlice";
import { setClientCompleteLoginFirstTime } from "../User/userSlice";
import { sagaActions } from "../sagaActions";
import {
  loginFail,
  loginPending,
  loginSuccess,
  setAuthToken
} from "./loginSlice";

function* startupRequest(request) {
  try {
    yield put(loginPending());
    const response = yield getAuthToken();
    if (!!response && response?.status === Status.Success) {
      let { token } = response.data;
      yield put(setAuthToken(token));
      setAuthorizationToken(token);
      const loginResponse: any = yield userLogin(request.payload);

      if (!!loginResponse && loginResponse?.status === Status.Success) {
        setAuthorizationToken(loginResponse?.data?.accessTokens?.token);
        yield put(setClientCompleteLoginFirstTime());
        yield put(loginSuccess(loginResponse?.data));
        if (
          loginResponse?.data?.userInfo?.role?.toLowerCase() === "tmf_admin"
        ) {
          setClientHeader(0);
          yield put(setClientCode(0));
          yield put(setClientName("All"));
        } else {
          setClientHeader(loginResponse?.data?.userInfo?.companyId);
          yield put(setClientCode(loginResponse?.data?.userInfo?.companyId));
          yield put(setClientName(loginResponse?.data?.userInfo?.companyName));
        }
        yield put(setClientRole(loginResponse?.data?.userInfo?.role));
        yield fetchAllClients();
      } else {
        setAuthorizationToken("");
        setClientHeader(0);
        yield put(loginFail(loginResponse?.message));
      }
    } else {
      setAuthorizationToken("");
      setClientHeader(0);
      yield put(setClientCompleteLoginFirstTime());
      yield put(loginFail(response?.data));
    }
  } catch (error: any) {
    setAuthorizationToken("");
    setClientHeader(0);
    yield put(setClientCompleteLoginFirstTime());
    yield put(loginFail(error.data));
  }
}

function* forgotPasswordRequestEx(request) {
  try {
    //yield put(forgotPasswordRequestPending());
    const response: any = yield forgotPasswordService(request.payload);
    console.log(response, "++++response", request.payload);
    if (!!response && response?.status === Status.Success) {
      //yield put(setClientRole(loginResponse?.data?.userInfo?.role));
      //yield fetchAllClients();
    } else {
      //yield put(forgotPasswordRequestFail(loginResponse?.data));
    }
  } catch (error: any) {
    //yield put(forgotPasswordRequestFail(error?.message));
  }
}

function* fetchAllClients() {
  try {
    const response = yield getAllWorkClients();
    if (!!response && response?.status === Status.Success) {
      let allSortedClients = response.data.sort((a, b)=> {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      })
      yield put(getAllWorkableClients(allSortedClients));
    } else {
      yield put(getAllWorkableClients([]));
    }
  } catch (error: any) {
    yield put(getAllWorkableClients([]));
  }
}

export default function* watcherSaga() {
  yield all([
    yield takeLatest(sagaActions.FETCH_DATA_SAGA, startupRequest),
    yield takeLatest(sagaActions.GET_ALL_WORKABLE_CLIENTS, fetchAllClients),
  ]);
}
