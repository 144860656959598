import {
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Moment from "moment";

import NoRecordFound from "../NoRecordFound/NoRecordFound";

const TimesheetTable = ({ timesheetList }) => {
  return (
    <Grid container xs={12} sx={{ mb: 2 }}>
      <Grid item xs={12}>
        <Card>
          <CardContent sx={{ display: "flex", width: "100%" }}>
            <Grid container xs={12} spacing={2}>
              <Grid container xs={12} sx={{ ml: 2, mr: 3, mt: 2 }}>
                {timesheetList.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableBody>
                        {timesheetList?.map((row) => (
                          <TableRow
                            key={row.workDate}
                            sx={{
                              td: {
                                border: 1,
                                borderColor: "#FFD96A",
                              },
                            }}
                          >
                            <TableCell
                              colSpan={2}
                              sx={{
                                borderColor: "#FFD96A",
                              }}
                            >
                              {`${row.day} I ${
                                row?.workDate
                                  ? Moment(row?.workDate).format("YYYY-MM-DD")
                                  : ""
                              }`}
                            </TableCell>
                            <TableCell
                              colSpan={6}
                              sx={{
                                borderColor: "#FFD96A",
                              }}
                            >
                              {row.desc}
                            </TableCell>
                            <TableCell
                              colSpan={2}
                              sx={{ borderColor: "#FFD96A" }}
                            >
                              {row.hours}
                              {parseInt(row.hours) > 1 ? ` hrs` : ` hr`}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <NoRecordFound />
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TimesheetTable;
