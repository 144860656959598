import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

//components
import DashboardLanding from "../modules/Dashboard/DashboardLanding/DashboardLanding";
import CompanyName from "../shared/components/CompanyName/CompanyName";
import ForgotPassword from "../shared/components/ForgotPassword/ForgotPassword";
import Info from "../shared/components/Info/Info";
import Login from "../shared/components/Login/Login";
import RegisterUser from "../shared/components/Registration/RegisterUser";
import VerifyRegistration from "../shared/components/VerifyRegisteration/VerifyRegistration";

import ApprovalDetails from "../modules/Approvals/ApprovalDetails";
import Approvals from "../modules/Approvals/Approvals";
import EditClient from "../modules/Client/EditClient";
import ProfileLanding from "../modules/Client/ProfileLanding/ProfileLanding";
import InvoiceList from "../modules/Invoice/InvoiceList";
import EditMember from "../modules/People/EditMember";
import PeopleList from "../modules/People/PeopleList";
import TeamMemberApprovals from "../modules/TeamMemberLeave/TeamMemberApprovals";
import Timesheet from "../modules/TimeSheets/Timesheet";
import TimesheetDetails from "../modules/TimeSheets/TimesheetDetails";
import NotFound from "../shared/components/NotFound/NotFound";

//utils
import ClientOnboarding from "../modules/Dashboard/ClientOnboarding/ClientOnboarding";
import CandidateListMobile from "../modules/Hire/CandidateListMobile";
import CandidateListV2 from "../modules/Hire/CandidateListV2";
import EditJobPost from "../modules/Hire/EditJobPost";
import HireContainer from "../modules/HireV2/HireContainer";
import NewJobContainer from "../modules/HireV2/newHire/NewJobContainer";
import ClientsInvoices from "../modules/Invoice/ClientsInvoices";
import AddMember from "../modules/People/AddMember";
import Performance from "../modules/Performance/Performance";
import AnnualFeedbackDetails from "../modules/Performance/annual/detail/AnnualFeedbackDetails";
import PerformanceDetail from "../modules/Performance/detail/PerformanceDetail";
import PerformanceMultipleFeedback from "../modules/Performance/emailMultipleFeedback/MultipleFeedbackContainer";
import TasksContainer from "../modules/Tasks/TasksContainer";
import Users from "../modules/Users/Users";
import ResetPassword from "../shared/components/ForgotPassword/ResetPassword";
import { PrivateRoute } from "../utils/PrivateRoute/PrivateRoute";

import Attendance from "../modules/Attendance/Attendance";
import Opportunities from "../modules/Opportunities/Opportunities";
import OpportunityDetails from "../modules/Opportunities/OpportunityDetails";
import Shop from "../modules/Shop/Shop";
import TeamMemberWeeklyView from "../modules/TeamMemberTimesheet/TeamMemberTimesheet/TeamMemberTimesheetWeeklyDetail";
import TimesheetTimelogTabs from "../modules/TeamMemberTimesheet/TimesheetTimelogTabs";
import { userAccess } from "../utils/CommonFunction";

//Lazy loaded components
const LazyClientLanding = React.lazy(
  () => import("../modules/Client/ClientLanding")
);

const Rountes: React.FunctionComponent = () => {
  const { isTeamMember } = userAccess();
  const TimeheetView = () => {
    return isTeamMember ? <TimesheetTimelogTabs /> : <Timesheet />;
  };

  const ApprovalView = () => {
    return isTeamMember ? <TeamMemberApprovals /> : <Approvals />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Register" element={<RegisterUser />} />
        <Route path="/ConfirmAccount" element={<VerifyRegistration />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/info" element={<Info />} />
        <Route path="/company-name" element={<CompanyName />} />

        <Route
          path="/dashboard"
          element={
            <PrivateRoute>

              <DashboardLanding />
            </PrivateRoute>
          }
        />

        <Route
          path="/client-onboarding"
          element={
            <PrivateRoute>

              <ClientOnboarding />
            </PrivateRoute>
          }
        />

        <Route
          path="/client"
          element={
            <PrivateRoute>
              <React.Suspense fallback="Loading...">
                <LazyClientLanding />
              </React.Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-client"
          element={
            <PrivateRoute>
              <EditClient />
            </PrivateRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <PrivateRoute>

              <ProfileLanding />
            </PrivateRoute>
          }
        />

        <Route
          path="/team-members"
          element={
            <PrivateRoute>

              <PeopleList />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-member"
          element={
            <PrivateRoute>

              <AddMember />
            </PrivateRoute>
          }
        />

        <Route
          path="/edit-job"
          element={
            <PrivateRoute>

              <EditJobPost />
            </PrivateRoute>
          }
        />

        <Route
          path="/edit-member"
          element={
            <PrivateRoute>

              <EditMember />
            </PrivateRoute>
          }
        />

        <Route
          path="/hire"
          element={
            <PrivateRoute>
              <HireContainer />
            </PrivateRoute>
          }
        />
        <Route
          path="/hire/:id"
          element={
            <PrivateRoute>
              <CandidateListV2 />
            </PrivateRoute>
          }
        />

        <Route
          path="/hire/new"
          element={
            <PrivateRoute>
              <NewJobContainer />
            </PrivateRoute>
          }
        />

        <Route
          path="/hire/new"
          element={
            <PrivateRoute>
              <NewJobContainer />
            </PrivateRoute>
          }
        />

        <Route
          path="/candidate-details"
          element={
            <PrivateRoute>
              <CandidateListMobile />
            </PrivateRoute>
          }
        />
        <Route
          path="/leave"
          element={
            <PrivateRoute>
              <ApprovalView />
            </PrivateRoute>
          }
        />

        <Route
          path="/leave/leave-details/:id"
          element={
            <PrivateRoute>
              <ApprovalDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/timesheets"
          element={
            <PrivateRoute>
              <TimeheetView />
            </PrivateRoute>
          }
        />
        <Route
          path="/timesheets/timesheet-details/:id"
          element={
            <PrivateRoute>
              <TimesheetDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/timesheets/timesheet-details"
          element={
            <PrivateRoute>
              <TeamMemberWeeklyView />
            </PrivateRoute>
          }
        />
        <Route
          path="/performance"
          element={
            <PrivateRoute>
              <Performance />
            </PrivateRoute>
          }
        />

        <Route
          path="/performance/:id"
          element={
            <PrivateRoute>
              <PerformanceDetail />
            </PrivateRoute>
          }
        />

        {/* Email link feedback: Multiple Employee feedback */}
        <Route
          path="/performance/feedback"
          element={<PerformanceMultipleFeedback />}
        />
        {/* Annual feedback clients and role */}
        <Route
          path="/performance/:id/annual/:year"
          element={
            <PrivateRoute>
              <AnnualFeedbackDetails />
            </PrivateRoute>
          }
        />

        <Route path="/tasks" element={<PrivateRoute>
          <TasksContainer />
        </PrivateRoute>} />

        <Route
          path="/invoices"
          element={
            <PrivateRoute>

              <InvoiceList />
            </PrivateRoute>
          }
        />

        <Route
          path="/client-invoices"
          element={
            <PrivateRoute>

              <ClientsInvoices />
            </PrivateRoute>
          }
        />

        <Route
          path="/opportunities"
          element={
            <PrivateRoute>
              <Opportunities />
            </PrivateRoute>
          }
        />

        <Route
          path="/opportunities/:id"
          element={
            <PrivateRoute>
              <OpportunityDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/shop"
          element={
            <PrivateRoute>
              <Shop />
            </PrivateRoute>
          }
        />
        <Route
          path="/attendance"
          element={
            <PrivateRoute>
              <Attendance />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
export default Rountes;
