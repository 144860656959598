import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { memo, useState } from "react";
import * as yup from "yup"
import { userAccess } from "../../utils/CommonFunction";
import { addTaskComment } from "../../services/Tasks";
import { useAlert } from "react-alert";
import FormikInput from "../../shared/components/FormikInput";


const initialValues = {
    comment: ''
}

const validationSchema = yup.object({
    comment: yup.string().required('Comment is required')
})

const TaskCommentsModal = ({ showModal, closeModal }) => {
    const [isLoading ,setIsLoading] = useState(false);
    const alert = useAlert()

    const postAddComment =async (values) => {
        setIsLoading(true)
        const request = {
            comment: values.comment,
            id: 0,
            taskId: showModal,
            commentedBy: userAccess().userId,

        }
        try {
            const result = await addTaskComment(request)
            alert.success('Comment added successfully');
            closeModal()
        } catch (e) {
            alert.error('Error adding comment ' + e)
        }
        setIsLoading(false)

    }

    return (
        <Dialog open={showModal} onClose={closeModal} maxWidth='md' sx={{

        }}>
            <DialogTitle variant="h4" fontWeight={400} fontSize={'32px'} justifyContent={'center'} display={'flex'} alignItems={'center'} flexDirection={'column'}>
                <Typography fontSize={'24px'} lineHeight={'32px'} fontWeight={700}>Comment</Typography>
                <DialogContent sx={{
                py: 0,
                pb: '1rem',
                width: '30vw'
            }}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize
                        onSubmit={(data) => postAddComment(data)}
                    >
                        {(formikProps) => (
                            <Form>
                                <Grid container spacing={2} mt={1}>
                                    <Grid item xs={12} md={12}>
                                        <FormikInput
                                            label="Comment Here*"
                                            name="comment"
                                            formikProps={formikProps}
                                            options={undefined}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    sx={{ mt: 0, mb: 2 }}
                                >
                                    <Button
                                        variant="text"
                                        sx={{ mt: 2 }}
                                        onClick={closeModal}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={isLoading}
                                        variant="contained"
                                        sx={{ mt: 2 }}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </DialogTitle>
        </Dialog>
    )
}

export default memo(TaskCommentsModal)