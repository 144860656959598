const DashboardIcon = ({ active }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.11111 7.18518C3.96563 7.18518 3.03704 6.25657 3.03704 5.11111C3.03704 3.96563 3.96563 3.03704 5.11111 3.03704C6.25657 3.03704 7.18518 3.96563 7.18518 5.11111C7.18518 6.25657 6.25657 7.18518 5.11111 7.18518ZM12.8889 7.18518C11.7434 7.18518 10.8148 6.25657 10.8148 5.11111C10.8148 3.96563 11.7434 3.03704 12.8889 3.03704C14.0343 3.03704 14.963 3.96563 14.963 5.11111C14.963 6.25657 14.0343 7.18518 12.8889 7.18518ZM5.11111 14.963C3.96563 14.963 3.03704 14.0343 3.03704 12.8889C3.03704 11.7434 3.96563 10.8148 5.11111 10.8148C6.25657 10.8148 7.18518 11.7434 7.18518 12.8889C7.18518 14.0343 6.25657 14.963 5.11111 14.963ZM12.8889 14.963C11.7434 14.963 10.8148 14.0343 10.8148 12.8889C10.8148 11.7434 11.7434 10.8148 12.8889 10.8148C14.0343 10.8148 14.963 11.7434 14.963 12.8889C14.963 14.0343 14.0343 14.963 12.8889 14.963ZM2 5.11111C2 3.39289 3.39289 2 5.11111 2C6.82933 2 8.22222 3.39289 8.22222 5.11111C8.22222 6.82933 6.82933 8.22222 5.11111 8.22222C3.39289 8.22222 2 6.82933 2 5.11111ZM9.77778 5.11111C9.77778 3.39289 11.1707 2 12.8889 2C14.6071 2 16 3.39289 16 5.11111C16 6.82933 14.6071 8.22222 12.8889 8.22222C11.1707 8.22222 9.77778 6.82933 9.77778 5.11111ZM5.11111 9.77778C3.39289 9.77778 2 11.1707 2 12.8889C2 14.6071 3.39289 16 5.11111 16C6.82933 16 8.22222 14.6071 8.22222 12.8889C8.22222 11.1707 6.82933 9.77778 5.11111 9.77778ZM9.77778 12.8889C9.77778 11.1707 11.1707 9.77778 12.8889 9.77778C14.6071 9.77778 16 11.1707 16 12.8889C16 14.6071 14.6071 16 12.8889 16C11.1707 16 9.77778 14.6071 9.77778 12.8889Z"
        className="fill-color"
      />
    </svg>
  );
};

export default DashboardIcon;
