import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Typography,
  useMediaQuery
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Moment from "moment";
import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import RowSkeleton from "../../shared/components/Skeletons/RowSkeleton";
import TableSkeleton from "../../shared/components/Skeletons/TableSkeleton";
import TextSkeleton from "../../shared/components/Skeletons/TextSkeleton";
import {
  getTimeSheetDetails
} from "../TimeSheets/Timesheet.helper";

const MemberTimesheetDetail: React.FunctionComponent<any> = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const [timesheetList, setTimesheetList] = useState<any>([]);
  const { isTimesheetDetailLoading, timesheetDetail } = useSelector(
    //@ts-ignore
    (state) => state.timesheet
  );

  useEffect(
    useCallback(() => {
      if (timesheetDetail?.tsArrRecords?.length > 0) {
        setTimesheetList(timesheetDetail?.tsArrRecords);
      }
    }, [timesheetDetail?.tsArrRecords]),
    [timesheetDetail?.tsArrRecords]
  );

  useEffect(
    useCallback(() => {
      getTimeSheetDetails(dispatch, { timesheetRecordId: id });
    }, [id]),
    [id]
  );

  return (
    <>
      <Grid container component="main">
        <Container maxWidth="xl">
          <Box mt={1} mb={2} sx={{ backgroundColor: "#CCD5FC" }}>
            <Grid container spacing={2} alignItems="center">
              {isMobile ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", p: 2 }}
                    alignItems={"center"}>
                    <ArrowBackIosIcon
                      sx={{ ml: 2, mr: 1 }}
                      onClick={props.back}
                    />
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                      sx={{
                        display: "inline-flex",
                        mx: 1,
                        width: 32,
                        height: 32,
                      }}
                    />
                    <div style={{ marginLeft: 10 }}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {timesheetDetail?.timesheetRecordOwner?.name}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: "normal" }}>
                        {`${Moment(timesheetDetail?.fromDate, "MM/DD/YYYY").format(
                          "ll (ddd)"
                        )} to ${Moment(timesheetDetail?.toDate, "MM/DD/YYYY").format(
                          "ll (ddd)"
                        )}`}
                      </Typography>
                    </div>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{ display: "flex", p: 2 }}
                    alignItems={"center"}>
                    <ArrowBackIosIcon
                      sx={{ ml: 2, mr: 1 }}
                      onClick={props.back}
                    />

                    {isTimesheetDetailLoading ? (
                      <Grid item xs={6}>
                        <TextSkeleton />
                      </Grid>
                    ) : (
                      <Typography variant="h5" sx={{ fontWeight: "normal" }}>
                        {timesheetDetail?.timesheetRecordOwner?.name}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{ display: "flex", p: 2 }}
                    justifyContent="flex-end">
                    {isTimesheetDetailLoading ? (
                      <Grid item xs={6}>
                        <TextSkeleton />
                      </Grid>
                    ) : (
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "normal", mr: 2 }}>
                        {`Project Manager: ${timesheetDetail?.timesheetApproverInfo?.name}`}
                      </Typography>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          {isTimesheetDetailLoading ? (
            <Grid container xs={12} sx={{ mb: 2, mt: 2 }}>
              <Grid item xs={12}>
                <RowSkeleton rowHeight={150} />
              </Grid>
            </Grid>
          ) : (
            <Grid container xs={12} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <Card sx={{ boxShadow: 10 }}>
                  <CardContent sx={{ display: "flex", width: "100%" }}>
                    <Grid container xs={12} spacing={2}>
                      <Grid item xs={12} sm={6} md={5} sx={{ mt: -4 }}>
                        <Grid container xs={12}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Grid container xs={12} sx={{ display: "flex" }}>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                sx={{
                                  ml: -2,
                                  textAlign: "center",
                                  alignItems: "center",
                                }}>
                                <img
                                  src="/images/time.png"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                    alignItems: "center",
                                  }}
                                  alt="time"
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={9}
                                sx={{ mt: 1, mb: 2, ml: 2 }}>
                                <Box>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: "600" }}>
                                    {"Total Hours"}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography variant="h6">
                                    {timesheetDetail.totalHours}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sx={{ mb: 1 }}>
                            <Grid container xs={12} sx={{ display: "flex" }}>
                              <Grid item xs={12} sm={6} md={3}>
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: "600" }}>
                                  {`Started on `}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <Typography variant="caption">
                                  {timesheetDetail?.fromDate
                                    ? Moment(timesheetDetail?.fromDate).format(
                                        "YYYY-MM-DD"
                                      )
                                    : ""}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container xs={12} sx={{ display: "flex" }}>
                              <Grid item xs={12} sm={6} md={3}>
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: "600" }}>
                                  {"Project name"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <Typography variant="caption">
                                  {timesheetDetail?.projectName}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6} md={7} sx={{ mt: -1 }}>
                        <Grid container xs={12}>
                          <Grid item xs={12} sx={{ display: "flex", mb: 2 }}>
                            <Grid container xs={12} sx={{ display: "flex" }}>
                              <Grid item xs={12} md={3}>
                                <Typography
                                  variant="body2"
                                  sx={{ mb: 2, fontWeight: "600" }}>
                                  {"Client"}
                                </Typography>
                                <Typography variant="caption">
                                  {timesheetDetail?.client}
                                </Typography>
                              </Grid>
                              <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ width: "1px", height: 60, mr: 5 }}
                              />
                              <Grid item xs={12} md={3}>
                                <Typography
                                  variant="body2"
                                  sx={{ mb: 2, fontWeight: "600" }}>
                                  {"Approver"}
                                </Typography>
                                <Typography variant="caption">
                                  {timesheetDetail?.timesheetApproverInfo?.name}
                                </Typography>
                              </Grid>

                              <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ width: "1px", height: 60, mr: 5 }}
                              />

                              <Grid item xs={12} md={3}>
                                <Typography
                                  variant="body2"
                                  sx={{ mb: 2, fontWeight: "600" }}>
                                  {"Billing status"}
                                </Typography>
                                <Typography variant="caption">
                                  {timesheetDetail?.status}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sx={{ display: "flex" }}>
                            <Grid item xs={12} sm={6} md={2}>
                              <Typography variant="caption">
                                {"Description"}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={10} sx={{ pr: 3 }}>
                              <Typography variant="caption">
                                {timesheetDetail?.description}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
          {isTimesheetDetailLoading ? (
            <Grid container xs={12} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <TableSkeleton />
              </Grid>
            </Grid>
          ) : (
            <Grid container xs={12} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <Card sx={{ boxShadow: 10 }}>
                  <CardContent sx={{ display: "flex", width: "100%" }}>
                    <Grid container xs={12} spacing={2}>
                      <Grid container xs={12} sx={{ ml: 2, mr: 3 }}>
                        {timesheetList.length > 0 ? (
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table">
                              <TableBody>
                                {timesheetList?.map((row) => (
                                  <TableRow
                                    key={row.workDate}
                                    sx={{
                                      td: {
                                        border: 1,
                                        borderColor: "#FFD96A",
                                      },
                                    }}>
                                    <TableCell
                                      colSpan={2}
                                      sx={{
                                        borderColor: "#FFD96A",
                                      }}>
                                      {`${row.day} I ${
                                        row?.workDate
                                          ? Moment(row?.workDate).format(
                                              "YYYY-MM-DD"
                                            )
                                          : ""
                                      }`}
                                    </TableCell>
                                    <TableCell
                                      colSpan={6}
                                      sx={{
                                        borderColor: "#FFD96A",
                                      }}>
                                      {row.desc}
                                    </TableCell>
                                    <TableCell
                                      colSpan={2}
                                      sx={{ borderColor: "#FFD96A" }}>
                                      {row.hours}
                                      {parseInt(row.hours) > 1 ? ` hrs` : ` hr`}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ) : (
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyItems: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              justifySelf: "center",
                              alignContent: "center",
                              alignSelf: "center",
                              textAlign: "center",
                            }}>
                            <Typography
                              variant="h6"
                              sx={{
                                justifyItems: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                justifySelf: "center",
                                alignContent: "center",
                                alignSelf: "center",
                                textAlign: "center",
                              }}>
                              {"No Record found"}
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Container>
      </Grid>
    </>
  );
};

export default MemberTimesheetDetail;
