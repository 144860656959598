import { Box, IconButton, Typography } from "@mui/material";
import moment from "moment";
import RowMenu from "../HireV2/common/RowMenu";
import AssigneeUser from "./Components/AssigneeUser";
import PriorityBadge from "./Components/PriorityBadge";
import TaskButton from "./Components/TaskButton";

export const makeColumns = (handleMenuClick, handleReporterClick, handlePriorityClick, handleOptionClick, handleCommentClick) => {


    return [{
        field: "title",
        headerName: "Title",
        disableColumnMenu: true,
        minWidth: 280,
        sortable: false,
        
        flex: 1,
        renderCell: (params: any) => {
            return (
                    <Typography whiteSpace={'normal'}>
                        {params.row.title}
                    </Typography>
            );
        },
    },
    {
        field: "dueDate",
        headerName: "Due Date",
        disableColumnMenu: true,
        sortable: false,
        align: "center",
        minWidth: 150,
        flex: 1,
        headerAlign: "center",
        renderCell: (params) => {

            return (
                <Box>

                    <Typography>{moment(params.row.dueDate ? params.row.dueDate : undefined).format('DD-MM-YYYY')}</Typography>

                </Box>
            );
        },
    },
    {
        field: "reporter",
        headerName: "Assigned To",
        disableColumnMenu: true,
        sortable: false,
        align: "center",
        minWidth: 150,
        flex: 1,
        headerAlign: "center",
        renderCell: (params) => {
            
            return (
               <AssigneeUser assigneeUser={params.row.assigneeUser} handleReporterClick={() => {
                handleReporterClick(params.row)
               }}/>
            );
        },
    },
    {
        field: "priority",
        headerName: "",
        disableColumnMenu: true,
        sortable: false,
        align: "center",
        minWidth: 150,
        flex: 1,
        headerAlign: "center",
        renderCell: (params) => {
            const actions = params.row.actionsJson
            let parsedActions: any = []
            if (actions) {
                parsedActions = JSON.parse(actions)
            }
            if (Array.isArray(parsedActions) && parsedActions.length) {
                return <TaskButton task={params.row} action={parsedActions[0]} refetchTasks={handleOptionClick}/>
            }
            else if (typeof parsedActions === 'object' && Object.keys(parsedActions).length > 0) {
                return <TaskButton task={params.row} action={parsedActions} refetchTasks={handleOptionClick}/>
            }
            else {
                return <Box><PriorityBadge badgeType={params.row.priority.toLowerCase()} /></Box>
            }

        },
    },
    {
        field: "status",
        headerName: "",
        disableColumnMenu: true,
        sortable: false,
        align: "center",
        minWidth: 150,
        flex: 1,
        headerAlign: "center",
        renderCell: (params) => {
            const actions = params.row.actionsJson
            let parsedActions: any = []
            if (actions) {
                parsedActions = JSON.parse(actions)
            }
            if (Array.isArray(parsedActions) && parsedActions.length > 1) {
                return <TaskButton task={params.row} action={parsedActions[1]} refetchTasks={handleOptionClick}/>
            }
            else {
                return null
            }
        },
    },


    {
        field: "comments",
        headerName: "",
        disableColumnMenu: true,
        sortable: false,
        headerAlign: "right",
        align: "center",
        width: 60,
        renderCell: (params) => {
            return (
                <Box>
                   <IconButton onClick={(e) => {
                            e.stopPropagation()
                            handleCommentClick(params.row)
                        }}>
                   <img
                        src="/images/comment.png"
                        alt="icon"
                        width={20}
                        height={20}
                        
                        
                    />
                   </IconButton>
                </Box>
            );
        },
    },
    {
        field: "action",
        headerName: "",
        disableColumnMenu: true,
        sortable: false,
        headerAlign: "right",
        align: "left",
        width: 60,
        renderCell: (params) => {
            return (
                <Box>
                    <RowMenu
                        isOpenRequest={false}
                        isTaskItem
                        onMenuClick={(action) => handleMenuClick(action, params.row)}
                    />
                </Box>
            );
        },
    },

    ]
}