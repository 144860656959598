import { Alert, Paper, Typography } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomizedDataGrid from "../../../shared/components/CustomizedDataGrid";

const AnnualFeedbackTable = ({
  handlePageChange,
  handleSort,
  handlePagesizeChange,
}) => {
  const {
    data = [],
    isLoading,
    error,
    pageNumber,
    pageSize,
    total,
  } = useSelector(
    //@ts-ignore
    (state) => state.performance.allEmployees
  );

  const navigate: any = useNavigate();

  const handleRedirectFeedbackDetails = (id, item) => {
    navigate(`/performance/${id}/annual/${new Date().getFullYear() - 1}`, {
      state: { item, pageSize, pageNumber },
    });
  };

  const isRowSelectable = (params) => {
    return params.row.clientId !== 0;
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div className="performance-table-avatar-name">
            <span className="row-names">{params.row.name}</span>
          </div>
        );
      },
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "client",
      headerName: "Client",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "lastYearFeedBackSubmittedDate",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          params.row.isLastYearFeedbackSubmitted &&
          moment(params.row.lastYearFeedBackSubmittedDate).format("MM/DD/YYYY")
        );
      },
    },
    {
      field: "isLastYearFeedbackSubmitted",
      headerName: "",
      width: 240,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const isSelected = isRowSelectable(params);

        if (!isSelected) return <></>;

        return params.row.isLastYearFeedbackSubmitted ? (
          <Typography
            variant="caption"
            color="#00E08C"
            onClick={() =>
              handleRedirectFeedbackDetails(params.row.id, params.row)
            }
            sx={{ cursor: "pointer", fontSize: "14px" }}
          >
            Annual feedback submitted
          </Typography>
        ) : (
          <Typography
            variant="caption"
            color="#002DFF"
            onClick={() =>
              handleRedirectFeedbackDetails(params.row.id, params.row)
            }
            sx={{ cursor: "pointer", fontSize: "14px" }}
          >
            Give annual feedback
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <Alert icon={false} severity="warning" className="performance-alert-bg">
        It's time for annual performance reviews! The purpose of annual reviews
        is to
        <br />
        evaluate your team's performance, provide feedback, set goals, and
        discuss career development.
      </Alert>
      <Paper sx={{ height: "calc(100% - 85px)", width: "100%", padding: 0 }}>
        <CustomizedDataGrid
          rows={data}
          columns={columns}
          totalRowCount={total}
          pageNumber={pageNumber}
          pageSize={pageSize}
          rowHeight={60}
          isDataLoading={isLoading}
          handlePageChange={handlePageChange}
          onPageSizeChange={handlePagesizeChange}
        />
      </Paper>
    </>
  );
};

export default AnnualFeedbackTable;
