import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  yellowContainer: {
    height: 66,
    backgroundColor: "#FFFAEA",
    borderRadius: 1,
  },
  whiteContainer: {
    height: 66,
    backgroundColor: "#FFF",
    borderRadius: 1,
  },
  headerGrid: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignSelf: "center",
  },
  headerTypoHeading: {
    fontSize: 13,
    fontWeight: "600",
    align: "center",
  },
  headerTypo: {
    fontSize: 11,
    fontWeight: "400",
    align: "center",
  },
}));

export { styles };
