import { all } from "redux-saga/effects";

import approvalSaga from "./Approval/approvalSaga";
import clientSaga from "./Client/clientSaga";
import companySaga from "./Company/companySaga";
import dashboardSaga from "./Dashboard/dashboardSaga";
import hireSaga from "./Hire/hireSaga";
import invoiceSaga from "./Invoice/invoiceSaga";
import loginSaga from "./Login/loginSaga";
import peopleSaga from "./People/peopleSaga";
import performanceSaga from "./Performance/performanceSaga";
import timesheetSaga from "./Timesheet/timesheetSaga";
import userSaga from "./User/userSaga";
import opportunitiesSaga from "./Opportunities/opportunitiesSaga";
import attendanceSaga from "./Attendance/attendanceSaga";

export default function* root() {
  yield all([
    loginSaga(),
    peopleSaga(),
    userSaga(),
    companySaga(),
    hireSaga(),
    clientSaga(),
    dashboardSaga(),
    approvalSaga(),
    invoiceSaga(),
    timesheetSaga(),
    performanceSaga(),
    opportunitiesSaga(),
    attendanceSaga(),
  ]);
}
