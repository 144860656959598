import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils/dateFormatter";
import RowMenu from "../common/RowMenu";

const makeOpenColumns = (handleMenuClick) => [
  {
    field: "title",
    headerName: "Request for",
    disableColumnMenu: true,
    minWidth: 280,
    flex: 1,
    renderCell: (params: any) => {
      return (
        <Box>
          <Link
            to={`/hire/${params.row.job_Id}`}
            className="fake-link hire-row"
          >
            <Typography variant="body2" fontWeight={600}>
              {params.row.job_Title}
            </Typography>
            <Typography variant="caption">
              Client: {params.row.company_Name} ({params.row.createdAt})
            </Typography>
          </Link>
        </Box>
      );
    },
  },
  {
    field: "appliedCount",
    headerName: "Applied",
    disableColumnMenu: true,
    sortable: false,
    align: "center",
    minWidth: 100,
    flex: 1,
    headerAlign: "center",
    renderCell: (params: any) => {
      const count =
        _.find(params.row.stages, { code: "APPLIED" })?.count || "00";
      return (
        <Box>
          <Link
            to={`/hire/${params.row.job_Id}?stage=applied`}
            className="fake-link hire-row"
          >
            <Typography>{count}</Typography>
            <div className="dummy-space" />
          </Link>
        </Box>
      );
    },
  },
  {
    field: "screenedByTT",
    headerName: "Screened by TT",
    disableColumnMenu: true,
    sortable: false,
    align: "center",
    minWidth: 150,
    flex: 1,
    headerAlign: "center",
    renderCell: (params) => {
      const count =
        _.find(params.row.stages, { code: "SOURCED" })?.count || "00";
      return (
        <Box>
          <Link
            to={`/hire/${params.row.job_Id}?stage=sourced`}
            className="fake-link hire-row align-center"
          >
            <Typography>{count}</Typography>
            <Typography variant="caption">
              Expected joining date: {params.row.exceptedJoiningDate || "NA"}
            </Typography>
          </Link>
        </Box>
      );
    },
  },
  {
    field: "shortList",
    headerName: "Shortlist",
    disableColumnMenu: true,
    sortable: false,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
    flex: 1,
    renderCell: (params: any) => {
      const count =
        _.find(params.row.stages, { code: "SHORTLIST" })?.count || "00";
      return (
        <Box>
          <Link
            to={`/hire/${params.row.job_Id}?stage=shortlist`}
            className="fake-link hire-row"
          >
            <Typography>{count}</Typography>
            <div className="dummy-space" />
          </Link>
        </Box>
      );
    },
  },
  {
    field: "endorsement",
    headerName: "Client endorsement",
    disableColumnMenu: true,
    sortable: false,
    align: "center",
    headerAlign: "center",
    minWidth: 220,
    flex: 1,
    renderCell: (params: any) => {
      const count =
        _.find(params.row.stages, { code: "CV_SENT" })?.count || "00";
      return (
        <Box>
          <Link
            to={`/hire/${params.row.job_Id}?stage=cv_sent`}
            className="fake-link hire-row"
          >
            <Typography>{count}</Typography>
            <div className="dummy-space" />
          </Link>
        </Box>
      );
    },
  },
  {
    field: "clientInterviewCount",
    headerName: "Client interview",
    disableColumnMenu: true,
    sortable: false,
    align: "center",
    headerAlign: "center",
    minWidth: 160,
    flex: 1,
    renderCell: (params: any) => {
      const count =
        _.find(params.row.stages, { code: "INTERVIEW" })?.count || "00";
      return (
        <Box>
          <Link
            to={`/hire/${params.row.job_Id}?stage=interview`}
            className="fake-link hire-row"
          >
            <Typography>{count}</Typography>
            <div className="dummy-space" />
          </Link>
        </Box>
      );
    },
  },
  {
    field: "offserSentCount",
    headerName: "Offer sent",
    disableColumnMenu: true,
    sortable: false,
    align: "center",
    headerAlign: "center",
    minWidth: 120,
    flex: 1,
    renderCell: (params) => {
      const count = _.find(params.row.stages, { code: "OFFER" })?.count || "00";
      return (
        <Box>
          <Link
            to={`/hire/${params.row.job_Id}?stage=offer`}
            className="fake-link hire-row align-center"
          >
            <Typography>{count}</Typography>
            <Typography variant="caption">
              Expected salary range: {params.row.salaryRange || "NA"}
            </Typography>
          </Link>
        </Box>
      );
    },
  },
  {
    field: "offerAcceptedCount",
    headerName: "Offer accepted",
    disableColumnMenu: true,
    sortable: false,
    align: "center",
    headerAlign: "center",
    minWidth: 240,
    flex: 1,
    renderCell: (params: any) => {
      const count =
        _.find(params.row.stages, { code: "PLACED" })?.count || "00";
      return (
        <Box>
          <Link
            to={`/hire/${params.row.job_Id}?stage=placed`}
            className="fake-link hire-row"
          >
            <Typography>{count}</Typography>
            <div className="dummy-space" />
          </Link>
        </Box>
      );
    },
  },
  {
    field: "action",
    headerName: "",
    disableColumnMenu: true,
    sortable: false,
    headerAlign: "right",
    align: "left",
    width: 60,
    renderCell: (params) => {
      return (
        <Box>
          <RowMenu
            isOpenRequest
            onMenuClick={(action) => handleMenuClick(action, params.row)}
          />
        </Box>
      );
    },
  },
];

const makeClosedColumns = (handleMenuClick) => [
  {
    field: "title",
    headerName: "Request for",
    disableColumnMenu: true,
    minWidth: 280,
    flex: 1,
    renderCell: (params: any) => {
      return (
        <Box>
          <Link
            to={`/hire/${params.row.job_Id}`}
            className="fake-link hire-row"
          >
            <Typography variant="body2" fontWeight={600}>
              {params.row.job_Title}
            </Typography>
            <Typography variant="caption">
              Client: {params.row.company_Name} ({params.row.createdAt})
            </Typography>
          </Link>
        </Box>
      );
    },
  },
  {
    field: "experience",
    headerName: "Experience",
    disableColumnMenu: true,
    sortable: false,
    align: "center",
    minWidth: 100,
    flex: 1,
    headerAlign: "center",
  },
  {
    field: "candidates",
    headerName: "Candidate Name",
    disableColumnMenu: true,
    sortable: false,
    align: "center",
    minWidth: 150,
    flex: 1,
    headerAlign: "center",
  },
  {
    field: "modifiedOn",
    headerName: "Completed date",
    disableColumnMenu: true,
    sortable: false,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
    flex: 1,
    valueFormatter: (params) => {
      return params.value
        ? formatDate(params.value.toString(), "DD/MM/YYYY HH:mm:ss")
        : "NA";
    },
  },
  {
    field: "action",
    headerName: "",
    disableColumnMenu: true,
    sortable: false,
    headerAlign: "right",
    align: "left",
    width: 60,
    renderCell: (params) => {
      return (
        <Box>
          <RowMenu
            isClosedRequest
            onMenuClick={(action) => handleMenuClick(action, params.row)}
          />
        </Box>
      );
    },
  },
];

const makeOnHoldColumns = (handleMenuClick) => [
  {
    field: "job_Title",
    headerName: "Request for",
    disableColumnMenu: true,
    minWidth: 280,
    flex: 1,
  },
  {
    field: "reason",
    headerName: "Reason for cancellation",
    disableColumnMenu: true,
    minWidth: 280,
    flex: 1,
  },
  {
    field: "client",
    headerName: "Client",
    disableColumnMenu: true,
    minWidth: 280,
    flex: 1,
  },
  {
    field: "cancelledOn",
    headerName: "Cancelled On",
    disableColumnMenu: true,
    minWidth: 280,
    flex: 1,
  },
  {
    field: "cancelledBy",
    headerName: "Cancelled By",
    disableColumnMenu: true,
    minWidth: 280,
    flex: 1,
  },
  {
    field: "stage",
    headerName: "",
    disableColumnMenu: true,
    minWidth: 280,
    flex: 1,
  },
  {
    field: "action",
    headerName: "",
    disableColumnMenu: true,
    sortable: false,
    headerAlign: "right",
    align: "left",
    width: 60,
    renderCell: (params) => {
      return (
        <Box>
          <RowMenu
            isClosedRequest
            onMenuClick={(action) => handleMenuClick(action, params.row)}
          />
        </Box>
      );
    },
  },
];

export const getColumns = (columnType: string, handleMenuClick) => {
  switch (columnType) {
    case "Open":
    case "published":
    case "Offer":
    case "1st Interview":
    case "2nd Interview":
    case "Final Interview":
    case "CV sent":
    case "Internal Interview":
    case "Assessment":
    case "Raised":
      return makeOpenColumns(handleMenuClick);
    case "Closed":
    case "archived":
    case "Filled By Us":
    case "Client Pulled Role":
      return makeClosedColumns(handleMenuClick);
    case "On Hold":
    case "draft":
      return makeOpenColumns(handleMenuClick);
    default:
      return makeOnHoldColumns(handleMenuClick);
  }
};
