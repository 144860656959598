import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EmployeePerformance {
  id: string;
  employeeId: string;
  name: string;
  jobTitle: string;
  client: string;
  lastScore: number;
  averageScore: number;
  clientId: number;
}

interface AllEmployeesPerformanceState {
  data: EmployeePerformance[];
  searchableList: EmployeePerformance[];
  total: number;
  pageNumber: number;
  pageSize: number;
  isLoading: boolean;
  error: string | null;
}

const initialState: AllEmployeesPerformanceState = {
  data: [],
  searchableList: [],
  total: 0,
  pageNumber: 1,
  pageSize: 10,
  isLoading: false,
  error: null,
};

const performanceSlice = createSlice({
  name: "allEmployeesPerformance",
  initialState,
  reducers: {
    setAllEmployeesPerformanceSuccess(
      state,
      action: PayloadAction<{
        employees: EmployeePerformance[];
        paginationFilter: any;
        pageNumber: number;
        pageSize: number;
      }>
    ) {
      const data = action.payload?.employees || [];
      const paginationFilter = action.payload?.paginationFilter || {};

      const transformedData = data.map((employee) => ({
        ...employee,
        id: employee.employeeId,
      }));
      console.log(action.payload);
      state.data = transformedData;
      state.total = paginationFilter.count;
      state.pageNumber = action.payload.pageNumber;
      state.pageSize = action.payload.pageSize;
      state.isLoading = false;
      state.error = null;
    },
    pushEmployeesPerformanceSuccess(
      state,
      action: PayloadAction<{
        employees: EmployeePerformance[];
        paginationFilter: any;
        pageNumber: number;
      }>
    ) {
      const data = action.payload?.employees || [];
      const paginationFilter = action.payload?.paginationFilter || {};

      const transformedData = data.map((employee) => ({
        ...employee,
        id: employee.employeeId,
      }));

      if (action.payload.pageNumber == 1) {
        state.data = transformedData;
      } else {
        state.data = [...state.data, ...transformedData];
      }

      state.total = paginationFilter.count;
      state.pageNumber = action.payload.pageNumber;
      state.error = null;
    },
    setIsLoadingMobile(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
      if (state.isLoading) {
        state.error = null;
      }
    },
    setAllEmployeesPerformanceFailure(state, action: PayloadAction<string>) {
      state.data = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
      if (state.isLoading) {
        state.data = [];

        state.error = null;
      }
    },
    setSearchingList(state, action: PayloadAction<EmployeePerformance[]>) {
      state.searchableList = action.payload;
    },
  },
});

export const {
  setAllEmployeesPerformanceSuccess,
  pushEmployeesPerformanceSuccess,
  setAllEmployeesPerformanceFailure,
  setIsLoading,
  setIsLoadingMobile,
  setSearchingList,
} = performanceSlice.actions;

export default performanceSlice.reducer;
