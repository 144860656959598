import { useMediaQuery } from "@mui/material";
import "./Performance.scss";
import PerformanceDesktopView from "./PerformanceDesktopView";
import PerformanceMobileView from "./PerformanceMobileView";

const Performance = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  if (isMobile) {
    return <PerformanceMobileView />;
  }

  return <PerformanceDesktopView />;
};

export default Performance;
