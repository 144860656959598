import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EmployeePerformance {
    employeeId: string;
    name: string;
    jobTitle: string;
    client: string;
    lastScore: number;
    averageScore: number;
    clientId: number
}

interface MultipleEmployeesState {
    employees: EmployeePerformance[];
    submitBulkFeedbackInfo: {
        submitted: boolean;
        isLoading: boolean;
        error: string | null;
    },
    isLoading: boolean;
    error: string | null;
}

const initialState: MultipleEmployeesState = {
    employees: [],
    submitBulkFeedbackInfo: {
        submitted: false,
        isLoading: false,
        error: null,
    },
    isLoading: false,
    error: null,
};

const multipleEmployeesSlice = createSlice({
    name: "multipleEmployees",
    initialState,
    reducers: {
        setMultipleEmployeesSuccess(
            state,
            action: PayloadAction<EmployeePerformance[]>
        ) {
            state.isLoading = false;
            state.employees = action.payload;
        },
        setMultipleEmployeesFailure(
            state,
            action: PayloadAction<string>
        ) {
            state.isLoading = false;
            state.employees = [];
            state.error = action.payload;
        },
        setMultipleEmployeesLoading(state) {
            state.isLoading = true;
            state.employees = [];
        },
        setSubmitBulkFeedbackInfo(state, action: PayloadAction<any>) {
            state.submitBulkFeedbackInfo = action.payload;
        },
        setMultipleEmployeesError(state, action: PayloadAction<string>) {
            state.error = action.payload;
        },
    },
});

export const {
    setMultipleEmployeesSuccess,
    setMultipleEmployeesFailure,
    setMultipleEmployeesLoading,
    setSubmitBulkFeedbackInfo,
    setMultipleEmployeesError,
} = multipleEmployeesSlice.actions;

export default multipleEmployeesSlice.reducer;