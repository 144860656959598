import _ from "lodash";
import { all, put, takeLatest } from "redux-saga/effects";
import { Status } from "../../services/ApiStatus";
import {
  addAttendanceInOutTime,
  getAttendance,
} from "../../services/Attendance";
import { sagaActions } from "../sagaActions";
import {
  attendanceFail,
  attendancePending,
  attendanceSuccess,
  checkIncheckOutFail,
  checkIncheckOutPending,
  checkIncheckOutSuccess,
} from "./attendanceSlice";

function* getAttendanceData(payload: any = "") {
  try {
    if (!_.isUndefined(payload?.payload)) {
      yield put(attendancePending());
    }
    const response = yield getAttendance(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(attendanceSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(attendanceFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(attendanceFail(error?.message));
  }
}

function* addPunchInPuchOutTime(payload: any = "") {
  try {
    if (!_.isUndefined(payload?.payload)) {
      yield put(checkIncheckOutPending());
    }
    const response = yield addAttendanceInOutTime(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(checkIncheckOutSuccess(response.message));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(checkIncheckOutFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(checkIncheckOutFail(error?.message));
  }
}

export default function* watcherSaga() {
  yield all([
    yield takeLatest(sagaActions.GET_ATTENDANCE, getAttendanceData),
    yield takeLatest(sagaActions.ADD_CHECK_IN_CHECK_OUT, addPunchInPuchOutTime),
  ]);
}
