import { Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import dayjs from "dayjs";

import TimeSheetHeader from "./Header/TeamMemberTimesheetHeader";
import { TabPanel } from "../../shared/components/TabPanel/TabPanel";
import TeamMemberTimesheet from "./TeamMemberTimesheet/TeamMemberTimesheet";
import { useStyles } from "../TeamMemberLeave/styles";
import TimeLogTable from "./TimeLogTable/TimeLogTable";

const TimesheetTimelogTabs = () => {
  const classes = useStyles();
  const now = dayjs().format("MM-DD-YYYY");
  const [tabValue, setValue] = useState(0);
  const [timeSheetView, setTimeSheetView] = useState("List");
  const [openLogTime, setOpenLogTime] = useState(false);
  const [logTimeDate, setLogTimeDate] = useState(now);
  const handleChange = (_, newValue: number) => {
    setValue(newValue);
  };

  const handleLogTimeOpenClose = () => {
    setOpenLogTime(!openLogTime);
  };

  return (
    <Grid>
      <Grid pl={3} pr={3}>
        <TimeSheetHeader
          timeSheetView={timeSheetView}
          setTimeSheetView={setTimeSheetView}
          openLogTime={openLogTime}
          setOpenLogTime={setOpenLogTime}
          handleLogTimeOpenClose={handleLogTimeOpenClose}
          logTimeDate={logTimeDate}
          setLogTimeDate={setLogTimeDate}
          tabIndex={tabValue}
        />
        <Tabs
          value={tabValue}
          variant="fullWidth"
          onChange={handleChange}
          sx={{ backgroundColor: "#FFFFFF" }}
        >
          <Tab label="Time Log" className={classes.tabFonts} />
          <Tab label="Timesheets" className={classes.tabFonts} />
        </Tabs>
      </Grid>
      <TabPanel value={tabValue} index={0}>
        <TimeLogTable />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <TeamMemberTimesheet
          timeSheetView={timeSheetView}
          handleLogTimeOpenClose={handleLogTimeOpenClose}
          setLogTimeDate={setLogTimeDate}
        />
      </TabPanel>
    </Grid>
  );
};

export default TimesheetTimelogTabs;
