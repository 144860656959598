import Moment from "react-moment";
import { useStyles } from "./styles";

import { Typography, useMediaQuery } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STRINGS } from "../../../Strings";
import { setHireCandidateStaging } from "../../../redux/Hire/hireSlice";
import { OpenJobsCards } from "./OpenJobsCards";

const OpenJobs = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stages = props.jobsData[0]?.stages;
  const classes = useStyles();

  const isMobile = useMediaQuery(`(max-width: 760px)`);

  const getDetails = (id, edit = false, staging: any) => {
    dispatch(setHireCandidateStaging(stages));
    navigate("/job-details", {
      state: {
        jobShortCode: id,
        isPostedFromTMF: false,
        candidates: null,
        stage: "Interview",
        edit: edit,
        tab: "Candidates",
      },
    });
  };

  if (isMobile) {
    return <OpenJobsCards jobsData={props.jobsData} getDetails={getDetails} />;
  }

  return (
    <>
      <TableContainer>
        <Table sx={{ marginTop: 2 }}>
          <TableHead>
            <TableRow>
              {/* @ts-ignore */}
              <TableCell className={classes.tbl_header}>
                {STRINGS.Job}
              </TableCell>
              {!!stages &&
                stages?.map((data: any) => (
                  <TableCell
                    //@ts-ignore
                    className={classes.tbl_header}
                    key={data?.current_Stage_Name}>
                    {data?.current_Stage_Name}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props &&
              props.jobsData &&
              props?.jobsData?.map((row: any) => (
                <TableRow
                  key={row.job_Id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => getDetails(row.shortCode, false, row.stages)}>
                  <TableCell>
                    {row.job_Title}
                    <Typography variant="body2">
                      {row.department_Name} -{" "}
                      <Moment format="MM/DD/YYYY">
                        {row.first_Published_At}
                      </Moment>
                    </Typography>
                  </TableCell>
                  {row.stages?.map((data: any) => (
                    <TableCell
                      key={data?.current_Stage_Name}
                      sx={{ justifyContent: "center", textAlign: "center" }}>
                      {data?.stage_Count}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

OpenJobs.propTypes = {};

export default OpenJobs;
