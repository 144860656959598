import { Avatar, Box, Button, Card, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MobileSkeleton from "../../shared/components/Skeletons/MobileSkeleton";
import { leaveStatusColor } from "./Timesheet.helper";

type Props = {
  listData?: any[];
  totalPageCount: number;
  onLoadmore: any;
  currentPage: number;
  isLoading: boolean;
};

const ApprovalCard = ({ data, navigate }) => {
  return (
    <Card
      key={data.job_Id}
      onClick={() => {
        navigate(`/timesheets/timesheet-details/${data.timesheetRecordId}`);
      }}
      className="tbl-card"
      sx={{ minWidth: 275, marginBottom: 3, width: "100%" }}>
      <Grid container spacing={2} className="tbl-header">
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}>
          <Avatar
            alt={data.employeeName}
            src={data.profilePhotoUrl}
            sx={{ display: "inline-flex", mx: 0 }}
          />
          <Typography sx={{ ml: 1, mb: 2 }} variant="h6" component="div" noWrap>
            {data.employeeName}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="body2">
              {data.leavetype}{" "}
              {`(${data.daysTaken >= 1 ? "Full Day" : "Half Day"})`}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">
            Leave from <br />
            {data.from}
          </Typography>
        </Grid>
        <Grid item xs={6} justifyContent={"flex-end"} display={"flex"}>
          <Typography variant="body2">
            Leave to <br /> {data.to}
          </Typography>
        </Grid>
      </Grid>
      <Box className="tbl-body">
        <Grid container rowSpacing={6}>
          <Grid
            item
            xs={6}
            justifyContent={"flex-start"}
            display={"flex"}
            sx={{ ml: -1 }}>
            <Typography variant="body2">{data.reasonforleave}</Typography>
          </Grid>
          <Grid item xs={6} justifyContent={"flex-end"} display={"flex"}>
            <Typography
              variant="body1"
              sx={{ color: leaveStatusColor(data?.approvalStatus) }}>
              {data.approvalStatus}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

const TimesheetListMobile: React.FunctionComponent<Props> = ({
  isLoading,
  listData = [],
  totalPageCount,
  onLoadmore,
  currentPage,
}) => {
  const navigate = useNavigate();

  if (isLoading && currentPage == 0) {
    return <MobileSkeleton />;
  }
  console.log(listData, "++++data");
  return (
    <Box className="responsive-tbl">
      {listData?.map((data) => (
        <ApprovalCard data={data} key={data.teamMemberId} navigate={navigate} />
      ))}
      {totalPageCount && currentPage < totalPageCount ? (
        <Box
          justifyContent="center"
          display="flex"
          onClick={() => onLoadmore(currentPage + 1)}>
          <Button
            disabled={isLoading}
            variant="outlined"
            size="small"
            color="primary">
            Load More
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

export default TimesheetListMobile;
