import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { CLOSED_MENU, OPEN_MENU, TASK_MENU } from "../constants";

const RowMenu = ({
  isOpenRequest = true,
  isTaskItem = false,
  isOnHoldRequest = false,
  isClosedRequest = false,
  onMenuClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItems, setMenuItems] = useState<{ id: string; name: string }[]>(
    []
  );

  const handleClick = (event: any) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClick = (type) => {
    onMenuClick(type);
    handleClose();
  };

  useEffect(() => {
    if (isOpenRequest) {
      setMenuItems(OPEN_MENU);
    } else if (isOnHoldRequest) {
      setMenuItems(CLOSED_MENU);
    } else if (isClosedRequest) {
      setMenuItems(CLOSED_MENU);
    } else if (isTaskItem) {
      setMenuItems(TASK_MENU)
    }


  }, [isOpenRequest, isOnHoldRequest, isClosedRequest, isTaskItem]);

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.id} onClick={() => onClick(item.id)}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default RowMenu;
