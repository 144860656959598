import { Dispatch } from "@reduxjs/toolkit";
import {
  forgotPasswordRequestFail,
  forgotPasswordRequestPending,
  forgotPasswordRequestSuccess,
  resetPasswordRequestFail,
  resetPasswordRequestPending,
  resetPasswordRequestSuccess,
} from "../../../redux/Login/loginSlice";
import { sagaActions } from "../../../redux/sagaActions";
import { AlertContainer } from "react-alert";
import jwt_decode from "jwt-decode";
import {
  forgotPasswordService,
  getAuthToken,
  getUserRef,
  resetPasswordService,
} from "../../../services/OnBoarding";
import { Status } from "../../../services/ApiStatus";
import { setAuthorizationToken } from "../../../services/BaseApi";

export const forgotPassAction = async (
  creds,
  dispatch: Dispatch<any>,
  alert: AlertContainer
) => {
  try {
    dispatch(forgotPasswordRequestPending());
    const response = await getAuthToken();
    if (!!response && response?.status === Status.Success) {
      let { token } = response.data;
      setAuthorizationToken(token);
      const forgotResponse: any = await forgotPasswordService(creds);
      if (!!forgotResponse && forgotResponse?.status === Status.Success) {
        alert.success("We sent a reset password link to your email address.");
        dispatch(
          forgotPasswordRequestSuccess(
            "We sent a reset password link to your email address."
          )
        );
      } else {
        alert.error(
          "Unable to sent the reset password link to your email address, Please contact your administrator !!!"
        );
        dispatch(
          forgotPasswordRequestFail(
            "Unable to sent the reset password link to your email address, Please contact your administrator !!!"
          )
        );
      }
    } else {
      alert.error(
        "Unable to sent the reset password link to your email address, Please contact your administrator !!!"
      );
      dispatch(
        forgotPasswordRequestFail(
          "Unable to sent the reset password link to your email address, Please contact your administrator !!!"
        )
      );
    }
  } catch (error: any) {
    alert.error(
      "Unable to sent the reset password link to your email address, Please contact your administrator !!!"
    );
    dispatch(
      forgotPasswordRequestFail(
        "Unable to sent the reset password link to your email address, Please contact your administrator !!!"
      )
    );
  }
};

export const resetPasswordAction = async (
  creds,
  dispatch: Dispatch<any>,
  history: any,
  alert: AlertContainer
) => {
  dispatch(resetPasswordRequestPending());
  const responseAuth = await getAuthToken();
  if (!!responseAuth && responseAuth?.status === Status.Success) {
    let { token } = responseAuth.data;
    setAuthorizationToken(token);
    const response = await resetPasswordService(creds);
    if (!!response && response.data) {
      if (response.status === Status.Success) {
        alert.success("Password Reset successfully.");
        dispatch(resetPasswordRequestSuccess("Password Reset successfully."));
        history("/");
      } else {
        alert.error(response.data);
        dispatch(
          resetPasswordRequestFail(
            "Unable to reset your password, Please contact your administrator !!!"
          )
        );
      }
    } else {
      alert.error(
        "Unable to reset your password, Please contact your administrator !!!"
      );
      dispatch(
        resetPasswordRequestFail(
          "Unable to reset your password, Please contact your administrator !!!"
        )
      );
    }
  } else {
    setAuthorizationToken("");
    alert.error("Somthing went wrong, Please contact administrator.");
    dispatch(
      resetPasswordRequestFail(
        "Somthing went wrong, Please contact administrator."
      )
    );
  }
};

export const getDetails = async (
  guid: string | null,
  token: string | null,
  history: any,
  alert: AlertContainer
) => {
  const dataObj = { refId: guid, operation: "SIGNUP", routeName: "" };
  const response = await getUserRef(dataObj, token);
  if (!!response && response.status === Status.Success) {
    let { data, isRg, message } = response.data.data;
    // return decoded;
    if (!isRg && message === "Link has Expired") {
      //if Link has been Expired
      history("/info", {
        state: {
          title: "The Reset password verification link has expired!",
          subTitle1:
            "Look like the verification link has expired. Please contact administrator.",
          subTitle2: "",
          source: "Link Expired",
        },
      });
    } else if (isRg === true && message == null) {
      //if user new go to verify account
      let decoded = jwt_decode(data);
      console.log(decoded, "+++++decoded");
      return decoded;
    }
  } else {
    if (response) {
      alert.error(response.data.errorMessage);
    }
  }
};

export const getToken = async (
  guid: string | null,
  alert: AlertContainer,
  history: any
) => {
  const response = await getAuthToken();
  if (!!response && response.status === Status.Success) {
    let { token } = response.data;
    return getDetails(guid, token, history, alert);
  } else {
    if (response) {
      alert.error(response.data.errorMessage);
    }
  }
};
