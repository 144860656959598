import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Modal,
  useMediaQuery,
  Grid,
  Typography,
  TextField,
  FormHelperText,
} from "@mui/material";
import Button from "@mui/material/Button";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { STRINGS } from "../../../Strings";
import SelectBox from "../../../shared/components/SelectBox/SelectBox";
import {
  addTimesheet,
  getClientList,
  getProjectList,
  getTaskList,
} from "../TeamMemberTimesheet.helper";
import { userAccess } from "../../../utils/CommonFunction";
import { addTimeSheetClearMessage } from "../../../redux/Timesheet/timesheetSlice";

const CreateTimeSheetModal = ({
  open,
  setOpenLogTime,
}: any) => {
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const dispatch = useDispatch();
  const { projectNameList, taskList, addTimeSheetMessage } = useSelector(
    //@ts-ignore
    (state) => state.timesheet
  );
  const { email } = userAccess();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.2)",
    padding: "16px",
    outline: "none",
    width: isMobile ? "300px" : "600px",
    maxWidth: "90vw",
    maxHeight: "90vh",
    "&.MuiBox-root": {
      border: "none",
      "&:focus-visible": {
        outlineColor: "white",
      },
    },
  };

  const formik = useFormik({
    initialValues: {
      empEmailId: email,
      timesheetName: "",
      timesheetDescription: "",
      fromDate: "",
      toDate: "",
      projectId: "",
      jobId: "",
    },
    validationSchema: yup.object().shape({
      timesheetName: yup.string().required("Timesheet name is required"),
      fromDate: yup.date().required("From Date is required"),
      toDate: yup.date().required("To Date is required"),
      projectId: yup.string().required("Project Name is required"),
      jobId: yup.string().required("Task Name is required"),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        fromDate: dayjs(values.fromDate).format("DD-MM-YYYY"),
        toDate: dayjs(values.toDate).format("DD-MM-YYYY"),
      };
      addTimesheet(dispatch, payload);
    },
  });

  useEffect(() => {
    if (addTimeSheetMessage.includes("successfully")) {
      setOpenLogTime(!open);
      formik.resetForm();
    }
  }, [addTimeSheetMessage]);

  useEffect(() => {
    dispatch(addTimeSheetClearMessage());
  }, [open]);

  const handleClose = () => {
    setOpenLogTime(!open);
    formik.resetForm();
  };

  const shouldDisableDate = (date: Date) => {
    const dayOfWeek = dayjs(date).day();
    return dayOfWeek === 0 || dayOfWeek === 6;
  };

  useEffect(() => {
    getClientList(dispatch);
    getProjectList(dispatch, { empEmailId: email });
  }, []);

  useEffect(() => {
    if (formik.values.projectId) {
      getTaskList(dispatch, {
        projectId: formik.values.projectId,
        empEmailId: email,
      });
    }
  }, [formik.values.projectId]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            textAlign="center"
          >
            Create Time Sheet
          </Typography>
        </Grid>
        <Grid container spacing={2} sx={{ flexGrow: 1, mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <SelectBox
              dataArray={projectNameList}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={"projectId"}
              selectValue={formik.values.projectId}
              label={"Project Name"}
              error={Boolean(
                formik.touched.projectId && formik.errors.projectId
              )}
              helperText={formik.touched.projectId && formik.errors.projectId}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <SelectBox
              dataArray={taskList}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={"jobId"}
              selectValue={formik.values.jobId}
              label={"Task Name"}
              error={Boolean(formik.touched.jobId && formik.errors.jobId)}
              helperText={formik.touched.jobId && formik.errors.jobId}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              id="timesheetName"
              name="timesheetName"
              label="Timesheet Name"
              variant="outlined"
              fullWidth
              value={formik.values.timesheetName}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={2} mt={2}>
          <Grid item xs={12} sm={6} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="From"
                inputFormat="DD-MM-YYYY"
                value={formik.values.fromDate}
                onChange={(date) => {
                  formik.setFieldValue("fromDate", date);
                }}
                shouldDisableDate={shouldDisableDate}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    error={Boolean(
                      formik.touched.fromDate && formik.errors.fromDate
                    )}
                    helperText={
                      formik.touched.fromDate && formik.errors.fromDate
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="To"
                inputFormat="DD-MM-YYYY"
                value={formik.values.toDate}
                onChange={(date) => {
                  formik.setFieldValue("toDate", date);
                }}
                shouldDisableDate={shouldDisableDate}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    error={Boolean(
                      formik.touched.toDate && formik.errors.toDate
                    )}
                    helperText={formik.touched.toDate && formik.errors.toDate}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} mt={3}>
          <TextField
            id="timesheetDescription"
            name="timesheetDescription"
            label="Description"
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            value={formik.values.timesheetDescription}
            onChange={formik.handleChange}
          />
        </Grid>
        {addTimeSheetMessage && (
          <Box ml={1}>
            <FormHelperText sx={{ color: "red" }}>
              {addTimeSheetMessage}
            </FormHelperText>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "Right " }}>
          <Button variant="text" sx={{ mt: 2 }} onClick={handleClose}>
            {STRINGS.Cancel}
          </Button>
          <Button
            variant="text"
            sx={{ mt: 2 }}
            onClick={() => formik.handleSubmit()}
          >
            {STRINGS.Save}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateTimeSheetModal;
