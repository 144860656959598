import { Grid, List, Paper, Typography, Box } from "@mui/material";
import { memo, useEffect, useState } from "react";
import Search from "../../shared/components/Search/Search";
import RowSkeleton from "../../shared/components/Skeletons/RowSkeleton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LaunchIcon from "@mui/icons-material/Launch";
import { styles } from "./HireStyle";
import moment from "moment";
import { STRINGS } from "../../Strings";
import { styled } from "@mui/material/styles";

import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const ScrollingCandidateList = ({
  candidates,
  isCandidateListLoading,
  showCandidate,
  selectedCandidate,
  moreCandidateListLoading,
  onScrollCandidateList,
}) => {
  const classes = styles();
  const [searchInputValue, setSearchInputValue] = useState("");

  const [candidateFilterList, setCandiateFilterList] = useState([]);

  useEffect(() => {
    setCandiateFilterList(
      candidates?.filter((filterData) => {
        return filterData?.candidate.label
          ?.toLowerCase()
          .includes(searchInputValue.toLowerCase());
      })
    );
  }, [searchInputValue, candidates]);

  return (
    <Grid
      container
      item
      xs={12}
      md={4}
      justifyContent={"flex-start"}
      alignSelf={"flex-start"}
    >


      <Box px={"0.5rem"} pb={"1rem"} width={"100%"}>
        <Search
          getSearch={(val) => {
            setSearchInputValue(val);
          }}
          closeSearch={() => setSearchInputValue("")}
        />
      </Box>
      {isCandidateListLoading && (
        <Box px={"0.5rem"} width={"100%"}>
          <Grid display={"flex"} gap={"0.475rem"} py="0.5rem">
            <Grid md={12}>
              <RowSkeleton rowHeight={40} />
            </Grid>
          </Grid>
          {/* <Divider /> */}
        </Box>
      )}
      <Box className="customScroll" px={"0.5rem"} width={"100%"}>
        <Paper
          id={"candidateListRoot"}
          elevation={0}
          style={{
            height: "calc(100vh - 350px)",
            overflow: "auto",
            backgroundColor: "transparent",
            borderRadius: 0,
          }}
          onScroll={onScrollCandidateList}
        >
          <List>
            <>
              {candidateFilterList?.length > 0 ? (
                candidateFilterList?.map((candidate: any, candidateIndex) => {
                  return (
                    <Box
                      key={candidateIndex}
                      onClick={() => showCandidate(candidate)}
                      mb={1}
                      className={classes.cd_list_container}
                      sx={{ cursor: 'pointer' }}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{ width: "100%" }}
                      >
                        <Box className={classes.cd_mid_col}>
                          <Typography
                            className={classes.cd_list_name}
                            variant="body2"
                            component="div"
                            color={candidate?.candidate.id === selectedCandidate?.candidate.id ? '#002DFF' : ''}
                          >
                            {candidate?.candidate.label}
                          </Typography>
                        </Box>

                        <Box>
                          <HtmlTooltip
                            title={
                              <>
                                <ul className="tooltip-ul-candiate-info">
                                  <li>
                                    <span className="label-span">
                                      Job title
                                    </span>{" "}
                                    <span>{candidate?.job.label}</span>
                                  </li>
                                  <li>
                                    <span className="label-span">Email</span>{" "}
                                    <span>{candidate?.candidate?.email}</span>
                                  </li>
                                  <li>
                                    <span className="label-span">Location</span>{" "}
                                    <span>
                                      {candidate?.candidate?.address?.cityName
                                        ? candidate?.candidate?.address
                                          ?.cityName
                                        : "NA"}
                                    </span>
                                  </li>
                                  <li>
                                    <span className="label-span">Source</span>{" "}
                                    <span>
                                      {candidate?.candidateSource
                                        ? candidate?.candidateSource
                                        : "NA"}
                                    </span>
                                  </li>
                                  <li>
                                    <span className="label-span">
                                      Applied on
                                    </span>{" "}
                                    <span>
                                      {candidate?.appliedDate
                                        ? candidate?.appliedDate
                                        : "NA"}
                                    </span>
                                  </li>
                                </ul>
                              </>
                            }
                          >
                            <InfoOutlinedIcon />
                          </HtmlTooltip>
                        </Box>
                      </Box>

                      <Box
                        className={classes.cd_last_col}
                        display={"flex"}
                        flexDirection={"column"}
                        rowGap={1}
                        alignItems={"flex-end"}
                      >
                        <LaunchIcon
                          className={classes.cursor}
                          sx={{
                            display: {
                              xs: "block",
                              md: "none",
                              lg: "none",
                            },
                          }}
                        />
                        {candidate?.expectedJoiningDate && (
                          <Typography component="div" variant="body2" noWrap>
                            {moment(candidate?.expectedJoiningDate).format(
                              "DD-MM-YYYY"
                            )}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <Typography variant="caption">
                  {STRINGS.NoRecordFound}
                </Typography>
              )}
            </>

            {moreCandidateListLoading && (
              <Box px={"0.5rem"}>
                <Grid display={"flex"} gap={"0.475rem"} py="0.5rem">
                  <Grid md={12}>
                    <RowSkeleton rowHeight={40} />
                  </Grid>
                </Grid>
                {/* <Divider /> */}
              </Box>
            )}
          </List>
        </Paper>
      </Box>
    </Grid>
  );
};

export default memo(ScrollingCandidateList)
