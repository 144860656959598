import api from "../services/BaseApi";
import { userAccess } from "../utils/CommonFunction";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_URL_RECRUITLY = process.env.REACT_APP_RECRUITLY_URL;
const getJobReqDetails = BASE_URL_RECRUITLY + "getJobReqDetailsByJobCode";
const getJobRequestById = BASE_URL_RECRUITLY + "getJobRequestDetails";
const getJobPipelineStats = BASE_URL_RECRUITLY + 'getJobPipelineStats';
const markJobReqAsCancelled = BASE_URL + "markJobReqAsCancelled";
const markJobReqAsRestored = BASE_URL + "markJobReqAsRestored";
const getAllCandidateByJobCodeAndStage =
  BASE_URL_RECRUITLY + "getQualifiedJobCandidateListPerStage";
const getCandidateDataByApiId = BASE_URL_RECRUITLY + "getCandidateDetails";
const moveCandidate = BASE_URL_RECRUITLY + "updateCandidatePipelineStatus";
const disqualifyCandidate = BASE_URL_RECRUITLY + "disqualifyCandidate";
const getJobRequestsByRequestType =
  BASE_URL_RECRUITLY + "getFilteredJobRequets";
const getAllWorkableClients = BASE_URL + "getAllClients";
const addJobRequest = BASE_URL + "addJobRequest";
const addCandidateComment = BASE_URL_RECRUITLY + "addCandidateActivityComment";
const updateJobRequest = BASE_URL + "updateJobRequest";
const generateChatGptJobDescriptionURL =
  BASE_URL + "generateChatGptJobDescription";

export const getJobs = async (payload: any = "") => {
  try {
    let request: any = {
      RequestType: "Open",
      Client: "",
      PageNumber: 1,
      PageSize: 10,
    };

    if (payload) {
      request = { ...request, ...payload };
    }
    request.ClientId = userAccess().clientCode;
    request.client = userAccess().clientCode;
    const res = await api.post(getJobRequestsByRequestType, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAllWorkClients = async (payload: any = "") => {
  try {
    const res = await api.get(getAllWorkableClients);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getJobDetails = async (payload: any = "") => {
  try {
    let request: any = {
      jobShortCode: "41124B297C",
      stage: "Applied",
      requestedDate: new Date(),
      pageNumber: 1,
      pageSize: 10,
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.ClientId = userAccess().clientCode;
    const res = await api.post(getJobReqDetails, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const jobDetailByJobId = async (payload: any = "") => {
  try {
    let request: any = {
      ...payload
    };

    const res = await api.post(getJobRequestById, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getCandidateList = async (payload: any = "") => {
  try {
    let request: any = {

    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(getAllCandidateByJobCodeAndStage, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getCandidateDetail = async (payload: any = "") => {
  try {
    let request: any = {
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(getCandidateDataByApiId, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const postJob = async (payload) => {
  try {
    let request: any = {
      uId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.uId = userAccess().userId;
    const res = await api.post(addJobRequest, request);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const postComment = async (payload) => {
  try {
    let request: any = {
    
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(addCandidateComment, request);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const editPostJob = async (payload) => {
  try {
    let request: any = {
      uId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.uId = userAccess().userId;
    const res = await api.post(updateJobRequest, request);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const cancelJobRequest = async (payload) => {
  try {
    let request: any = {
      uId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.uId = userAccess().userId;
    const res = await api.post(markJobReqAsCancelled, request);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const restoreJobRequest = async (payload) => {
  try {
    let request: any = {
      uId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.uId = userAccess().userId;
    const res = await api.post(markJobReqAsRestored, request);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const changeCandidateStage = async (payload) => {
  try {
    let request: any = {
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(moveCandidate, request);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const disQualifyCandidate = async (payload) => {
  try {
    let request: any = {
      uId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.uId = userAccess().userId;
    const res = await api.post(disqualifyCandidate, request);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const generateChatGptJobDescription = async (payload) => {
  try {
    let request: any = {
      jobTitle: "",
      clientId: 0,
      skills: "",
      expLevel: "",
      employmentType: "",
      salaryFrom: "",
      salaryTo: "",
      location: "",
      noticePeriod: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(generateChatGptJobDescriptionURL, request);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};


export const getHireCandidateStagingApi = async (payload) => {
  try {
    let request: any = {
      jobId: payload
    }

    const res = await api.post(getJobPipelineStats, request)
    return res
  }catch (error){
    console.log(error)
    throw error
  }
}