import { color } from '@mui/system';
import React from 'react'
import "./styles.scss";

const LogoutSpinner = () => {
    return (
        <div id="spinner">
            <img alt='loading....' src="/images/Teamified-Logo.svg" />
            <h3>Loading....</h3>
        </div>
    )
}

export default LogoutSpinner