import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import PublishIcon from "@mui/icons-material/Publish";
import { STRINGS } from "../../Strings";
import { useFormik } from "formik";
import * as yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: "8px",
  boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.2)",
  padding: "16px",
  outline: "none",
  width: "auto",
  maxWidth: "90vw",
  maxHeight: "90vh",
};

const ApplyJobModal = ({ handleOpenClose, open }) => {
  const validationSchema = yup.object({
    resume: yup.mixed().required("Resume is required"),
    certificate: yup.mixed().required("Certificate is required"),
    description: yup.string().required("Description is required"),
  });

  const formik:any = useFormik({
    initialValues: {
      resume: null,
      certificate: null,
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      resetForm();
    },
  });

  const handleResumeClick = () => {
    const fileInput = document.getElementById(
      "resume-file"
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleCertificationClick = () => {
    const fileInput = document.getElementById(
      "certification-file"
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };
  
  console.log(formik.isValid, formik.dirty);
  

  return (
    <Modal
      open={open}
      onClose={handleOpenClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box component="form" noValidate onSubmit={formik.handleSubmit}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            textAlign="center"
          >
            Apply Now
          </Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                id="resume"
                name="resume"
                label="Upload resume*"
                fullWidth
                value={formik.values.resume ? formik.values.resume.name : ""}
                onClick={handleResumeClick}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <input
                        style={{ display: "none" }}
                        id="resume-file"
                        type="file"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (
                            e.currentTarget.files &&
                            e.currentTarget.files[0]
                          ) {
                            formik.setFieldValue(
                              "resume",
                              e.currentTarget.files[0]
                            );
                          }
                        }}
                        onClick={(
                          e: React.MouseEvent<HTMLInputElement, MouseEvent>
                        ) => e.stopPropagation()}
                      />
                      <label htmlFor="resume-file">
                        <IconButton
                          aria-label="upload"
                          component="span"
                          onClick={(
                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                          ) => e.stopPropagation()}
                        >
                          <PublishIcon />
                        </IconButton>
                      </label>
                    </InputAdornment>
                  ),
                }}
                error={formik.touched.resume && Boolean(formik.errors.resume)}
                helperText={formik.touched.resume && formik.errors.resume}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                id="certification"
                name="certification"
                onClick={handleCertificationClick}
                label="Upload certification*"
                fullWidth
                value={
                  formik.values.certificate
                    ? formik.values.certificate.name
                    : ""
                }
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <>
                      <input
                        style={{ display: "none" }}
                        id="certification-file"
                        type="file"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (
                            e.currentTarget.files &&
                            e.currentTarget.files[0]
                          ) {
                            formik.setFieldValue(
                              "certificate",
                              e.currentTarget.files[0]
                            );
                          }
                        }}
                        onClick={(e: any) => e.stopPropagation()}
                      />
                      <label htmlFor="certification-file">
                        <IconButton
                          aria-label="upload"
                          component="span"
                          onClick={(e: any) => e.stopPropagation()}
                        >
                          <PublishIcon />
                        </IconButton>
                      </label>
                    </>
                  ),
                }}
                error={
                  formik.touched.certification &&
                  Boolean(formik.errors.certification)
                }
                helperText={
                  formik.touched.certification && formik.errors.certification
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                id="description"
                name="description"
                label="Description*"
                multiline
                rows={5}
                variant="outlined"
                fullWidth
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="text" sx={{ mt: 2 }} onClick={handleOpenClose}>
                  {STRINGS.Cancel}
                </Button>
                <Button
                  variant="contained"
                  sx={{ mt: 2 }}
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ApplyJobModal;
