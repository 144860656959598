import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

import { RatingProps } from "@mui/material/Rating";

const StyledRating = styled(Rating, {
  shouldForwardProp: (prop) => prop !== "hoverRating", // Don't pass this prop to the DOM element
})<RatingProps & { hoverRating: number }>(({ theme, hoverRating }) => ({
  "&.Mui-disabled": {
    opacity: 1,
  },
}));

function CustomRatings({
  rating = 0,
  disabled = false,
  onChange = (a: number) => {},
  color = "#CCD5FC",
}) {
  const [ratingValue, setRatingValue] = useState(rating);
  const [hoverRating, setHoverRating] = useState(rating);

  useEffect(() => {
    setRatingValue(rating);
  }, [rating]);

  return (
    <StyledRating
      name="customized-color"
      value={ratingValue}
      icon={
        <StarIcon
          fontSize="large"
          sx={{
            backgroundColor: color,
            marginRight: 1,
            color: "white",
          }}
        />
      }
      emptyIcon={
        <StarIcon
          fontSize="large"
          sx={{ backgroundColor: "black", color: "white", marginRight: 1 }}
        />
      }
      onChange={(event, newValue) => {
        setRatingValue(newValue || (0 as number));
        onChange(newValue || (0 as number));
      }}
      onChangeActive={(event, value) => {
        setHoverRating(value);
      }}
      disabled={disabled}
      precision={1}
      hoverRating={hoverRating}
    />
  );
}

export default CustomRatings;
