import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import * as React from "react";
import RowSkeleton from "./RowSkeleton";
import TextSkeleton from "./TextSkeleton";

type TableSkeletonProps = {
  headerCount?: Array<number>;
  rowCount?: Array<number>;
  header?: boolean;
};

const TableSkeleton: React.FunctionComponent<TableSkeletonProps> = (props) => {
  const defaultHeaderCell = [1, 2, 3, 4, 5];
  const defaultRowCell = [1, 2, 3, 4, 5];
  const {
    headerCount = defaultHeaderCell,
    rowCount = defaultRowCell,
    header = true,
  } = props;

  return (
    <TableContainer>
      <Table sx={{ marginTop: 3 }}>
        {header && (
          <TableHead>
            <TableRow>
              {headerCount?.map((_v, index) => {
                return (
                  <TableCell key={index}>
                    <TextSkeleton />
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {rowCount?.map((_v, index) => {
            return (
              <TableRow key={index}>
                {headerCount?.map((_v, index) => {
                  return (
                    <TableCell key={index}>
                      <RowSkeleton />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSkeleton;
