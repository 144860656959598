import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  sticky_filters: {
    position: "-webkit-sticky",
    position: "sticky",
    top: "62px",
    zIndex: "99",
    ["@media (max-width:780px)"]: {
      position: "initial",
    },
  },
  filter_content: {
    padding: "12px 24px",
  },
  leaveDashboardResBox: {
    display: "none",
  },

  sameheight: {
    padding: "5px 10px 5px 10px",
    maxHeight: 47,
  },
  totalCardsContent: {
    display: "flex",
    justifyContent: "space-between",
    // padding: "10px 10px 10px 10px",
  },
  pendingInvoiceHeader: {
    marginLeft: 16,
    display: "flex",
    justifyContent: "space-between",
    ml: 2,
    mr: 2,
    mt: 1,
  },

  "@media (max-width: 768px)": {
    pendingInvoiceHeader: {
      display: "block",
      marginLeft: 16,
    },
    pendingInvoiceHeaderAmount: {
      marginTop: 5,
      fontSize: ".9rem",
    },
    pendingInvoiceHeaderViewAll: {
      fontSize: ".7rem",
    },
    jobActivity: {
      marginTop: -30,
    },
    jobText: {
      fontSize: ".9rem",
    },
    jobTextTeamMember: {
      fontSize: ".9rem",
      marginLeft: -10,
    },
    jobTextTeamMemberCount: {
      marginRight: -15,
    },
    leaveDashboardBox: {
      display: "none",
    },
    leaveDashboardResBox: {
      display: "block",
      marginTop: 5,
    },
    sameheight: {
      padding: "5px 10px 5px 10px",
      maxHeight: 40,
    },
    totalCardsContent: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 10px 10px 10px",
    },
    responsiveImage: {
      height: "15px !important",
    },
  },
}));

export { useStyles };
