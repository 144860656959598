import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useCallback, useEffect, useState } from "react";


import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikInput from "../../../shared/components/FormikInput";

import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useAddDraftSummaryMutation, useAddNoteMutation, useAddQuestionsMutation, useGetInterviewQuery } from "../../../redux/Hire/interviewQuestionSlice";
import { useGetInterviewQuestionsQuery } from "../../../redux/Hire/mandatoryQuestionsSlice";
import api from "../../../services/BaseApi";
import MoveCandidate from "../../../shared/components/MoveCandidate/MoveCandidate";
import { userAccess } from "../../../utils/CommonFunction";
import { moveCandidateStage, postComment } from "../Hire.helper";
import AddQuestionModal from "./AddQuestionModal";
import InterviewQuestionList from "./InterviewQuestionList";
import Summary from "./Summary";

const feedBackSchema = Yup.object({
  status: Yup.string().required("Please select status"),
  feedback: Yup.string().when('status', (status: string) => {
    if (status === 'unlike') {
      return Yup.string().required('Please add reason')
    }
    return Yup.string()
  }),
});

const CandiateInterviewDetails = ({ cvDetails, selectedStage, jobId, candidateId, jobDescription, internalCode = '', candidateEmail, setSelectedStage }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  },[])
  const handleClose = () => {
    setAnchorEl(null);
  };



  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [snackBarOpen, setSnackBarkOpen] = useState(false);
  const [generateQuestionnaireLoading, setGenerateQuestionairreLoading] = useState(false);
  const [pollingInterval, setPollingInterval] = useState<number | undefined>(5000)
  const { data: interviewData, isError, isFetching, isLoading, isSuccess, error } = useGetInterviewQuery({
    candidateId,
    roleId: jobId
  }, {
    pollingInterval: pollingInterval,
    skip: !candidateId,
  })

  const isNotApplied = selectedStage?.code !== 'APPLIED'
  const alert = useAlert()


  const isPreInterview = selectedStage?.code === 'SOURCED' || selectedStage?.code === 'APPLIED' || selectedStage?.code === 'SHORTLIST'

  const { data: mandatoryQuestionsData, isSuccess: isSuccessMandatoryQuestions, isUninitialized } = useGetInterviewQuestionsQuery({ internalCode }, {
    skip: !internalCode
  })

  const { hireCandidateStaging } = useSelector(
    //@ts-ignore
    (state) => state.hire
  );

  const [addDraftSummary, { isLoading: isDraftSummaryLoading }] = useAddDraftSummaryMutation()

  const dispatch = useDispatch()



  const [addQuestions,] = useAddQuestionsMutation();
  const [addNote,] = useAddNoteMutation()


  useEffect(() => {
    setPollingInterval(5000)
  }, [jobId, candidateId])

  const strip = useCallback((html) => {
    var doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }, [])




  const generateQuestionairre = () => {
    try {
      api.post(`${process.env.REACT_APP_INTERVIEW_URL}/interview/questionnaire`, {
        roleId: jobId,
        candidateId: candidateId,
        jobDescription: jobDescription,
        candidateResume: strip(cvDetails?.html),
        mandatoryQuestions: (mandatoryQuestionsData?.data || []).map(q => ({
          question: q.question,
          hint: q.hint
        }))
      })
    } catch (e) {
      console.log(e)
    }


  }

  useEffect(() => {
    if (isNotApplied && cvDetails?.html && candidateId && (isUninitialized || isSuccessMandatoryQuestions)) {
      generateQuestionairre()

    }
  }, [isNotApplied, cvDetails, candidateId, isUninitialized, isSuccessMandatoryQuestions])

  const addNewQuestions = (data) => {
    if (interviewData?._id) {
      addQuestions({
        interviewId: interviewData?._id,
        questions: data.questionList
      })
    }
    setShowQuestionModal(false);
  };

  const submitForm = async (values) => {
    if (interviewData) {
      try {
        if (values.status === 'like') {
          const payload = {
            CandidateId: candidateId,
            JobId: jobId,
            Code: 'CV_SENT'

          };
          moveCandidateStage(payload, dispatch);
          setSelectedStage(hireCandidateStaging.find(el => el.code === payload.Code))
        }
        if (values.status === 'unlike') {
          const payload = {
            noteFrom: {
              id: '',
              email: userAccess().email
            },
            noteTo: {
              id: '',
              email: candidateEmail
            },
            activityType: 'a1086f15-7616-4a55-9c4b-08ec2322bb62',
            notes: values.feedback

          }
          postComment(payload, dispatch);

        }

        await addDraftSummary({
          interviewId: interviewData?._id
        }).unwrap()

      } catch (e: any) {
        alert.error('Error updating candidate summary ' + e.message)
      }

    }

  }


  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarOpen}
        onClose={() => setSnackBarkOpen(false)}
        autoHideDuration={2000}
      >
        <Alert
          onClose={() => setSnackBarkOpen(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
          className="custom-snackbar-msg"
        >
          Questions added successfully
        </Alert>
      </Snackbar>

      <AddQuestionModal
        show={showQuestionModal}
        setModel={() => setShowQuestionModal(false)}
        onFormSubmitAction={addNewQuestions}
      />

      <Grid item xs={12} md={8}>
        <Box>
          <Summary handleClick={handleClick} interviewData={interviewData} isPreInterview={isPreInterview} selectedStage={selectedStage} />

          <Box
            className="interview-list-page-sub-header"
            style={{ marginTop: "10px" }}
          >
            <h3>Interview questions list</h3>
            {isPreInterview ? <Box>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => setShowQuestionModal(true)}
              >
                Add interview questions
              </Button>
            </Box> : null}
          </Box>
          <Box>
            <MoveCandidate
              anchorEl={anchorEl}
              handleClose={handleClose}
              selectedStage={selectedStage}
              selectedCandidate={{
                candidate: {
                  id: candidateId,
                  email: candidateEmail,
                },
                job: {
                  id: jobId
                }
              }}
              setSelectedStage={setSelectedStage}
              showCommentBox={false}
              setShowCommentBox={() => null}></MoveCandidate>

            <InterviewQuestionList
              isShortlistStage={selectedStage.code === 'SHORTLIST'}
              isPostInterview={!isPreInterview}
              disabled={selectedStage?.code !== 'SHORTLIST'}
              addNoteFun={(text, questionId) => {
                if (interviewData && questionId) {
                  addNote({
                    interviewId: interviewData?._id,
                    interviewNote: text,
                    questionId: questionId
                  })
                }
              }}
              questionsList={interviewData?.questions || []}
            />
          </Box>

          {selectedStage?.code === 'SHORTLIST' && <Formik
            initialValues={{
              feedback: "",
              status: "unlike",
            }}
            validationSchema={feedBackSchema}
            onSubmit={submitForm}
          >
            {(formikProps) => {
              return (
                <Form>
                  <Box className="candiate-client-box" sx={{
                    mt: '8px'
                  }}>
                    <Box className="interview-list-page-sub-header">
                      <h3>Move candidate to client endorsement?</h3>
                      <Box className="icon-div-box">
                        <Box
                          className={
                            formikProps.values.status === "like"
                              ? "selected"
                              : ""
                          }
                        >
                          <IconButton
                            className="like-button"
                            onClick={() =>
                              formikProps.setFieldValue("status", "like")
                            }
                          >
                            <ThumbUpOffAltIcon />
                          </IconButton>
                        </Box>
                        <Box
                          className={
                            formikProps.values.status === "unlike"
                              ? "selected"
                              : ""
                          }
                        >
                          <IconButton
                            className="unlike-button"
                            onClick={() =>
                              formikProps.setFieldValue("status", "unlike")
                            }
                          >
                            <ThumbDownOffAltIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      {formikProps.values.status === 'unlike' && <FormikInput
                        label="Reason"
                        name="feedback"
                        options={null}
                        formikProps={formikProps}
                        fullWidth
                      />}
                    </Box>
                  </Box>

                  <Box className="save_submit_box">
                    <Button color="primary" variant="contained" type="submit" disabled={isDraftSummaryLoading}>
                      Save and submit
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>}
        </Box>
      </Grid>
    </>
  );
};

export default CandiateInterviewDetails;
