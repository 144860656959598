import { Grid, Typography } from "@mui/material"
import AssigneeUser from "./AssigneeUser"

const TaskComment = ({ taskComment, last = false }) => {
    return (<Grid pb={1} borderBottom={last ? '0px solid #707D84' : '1px solid #707D84'} width={'100%'}>
        <Grid><Typography variant="body1">{taskComment.comment}</Typography></Grid>
        <Grid container item gap={'4px'}>
            <Typography variant="body2">Commented By:</Typography>
            <Typography><AssigneeUser assigneeUser={taskComment.commentedBy}></AssigneeUser></Typography>
        </Grid>
    </Grid>)
}
export default TaskComment