import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  Box,
  Button,
  Fab,
  FormControl,
  Grid,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STRINGS } from "../../Strings";
import DataGrid from "../../shared/components/DataGrid/DataGrid";
import MobileSkeleton from "../../shared/components/Skeletons/MobileSkeleton";
import TableSkeleton from "../../shared/components/Skeletons/TableSkeleton";
import { getCurrentFiscalYear, userAccess } from "../../utils/CommonFunction";
import InvoiceGraph from "../Dashboard/LineGraph/InvoiceGraph";
import {
  getFilterPaidInvoiceList,
  getInvoiceDetailById,
  getInvoiceDownloadingById,
  getInvoiceGraphList,
  getPaidSearchList,
  getPaidSelectPageRowList,
  getPaidnvoiceList,
  getPaidnvoiceListMobile,
  getPendingInvoiceList,
  getPendingSearchList,
  getPendingSelectPageRowList,
  getPendingSortRowList,
  getSelectPaidList,
  getSelectPendingList,
  invoiceHeader,
  invoicePaidHeader,
  invoicePaidRow,
  invoicePendingRow,
  scrollToTop,
} from "./Invoice.helper";
import { styles } from "./InvoiceStyle";
import InvoiveMobileDataGrid from "./InvoiveMobileDataGrid";

const defaultMemberAction = [
  { name: "View", action: "getDetails", display: true },
  {
    name: "Download",
    action: "download",
    display: true,
  },
];

const pendingInvoiceAction = [
  { name: "View", action: "getDetails", display: true },
  {
    name: "Download",
    action: "download",
    display: true,
  },
];

const InvoiceList: React.FunctionComponent = () => {
  const {
    totalPending,
    totalPendingAmount,
    isPendingInvoiceLoading,
    pendingInvoices,
    pendingError,
    isPaging,
    pendingPage,
    pendingCurrentPage,
    pendingPageSize,
    isPaidInvoiceLoading,
    paidInvoices,
    paidError,
    isPaidPaging,
    paidPage,
    paidCurrentPage,
    paidPageSize,
    isInvoiceGraphLoading,
    invoiceGraph,
  } = useSelector(
    //@ts-ignore
    (state) => state.invoice
  );
  const alert = useAlert();
  const classes = styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const [chartData, setChartData] = React.useState([]);

  const [tabValue, setValue] = useState(0);
  const [Showfloat, setShowfloat] = React.useState<boolean>(false);
  const [mobileUserList, setMobileUserList] = React.useState<any>([]);
  const [mobilePageLoad, setMobilePageLoad] = React.useState<number>(1);
  const [durationFrom, setDurationFrom] = React.useState<any>(
    getCurrentFiscalYear()?.startDate
  );
  const [durationTo, setDurationTo] = React.useState<any>(
    getCurrentFiscalYear()?.endDate
  );
  const [disableFilter, setDisableFilter] = React.useState<boolean>(true);

  useEffect(() => {
    if (!_.isNull(invoiceGraph)) {
      formatChartData(invoiceGraph);
    }
  }, [invoiceGraph]);

  const formatChartData = async (stages) => {
    let arr = stages;
    const data = {
      labels: arr?.map((data) => data.month),
      datasets: [
        {
          label: "",
          data: arr?.map((data) => data.paidAmount),
          backgroundColor: "rgba(204, 213, 252, 0.9)",
        },
      ],
    };
    //@ts-ignore
    return setChartData(data);
  };

  useEffect(() => {
    getPendingInvoiceList(dispatch);
    getInvoiceGraphList(dispatch);
    setTimeout(() => {
      getPaidnvoiceList(dispatch);
    }, 1000);

    return () => {
      setMobileUserList([]);
    };
  }, []);

  const getPendingPageList = (page) => {
    const pageSize = pendingPageSize;
    getSelectPendingList(page, pageSize, dispatch);
  };

  const getPaidPageList = (page) => {
    const pageSize = paidPageSize;
    getSelectPaidList(page, pageSize, dispatch);
  };

  const getDetails = (id) => {
    getInvoiceDetailById(id, dispatch);
    navigate("/client-invoices", {
      state: {
        invoiceId: id,
      },
    });
  };

  const skeleton = isMobile ? <MobileSkeleton /> : <TableSkeleton />;

  useEffect(() => {
    if (mobilePageLoad === 1) {
      setMobileUserList(paidInvoices);
    } else {
      const newList = [...mobileUserList, ...paidInvoices];
      setMobileUserList(newList);
    }
  }, [paidInvoices]);

  const loadList = () => {
    const loadPage = mobilePageLoad + 1;
    getPaidnvoiceListMobile(dispatch, loadPage, durationFrom, durationTo);
    setMobilePageLoad(loadPage);
    if (loadPage > 1) {
      setShowfloat(true);
    }
  };

  return (
    <Grid container sx={{ flexGrow: 1 }}>
      <Grid
        container
        xs={12}
        sx={{ ml: isMobile ? 0 : 2, mr: isMobile ? 0 : 2 }}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          sx={{
            backgroundColor: "#ffffff",
            pt: 2,
            pb: 2,
            pl: 1,
            pr: 1,
            mb: 2,
          }}>
          {isPendingInvoiceLoading ? (
            skeleton
          ) : isMobile ? (
            <>
              {totalPending > 0 && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  spacing={2}>
                  <Typography variant="h5" sx={{ color: "#E61A1A" }}>
                    {`${totalPending} ${
                      totalPending > 1 ? `invoices` : `invoice`
                    } awaiting payment`}
                  </Typography>
                  <Typography variant="h5" sx={{ color: "#E61A1A" }}>
                    {`$${totalPendingAmount}`}
                  </Typography>
                </Grid>
              )}
              <InvoiveMobileDataGrid
                row={invoicePendingRow(pendingInvoices, userAccess().client)}
                actionList={pendingInvoiceAction}
                paid={false}
                getDetails={getDetails}
                getDownload={(id) => {
                  getInvoiceDownloadingById(id, dispatch);
                }}
              />
            </>
          ) : (
            <>
              {totalPending > 0 && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  spacing={2}>
                  <Typography variant="h5" sx={{ color: "#E61A1A" }}>
                    {`${totalPending} ${
                      totalPending > 1 ? `invoices` : `invoice`
                    } awaiting payment`}
                  </Typography>
                  <Typography variant="h5" sx={{ color: "#E61A1A" }}>
                    {`$${totalPendingAmount}`}
                  </Typography>
                </Grid>
              )}

              <DataGrid
                header={invoiceHeader(userAccess().client)}
                row={invoicePendingRow(pendingInvoices, userAccess().client)}
                getDetails={getDetails}
                getDownload={(id) => {
                  getInvoiceDownloadingById(id, dispatch);
                }}
                actionList={pendingInvoiceAction}
                showPaging={isPaging}
                page={pendingPage}
                getPageList={getPendingPageList}
                sortTable={(value, key) => {
                  getPendingSortRowList(key, value, dispatch);
                }}
                rowPerPage={(row) => {
                  getPendingSelectPageRowList(row, dispatch);
                }}
                defaultRowPerPageCount={pendingPageSize}
                currentPage={pendingCurrentPage}
                search={(value) => {
                  const searchText = value;
                  getPendingSearchList(searchText, dispatch);
                }}
              />
            </>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          sx={{
            backgroundColor: "#ffffff",
            pt: 2,
            pb: 2,
            pl: 1,
            pr: 1,
            mb: 2,
          }}>
          {isInvoiceGraphLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <Typography
                variant="h6"
                sx={{ height: 50 }}
                align="center"
                color="text.secondary">
                {"Money spent over last 6 months"}
              </Typography>
              <hr />
              {chartData && Object.keys(chartData).length > 0 && (
                <InvoiceGraph
                  graphdata={chartData}
                  height="130px"
                  filter={true}
                  callBack={(data) => {
                    setDurationFrom(data?.dateFrom);
                    setDurationTo(data?.dateTo);
                    getFilterPaidInvoiceList(
                      data?.dateFrom,
                      data?.dateTo,
                      dispatch
                    );
                  }}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "white",
          ml: isMobile ? 0 : 2,
          mr: isMobile ? 0 : 2,
        }}>
        {isPaidInvoiceLoading ? (
          skeleton
        ) : isMobile ? (
          <>
            {Showfloat && (
              <Fab
                sx={{
                  position: "fixed",
                  bottom: (theme) => theme.spacing(10),
                  right: (theme) => theme.spacing(3),
                  backgroundColor: "#ccd5fc",
                }}
                onClick={() => scrollToTop(setShowfloat)}>
                <ArrowUpwardIcon />
              </Fab>
            )}
            <Box mt={2}>
              <Grid
                container
                direction="row"
                alignItems="center"
                sx={{ backgroundColor: "white", pt: 2 }}>
                {/* @ts-ignore */}
                <Grid
                  item
                  xs={12}
                  md={12}
                  mb={-2}
                  ml={2}
                  mr={2}
                  className={"page-header-white"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "rgba(204,213,252, .5)",
                  }}
                  spacing={2}>
                  <Grid container spacing={2} sx={{ pt: 2 }}>
                    <Grid container xs={12} sm={6} md={6}>
                      <Typography
                        variant="h5"
                        sx={{
                          ml: 2,
                          height: 40,
                        }}>{`List of paid invoices`}</Typography>
                    </Grid>
                    <Grid container xs={12} sm={6} md={6}>
                      <Box sx={{ display: "flex" }}>
                        <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              label={"Duration from"}
                              inputFormat="MM/DD/YYYY"
                              value={durationFrom}
                              onChange={(e) => {
                                setDurationFrom(e);
                                setDisableFilter(false);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="myDatePicker"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <FormControl fullWidth sx={{ ml: 2 }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              label={"Duration to"}
                              inputFormat="MM/DD/YYYY"
                              value={durationTo}
                              onChange={(e) => {
                                setDurationTo(e);
                                setDisableFilter(false);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="myDatePicker"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <Button
                          disabled={disableFilter}
                          sx={{ width: "30%", ml: 1 }}
                          onClick={() => {
                            getFilterPaidInvoiceList(
                              durationFrom,
                              durationTo,
                              dispatch
                            );
                            setMobileUserList([]);
                          }}
                          variant="contained">
                          {"Filter"}
                        </Button>
                        <Button
                          variant="text"
                          onClick={() => {
                            setDurationFrom(getCurrentFiscalYear()?.startDate);
                            setDurationTo(getCurrentFiscalYear()?.endDate);
                            setDisableFilter(true);
                            setTimeout(() => {
                              getFilterPaidInvoiceList(
                                getCurrentFiscalYear()?.startDate,
                                getCurrentFiscalYear()?.endDate,
                                dispatch
                              );
                              setMobileUserList([]);
                              setMobilePageLoad(1);
                            }, 1000);
                          }}>
                          <img
                            src="/images/reset.png"
                            style={{ height: 25, width: 25 }}
                            alt="reset"
                          />
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box sx={{ p: 2, backgroundColor: "#ffffff" }}>
                <InvoiveMobileDataGrid
                  row={invoicePaidRow(mobileUserList, userAccess().client)}
                  actionList={defaultMemberAction}
                  paid={true}
                  getDetails={getDetails}
                  getDownload={(id) => {
                    getInvoiceDownloadingById(id, dispatch);
                  }}
                />
                {isPaidPaging && isMobile && mobilePageLoad < paidPage && (
                  <Box mt={1} mb={3} textAlign="center">
                    <Button
                      variant="outlined"
                      size="small"
                      color="warning"
                      onClick={loadList}>
                      {STRINGS.LoadMore}
                    </Button>
                  </Box>
                )}
                {/* <DataGrid
                header={invoicePaidHeader(userAccess().client)}
                row={invoicePaidRow(paidInvoices, userAccess().client)}
                getDetails={getDetails}
                getDownload={(id) => {
                  getInvoiceDownloadingById(id, dispatch);
                }}
                actionList={defaultMemberAction}
                showPaging={isPaidPaging}
                page={paidPage}
                getPageList={getPaidPageList}
                // sortTable={sortMember}
                rowPerPage={(row) => {
                  getPaidSelectPageRowList(row, dispatch);
                }}
                defaultRowPerPageCount={paidPageSize}
                currentPage={paidCurrentPage}
                search={(value) => {
                  const searchText = value;
                  getPaidSearchList(searchText, dispatch);
                }}
              /> */}
              </Box>
            </Box>
          </>
        ) : (
          <Box mt={2}>
            <Grid
              container
              direction="row"
              alignItems="center"
              sx={{ backgroundColor: "white", pt: 2 }}>
              {/* @ts-ignore */}
              <Grid
                item
                xs={12}
                md={12}
                mb={-2}
                ml={2}
                mr={2}
                className={"page-header-white"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "rgba(204,213,252, .5)",
                }}
                spacing={2}>
                <Grid container spacing={2} sx={{ pt: 2 }}>
                  <Grid container xs={12} sm={6} md={6}>
                    <Typography
                      variant="h5"
                      sx={{
                        ml: 2,
                        height: 40,
                      }}>{`List of paid invoices`}</Typography>
                  </Grid>
                  <Grid container xs={12} sm={6} md={6}>
                    <Box sx={{ display: "flex" }}>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label={"Duration from"}
                            inputFormat="MM/DD/YYYY"
                            value={durationFrom}
                            onChange={(e) => {
                              setDurationFrom(e);
                              setDisableFilter(false);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} className="myDatePicker" />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl fullWidth sx={{ ml: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label={"Duration to"}
                            inputFormat="MM/DD/YYYY"
                            value={durationTo}
                            onChange={(e) => {
                              setDurationTo(e);
                              setDisableFilter(false);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} className="myDatePicker" />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <Button
                        disabled={disableFilter}
                        sx={{ width: "30%", ml: 1 }}
                        onClick={() =>
                          getFilterPaidInvoiceList(
                            durationFrom,
                            durationTo,
                            dispatch
                          )
                        }
                        variant="contained">
                        {"Filter"}
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => {
                          setDurationFrom(getCurrentFiscalYear()?.startDate);
                          setDurationTo(getCurrentFiscalYear()?.endDate);
                          setDisableFilter(true);
                          setTimeout(() => {
                            getFilterPaidInvoiceList(
                              getCurrentFiscalYear()?.startDate,
                              getCurrentFiscalYear()?.endDate,
                              dispatch
                            );
                          }, 1000);
                        }}>
                        <img
                          src="/images/reset.png"
                          style={{ height: 25, width: 25 }}
                          alt="reset"
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ p: 2, backgroundColor: "#ffffff" }}>
              <DataGrid
                header={invoicePaidHeader(userAccess().client)}
                row={invoicePaidRow(paidInvoices, userAccess().client)}
                getDetails={getDetails}
                getDownload={(id) => {
                  getInvoiceDownloadingById(id, dispatch);
                }}
                actionList={defaultMemberAction}
                showPaging={isPaidPaging}
                page={paidPage}
                getPageList={getPaidPageList}
                // sortTable={sortMember}
                rowPerPage={(row) => {
                  getPaidSelectPageRowList(row, dispatch);
                }}
                defaultRowPerPageCount={paidPageSize}
                currentPage={paidCurrentPage}
                search={(value) => {
                  const searchText = value;
                  getPaidSearchList(searchText, dispatch);
                }}
              />
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default InvoiceList;
