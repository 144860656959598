import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

type RowSkeletonProps = {
  rowHeight?: number;
};

const RowSkeleton: React.FunctionComponent<RowSkeletonProps> = (props) => {
  const { rowHeight = 45 } = props;
  return (
    <Box>
      <Skeleton animation="wave" variant="rounded" height={rowHeight} />
    </Box>
  );
};

export default RowSkeleton;
