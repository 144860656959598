import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
const BASE_URL = process.env.REACT_APP_BASE_API_URL

type InterviewQuestionRequest = {
    internalCode: string
}


type Question = {
    id: string
    question: string
    hint: string
}

type InterviewQuestionResponse = {

    status: number
    successful: boolean,
    message: string,
    data: Array<Question>


}
export const mandatoryQuestionsApi = createApi({
    reducerPath: 'mandatoryQuestions',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + '',
        prepareHeaders: (headers, {
            getState
        }) => {
            const token = (getState() as any).login.accessToken
            if (token) headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        getInterviewQuestions: builder.query<InterviewQuestionResponse, InterviewQuestionRequest>({
            query: ({ internalCode }) => ({
                url: '/getInterviewQuestions',
                method: 'POST',
                body: {
                    internalCode
                }
            })
        })
    })
})

export const { useGetInterviewQuestionsQuery } = mandatoryQuestionsApi