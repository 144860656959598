import { Box, Skeleton, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { getClientById } from "../../../services/Client";

const ClientName = ({ id }) => {
    const [client, setClient] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);
    const alert = useAlert()
    const getClient = async (id) => {

        try {
            const res = await getClientById(id)
            setClient(res?.data)
        } catch (e) {
            alert.error('Error fetching user ' + e)
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (id) {
            getClient(id)
        }
    }, [id])
    return (
        <Box>


            {id && isLoading && <Skeleton variant="rectangular" sx={{
                width: '100px',
                height: '20px',
            }}></Skeleton>}
            {id && !isLoading && <Typography>{client.name}</Typography>}
            {!id && <Typography>Patona Digital</Typography>}
            
        </Box>)
}

export default memo(ClientName,(prevProps, nextProps) => {
    return prevProps.id === nextProps.id
})