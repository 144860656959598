import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  company: {},
  isLoading: false,
  error: "",
  isCompanyUpdated: false,
  updateLoading: false,
  isCompanyUpdatedError: false,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    getCompanyPending: (state) => {
      state.isLoading = true;
    },
    getCompanySuccess: (state, { payload }) => {
      state.isLoading = false;
      state.company = payload;
      state.error = "";
    },
    getCompanyFail: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    companyUpdated: (state, { payload }) => {
      state.isCompanyUpdated = payload;
      state.updateLoading = false;
    },
    companyUpdatedError: (state) => {
      state.isCompanyUpdatedError = true;
      state.updateLoading = false;
    },
    companyUpdatedDone: (state) => {
      state.isCompanyUpdated = false;
      state.isCompanyUpdatedError = false;
    },
    getUpdatePending: (state) => {
      state.updateLoading = true;
    },
    clearCompany: (state) => {
      state.company = {};
    },
  },
});

export const {
  getCompanyPending,
  getCompanySuccess,
  getCompanyFail,
  companyUpdated,
  companyUpdatedDone,
  getUpdatePending,
  clearCompany,
  companyUpdatedError,
} = companySlice.actions;

export default companySlice.reducer;
