import { Button } from "@mui/material";

const CustomCommentsHeader = ({ onSubmit, handleOnCancel }) => {
  return (
    <>
      <Button
        color="secondary"
        onClick={handleOnCancel}
        sx={{ height: 40, color: "blue", marginRight: "10px" }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        sx={{ backgroundColor: "#002DFF", height: 40 }}
      >
        Submit
      </Button>
    </>
  );
};

export default CustomCommentsHeader;
