import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AnnualQuestionsPropsState {
  isLoading: boolean;
  error: string | null;
  data: [];
}

const initialState: AnnualQuestionsPropsState = {
  isLoading: false,
  error: null,
  data: [],
};

const annualQuestionsSlice = createSlice({
  name: "annualquestions",
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
      if (state.isLoading) {
        state.data = [];
        state.error = null;
      }
    },
    setAllQuestions(state, action: PayloadAction<any>) {
      state.data = action.payload;
    },
  },
});

export const { setIsLoading, setAllQuestions } = annualQuestionsSlice.actions;

export default annualQuestionsSlice.reducer;
