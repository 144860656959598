import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  company_panel: {
    // backgroundColor: "#F7EBFC"
    alignItems: 'center',
    color: "#000000",
    display: "flex",
  },
  back_icon: {
    cursor: "pointer",
    marginRight: "15px",
    color: "#000000",
    verticalAlign: "middle",
  },
  display_card: {
    backgroundColor: "#FFFFFF",
    borderRadius: "16px",
    boxShadow: "1px 4px 20px rgba(132, 132, 132, 0.2)",
    padding: "2rem",
    margin: "2rem 0",
  },
  company_title_text: {
    fontSize: "16px",
    fontWeight: "700",
  },
  user_pic: {
    border: "1px solid #CCD3D5",
    borderRadius: "10px",
    width: 100,
    height: 100,
  },
  user_logo: {
    border: "1px solid #CCD3D5",
    borderRadius: "25px",
    width: 40,
    height: 40,
    marginRight: 10,
  },
  companyLogo: {
    width: 100,
    height: 100,
    borderRadius: "8px",
    borderTopLeftRadius: 10,
    backgroundColor: "#CCD5FC",
  },
  companyLogoText: {
    textAlign: "center",
    paddingTop: "24px",
    backgroundColor: "#CCD5FC",
    borderRadius: "8px",
  },
}));

export { useStyles };
