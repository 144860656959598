import axios from "axios";
import api from "../services/BaseApi";
import { userAccess } from "../utils/CommonFunction";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const getAllPerformanceAnualQuestionsAPI =
  BASE_URL + "getAllPerformanceAnualQuestions";
const getFilteredPerformingEmployeesAPI =
  BASE_URL + "getfilteredPerformingEmployees";
const getemployeesFeedbackListAPI = BASE_URL + "getemployeesFeedbackList";
const getAnualFeedbackDetailsAPI = BASE_URL + "getAnualFeedbackDetails";
const getemployeesPerformanceGraphAPI =
  BASE_URL + "getemployeesFeedbackGraphData";
const getemployeeFeedbackDetailsAPI = BASE_URL + "getFeedbackDetails";
const submitAnnualFeedbackAPI = BASE_URL + "submitAnualFeedback";
const submitFeedbackAPI = BASE_URL + "submitFeedback";
const getsubmittedFeedbackMonthsAPI = BASE_URL + "getsubmittedFeedbackMonths";
const getPendingEmployeessForFeedbackAPI =
  BASE_URL + "GetPendingEmployeessForFeedback";
const submitBulkFeedbackAPI = BASE_URL + "submitBulkFeedback";
const submitEmailBulkFeedbackAPI = BASE_URL + "submitEmailBulkFeedback";

export const getAllEmployeesPerformance = async (payload: any = {}) => {
  try {
    let request: any = {
      clientId: userAccess().clientCode,
      pageNumber: 1,
      pageSize: 10,
      employeeType: "",
      isDesc: false,
      columnName: "Name",
      ...payload,
    };

    const res = await api.post(getFilteredPerformingEmployeesAPI, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getAllPerformanceAnualQuestions = async (payload: any = {}) => {
  try {
    const res = await api.get(getAllPerformanceAnualQuestionsAPI);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getEmployeeAnnualDetails = async (payload: any = {}) => {
  try {
    let request: any = {
      ...payload,
    };

    const res = await api.post(getAnualFeedbackDetailsAPI, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getEmployeesFeedbackList = async (payload: any = {}) => {
  try {
    let request: any = {
      ...payload,
    };

    const res = await api.post(getemployeesFeedbackListAPI, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getEmployeesPerformanceGraphData = async (payload: any = {}) => {
  try {
    const res = await api.post(getemployeesPerformanceGraphAPI, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getEmployeeFeedbackDetails = async (payload: any = {}) => {
  try {
    const res = await api.post(getemployeeFeedbackDetailsAPI, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const submitFeedback = async (payload: any = {}) => {
  try {
    const res = await api.post(submitFeedbackAPI, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getFeedbackSubmittedMonths = async (payload: any = {}) => {
  try {
    const res = await api.post(getsubmittedFeedbackMonthsAPI, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getMultipleEmployeesPerformance = async (
  payload: any = {},
  authToken: string
) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    };
    const res = await axios.post(getPendingEmployeessForFeedbackAPI, payload, {
      headers: headers,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const submitBulkFeedbackWithToken = async (
  payload: any = {},
  authToken: string
) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    };
    const res = await axios.post(submitEmailBulkFeedbackAPI, payload, {
      headers: headers,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const submitBulkFeedback = async (payload: any = {}) => {
  try {
    const res = await api.post(submitBulkFeedbackAPI, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const submitAnnaulFeedback = async (payload: any = {}) => {
  try {
    const res = await api.post(submitAnnualFeedbackAPI, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};
