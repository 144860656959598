import { Dispatch } from "@reduxjs/toolkit";
import { AlertContainer } from "react-alert";
import { Status } from "../../../services/ApiStatus";
import { googleRegistration } from "../../../services/OnBoarding";
import { registerGoogleProps } from "../../../Types/Users/User";
import { loggedUserIn } from "../VerifyRegisteration/VerifyRegisteration.helper";

export const registerGoogleAction = async (
  creds: registerGoogleProps,
  alert: AlertContainer,
  dispatch: Dispatch<any>,
  history: any
) => {
  const response = await googleRegistration(creds);

  if (!!response && response.data) {
    if (response.data.status === Status.Success) {
      alert.success("User created successfully.");
      //Logged user in after successfully registered
      let dataObj = { Email: creds.Email, Password: "Admin@12345" };
      loggedUserIn(dataObj, dispatch, history, alert);
    } else {
      alert.error(response.data.errorMessage);
    }
  }
};
