import { createSlice } from "@reduxjs/toolkit";
import { rowPerPage } from "../../Constants";

const initialState = {
  timesheetList: [],
  timesheetWeeklyList: [],
  timesheetMonthlyList: [],
  isTimesheetListLoading: false,
  timesheetListError: "",
  isPaging: false,
  page: 0,
  timesheetPageSize: rowPerPage,
  timesheetCurrentPage: 0,
  isTimesheetDetailLoading: false,
  isTimesheetDetailListLoading: false,
  timesheetDetail: {},
  timesheetDetailError: "",
  timesheetActionPending: false,
  timesheetActionFail: false,
  clientNameList: [],
  clientNameListLoading: false,
  projectNameList: [],
  projectNameListLoading: false,
  taskListLoading: false,
  taskList: [],
  timeLogDetail: [],
  timeLogDetailLoading: false,
  addTimeLogMessage: "",
  addTimeSheetMessage: "",
};

const timesheetSlice = createSlice({
  name: "timesheet",
  initialState,
  reducers: {
    timesheetActionPending: (state) => {
      state.timesheetActionPending = true;
      state.timesheetActionFail = false
    },
    timesheetActionSuccess: (state, { payload }) => {
      state.timesheetActionPending = false;
    },
    timesheetActionFail: (state) => {
      state.timesheetActionPending = false;
      state.timesheetActionFail = true
    },
    getTimesheetListPending: (state) => {
      state.isTimesheetListLoading = true;
    },
    getTimesheetListSuccess: (state, { payload }) => {
      state.isTimesheetListLoading = false;
      state.timesheetList = payload.timesheetApprovals;
      state.timesheetListError = "";
    },
    getTimesheetListFail: (state, { payload }) => {
      state.isTimesheetListLoading = false;
      state.timesheetListError = payload;
    },
    clearTimesheetList: (state) => {
      state.timesheetList = [];
    },
    paging: (state, { payload }) => {
      state.isPaging = payload.isPaging;
      state.page = payload.page;
      state.timesheetPageSize = payload.pageSize;
      state.timesheetCurrentPage = payload.currentPage;
    },
    getTimesheetDetailPending: (state) => {
      state.isTimesheetDetailLoading = true;
    },
    getTimesheetDetailListPending: (state) => {
      state.isTimesheetDetailListLoading = true;
    },
    getTimesheetMonthlyListSuccess: (state, { payload }) => {
      state.isTimesheetDetailListLoading = false;
      state.timesheetMonthlyList = payload?.dateData;
    },
    getTimesheetMonthlyListFail: (state, { payload }) => {
      state.isTimesheetDetailListLoading = false;
      state.timesheetMonthlyList = [];
    },
    getTimesheetWeeklyListSuccess: (state, { payload }) => {
      state.isTimesheetDetailListLoading = false;
      state.timesheetWeeklyList = payload;
    },
    getTimesheetWeeklyListFail: (state, { payload }) => {
      state.isTimesheetDetailListLoading = false;
      state.timesheetWeeklyList = [];
    },
    getTimesheetDetailDonePending: (state) => {
      state.isTimesheetDetailLoading = false;
      state.isTimesheetDetailListLoading = false;
    },
    getTimesheetDetailSuccess: (state, { payload }) => {
      state.isTimesheetDetailLoading = false;
      state.isTimesheetDetailListLoading = false;
      state.timesheetDetail = payload;
      state.timesheetDetailError = "";
    },
    getTimesheetDetailFail: (state, { payload }) => {
      state.isTimesheetDetailLoading = false;
      state.isTimesheetDetailListLoading = false;
      state.timesheetDetailError = payload;
    },
    clearTimesheetDetail: (state) => {
      state.timesheetDetail = {};
      state.isTimesheetDetailLoading = false;
      state.isTimesheetDetailListLoading = false;
      state.timesheetDetailError = "";
    },
    getClientNameListPending: (state) => {
      state.clientNameListLoading = true;
    },
    getClientNameListSuccess: (state, { payload }) => {
      state.clientNameListLoading = false;
      state.clientNameList = payload;
    },
    getClientNameListFail: (state) => {
      state.clientNameListLoading = false;
      state.clientNameList = [];
    },
    getProjectNameListPending: (state) => {
      state.projectNameListLoading = true;
    },
    getProjectNameListSuccess: (state, { payload }) => {
      state.projectNameListLoading = false;
      state.projectNameList = payload;
    },
    getProjectNameListFail: (state) => {
      state.projectNameListLoading = false;
      state.projectNameList = [];
    },
    getTaskNameListPending: (state) => {
      state.taskListLoading = true;
    },
    getTaskNameListSuccess: (state, { payload }) => {
      state.taskListLoading = false;
      state.taskList = payload;
    },
    getTaskNameListFail: (state) => {
      state.taskListLoading = false;
      state.taskList = [];
    },
    getTimeLogDetailPending: (state) => {
      state.timeLogDetailLoading = true;
    },
    getTimeLogDetailSuccess: (state, { payload }) => {
      state.timeLogDetailLoading = false;
      state.timeLogDetail = payload;
    },
    getTimeLogDetailFail: (state) => {
      state.timeLogDetailLoading = false;
      state.timeLogDetail = [];
    },
    addTimeLogMessageSuccess: (state, { payload }) => {
      state.addTimeLogMessage = payload;
    },
    addTimeLogClearMessage: (state) => {
      state.addTimeLogMessage = "";
    },
    addTimeSheetMessageSuccess: (state, { payload }) => {
      state.addTimeSheetMessage = payload;
    },
    addTimeSheetClearMessage: (state) => {
      state.addTimeSheetMessage = "";
    },
  },
});

export const {
  timesheetActionFail,
  timesheetActionSuccess,
  timesheetActionPending,
  getTimesheetMonthlyListFail,
  getTimesheetMonthlyListSuccess,
  getTimesheetWeeklyListFail,
  getTimesheetWeeklyListSuccess,
  getTimesheetDetailListPending,
  getTimesheetDetailPending,
  getTimesheetDetailDonePending,
  getTimesheetDetailSuccess,
  getTimesheetDetailFail,
  clearTimesheetDetail,
  getTimesheetListPending,
  getTimesheetListSuccess,
  getTimesheetListFail,
  clearTimesheetList,
  paging,
  getClientNameListPending,
  getClientNameListSuccess,
  getClientNameListFail,
  getProjectNameListPending,
  getProjectNameListFail,
  getProjectNameListSuccess,
  getTaskNameListPending,
  getTaskNameListSuccess,
  getTaskNameListFail,
  getTimeLogDetailPending,
  getTimeLogDetailSuccess,
  getTimeLogDetailFail,
  addTimeLogMessageSuccess,
  addTimeLogClearMessage,
  addTimeSheetMessageSuccess,
  addTimeSheetClearMessage
} = timesheetSlice.actions;

export default timesheetSlice.reducer;
