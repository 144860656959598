import React, { useState, useEffect } from "react";
import ChangeUserPassword from "./ChangeUserPassword";
import EditUser from "./EditUser";
import UserDetails from "./UserDetails";
import { getProfile } from "./User.helper";
import { useDispatch, useSelector } from "react-redux";

const User: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const [editUser, setEditUser] = useState<boolean>(false);
  const [editPassword, setEditPassword] = useState<boolean>(false);
  const [viewUser, setViewUser] = useState<boolean>(true);

  const enableEdit = () => {
    setEditUser(true);
    setEditPassword(false);
    setViewUser(false);
  };
  const enablePassword = () => {
    setEditUser(false);
    setEditPassword(true);
    setViewUser(false);
  };
  const cancel = () => {
    setEditUser(false);
    setEditPassword(false);
    setViewUser(true);
  };

  useEffect(() => {
    getProfile(dispatch);
  }, []);

  const { isLoading, user } = useSelector(
    //@ts-ignore
    (state) => state.user
  );

  return (
    <>
      {viewUser && (
        <UserDetails
          enableEdit={enableEdit}
          enablePassword={enablePassword}
          user={user}
          isLoading={isLoading}
        />
      )}
      {editPassword && (
        <ChangeUserPassword cancel={cancel} user={user} isLoading={isLoading} />
      )}
      {editUser && (
        <EditUser cancel={cancel} user={user} isLoading={isLoading} />
      )}
    </>
  );
};

export default User;
