import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { TabPanel } from "../../../shared/components/TabPanel/TabPanel";
import ProfileDetails from "./ProfileDetails";
import EditCompanyDetails from "./EditCompanyDetails";
import User from "../UserDetails/User";
import { STRINGS } from "../../../Strings";

const ProfileLanding: React.FunctionComponent = () => {
  const [value, setValue] = React.useState(0);
  const [editCompany, setEditCompany] = useState<boolean>(false);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%", mt: 3 }}>
        <Tabs value={value} onChange={handleTabChange} variant="fullWidth">
          <Tab label={STRINGS.IndividualDetails} />
          <Tab label={STRINGS.CompanyDetails} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <User />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {editCompany ? (
          <EditCompanyDetails editCompany={setEditCompany} />
        ) : (
          <ProfileDetails editCompany={setEditCompany} />
        )}
      </TabPanel>
    </>
  );
};

export default ProfileLanding;
