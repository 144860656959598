export const dateFormat = "MM-DD-YYYY";
export const patonaAdmin = "Patona_Admin";
export const clientAdmin = "Client_Admin";
export const teamMember = "Team_Member";
export const rowPerPage = 10;
export const rowPerPageArray = [
  2, 3, 5, 8, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 80, 90, 100,
];
export const language = [
  { id: 0, name: "English" },
  { id: 1, name: "Hindi" },
  { id: 2, name: "Japanese" },
  { id: 3, name: "German" },
];

export const leaveTypesArray = [
  "Casual Leave",
  "Earned Leave",
  "Sick Leave",
  "Bereavement Leave",
  "Maternity Leave",
  "Paternity Leave",
  "Optional Leave",
  "Leave without pay",
];

export const periods = [
  "Upcoming",
  "Today on leave",
  "Monthly",
  "Quarterly",
  "Annual",
  "Custom",
];

export const timesheetPeriods = [
  "All",
  "This month",
  "This week",
  "Last week",
  "Last month",
];

export const QuarterPeriods = [
  { id: 1, value: "Q1 (Jan to Mar)" },
  { id: 2, value: "Q2 (Apr to Jun)" },
  { id: 3, value: "Q3 (Jul to Sep)" },
  { id: 4, value: "Q4 (Oct to Dec)" },
];
export const leaveStatusArray = [
  "Pending",
  // "Upcoming",
  // "Today on leave",
  "Approved",
  "Rejected",
];


export const timesheetStatusArray = ["All", "Pending", "Approved", "Rejected"];

export const reactQuillModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
export const reactQuillFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export const REWARD_TYPES = {
  Incentive: "Incentive",
  ADayOff: "A day off",
  Guddies: "Goodies",
  Chocalates: "Chocolates",
  AmazonVoucher: "Amazon voucher",
};

export const FEEDBACK_TYPES = {
  Monthly: "Monthly",
  Reward: "Reward",
  ImmedicateAction: "Immediate Action",
};
