import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);

export const verifyRegistrationSchema = Yup.object({
  Password: Yup.string()
    .min(8, "Passwords must be at least 8 characters.")
    .minLowercase(1, "password must contain at least 1 lower case letter")
    .minUppercase(1, "password must contain at least 1 upper case letter")
    .minNumbers(1, "password must contain at least 1 number")
    .minSymbols(1, "password must contain at least 1 special character")
    .required("Please fill the password"),
  ConfirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("Password"), null], "Passwords must match"),
});
