import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

type searchProps = {
  getSearch?: (string) => void;
  closeSearch?: () => void;
  placeHolder?: string;
  sx?: any;
  isDisabled?:boolean
};

const Search: React.FunctionComponent<searchProps> = (props) => {
  const {
    getSearch = () => null,
    closeSearch = () => null,
    placeHolder = "Search",
    sx = {},
    isDisabled
  } = props;
  const [searchPeople, setSearchPeople] = useState<any>("");
  const [searchTrue, setSearchTrue] = useState<boolean>(false);

  useEffect(() => {
    if (searchPeople) {
      const timeoutId = setTimeout(() => {
        getSearch(searchPeople);
        setSearchTrue(true);
      }, 1000);
      return () => clearTimeout(timeoutId);
    } else if (searchPeople === "" && searchTrue) {
      getSearch("");
    }
  }, [searchPeople]);

  return (
    <TextField
      sx={sx}
      fullWidth
      id="clientName"
      name="clientName"
      variant="outlined"
      placeholder={placeHolder}
      autoComplete="off"
      inputProps={{ maxLength: 100 }}
      value={searchPeople}
      onChange={(event) => setSearchPeople(event.target.value)}
      disabled={isDisabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={() => getSearch(searchPeople)} >
              <SearchIcon width={20}  />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              {searchPeople && (
                <ClearIcon
                width={20}
                  onClick={() => {
                    getSearch(" ");
                    setSearchPeople("");
                    closeSearch();
                  }}
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Search;
