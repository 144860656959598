import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useEffect, useState } from "react";
import TabPanel from "../common/TabPanel";

import AccordionList from "./AccordionList";
import { Typography } from "@mui/material";

function tabAriaProps(index: number) {
  return {
    id: `performance-tab-${index}`,
    "aria-controls": `performance-tabpanel-${index}`,
  };
}

const InterviewQuestionList = ({ questionsList, addNoteFun, disabled = false, isShortlistStage=false, isPostInterview=false }) => {
  const [tabValue, setTabValue] = useState<number>(0);
  const [subjects, setSubjects] = useState({});


  useEffect(() => {
    const newSubjectCountObject = {}
    questionsList.forEach((q) => {
      if (newSubjectCountObject[q.subject]) {
        newSubjectCountObject[q.subject]++
      } else {
        newSubjectCountObject[q.subject] = 1
      }
    })
    setSubjects(newSubjectCountObject)

  }, [questionsList])
  const handleTabChange = (
    event: React.SyntheticEvent,
    newTabValue: number
  ) => {
    setTabValue(newTabValue);
  };

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            background: "#fff",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            marginTop: "12px",
          }}
          className="question-list-tabs"
        >
          {questionsList.length === 0 ? <Typography sx={{
            p: 1
          }}>Please wait while we generate relevant questions</Typography> : <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="fullWidth"
            aria-label="basic tabs example"
          >
            {Object.keys(subjects).map(subjectTab => {
              return (<Tab label={subjectTab} {...tabAriaProps(0)} key={subjectTab} />)

            })}
          </Tabs>}
        </Box>
      </Box>

      <Box className="inteview-questions-list-accorditions-box">

        {Object.keys(subjects).map((subjectTab, index) => {
          return (<TabPanel tabValue={tabValue} index={index} key={subjectTab}>

            <Box>
              <AccordionList
                isShortlistStage={isShortlistStage}
                isPostInterview={isPostInterview}
                disabled={disabled}
                addNoteFun={addNoteFun}
                questionsList={questionsList.filter(
                  ({ subject }) => subject === subjectTab
                )}
              />
            </Box>
          </TabPanel>)

        })}


      </Box>
    </Box>
  );
};

export default InterviewQuestionList;
