import { userAccess } from "../utils/CommonFunction";
import api from "./BaseApi";

const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL;
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const getDashboardDataUrl = DASHBOARD_URL + "getDashboardData";
const getWorkableClientsUrl = DASHBOARD_URL + "getAllWorkableClients";
const getCountAndFilterUrl = DASHBOARD_URL + "getCountsAndFilterValues";
const filterDashboardDataUrl = DASHBOARD_URL + "getFilteredDashboardData";
const getFilteredLeaveApprovalDashboardDataUrl =
  BASE_URL + "getFilteredLeaveApprovalDashboardData";

export const getDashboardData = async (frmData) => {
  try {
    const res = await api.post(getDashboardDataUrl, frmData);
    return res;
  } catch (error) {
    console.log(error, "~~~~~~~~getDashboardData error");
  }
};

export const getWorkableClients = async () => {
  try {
    const res = await api.get(getWorkableClientsUrl);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getCountAndFilterData = async () => {
  try {
    let request: any = {
      clientId: 0,
    };
    request.clientId = userAccess().clientCode;
    const res = await api.post(getCountAndFilterUrl, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

// export const filterDashboard = async (frmData) => {
//   try {
//     const res = await api.post(filterDashboardDataUrl, frmData);
//     return res;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const filterDashboard = async (payload: any = "") => {
  try {
    let request: any = {
      clientId: 0,
      jobTitle: "",
      locationCode: "",
      month: 0,
      searchValue: "",
      isDesc: true,
      columnName: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.clientId = userAccess().clientCode.toString();
    const res = await api.post(filterDashboardDataUrl, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const dashboardCalender = async (payload: any = "") => {
  try {
    let request: any = {
      clientId: 0,
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.clientId = userAccess().clientCode;
    const res = await api.post(
      getFilteredLeaveApprovalDashboardDataUrl,
      request
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};


type locationPayload = "Pune" | "Philippines";
export const getPublicHolidayList = async (payload: locationPayload = "Pune") => {
  try {
    const response = await api.post(`${BASE_URL}getallHolidayListRegionWise`, { location: payload });
    return response
  } catch (error) {
    console.log(error);
  }
}