import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { styles } from "./UserStyle";
import { useDispatch } from "react-redux";
import UserList from "./UserList";
import { clientAdmin } from "../../Constants";

const Users: React.FunctionComponent = () => {
  const classes = styles();
  const [getUsersType, setGetUsersType] = React.useState<string>(clientAdmin);
  const [staticCompany, setStaticCompany] = React.useState<string>("");

  return (
    <Box sx={{ flexGrow: 1 }}>
      <UserList
        getUserListType={getUsersType}
        staticCompany={staticCompany}
        back={() => null}
      />
    </Box>
  );
};

export default Users;
