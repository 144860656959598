import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import { useAlert } from "react-alert";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { verifyRegistrationSchema } from "../../../schemas";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

//styles
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import { getToken, resetPasswordAction } from "./ForgotPassword.helper";

const initialValues = {
  email: "",
  Password: "",
  ConfirmPassword: "",
  tokenId: "",
};

//To get the query params
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ResetPassword = () => {
  const { resetPassPending } = useSelector(
    //@ts-ignore
    (state) => state.login
  );
  let query = useQuery();
  const guid = query.get("ref");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const classes = useStyles();
  const [isTokenLoading, setIsLoading] = React.useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getToken(guid, alert, navigate).then((decoded: any) => {
      initialValues.email = decoded.email;
      initialValues.tokenId = guid?.toString() || "";
      setIsLoading(false);
    });
  }, [guid]);

  const {
    values,
    errors,
    touched,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: verifyRegistrationSchema,
    onSubmit: (values, action) => {
      const confirmPassword: { ConfirmPassword?: string } = {
        ConfirmPassword: values.ConfirmPassword,
      };
      delete confirmPassword["ConfirmPassword"];
      const payload = {
        email: values.email,
        password: values.Password,
      };
      resetPasswordAction(payload, dispatch, navigate, alert);
      action.resetForm();
    },
  });

  return (
    <>
      <Container maxWidth="xl" disableGutters={true}>
        <Grid
          container
          component="main"
          spacing={0}
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}>
          <Grid item sm={4} md={6}>
            <Box>
              <img
                src="/images/Teamified-Logo-Black.svg"
                alt="Teamified logo"
              />
            </Box>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <img src="/images/signup-banner.svg" alt="sign up" />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6} className={classes.right_column}>
            <Box
              sx={{
                display: "grid",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}>
              <Box>
                <Typography variant="body1" align="center" mb={3}>
                  Reset your password
                </Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "block",
                    maxWidth: "480px",
                    margin: "auto",
                    pointerEvents: isTokenLoading ? "none" : "auto",
                  }}
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}>
                  <TextField
                    error={Boolean(touched.Password && errors.Password)}
                    helperText={touched.Password && errors.Password}
                    margin="normal"
                    fullWidth
                    type="password"
                    id="Password"
                    label="Password"
                    name="Password"
                    variant="outlined"
                    autoComplete="off"
                    value={values.Password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <TextField
                    error={Boolean(
                      touched.ConfirmPassword && errors.ConfirmPassword
                    )}
                    helperText={
                      touched.ConfirmPassword && errors.ConfirmPassword
                    }
                    margin="normal"
                    fullWidth
                    type="password"
                    id="ConfirmPassword"
                    label="Confirm Password"
                    name="ConfirmPassword"
                    variant="outlined"
                    autoComplete="off"
                    value={values.ConfirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Button
                    disabled={!dirty}
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    sx={{ mt: 2 }}>
                    {(isTokenLoading || resetPassPending) && (
                      <CircularProgress size={18} color="warning" />
                    )}
                    {(!isTokenLoading || !resetPassPending) && "Confirm"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ResetPassword;
