import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  Pagination,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import * as React from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import {
  rowPerPageArray,
  rowPerPage as rowPerPageCount,
} from "../../../Constants";
import { STRINGS } from "../../../Strings";
import { userAccess } from "../../../utils/CommonFunction";
import Search from "../Search/Search";

type TableProps = {
  header?: Array<any>;
  row?: Array<any>;
  getDetails?: (
    arg0: number,
    arg1?: boolean,
    arg2?: Array<any>,
    arg3?: boolean
  ) => void;
  getDownload?: (number) => void;
  cloneRow?: (number, Array?) => void;
  deleteMember?: (number) => void;
  actionList?: Array<any>;
  moveToTeamMember?: (number) => void;
  disableTeamMember?: (number) => void;
  enableTeamMember?: (number) => void;
  showPaging?: boolean;
  page?: number;
  getPageList?: (number) => void;
  enableDisableRow?: (boolean, Array?) => void;
  sortTable?: (boolean, string) => void;
  headerFilterActionList?: Array<any>;
  headerSort?: (val: string) => void;
  cellClick?: (data: any) => void;
  textAction?: (data: any) => void;
  tooltipHtml?: any;
  currentPage?: number;
  rowPerPage?: (number) => void;
  defaultRowPerPageCount?: number;
  headerColor?: string;
  search?: (value?: boolean) => void;
  progressPercent?: number;
  progressTime?: any;
  punchOutTime?: any;
};

function leaveStatusColor(color: string) {
  let textColor = "#000000";
  switch (color?.toLocaleLowerCase()) {
    case "approved":
      textColor = "green";
      break;
    case "rejected":
      textColor = "red";
      break;
    case "cancelled":
      textColor = "red";
      break;

    case "pending":
      textColor = "orange";
      break;
  }
  return textColor;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const useStyles = makeStyles((theme) => ({
  outerdiv: {
    display: "flex",
    "&:hover": {
      cursor: "pointer",
      "& $addIcon": {
        visibility: "visible",
      },
    },
  },
  addIcon: {
    visibility: "hidden",
  },
  inputTextField: {
    display: "none",
  },
  when: (addIcon) => addIcon,
  subrow: {
    // borderBottom: "1px solid",
  },
}));

const DataGrid: React.FunctionComponent<TableProps> = (props) => {
  const classes = useStyles();
  const alert = useAlert();
  const dispatch = useDispatch();
  const defaultHeaderCell = [1, 2, 3, 4, 5];
  const defaultRowCell = [1, 2, 3];
  const {
    header = defaultHeaderCell,
    row = defaultRowCell,
    getDetails = () => null,
    getDownload = () => null,
    cloneRow = () => null,
    deleteMember = () => null,
    moveToTeamMember = () => null,
    disableTeamMember = () => null,
    enableTeamMember = () => null,
    actionList = [],
    showPaging = false,
    page = 0,
    getPageList = () => null,
    enableDisableRow = () => null,
    sortTable = () => null,
    headerFilterActionList = [],
    headerSort = () => null,
    cellClick = () => null,
    textAction = () => null,
    currentPage = 1,
    rowPerPage = () => null,
    defaultRowPerPageCount = rowPerPageCount,
    headerColor = "rgba(204,213,252, .5)",
    search = () => null,
    progressPercent,
    progressTime,
    punchOutTime,
  } = props;
  const [searchEnable, setSearchEnable] = React.useState<boolean>(false);
  const [updateLabel, setUpdateLabel] = React.useState<string>("");
  const [selectedRow, setSelectedRow] = React.useState<number>(
    defaultRowPerPageCount
  );

  const [isDesc, setIsDesc] = React.useState<boolean>(false);
  const [cellId, setCellId] = React.useState<number>(0);
  const [cellAction, setCellAction] = React.useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [actionPeopleId, setActionPeopleId] = React.useState<number>();
  const [rowDetails, setRowDetails] = React.useState<Array<any>>([]);
  const open = Boolean(anchorEl);

  const [anchorFilterEl, setAnchorFilterEl] =
    React.useState<null | HTMLElement>(null);
  const openFilter = Boolean(anchorFilterEl);
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorFilterEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorFilterEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const rowAction = (action) => {
    switch (action) {
      case "getDetails":
        getDetails(actionPeopleId || 0, true, rowDetails);
        break;
      case "cloneRow":
        cloneRow(actionPeopleId, rowDetails);
        break;
      case "deleteMember":
        deleteMember(actionPeopleId);
        break;
      case "moveToTeamMember":
        moveToTeamMember(actionPeopleId);
        break;
      case "disableTeamMember":
        disableTeamMember(actionPeopleId);
        break;
      case "enableTeamMember":
        enableTeamMember(actionPeopleId);
        break;
      case "getAllUsers":
        headerSort(action);
        break;
      case "getCurrentMonthUsers":
        headerSort(action);
        break;
      case "getActiveUsers":
        headerSort(action);
        break;
      case "getInactiveUsers":
        headerSort(action);
        break;
      case "download":
        getDownload(actionPeopleId);
        break;
    }
  };

  const rowColum = (value, index) => {
    let defaultView = (
      <TableCell
        key={Math.floor(Math.random() * 1000000) + index}
        colSpan={value.colSpan ? value.colSpan : 0}
      >
        <></>
      </TableCell>
    );

    switch (value.type) {
      case "heading":
        defaultView = (
          <TableCell
            key={Math.floor(Math.random() * 1000000) + index}
            colSpan={value.colSpan ? value.colSpan : 0}>
            <Box display={"flex"} alignItems={'center'}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: value.fontSize,
                  fontWeight: value.fontWeight,
                  // align: "center",
                  textTransform: value.textTransform,
                  color: value.color,
                }}
              >
                {value?.name}
              </Typography>
              {/* {value?.sortable && (
                <IconButton
                  disableRipple={true}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCellAction("sort");
                    setCellId(value?.id);
                    setIsDesc(!isDesc);
                    sortTable(!isDesc, value?.soryKey);
                  }}
                >
                  <ArrowDownwardOutlinedIcon
                    fontSize={'small'}
                    sx={{
                      transform:
                        isDesc && cellId === value?.id
                          ? "scaleY(-1)"
                          : "scaleY(1)",
                      color:
                        isDesc && cellId === value?.id && cellAction === "sort"
                          ? "black"
                          : "grey",
                    }}
                  ></ArrowDownwardOutlinedIcon>
                </IconButton>
              )} */}
            </Box>
          </TableCell>
        );
        break;
      case "headingAndSearch":
        defaultView = (
          <TableCell
            key={Math.floor(Math.random() * 1000000) + index}
            colSpan={value.colSpan ? value.colSpan : 0}>
            <Box display={"flex"} alignItems={'center'}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: value.fontSize,
                  fontWeight: value.fontWeight,
                  align: "center",
                  textTransform: value.textTransform,
                  color: value.color,
                }}
              >
                {value?.name}
              </Typography>
              {value?.sortable && (
                <IconButton
                  disableRipple={true}

                  sx={{ mt: -0.5 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCellAction("sort");
                    setCellId(value?.id);
                    setIsDesc(!isDesc);
                    sortTable(!isDesc, value?.soryKey);
                  }}
                >
                  <ArrowDownwardOutlinedIcon
                    fontSize={"small"}
                    sx={{
                      transform:
                        isDesc && cellId === value?.id
                          ? "scaleY(-1)"
                          : "scaleY(1)",
                      color:
                        isDesc && cellId === value?.id && cellAction === "sort"
                          ? "black"
                          : "grey",
                    }}
                  ></ArrowDownwardOutlinedIcon>
                </IconButton>
              )}
              <Box
                sx={{
                  display: "flex",
                  mt: -0.5,
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="text"
                  onClick={() => setSearchEnable(!searchEnable)}
                >
                  <img
                    src="/images/Union.png"
                    style={{ height: 15, width: 15 }}
                    alt="reset"
                  />
                </Button>
              </Box>
            </Box>
          </TableCell>
        );
        break;
      case "title":
        defaultView = (
          <TableCell
            key={Math.floor(Math.random() * 1000000) + index}
            colSpan={value.colSpan ? value.colSpan : 0}
            onClick={() => { value.click && cellClick(value) }}
          >
            <Box display={"flex"}>
              {value.avatar && (
                <Avatar
                  alt={value?.name}
                  src={
                    value?.imageName
                      ? value?.imageUrl
                      : "/static/images/avatar/1.jpg"
                  }
                  sx={{ marginRight: 1, height: 30, width: 30, marginTop: 1 }}
                />
              )}
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: value.fontSize,
                  fontWeight: value.fontWeight,
                  align: "center",
                  textTransform: value.textTransform,
                  color: value.color,
                }}

                py={1}>
                {value?.name}<br />{value.dateFromTo && value.dateFromTo}
              </Typography>
            </Box>
          </TableCell>
        );
        break;
      case "array":
        defaultView = (
          <TableCell
            key={Math.floor(Math.random() * 1000000) + index}
            colSpan={value.colSpan ? value.colSpan : 0}
          >
            <Box>
              {value.list?.map((listData, index) => {
                return (
                  <Typography
                    key={index}
                    variant="subtitle1"
                    sx={{
                      fontSize: value.fontSize,
                      fontWeight: value.fontWeight,
                      align: "center",
                      textTransform: value.textTransform,
                      color: value.color,
                    }}
                    py={1}
                  >
                    {listData?.name}
                  </Typography>
                );
              })}
            </Box>
          </TableCell>
        );
        break;
      case "objectarray":
        defaultView = (
          <TableCell
            key={Math.floor(Math.random() * 1000000) + index}
            colSpan={value.colSpan ? value.colSpan : 0}
          >
            <Box>
              {value.list?.map((listData, index) => {
                return (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      key={index}
                      variant="h6"
                      sx={{
                        fontSize: value.fontSize,
                        fontWeight: "600",
                        align: "center",
                        textTransform: value.textTransform,
                        color: value.color,
                      }}
                      py={1}
                    >
                      {listData?.name}
                    </Typography>
                    <Typography
                      key={index}
                      variant="subtitle1"
                      sx={{
                        fontSize: value.fontSize,
                        fontWeight: value.fontWeight,
                        align: "center",
                        textTransform: value.textTransform,
                        color: leaveStatusColor(listData?.value),
                      }}
                      py={1}
                    >
                      {listData?.value}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </TableCell>
        );
        break;
      case "object":
        defaultView = (
          <TableCell
            key={Math.floor(Math.random() * 1000000) + index}
            colSpan={value.colSpan ? value.colSpan : 0}
          >
            <Box>
              <Typography
                key={index}
                variant="h6"
                sx={{
                  fontSize: value.fontSize,
                  fontWeight: "600",
                  align: "center",
                  textTransform: value.textTransform,
                  color: value.color,
                }}
                py={1}
              >
                {value?.list?.name}
              </Typography>
              <Typography
                key={index}
                variant="subtitle1"
                sx={{
                  fontSize: value.fontSize,
                  fontWeight: value.fontWeight,
                  align: "center",
                  textTransform: value.textTransform,
                  color: value.color,
                }}
                py={1}
              >
                {value?.list?.value}
              </Typography>
            </Box>
          </TableCell>
        );
        break;
      case "text":
        defaultView = (
          <TableCell
            onClick={() => { value.click && cellClick(value) }}
            key={Math.floor(Math.random() * 1000000) + index}
            colSpan={value.colSpan ? value.colSpan : 0}
          >
            <Grid
              className={classes.outerdiv}
              sx={{ maxWidth: 400 }}
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: value.fontSize,
                  fontWeight: value.fontWeight,
                  align: "center",
                  textTransform: value.textTransform,
                  color: value.color,
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
                py={1}
                position={"relative"}
              >
                {value?.name}
                {value?.experience && (
                  <span>{`(${value?.experienceLevel})`}</span>
                )}
                {value.progressBar && !punchOutTime && (
                  <Box
                    width={"122%"}
                    display={"flex"}
                    alignItems={"center"}
                    position={"absolute"}
                    left={30}
                  >
                    <Box
                      sx={{
                        width: `${progressPercent}%`,
                        maxWidth: "100%",
                        minWidth: value.name === "00:00" ? "0%" : "2%",
                      }}
                      ml={3}
                    >
                      <LinearProgress
                        color="success"
                        variant="buffer"
                        sx={{
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: "inherit",
                          },
                          "& .MuiLinearProgress-dashed": {
                            backgroundImage:
                              "radial-gradient(#00E08C 20%, #00E08C 16%, transparent 45%)",
                          },
                        }}
                        valueBuffer={1}
                        value={1}
                      />
                    </Box>
                    <span>{progressTime}</span>
                  </Box>
                )}
              </Typography>
              {/* <TextField
                margin="normal"
                fullWidth
                label={updateLabel}
                variant="outlined"
                autoComplete="off"
                inputProps={{ maxLength: 25 }}
                value={""}
                className={classes.inputTextField}
                // onChange={handleChange}
                // onBlur={handleBlur}
              /> */}
              {value?.editable && (
                <Button
                  className={classes.addIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    //handleEditColumnClick(e);
                  }}
                >
                  <CreateOutlinedIcon
                    sx={{ mr: 2 }}
                    color="action"
                  ></CreateOutlinedIcon>
                </Button>
              )}
            </Grid>
          </TableCell>
        );
        break;

      case "filter":
        defaultView = (
          <TableCell
            colSpan={value.colSpan ? value.colSpan : 0}
            key={index}
            sx={{ textAlign: "right" }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleFilterClick(e);
              }}
            >
              <FilterAltIcon />
            </IconButton>
          </TableCell>
        );
        break;
      case "textAction":
        defaultView = (
          <TableCell
            colSpan={value.colSpan ? value.colSpan : 0}
            key={Math.floor(Math.random() * 1000000) + index}
            sx={{ textAlign: "right" }}
          >
            <Button onClick={() => textAction(value)} variant="text">
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: value.fontSize,
                  fontWeight: value.fontWeight,
                  align: "center",
                  textTransform: value.textTransform,
                  color: value.color,
                }}
                py={1}
              >
                {value?.name}
              </Typography>
            </Button>
          </TableCell>
        );
        break;
      case "rightAlignText":
        defaultView = (
          <TableCell
            colSpan={value.colSpan ? value.colSpan : 0}
            key={Math.floor(Math.random() * 1000000) + index}
            sx={{ textAlign: "right" }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: value.fontSize,
                fontWeight: value.fontWeight,
                align: "center",
                textTransform: value.textTransform,
                color: value.color,
              }}
              py={1}
            >
              {value?.name}
            </Typography>
          </TableCell>
        );
        break;
      case "button":
        defaultView = (
          <TableCell
            key={Math.floor(Math.random() * 1000000) + index}
            colSpan={value.colSpan ? value.colSpan : 0}
          >
            <FilterAltIcon />
          </TableCell>
        );
        break;
      case "switch":
        defaultView = (
          <TableCell
            key={Math.floor(Math.random() * 1000000) + index}
            colSpan={value.colSpan ? value.colSpan : 0}
          >
            <Switch defaultChecked />
          </TableCell>
        );
        break;
      case "outlined_button":
        defaultView = (
          <TableCell
            key={Math.floor(Math.random() * 1000000) + index}
            sx={{
              textAlign: value?.textAlign ? value?.textAlign : "right",
              ...value?.sx,
            }}
          >
            <Button onClick={() => cellClick(value)} variant="outlined">
              {value?.name}
            </Button>
          </TableCell>
        );
        break;
      case "chip":
        defaultView = (
          <TableCell key={Math.floor(Math.random() * 1000000) + index}>
            <Chip label={value?.name} color={value?.color} />
          </TableCell>
        );
        break;
      case "searchIcon":
        defaultView = (
          <TableCell key={Math.floor(Math.random() * 1000000) + index}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mr: 1 }}
            >
              <Typography>{""}</Typography>
              <SearchIcon onClick={() => setSearchEnable(!searchEnable)} />
            </Box>
          </TableCell>
        );
        break;
      case "action":
        defaultView = (
          <TableCell
            key={index}
            sx={{ textAlign: "right", pl: 0 }}
            colSpan={value.colSpan ? value.colSpan : 0}
          >
            {userAccess().client && value?.isPatonaTeamMember ? (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <MoreVertIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(e);
                  setActionPeopleId(value?.peopleId);
                  if (!_.isUndefined(value?.details)) {
                    setRowDetails(value?.details);
                  }
                  if (!_.isUndefined(value?.details)) {
                    enableDisableRow(value?.disable, value?.details);
                  } else {
                    enableDisableRow(value?.disable, rowDetails);
                  }
                }}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </TableCell>
        );
        break;
      case "info":
        defaultView = (
          <TableCell
            key={Math.floor(Math.random() * 1000000) + index}
            colSpan={value.colSpan ? value.colSpan : 0}
          >
            <HtmlTooltip title={props.tooltipHtml(value.toolTipInfo)}>
              <IconButton sx={{ ml: -6 }}>
                <InfoOutlinedIcon />
              </IconButton>
            </HtmlTooltip>
          </TableCell>
        );
        break;
    }

    return defaultView;
  };

  return (
    <>
      <TableContainer>
        <Table
          sx={{
            marginTop: 1,
            marginBottom: 1,
            borderCollapse: 'separate',
            borderSpacing: '0 16px'
          }}>
          <TableHead
            sx={{
              backgroundColor: '#fff'
            }}>
            <TableRow>
              {!searchEnable &&
                header?.map((value, index) => {
                  if (value.display) {
                    return rowColum(value, index);
                  } else {
                    return null;
                  }
                })}
              {searchEnable && (
                <TableCell colSpan={header?.length}>
                  <Box sx={{ display: "flex" }}>
                    <Search getSearch={search} closeSearch={() => null} />
                    <Button
                      onClick={() => {
                        search();
                        setSearchEnable(false);
                      }}
                    >
                      <UndoIcon></UndoIcon>
                    </Button>
                  </Box>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {row?.map((rawValue, index) => {
              return (
                <>
                  <TableRow
                    sx={{
                      backgroundColor: rawValue.isDisabled
                        ? "#ecedf2"
                        : "white",
                      "& td": {
                        borderBottom: 0
                      },
                    }}
                    key={index}
                    onClick={() => {
                      getDetails(rawValue.teamMemberId, false, rawValue.details)
                    }
                    }
                    hover={true}>
                    {rawValue?.columns?.map((v, index) => {
                      if (v.display) {
                        return rowColum(v, index);
                      } else {
                        return null;
                      }
                    })}
                  </TableRow>
                  {rawValue?.subRow?.length > 0 && (
                    <TableRow
                      className={classes.subrow}
                      sx={{
                        backgroundColor: rawValue.isDisabled
                          ? "#ecedf2"
                          : "white",
                      }}
                      key={Math.floor(Math.random() * 1000000) + index}
                      onClick={() =>
                        getDetails(
                          rawValue.teamMemberId,
                          false,
                          rawValue.details,
                          true
                        )
                      }
                      hover={false}
                    >
                      {rawValue?.subRow?.map((v, index) => {
                        if (v.display) {
                          return rowColum(v, index);
                        } else {
                          return null;
                        }
                      })}
                    </TableRow>
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
        <Menu
          anchorEl={anchorFilterEl}
          id="filter-menu"
          open={openFilter}
          onClose={handleFilterClose}
          onClick={handleFilterClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {!!headerFilterActionList &&
            headerFilterActionList?.length > 0 &&
            headerFilterActionList.map((action, index) => {
              return (
                action.display && (
                  <MenuItem
                    key={Math.floor(Math.random() * 1000000) + index}
                    onClick={() => rowAction(action.action)}
                  >
                    {action.name}
                  </MenuItem>
                )
              );
            })}
        </Menu>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {!!actionList &&
            actionList?.length > 0 &&
            actionList.map((action, index) => {
              return (
                action.display && (
                  <MenuItem
                    key={Math.floor(Math.random() * 1000000) + index}
                    onClick={() => rowAction(action.action)}
                    disabled={action.disabled ? true : false}
                  >
                    {action.name}
                  </MenuItem>
                )
              );
            })}
        </Menu>
      </TableContainer>
      {row.length === 0 && (
        <Typography variant="subtitle1" py={3} align={"center"}>
          {"No Record found"}
        </Typography>
      )}
      {showPaging && (
        <Box display={"flex"} flexDirection={"row-reverse"} mt={1} mb={2}>
          <Pagination
            count={page}
            page={currentPage}
            onChange={(e, pageNumber) => getPageList(pageNumber)}
          />
          <Box display={"flex"}>
            <Typography>{STRINGS.RowsPerPage}</Typography>
            <FormControl>
              <Select
                value={selectedRow}
                sx={{ height: 35, ml: 1 }}
                onChange={(e) => {
                  const row = Number(e.target.value);
                  setSelectedRow(row);
                  rowPerPage(row);
                }}
              >
                {rowPerPageArray?.map((row, index) => {
                  return (
                    <MenuItem key={index} value={row}>
                      {row}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DataGrid;
