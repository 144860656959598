import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataGrid from "../../../shared/components/DataGrid/DataGrid";
import MobileSkeleton from "../../../shared/components/Skeletons/MobileSkeleton";
import TableSkeleton from "../../../shared/components/Skeletons/TableSkeleton";
import {
  getWeekStartAndEndDate,
  userAccess,
} from "../../../utils/CommonFunction";
import {
  Columns,
  attendanceRow,
  getAttendanceList,
} from "../attendance.helper";

const flexProperty = {
  display: "flex",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  mr: 2,
};

const AttendanceList = ({ progressPercent,progressTime,punchOutTime }) => {
  const [weeklyView, setWeeklyView] = useState<number>(0);
  const [startEndDate, setStartEndDate] = useState("");
  const dispatch = useDispatch();
  const { email } = userAccess();
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const skeleton = isMobile ? <MobileSkeleton /> : <TableSkeleton />;
  const { attendanceList, attendanceLoading } = useSelector(
    //@ts-ignore
    (state) => state.attendance
  );

  useEffect(() => {
    var d = new Date();
    const daysMinus = d.getDate() + weeklyView * 7;
    d.setDate(daysMinus);
    const { start, end } = getWeekStartAndEndDate(d);
    const startDate = start ? moment(new Date(start)).format("DD-MM-YYYY") : "";
    const enDate = start ? moment(new Date(end)).format("DD-MM-YYYY") : "";
    const dateName = `${startDate} to ${enDate}`;
    setStartEndDate(dateName);
    getAttendanceList(dispatch, {
      sDate: startDate,
      eDate: enDate,
      emailId: email,
    });
  }, [weeklyView]);

  //sort attendanceList according to date
  const entries = Object.entries(attendanceList);
  entries.sort((a, b) => {
    const dateA: any = moment(a[0], "YYYY-MM-DD");
    const dateB: any = moment(b[0], "YYYY-MM-DD");
    return dateA - dateB;
  });
  const sortedObj = Object.fromEntries(entries);
  //sort attendanceList according to date

  return (
    <>
      <Grid
        container
        justifyContent={"space-between"}
        sx={{ backgroundColor: "#fff" }}
        p={2}
        mt={2}
      >
       
        <Grid item xs={12} sm={6} md={7} sx={{ ...flexProperty }}>
          {
            <IconButton
              onClick={() => {
                setWeeklyView(weeklyView - 1);
              }}
            >
              <ArrowBackIosIcon sx={{ mr: -2 }} />
            </IconButton>
          }
          <Typography variant="h6" sx={{ fontWeight: "normal", ml: 1 }}>
            {startEndDate}
          </Typography>
          {
            <IconButton
              disabled={weeklyView === 0 ? true : false}
              onClick={() => {
                setWeeklyView(weeklyView + 1);
              }}
            >
              <ArrowForwardIosIcon sx={{ mr: 1 }} />
            </IconButton>
          }
        </Grid>
      </Grid>
      <Grid>
        {attendanceLoading ? (
          skeleton
        ) : (
          <DataGrid
            header={Columns}
            row={attendanceRow(
              Object.keys(sortedObj).map((item: any) => {
                return {
                  ...attendanceList[item],
                  date: item,
                };
              })
            )}
            progressPercent={progressPercent}
            progressTime={progressTime}
            punchOutTime={punchOutTime}
          />
        )}
      </Grid>
    </>
  );
};
export default AttendanceList;
