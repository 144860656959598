/* eslint-disable no-dupe-args */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Rating from "@mui/material/Rating";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ErrorMessage, Field } from "formik";
import { useState } from "react";
import CustomRatings from "../Ratings/CustomRatings";

import { OutlinedInput, TextField } from "@mui/material";
import { userAccess } from "../../../utils/CommonFunction";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#6781F6",
  },
  "& .MuiRating-iconHover": {
    color: "#6781F6",
  },
});

export default function FormikInput({
  type = "text",
  name,
  className = "",
  inputType = "",
  options,
  readonly = false,
  starIconStyle = {},
  schemaChange = false,
  schemaOnChangeFun = (value) => {},
  formikProps: { values, setFieldValue, errors, touched, initialValues },
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={`formik-input-div ${className}`}>
      <Field
        render={({ field, form }) => {
          switch (inputType) {
            case "textarea":
              return <TextField {...field} {...rest} readOnly={readonly} />;
            case "outlined-input":
              return (
                <FormControl>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    {rest.label}
                  </InputLabel>
                  <OutlinedInput {...field} {...rest} readOnly={readonly} />
                </FormControl>
              );
            case "password":
              return (
                <FormControl
                  sx={{ m: 1, width: "25ch" }}
                  className="password-form-control"
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    {...field}
                    {...rest}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              );
            case "checkbox":
              return (
                <FormControlLabel
                  required
                  control={<Checkbox {...field} {...rest} />}
                  {...field}
                  {...rest}
                />
              );
            case "multiple-checkbox":
              return (
                <FormGroup>
                  {options.length > 0 &&
                    options.map((checkboxItem, index) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            {...rest}
                            value={checkboxItem.value?.checkboxItem.value}
                          />
                        }
                        label={checkboxItem.name?.checkboxItem.name}
                      />
                    ))}
                </FormGroup>
              );
            case "rating":
              return (
                <>
                  <Typography variant="h6">{rest.label}</Typography>
                  <Box className="rating-form-control">
                    <StyledRating
                      defaultValue={initialValues[name]}
                      {...field}
                      {...rest}
                      onChange={(event, value) => setFieldValue([name], value)}
                      icon={
                        <StarIcon fontSize="inherit" style={starIconStyle} />
                      }
                      emptyIcon={
                        <StarOutlineIcon
                          fontSize="inherit"
                          style={starIconStyle}
                        />
                      }
                    />
                  </Box>
                </>
              );
            case "custom-rating":
              const { ClientId } = userAccess();
              return (
                <>
                  <Typography variant="h6">{rest.label}</Typography>
                  <Box className="rating-form-control">
                    <CustomRatings
                      rating={initialValues[name]}
                      {...field}
                      {...rest}
                      color={ClientId === 0 ? "#6781F6" : "#DB9BF3"}
                      onChange={(value) => {
                        setFieldValue([name], value);
                      }}
                    />
                  </Box>
                </>
              );
            case "radio-button":
              return (
                <>
                  <Typography variant="h6">{rest.label}</Typography>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={initialValues[name]}
                    onChange={(e) => {
                      if (schemaChange) {
                        schemaOnChangeFun(e.target.value);
                      }
                      setFieldValue([name], e.target.value);
                    }}
                    row
                    name="radio-buttons-group"
                  >
                    {options?.map((radioItem, index) => (
                      <FormControlLabel
                        key={`radio-item${index}`}
                        value={radioItem.value}
                        control={<Radio />}
                        label={radioItem.name}
                      />
                    ))}
                  </RadioGroup>
                </>
              );
            case "select":
              return (
                <>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      {rest.label}
                    </InputLabel>
                    <Select
                      defaultValue={initialValues[name]}
                      {...field}
                      {...rest}
                      onChange={(e) => {
                        if (schemaChange) {
                          schemaOnChangeFun(e.target.value);
                        }
                        setFieldValue([name], e.target.value);
                      }}
                    >
                      {options?.map((selectItem, index) => (
                        <MenuItem
                          key={`select-item${index}`}
                          value={selectItem.value}
                        >
                          {selectItem.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              );
            case "date-picker":
              return (
                <>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        value={values[name]}
                        {...field}
                        {...rest}
                        onChange={(value) => {
                          if (schemaChange) {
                            schemaOnChangeFun(value);
                          }
                          setFieldValue([name], value);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </>
              );
            default:
              return <TextField {...field} {...rest} readOnly={readonly} />;
          }
        }}
        type={type}
        name={name}
        className={errors[name] && touched[name] ? "input-error" : null}
      />
      <ErrorMessage name={name} component="span" className="error" />
    </div>
  );
}
