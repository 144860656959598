import { STRINGS } from "../../../Strings";
import { registrationInvite } from "../../../services/OnBoarding";
import {
  registrationPending,
  registrationSuccess,
  registrationError,
} from "../../../redux/Registration/userRegistrationSlice";
import { userProps } from "../../../Types/Users/User";
import { Dispatch } from "@reduxjs/toolkit";
import { AlertContainer } from "react-alert";
import { Status } from "../../../services/ApiStatus";



export const sendInvite = async (
  data: userProps,
  dispatch: Dispatch<any>,
  history: any,
  alert: AlertContainer
) => {
  //dispatch(registrationPending());
  const response = await registrationInvite(data);
  if (!!response?.data) {
    if (response.data.status === Status.Success) {
      dispatch(registrationSuccess(response.data.errorMessage));
      history.push({
        pathname: "/info",
        state: {
          title: STRINGS.verificationToEmail,
          subTitle1: STRINGS.linkToEmail.params({hours: '24'}),
          subTitle2: "",
        },
      });
    } else {
      dispatch(registrationError(response.data.errorMessage));
      alert.error(response.data.errorMessage);
    }
  }
};
