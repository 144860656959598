import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useState } from "react";

import HolidayListModal from "./HolidayListModal";
import { useStyles } from "./styles";

const UpcomingPublicHoliday = ({ data, isAdmin = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <Card
        onClick={() => setIsModalOpen(true)}
        className={classes.holidayCard}
      >
        <CardContent className={classes.holidayCardContent} >
          <Typography variant="h6" align="left" sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
            {isAdmin && "Upcoming Public Holiday"}
            {!isAdmin && "Upcoming Holiday"}
          </Typography>
          {data.holidayDatas?.map((holiday: any, index: any) => (
            <Grid container xs={12} key={index}>
              <Grid item xs={6} sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                <Typography variant="caption" pt={1} align="center">
                  {holiday?.holidayName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" pt={1} align="center">
                  {holiday?.holidaydate}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </CardContent>
      </Card>

      <HolidayListModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export default UpcomingPublicHoliday;
