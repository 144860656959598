import { rowPerPage } from "../Constants";
import api from "../services/BaseApi";
import { userAccess } from "../utils/CommonFunction";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const getFilteredTimesheetApprovalData =
  BASE_URL + "getFilteredTimesheetApprovalData";
const GetTimesheetDetails = BASE_URL + "GetTimesheetDetails";
const GetTeamMemberTimesheets = BASE_URL + "GetTeamMemberTimesheets";
const getWeeklyTimesheetDetails = BASE_URL + "GetWeeklyTimeLogsDetails";
const getMonthlyTimesheetDetails = BASE_URL + "GetMonthlyTimesheetDetails";

const getLeaveSummaryDataByEmail =
  BASE_URL + "getLeaveSummaryDataByEmailAndZohoId";
const getFilteredLeaveApprovalCalendarData =
  BASE_URL + "getFilteredLeaveApprovalCalendarData";
const getLeaveRequestsShortInfo = BASE_URL + "getLeaveRequestsShortInfo";
const approveTimesheet = BASE_URL + "approveTimesheet";
const GetAllProjectListForClientFromZ =
  BASE_URL + "GetAllProjectListForClientFromZ";
const getAllClients = BASE_URL + "getAllClients";
const GetAllTaskListForProjectFromZ =
  BASE_URL + "GetAllTaskListForProjectFromZ";
const AddTimeLogs=BASE_URL+"AddTimeLogs"
const GetWeeklyTimeLogsDetails=BASE_URL+'GetWeeklyTimeLogsDetails'
const AddTimeSheet=BASE_URL+'AddTimeSheet'

export const calenderApprovals = async (payload) => {
  try {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    let request: any = {
      clientId: 0,
      searchValue: "",
      timesheetApprovalStatus: "",
    };

    if (payload) {
      request = { ...request, ...payload };
    }
    request.clientId = userAccess().clientCode;
    const res = await api.post(getFilteredLeaveApprovalCalendarData, request);
    return res;
  } catch (error) {
    console.log(error, "===== approvals calender list API Error");
    //return error;
  }
};

export const weeklyTimesheetDetails = async (payload) => {
  try {
    let request: any = {
      zohoID: "",
      fromDate: "",
      toDate: "",
    };

    if (payload) {
      request = { ...request, ...payload };
    }

    const res = await api.post(getWeeklyTimesheetDetails, request);
    return res;
  } catch (error) {
    console.log(error, "===== timesheet list weekly API Error");
    //return error;
  }
};

export const monthlyTimesheetDetails = async (payload) => {
  try {
    let request: any = {
      zohoID: "",
      month: "",
      year: "",
    };

    if (payload) {
      request = { ...request, ...payload };
    }

    const res = await api.post(getMonthlyTimesheetDetails, request);
    return res;
  } catch (error) {
    console.log(error, "===== timesheet list monthly API Error");
    //return error;
  }
};

export const timesheets = async (payload) => {
  try {
    let request: any = {
      pageNumber: 1,
      pageSize: rowPerPage,
      clientId: 0,
      searchValue: "",
      isDesc: true,
      columnName: "",
      role: "Patona_Admin",
      period: "",
      timesheetApprovalStatus: "",
    };

    if (payload) {
      request = { ...request, ...payload };
    }
    request.timesheetApprovalStatus =
      request.timesheetApprovalStatus === "All"
        ? ""
        : request.timesheetApprovalStatus;
    request.period = request.period === "All" ? "" : request.period;
    request.clientId = userAccess().clientCode;
    request.role = userAccess().role;
    const res = await api.post(getFilteredTimesheetApprovalData, request);
    return res;
  } catch (error) {
    console.log(error, "===== timesheet list API Error");
    //return error;
  }
};

export const timesheetById = async (payload) => {
  try {
    let request: any = {
      timesheetRecordId: 0,
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(GetTimesheetDetails, request);
    return res;
  } catch (error) {
    console.log(error, "===== timesheet detail By Id API Error");
    // return error;
  }
};

export const approvalLeaveSummary = async (payload) => {
  try {
    let request: any = {
      email: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(getLeaveSummaryDataByEmail, request);
    return res;
  } catch (error) {
    console.log(error, "===== approval leave summary API Error");
    //return error;
  }
};

export const getLeaveShortInfo = async (payload) => {
  try {
    let request: any = {
      date: new Date(),
      status: "approved",
    };

    if (payload) {
      request = { ...request, ...payload };
    }
    request.clientId = userAccess().clientCode;
    const res = await api.post(getLeaveRequestsShortInfo, request);
    return res;
  } catch (error) {
    console.log(error, "===== approval leave summary API Error");
    //return error;
  }
};

export const timesheetActionService = async (payload) => {
  try {
    let request: any = {
      timesheetID: "",
      client: "",
      role: "",
      firstName: "",
      lastName: "",
      email: "",
      status: "0",
      remark: "",
    };

    if (payload) {
      request = { ...request, ...payload };
    }
    request.role = userAccess().role;
    request.firstName = userAccess().firstName;
    request.lastName = userAccess().lastName;
    request.email = userAccess().email;
    const res = await api.post(approveTimesheet, request);
    return res;
  } catch (error) {
    console.log(error, "===== approval leave action API Error");
    //return error;
  }
};

export const teamMemberTimesheetById = async (payload) => {
  try {
    let request: any = {
      zohoId: 0,
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(GetTeamMemberTimesheets, request);
    return res;
  } catch (error) {
    console.log(error, "===== timesheet detail By Id API Error");
    // return error;
  }
};

export const projectsNameList = async (payload) => {
  try {
    let request: any = {
      empEmailId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(GetAllProjectListForClientFromZ, request);
    return res;
  } catch (error) {
    console.log(error, "===== projects List API Error");
    // return error;
  }
};

export const clientsNameList = async () => {
  try {
    const res = await api.get(getAllClients);
    return res;
  } catch (error) {
    console.log(error, "===== client List API Error");
    // return error;
  }
};

export const taskList = async (payload) => {
  try {
    let request: any = {
      projectId: "",
      empEmailId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(GetAllTaskListForProjectFromZ, request);
    return res;
  } catch (error) {
    console.log(error, "===== task List API Error");
    // return error;
  }
};

export const addingTimeLogs=async(payload)=>{
  try {
    let request: any = {
      workDate: "",
      empEmailId: "",
      jobId: "",
      hours: ""
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(AddTimeLogs, request);
    return res;
  } catch (error) {
    console.log(error, "===== AddTimeLogs API Error");
    // return error;
  }
}

export const addingTimeSheet=async(payload)=>{
  try {
    let request: any = {
      empEmailId: "",
      timesheetName: "",
      timesheetDescription: "",
      fromDate: "",
      toDate: "",
      projectId: "",
      jobId: ""
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(AddTimeSheet, request);
    return res;
  } catch (error) {
    console.log(error, "===== AddTimeSheet API Error");
    // return error;
  }
}

export const fetchTimeLogDetail=async(payload)=>{
  try {
    let request: any = {
      zohoID:"",
      fromDate: '',
      toDate: '',
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(GetWeeklyTimeLogsDetails, request);
    return res;
  } catch (error) {
    console.log(error, "===== AddTimeLogs API Error");
    // return error;
  }
}