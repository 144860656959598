import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface SelectBoxProps {
  label: string;
  dataArray: any[];
  handleChange: (event: SelectChangeEvent<any>) => void;
  selectValue: string;
  error?: boolean;
  helperText?: any;
  onBlur?: any;
  name?:string
}

const SelectBox: React.FC<SelectBoxProps> = ({
  label,
  dataArray,
  handleChange,
  selectValue,
  error,
  helperText,
  onBlur,
  name
}: SelectBoxProps) => {
  return (
    <FormControl fullWidth error={error}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name={name}
        value={selectValue}
        label={label}
        onChange={handleChange}
        MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
        onBlur={onBlur}
      >
        {dataArray?.map((item) => (
          <MenuItem key={item?.id} value={item?.id}>
            {item?.name}
          </MenuItem>
        ))}
      </Select>
      {error ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};

export default SelectBox;
