import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import { memo } from "react"
import CloseIcon from '@mui/icons-material/Close';
import TaskInfo from "./TaskInfo";
import PriorityBadge from "./Components/PriorityBadge";


const TaskInfoModal = ({ showModal, closeModal, }) => {
    return (
        <Dialog open={showModal !== null} onClose={closeModal} maxWidth='md' sx={{

        }}>
            <DialogTitle variant="h4" fontWeight={400} fontSize={'32px'} display={'flex'}>
                Task
                {/* <PriorityBadge badgeType={showModal?.priority}></PriorityBadge> */}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={closeModal}
                sx={{
                    position: 'absolute',
                    right: 16,
                    top: 16,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{
                py: 0,
                pb: '1rem',
                minWidth: '50vw'
            }}>
                <TaskInfo taskInfo={showModal}></TaskInfo>
            </DialogContent>
        </Dialog>
    )
}

export default memo(TaskInfoModal)