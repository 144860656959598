import { Box } from "@mui/system";
import { styles } from "./HireStyle";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { memo, useEffect, useState } from "react";
import { getHireCandidateStagingHelper } from "./Hire.helper";

const CandidateListStageHeader = ({ stageCandidateList, selectedStage, jobId, initialStage }) => {

    //@ts-ignore
    const { hireCandidateStaging } = useSelector(state => state.hire)

    const [stagesData, setStagesData] = useState<Array<any>>([])

    const dispatch = useDispatch()

    useEffect(() => {
        if (stagesData.length > 0) {
            if (initialStage) {
                const res = stagesData.find(el => el.code.toLowerCase() === initialStage?.toLowerCase())
                if (res) stageCandidateList(res)
                else stageCandidateList(stagesData[0])
            } else {
                stageCandidateList(stagesData[0])
            }

        }
    }, [stagesData, initialStage])

    useEffect(() => {
        if (hireCandidateStaging.length) {
            setStagesData(hireCandidateStaging)
        }
    }, [hireCandidateStaging])

    useEffect(() => {
        if (jobId) {
            getHireCandidateStagingHelper(jobId, dispatch)
        }
    }, [jobId])
    const classes = styles()
    return (
        <Box
            className={classes.pageHeader}>
            <Grid
                spacing={2}
                p={2}
                display={"flex"}
                textAlign={"center"}>
                {stagesData?.map((stage, index) => {
                    return (
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"space-around"}
                            key={index}
                            flex={1}
                            className={
                                selectedStage?.code ===
                                    stage?.code
                                    ? classes.candidate_Selected_stage
                                    : classes.candidate_stage
                            }
                            onClick={() => stageCandidateList(stage)}>

                            <Box>
                                <Typography
                                    variant="body1">
                                    {stage?.count}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant="caption">
                                    {stage?.name}
                                </Typography>
                            </Box>
                        </Box>
                    );
                })}
            </Grid>
        </Box>)
}

export default memo(CandidateListStageHeader)