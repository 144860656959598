import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import PublishIcon from "@mui/icons-material/Publish";
import { STRINGS } from "../../Strings";
import SelectBox from "../../shared/components/SelectBox/SelectBox";

const ReferModal = ({ handleOpenClose, open }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.2)",
    padding: "16px",
    outline: "none",
    width: "auto",
    maxWidth: "90vw",
    maxHeight: "90vh",
  };

  const handleClick = (e: any) => {
    const fileInput = document.getElementById(
      "choose-file"
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleOpenClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box component="form" noValidate>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            textAlign="center"
          >
            Refer Now
          </Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6} sm={6} md={6}>
              <TextField fullWidth label="Candidates's name*" />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField fullWidth label="Job title*" />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <SelectBox
                dataArray={[{ name: "abc" }, { name: "bcd" }]}
                handleChange={() => {}}
                onBlur={false}
                name={"abc"}
                selectValue={"abc"}
                label={"Project Name"}
                error={Boolean(false)}
                helperText={"hmbx"}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField fullWidth label="Currently working" />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField fullWidth label="Email Id*" />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField fullWidth label="Contact number*" />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                id="input-with-icon-textfield"
                onClick={handleClick}
                label="Upload candidate's resume*"
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <>
                      <input
                        style={{ display: "none" }}
                        id="choose-file"
                        type="file"
                        onChange={(e: any) => console.log(e.target.files[0])}
                      />
                      <label htmlFor="choose-file">
                        <IconButton
                          aria-label="upload"
                          component="span"
                          onClick={(e: any) => e.stopPropagation()}
                        >
                          <PublishIcon />
                        </IconButton>
                      </label>
                    </>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="text" sx={{ mt: 2 }} onClick={handleOpenClose}>
                  {STRINGS.Cancel}
                </Button>
                <Button variant="contained" sx={{ mt: 2 }}>
                  Submit
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReferModal;
