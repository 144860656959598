import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    minHeight: 150,
    backgroundColor: "white",
    borderRadius: 16,
    outline: "none",
    padding: 24,
    ["@media (max-width:800px)"]: {
      width: "calc(100% - 48px)",
      padding: "16px 10px",
    },
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  closeIcon: {
    fontSize: 30,
    color: "black",
    ["@media (max-width:800px)"]: {
      fontSize: 24,
    },
  },
  modalBody: {
    marginTop: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 12
  },
  commentBox: {
    border: "1px solid black",
    borderRadius: 4,
    padding: 8,
  },
  actions: {
    marginTop: 24,
    display: "flex",
    justifyContent: "flex-end",
    gap: 12,
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  }
}));

export { useStyles };
