import { userAccess } from "../utils/CommonFunction";
import api from "./BaseApi";

const getClientsUrl = process.env.REACT_APP_BASE_API_URL + "getAllClients";
const addClientsUrl = process.env.REACT_APP_BASE_API_URL + "addClient";
const getClientByIdUrl = process.env.REACT_APP_BASE_API_URL + "getClientById";
const getClientByNameUrl =
  process.env.REACT_APP_BASE_API_URL + "getClientByName";
const toggleClientStatusUrl =
  process.env.REACT_APP_BASE_API_URL + "toggleActiveInactiveStatus";
const clientInviteUrl =
  process.env.REACT_APP_ONBAORDING_URL + "signUpClientInvite";
const updateClientUrl = process.env.REACT_APP_BASE_API_URL + "updateClient";
const clientUsersUrl =
  process.env.REACT_APP_ONBAORDING_URL + "getallclientadminsforclient";
const getFilteredClientsUrl =
  process.env.REACT_APP_BASE_API_URL + "getFilteredClients";
const getAllClientAdminsForClient =
  process.env.REACT_APP_ONBAORDING_URL + "getAllClientAdminsForClient";

export const getClients = async () => {
  try {
    const res = await api.get(getClientsUrl);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const saveClient = async (frmData) => {
  try {
    let request: any = {
      uId: "",
    };
    if (frmData) {
      request = { ...request, ...frmData };
    }
    request.uId = userAccess().userId;
    const res = await api.post(addClientsUrl, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getClientById = async (id) => {
  let req = { Id: id };
  try {
    const res = await api.post(getClientByIdUrl, req);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getClientByName = async (name) => {
  let req = { Name: name };
  try {
    const res = await api.post(getClientByNameUrl, req);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const toggleClientStatus = async (req) => {
  try {
    let request: any = {
      uId: "",
    };
    if (req) {
      request = { ...request, ...req };
    }
    request.uId = userAccess().userId;
    const res = await api.post(toggleClientStatusUrl, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateClientRecord = async (req) => {
  try {
    let request: any = {
      uId: "",
    };
    if (req) {
      request = { ...request, ...req };
    }
    request.uId = userAccess().userId;
    const res = await api.post(updateClientUrl, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const signUpClientInvite = async (req) => {
  try {
    const res = await api.post(clientInviteUrl, req);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const clientUsersList = async (id) => {
  let req = { ClientId: id };
  try {
    const res = await api.post(clientUsersUrl, req);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getFilteredClients = async (payload: any = "") => {
  try {
    let request: any = {
      PageNumber: 1,
      PageSize: "10",
      SearchValue: "",
      ClientId: 0,
      IsDeleted: false,
      isDesc: false,
      columnName: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.ClientId = userAccess().clientCode;
    const res = await api.post(getFilteredClientsUrl, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getFilteredClientAdmin = async (payload: any = "") => {
  try {
    const res = await api.post(getAllClientAdminsForClient, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};
