import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { inviteAdminInviteSchema } from "../../schemas/admin-invites";

import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { clientAdmin, patonaAdmin } from "../../Constants";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { STRINGS } from "../../Strings";
import { AdminRoleDropdown, userAccess } from "../../utils/CommonFunction";
import { getAllWorkClients, saveClient, saveSuccessFully } from "./User.helper";

const initialValues = {
  firstName: "",
  lastName: "",
  ClientName: "",
  email: "",
  role: "",
  roleId: "",
  operation: "CLIENT_ADMIN_INVITE",
  clientID: "",
  IsAllAccess: true,
  createdBy: "",
  fetchRole: "",
};

type SendInvitesProps = {
  show: boolean;
  close: () => void;
  type: string;
  invitedEmail: (val: string) => void;
  invitationSent: () => void;
};

const SendInvites: React.FunctionComponent<SendInvitesProps> = (props) => {
  const isMobile = useMediaQuery(`(max-width: 760px)`);

  const style = {
    width: isMobile ? "70%" : "50%",
    overflowY: "scroll",
    height: "65%",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };
  const alert = useAlert();
  const dispatch = useDispatch();
  const [selectedClients, setSelectedClients] = useState<any>("");
  const [formAction, setFormAction] = useState<any>("");
  const [selectedInviteRole, setInviteSelectedRole] = useState<any>("");
  const [companyName, setCompanyName] = useState<string>("");

  const { allClients, isClientUserAdded, clientAddingInProgress } = useSelector(
    //@ts-ignore
    (state) => state.user
  );
  const { allWorkableClients } = useSelector(
    //@ts-ignore
    (state) => state.hire
  );

  useEffect(() => {
    getAllWorkClients(dispatch);
  }, []);

  useEffect(() => {
    if (isClientUserAdded) {
      props.close();
      saveSuccessFully(dispatch);
      formAction.resetForm();
      props.invitationSent();
    }
  }, [isClientUserAdded]);

  useEffect(() => {
    if (userAccess().client) {
      initialValues.clientID = userAccess().ClientId.toString();
      setFieldValue("clientID", userAccess().ClientId.toString());
      setCompanyName(userAccess().companyName);
    } else if (props.type !== clientAdmin) {
      initialValues.clientID = "0";
      setFieldValue("clientID", "0");
    } else {
      setFieldValue("clientID", "");
    }
  }, [props.type]);

  const {
    values,
    errors,
    touched,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: inviteAdminInviteSchema,
    onSubmit: (values, action) => {
      values.ClientName = companyName;
      values.clientID = `${values.clientID}`;
      values.operation =
        values.role === clientAdmin
          ? "CLIENT_ADMIN_INVITE"
          : values.role === patonaAdmin
          ? "PATONA_ADMIN_INVITE"
          : "TEAM_MEMBER_INVITE";
      values.role = values.role;
      values.roleId =
        values.role === clientAdmin
          ? "d9ae615b-9cb0-4ab0-b759-5f76bde01487"
          : values.role === patonaAdmin
          ? "24b13116-ef70-4b0d-af96-a6a5a1820b8b"
          : "23c3fffb-c5da-4b39-8113-c8fe8d2df819";
      values.createdBy = userAccess().userId;
      values.fetchRole = props.type;
      props.invitedEmail(values.email);
      saveClient(values, dispatch);
      setFormAction(action);
    },
  });
  

  return (
    <>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={props.show}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2" mb={2}>
            {STRINGS.SendNewInvite}
          </Typography>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ pointerEvents: clientAddingInProgress ? "none" : "auto" }}
          >
            <TextField
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              margin="normal"
              fullWidth
              id="firstName"
              label={STRINGS.FirstName}
              name="firstName"
              variant="outlined"
              autoComplete="off"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              margin="normal"
              fullWidth
              id="lastName"
              label={STRINGS.LastName}
              name="lastName"
              variant="outlined"
              autoComplete="off"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {values.role !== patonaAdmin && values.role.length > 0 &&(
              <Grid container sx={{ mt: 1 }}>
                <FormControl fullWidth>
                  <InputLabel>{STRINGS.Company}</InputLabel>
                  <Select
                    error={Boolean(touched.clientID && errors.clientID)}
                    value={selectedClients}
                    id="clientID"
                    name="clientID"
                    label={STRINGS.Company}
                    onChange={(e) => {
                      setSelectedClients(e.target.value);
                      setFieldValue("clientID", e.target.value);
                    }}
                  >
                    <MenuItem value={0} key={0}>
                      {"All"}
                    </MenuItem>
                    {allWorkableClients &&
                      allWorkableClients?.map((e, key) => {
                        return (
                          <MenuItem
                            value={e.id}
                            onClick={(event) => {
                              event.stopPropagation();
                              setCompanyName(e.name);
                            }}
                            key={key}
                          >
                            {e.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <div
                    style={{
                      marginTop: 10,
                      marginLeft: 5,
                      color: "#D14343",
                    }}
                  >
                    {touched.clientID && errors.clientID
                      ? errors.clientID
                      : null}
                  </div>
                </FormControl>
              </Grid>
            )}
            <TextField
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              margin="normal"
              fullWidth
              id="email"
              label={STRINGS.EmailAddress}
              name="email"
              variant="outlined"
              autoComplete="off"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Grid container sx={{ mt: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="Role">{STRINGS.SelectRole}</InputLabel>
                <Select
                  disabled={userAccess().role === "Client_Admin" ? true : false}
                  error={Boolean(touched.role && errors.role)}
                  helperText={touched.role && errors.role}
                  //@ts-ignore
                  margin="normal"
                  fullWidth
                  id="role"
                  label={STRINGS.SelectRole}
                  name="role"
                  variant="outlined"
                  autoComplete="off"
                  value={
                    userAccess().role === "Client_Admin"
                      ? props.type
                      : values.role
                  }
                  onChange={(e) => {
                    setInviteSelectedRole(e.target.value);
                    setFieldValue("role", e.target.value);
                  }}
                >
                  {AdminRoleDropdown?.map((e, key) => {
                    return (
                      <MenuItem value={e.id} key={key}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 5,
                    color: "#D14343",
                  }}
                >
                  {touched.role && errors.role ? errors.role : null}
                </div>
              </FormControl>
            </Grid>
            <Grid container sx={{ mt: 1 }}>
              <Box
                display={"flex"}
                alignItems={"center"}
                sx={{ pointerEvents: "none" }}
              >
                <Typography variant="subtitle1">{STRINGS.AllAccess}</Typography>
                <Checkbox defaultChecked />
              </Box>
            </Grid>

            {/* <Grid container sx={{ mt: 1, boxShadow: 20, padding: 2 }}>
              <Grid
                mt={1}
                mb={1}
                xs={12}
                display={"flex"}
                justifyContent={"space-between"}
                className={
                  userAccess().client ? "page-header-pink" : "page-header-blue"
                }>
                <Grid item xs={12} sm={6} md={14}>
                  <Typography variant="subtitle1">{"Features"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="subtitle1">{"View and edit"}</Typography>
                </Grid>
              </Grid>
              <Grid container ml={2}>
                <Grid item xs={12} sm={6} md={10}>
                  <Typography variant="subtitle1">{"Dashboard"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox defaultChecked />
                </Grid>
              </Grid>

              <Grid container ml={2}>
                <Grid item xs={12} sm={6} md={10}>
                  <Typography variant="subtitle1">{"Team member"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox defaultChecked />
                </Grid>
              </Grid>

              <Grid container ml={2}>
                <Grid item xs={12} sm={6} md={10}>
                  <Typography variant="subtitle1">
                    {"Removed team member"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox defaultChecked />
                </Grid>
              </Grid>
              <Grid container ml={2}>
                <Grid item xs={12} sm={6} md={10}>
                  <Typography variant="subtitle1">
                    {"Individual details"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox defaultChecked />
                </Grid>
              </Grid>
              <Grid container ml={2}>
                <Grid item xs={12} sm={6} md={10}>
                  <Typography variant="subtitle1">
                    {"Company details"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox defaultChecked />
                </Grid>
              </Grid>
            </Grid> */}
            <Box display="flex" justifyContent="flex-end">
              <Button variant="text" sx={{ mt: 2 }} onClick={props.close}>
                {STRINGS.Cancel}
              </Button>
              <Button
                disabled={!dirty}
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
              >
                {clientAddingInProgress && (
                  <CircularProgress size={18} color="warning" />
                )}
                {!clientAddingInProgress && STRINGS.SaveAndSendInvite}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SendInvites;
