import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    "& p": {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      WebkitLineClamp: 3,
    },
    "& ul": {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      WebkitLineClamp: 3,
    },
  },
  card: {
    height: "362px",
    borderRadius: "0 0 10px 10px",
    boxShadow: "0px 4px 8px 0px #00000026",
    background: "#FFFFFF",
    overflow: "hidden",
    padding: "18px",
  },
  cardDetail: {
    height: "310px",
    overflow: "hidden",
  },
  cardHeader: {
    backgroundColor: "#F7EBFC",
    height: "46px",
    boxShadow: "0px 4px 8px 0px #00000026",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "10px 10px 0 0 ",
  },
}));

export { useStyles };
