/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { styles } from "./HireStyle";
import {
  Typography,
  Grid,
  Box,
  Card,
  Divider,
  useMediaQuery,
} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import TextSkeleton from "../../shared/components/Skeletons/TextSkeleton";
import { STRINGS } from "../../Strings";
import { useSelector } from "react-redux";
import _ from "lodash";
import { getBase64Download } from "../People/People.helper";

const ViewCandidateWorkExperience: React.FunctionComponent<any> = (props) => {
  const classes = styles();
  const { headerColor = "#fffaea", details, viewSkills } = props;

  const isMobile = useMediaQuery(`(max-width: 760px)`);

  // useEffect(() => {
  //   if (!_.isNull(details)) {
  //     if (details?.skillSet?.length > 1) {
  //       const skills = details?.skillSet.reduce(function (a, b) {
  //         return (a.skill || a) + ", " + b.skill;
  //       });
  //       setViewSkills(skills);
  //     } else if (details?.skillSet?.length === 1) {
  //       const skills = details?.skillSet[0].skill;
  //       setViewSkills(skills);
  //     } else {
  //       setViewSkills("");
  //     }
  //   }
  // }, [details]);

  return (
    <Card
      className="tbl-card"
      sx={{
        minWidth: 275,
        marginBottom: 2,
        marginTop: 2,
        boxShadow: 10,
        width: '100%'

      }} >
      <Grid
        container
        spacing={2}

        sx={{
          backgroundColor: headerColor,
          paddingLeft: "1.5rem",
          paddingBottom: "0.5rem",
          paddingTop: "0.5rem",
          justifyContent: "flex-start",
          minHeight: 52,
        }}>
        <Grid item xs={11} display={"flex"} justifyContent={"space-between"}>
          <Typography variant="subtitle2" component="div">
            {STRINGS.WorkInformation}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Box
        className="tbl-body"
        sx={{
          padding: "1.5rem",
        }}>
        <Grid container spacing={2} sx={{ display: "flex" }}>
          <Grid item xs={6}>
            <Typography variant="body2" component="div">
              {STRINGS.JobTitle}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {props.isMemLoading ? (
              <TextSkeleton />
            ) : (
              <Typography variant="body2" component="div">
                {details.jobTitle}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", paddingTop: "1.0rem" }}>
          <Grid item xs={6}>
            <Typography variant="body2" component="div">
              {STRINGS.Experience}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {props.isMemLoading ? (
              <TextSkeleton />
            ) : (
              <Typography variant="body2" component="div">
                {details.experience}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{ display: "flex", paddingTop: "1.0rem" }}>
          <Grid item xs={6}>
            <Typography variant="body2" component="div">
              {STRINGS.Skills}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {props.isMemLoading ? (
              <TextSkeleton />
            ) : (
              <Typography variant="body2" component="div">
                {viewSkills}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", paddingTop: "1.0rem" }}>
          <Grid item xs={6}>
            <Typography variant="body2" component="div">
              {STRINGS.Resume}
            </Typography>
          </Grid>
          {details.file && (
            <Grid item xs={5}>
              {props.isMemLoading ? (
                <TextSkeleton />
              ) : (
                <Typography variant="body2" component="div">
                  {details.fileName}
                </Typography>
              )}
            </Grid>
          )}
          {details.file && (
            <Grid item xs={1}>
              {props.isMemLoading ? (
                <TextSkeleton />
              ) : (
                <FileDownloadOutlinedIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    getBase64Download(details.fileName, details.file);
                  }}
                  style={{ color: "#002DFF" }}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </Card>
  );
};

export default ViewCandidateWorkExperience;
