import { Dispatch } from "@reduxjs/toolkit";
import { sagaActions } from "../../../redux/sagaActions";
import { userAccess } from "../../../utils/CommonFunction";
import { userUpdatedFail } from "../../../redux/User/userSlice";

export const getProfile = async (dispatch: Dispatch<any>) => {
  try {
    const payload = {
      userId: userAccess().userId,
    };
    dispatch({ type: sagaActions.GET_PROFILE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getTimeZone = async (dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.GET_TIMEZONE });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const updateProfile = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.UPDATE_PROFILE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const resetUpdateProfileError = async (dispatch: Dispatch<any>) => {
  dispatch(userUpdatedFail(false));
};

export const updatePassword = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.CHANGE_PASSWORD, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getBase64File = (file: string, b64: any) => {
  const fileExtension = file.split(".").pop();
  let data = ``;
  switch (fileExtension) {
    case "jpg":
      data = `data:image/jpeg;base64,`;
      break;
    case "JPG":
      data = `data:image/jpeg;base64,`;
      break;
    case "jpeg":
      data = `data:image/jpeg;base64,`;
      break;
    case "png":
      data = `data:image/png;base64,`;
      break;
    case "PNG":
      data = `data:image/png;base64,`;
      break;
  }
  return data + b64;
};

export const imageUpload = (
  e,
  setFileName,
  setFile,
  alert,
  setIsPicUpdated,
  setUploadfile
) => {
  e.preventDefault();
  let reader = new FileReader();
  let file = e.target.files[0];
  if (!/(\.jpg|\.jpeg|\.png)$/i.test(file.name)) {
    alert.error("Expected file type .jpg,.jpeg, .png");
    return false;
  }
  reader.onloadend = () => {
    setFileName(file.name);
    if (!!reader) {
      // @ts-ignore
      const base64String = reader?.result
        // @ts-ignore
        ?.replace("data:", "")
        ?.replace(/^.+,/, "");
      setUploadfile(base64String);
      setFile(reader?.result);
      setIsPicUpdated(true);
    }
  };
  reader.readAsDataURL(file);
};
