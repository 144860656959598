import { makeStyles } from "@mui/styles";
import { Typography, styled } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  m_title: {
    textTransform: "capitalize",
  },
  select: {
    ".css-1wxbabk-MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
}));

// text-transform: capitalize;
export { useStyles };
