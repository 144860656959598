
import { Paper } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { clientAdmin, patonaAdmin } from "../../Constants";
import { deleteTaskApi } from "../../services/Tasks";
import CustomizedDataGrid from "../../shared/components/CustomizedDataGrid";
import { userAccess } from "../../utils/CommonFunction";
import { getAllClientAdminsList } from "../Client/Client.helper";
import { getList } from "../Users/User.helper";
import { makeColumns } from "./Columns";
import TaskCommentListModal from "./TaskCommentListModal";
import TaskCommentsModal from "./TaskCommentsModal";
import TaskInfoModal from "./TaskInfoModal";



const TasksList = ({
    isDataLoading,
    taskList,
    handleReporterClick,
    handlePriorityClick,
    totalCount,
    pageNumber,
    pageSize,
    handlePageChange,
    handlePageSizeChange,
    refetchTasks,
}) => {
    const getRowId = (row) => row.id;
    const [showInfoModal, setShowInfoModal] = useState(null)
    const [showCommentModal, setShowCommentModal ] = useState('')
    const [showViewCommentsModal, setShowViewCommentsModal] = useState('')
    const [, setIsDeleting] = useState(false)
    const alert = useAlert()

    const dispatch = useDispatch()

    useEffect(() => {
        if (userAccess().role === clientAdmin) {
            getAllClientAdminsList(dispatch, userAccess().ClientId)
        }
        if (userAccess().role === patonaAdmin || userAccess().role === 'TMF_Admin') {
            getList(dispatch, 'getActiveUsers', 1, patonaAdmin, 50)
        }

    }, [dispatch])

    const closeModal = useCallback(() => {
        setShowInfoModal(null)
    },[])
    const closeCommentModal = useCallback(() => {
        setShowCommentModal('')
    },[])

    const closeViewCommentModal = useCallback(() => {
        setShowViewCommentsModal('')
    },[])

    const deleteTask = async (id) => {
        setIsDeleting(true)
        try{
            await deleteTaskApi(id)
            alert.success('Task Deleted Successfully')
            refetchTasks()
        }
        catch(e){
            alert.error('Error Deleting Task '+ e)
        }  

        setIsDeleting(false)
    }

    const handleMenuClick = (action: string, row: any) => {
        switch (action) {
            case 'addComment':
                setShowCommentModal(row.id);
                break;

            case 'delete': {
                deleteTask(row.id)
                break;
            }
            default:
                break;
        }
    };

    const handleOptionClick = () => {
      refetchTasks()
    }

    const handleCommentClick = (row) => {
        setShowViewCommentsModal(row.id)
    }

    const columns = makeColumns(handleMenuClick, handlePriorityClick, handleReporterClick,handleOptionClick, handleCommentClick);



    return (
        <Paper className="hire-list-container">
            <TaskInfoModal showModal={showInfoModal} closeModal={closeModal}></TaskInfoModal>
            <TaskCommentsModal showModal={showCommentModal} closeModal={closeCommentModal}></TaskCommentsModal>
            <TaskCommentListModal showModal={showViewCommentsModal} closeModal={closeViewCommentModal}></TaskCommentListModal>
            <CustomizedDataGrid
                version={2}
                customClassName="hire-list"
                rows={taskList}
                columns={columns}
                totalRowCount={totalCount}
                pageNumber={pageNumber}
                pageSize={pageSize}
                rowHeight={74}
                
                handleRowClick={(rowInfo) => {
                    setShowInfoModal(rowInfo.row)
                }}
                getRowId={getRowId}
                isDataLoading={isDataLoading}
                handlePageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
        </Paper>

    )
}

export default memo(TasksList)