import React from "react";
import { registrationSchema } from "../../../schemas";
import { useFormik } from "formik";
import { useStyles } from "./styles";
import { useDispatch } from "react-redux";
import { registrationSuccess } from "../../../redux/Registration/userRegistrationSlice";
import { useNavigate, Link } from "react-router-dom";
import { useAlert } from "react-alert";


//styles
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Grid,
} from "@mui/material";

import { sendInvite } from "./Registration.helper";
import { STRINGS } from "../../../Strings";


const initialValues = {
  FirstName: "",
  LastName: "",
  Email: "",
  CompanyName: "",
  Operation: "SIGNUP",
};

const RegisterUser: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const classes = useStyles();  

  const {
    values,
    errors,
    touched,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, action) => {
      dispatch(registrationSuccess(values));
      action.resetForm();
      sendInvite(values, dispatch, navigate, alert);
    },
  });

  return (
    <Container maxWidth="xl" disableGutters={true}>
      <Grid
        container
        component="main"
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        >
        <Grid item sm={4} md={6}>
          <Box>
            <img src="/images/Teamified-Logo-Black.svg" alt="Teamified logo" />
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" }, textAlign: "center" }}>
            <img src="/images/signup-banner.svg" alt="sign up" />
          </Box>
        </Grid>

        <Grid item xs={12} sm={8} md={6} className={classes.right_column}>
          <Box sx={{ display: "grid" }}>
            <Box>
              <Typography variant="h3" align="center" mb={3}>
                {STRINGS.createNew}
              </Typography>            
            </Box>

            <Box>
              <Box
                sx={{ display: "block", maxWidth: "480px", margin: "auto" }}
                component="form"
                noValidate
                onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.FirstName && errors.FirstName)}
                  helperText={touched.FirstName && errors.FirstName}
                  margin="normal"
                  fullWidth
                  id="FirstName"
                  label="First Name"
                  name="FirstName"
                  variant="outlined"
                  autoComplete="off"
                  value={values.FirstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextField
                  error={Boolean(touched.LastName && errors.LastName)}
                  helperText={touched.LastName && errors.LastName}
                  margin="normal"
                  fullWidth
                  id="LastName"
                  label="Last Name"
                  name="LastName"
                  variant="outlined"
                  autoComplete="off"
                  value={values.LastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextField
                  error={Boolean(touched.Email && errors.Email)}
                  helperText={touched.Email && errors.Email}
                  margin="normal"
                  fullWidth
                  id="Email"
                  label="Email"
                  name="Email"
                  variant="outlined"
                  autoComplete="off"
                  value={values.Email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextField
                  error={Boolean(touched.CompanyName && errors.CompanyName)}
                  helperText={touched.CompanyName && errors.CompanyName}
                  margin="normal"
                  fullWidth
                  id="CompanyName"
                  label="Company Name"
                  name="CompanyName"
                  variant="outlined"
                  autoComplete="off"
                  value={values.CompanyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Button
                  disabled={!dirty}
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  sx={{ mt: 2 }}>
                  Sign up
                </Button>
              </Box>
            </Box>
            <Typography variant="body2" align="center" mt={3}>
              Already have an account? <Link to="/">Login</Link>{" "}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RegisterUser;
