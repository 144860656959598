import _ from "lodash";
import { all, put, takeLatest } from "redux-saga/effects";
import { UserList } from "../../Types/Users/User";
import { Status } from "../../services/ApiStatus";
import {
  getFilteredClientAdmin,
  getFilteredClients,
  toggleClientStatus,
} from "../../services/Client";
import { getAllWorkClients } from "../../services/Hire";
import { getAllWorkableClients } from "../Hire/hireSlice";
import { sagaActions } from "../sagaActions";
import {
  getClientAdminFail,
  getClientAdminPending,
  getClientAdminSuccess,
  getClientsFail,
  getClientsPending,
  getClientsSuccess,
  paging,
} from "./clientSlice";

function* fetchClientsList(payload: any = "") {
  try {
    if (!_.isUndefined(payload?.payload?.load)) {
      yield put(getClientsPending());
    }

    const response: UserList = yield getFilteredClients(payload.payload);
    if (!!response && response?.status === Status.Success) {
      const { paginationFilter } = response.data;
      const page = Math.ceil(
        paginationFilter.count / paginationFilter.pageSize
      );
      const isPaging = page > 1 ? true : false;
      yield put(
        paging({
          page: page,
          isPaging: isPaging,
          currentPage: paginationFilter.pageNumber,
          pageSize: paginationFilter.pageSize,
        })
      );
      yield put(getClientsSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getClientsFail(response?.message));
        yield put(paging({ page: 0, isPaging: false }));
      }
    }
  } catch (error: any) {
    yield put(getClientsFail(error?.message));
    yield put(paging({ page: 0, isPaging: false }));
  }
}

function* fetchAdminClientsList(payload: any = "") {
  try {
    yield put(getClientAdminPending());

    const response = yield getFilteredClientAdmin(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getClientAdminSuccess(response?.data?.clientAdminList));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getClientAdminFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(getClientAdminFail(error?.message));
  }
}

function* fetchAllClients() {
  try {
    const response = yield getAllWorkClients();
    if (!!response && response?.status === Status.Success) {
      yield put(getAllWorkableClients(response.data));
    } else {
      yield put(getAllWorkableClients([]));
    }
  } catch (error: any) {
    yield put(getAllWorkableClients([]));
  }
}

function* disableEnableClient(payload) {
  try {
    const response = yield toggleClientStatus(payload.payload);
    if (!!response && response?.status === Status.Success) {
      const request = {
        payload: {
          PageNumber: 1,
          PageSize: "10",
          SearchValue: "",
          ClientId: 0,
          IsDeleted: false,
          isDesc: true,
          columnName: "",
        },
      };

      yield fetchClientsList(request);
      yield fetchAllClients();
    }
  } catch (error: any) {
    //yield put(memberFail(error?.message));
  }
}

export default function* watcherSaga() {
  yield all([
    yield takeLatest(sagaActions.FETCH_CLIENTS, fetchClientsList),
    yield takeLatest(sagaActions.DISABLE_ENABLE_CLIENT, disableEnableClient),
    yield takeLatest(sagaActions.FETCH_CLIENTS_ADMINS, fetchAdminClientsList),
  ]);
}
