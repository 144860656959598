import { Dispatch } from "@reduxjs/toolkit";
import _ from "lodash";
import { sagaActions } from "../../redux/sagaActions";
export const getTeamMemberTimeSheetDetails = (
  dispatch: Dispatch<any>,
  request
) => {
  try {
    dispatch({ type: sagaActions.GET_TEAM_MEMBER_TIMESHEET, payload: request });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const teamMemberTimesheetColoumn = [
  {
    id: 1,
    name: "Week",
    type: "heading",
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 3,
    name: "Client",
    type: "heading",
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 4,
    name: "Submitted Hours",
    type: "heading",
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 5,
    name: "Status",
    type: "heading",
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
];

export const teamMemberTimesheetRow = (rowValue: Array<any>) => {
  const row = rowValue?.map((rowV) => {
    let columns: any = [];
    const statusColor =
      rowV.status === "Approved"
        ? "#00E08C"
        : rowV.status === "Pending"
        ? "#F27F0D"
        : rowV.status === "Reject"
        ? "#E61A1A"
        : "#000000";

    const timeSheetName = rowV.timesheetName
      .replace("Timesheet", "")
      .replace("(", "")
      .replace(")", "");

    columns.push(
      {
        id: rowV.timesheetId,
        date: timeSheetName,
        name: timeSheetName ? timeSheetName : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        click: true,
      },
      {
        id: rowV.timesheetId,
        date: timeSheetName,
        name: rowV.client ? rowV.client : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        click: true,
      },
      {
        id: rowV.timesheetId,
        date: timeSheetName,
        name: rowV.totalHours ? rowV.totalHours : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        click: true,
      },
      {
        id: rowV.timesheetId,
        date: timeSheetName,
        name: rowV.status ? rowV.status : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: statusColor,
        fontSize: 14,
        fontWeight: "400",
        textTransform: "capitalize",
        click: true,
      }
    );
    return { ...rowV, columns };
  });
  return row;
};

export const getClientList = (dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.GET_CLIENT_LIST });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getProjectList = (dispatch: Dispatch<any>, request) => {
  try {
    dispatch({ type: sagaActions.GET_PROJECT_LIST,payload: request });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getTaskList = (dispatch: Dispatch<any>, request) => {
  try {
    dispatch({ type: sagaActions.GET_TASK_LIST, payload: request });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const addTimeLogs = (dispatch: Dispatch<any>, request) => {  
  try {
    dispatch({ type: sagaActions.ADD_TIME_LOG, payload: request });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getWeeklyTimeLogDetail = (dispatch: Dispatch<any>, request) => {  
  try {
    dispatch({ type: sagaActions.GET_WEEKLY_TIME_LOG, payload: request });
  } catch (error: any) {
    if (error) {
      console.log("error");
    } 
  }
};

export const addTimesheet = (dispatch: Dispatch<any>, request) => {  
  try {
    dispatch({ type: sagaActions.ADD_TIME_SHEET, payload: request });
  } catch (error: any) {
    if (error) {
      console.log("error");
    } 
  }
};
