import { Dispatch } from "@reduxjs/toolkit";
import _ from "lodash";
import { sagaActions } from "../../redux/sagaActions";

export const UpcomingLeaveColoumn = [
  {
    id: 1,
    name: "Date",
    type: "heading",
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 2,
    name: "Status",
    type: "heading",
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
];

export const LeaveColoumn = [
  {
    id: 1,
    name: "Date",
    type: "heading",
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 3,
    name: "Reason for leave",
    type: "heading",
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 4,
    name: "Leave type",
    type: "heading",
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
];

export const upcomingLeaveGridRow = (rowValue: any) => {
  const row = rowValue?.map((rowV, index) => {
    let columns: any = [];
    columns.push(
      {
        id: rowV.id,
        name: rowV.dateFromTo.replace(" ", "-"),
        dateFromTo: rowV.leaveType,
        type: "text",
        experience: false,
        click: true,
        display: true,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.id,
        name: rowV.status,
        type: "text",
        experience: false,
        click: true,
        display: true,
        color:
          rowV.status === "Approved"
            ? "#00E08C"
            : rowV.status === "Rejected"
            ? "#E61A1A"
            : "#F27F0D",
        fontSize: 16,
        fontWeight: "400",
        textTransform: "capitalize",
      }
    );
    return { ...rowV, columns };
  });

  return row;
};

export const leaveHistoryGridRow = (rowValue: any) => {
  const row = rowValue?.map((rowV, index) => {
    let columns: any = [];
    columns.push(
      {
        id: rowV.leaveRecordId,
        name: rowV.dateFromTo.replace(" ", "-"),
        type: "text",
        click: true,
        display: true,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.leaveRecordId,
        name: rowV.reason,
        type: "text",
        click: true,
        display: true,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.leaveRecordId,
        name: rowV.leaveType,
        type: "text",
        click: true,
        display: true,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      }
    );
    return { ...rowV, columns };
  });

  return row;
};

export const getTeamMemberLeaveShortInfo = (
  dispatch: Dispatch<any>,
  payload
) => {  
  try {
    dispatch({
      type: sagaActions.GET_TEAM_MEMBER_SHORT_LEAVE_INFO,
      payload:payload
    });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};


export const applyLeaveType = (dispatch: Dispatch<any>, request) => {
  try {
    dispatch({ type: sagaActions.GET_LEAVE_TYPE_TO_APPLY_LEAVES, payload: request });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const applyLeave = (dispatch: Dispatch<any>, request) => {
  try {
    dispatch({ type: sagaActions.APPLY_LEAVES, payload: request });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};
