import { all, put, takeLatest } from "redux-saga/effects";
import { Status } from "../../services/ApiStatus";
import {
  dashboardCalender,
  filterDashboard,
  getCountAndFilterData,
  getPublicHolidayList,
} from "../../services/Dashboard";
import { sagaActions } from "../sagaActions";
import {
  dashboardActivity,
  dashboardJobs,
  getDashboardCalendarFail,
  getDashboardCalendarPending,
  getDashboardCalendarSuccess,
  getDashboardFilterPending,
  getDashboardHeaderFail,
  getDashboardHeaderSuccess,
  getDashboardListPending,
  setHolidayListLoading,
  setPublicHolidays
} from "./dashboardSlice";

function* fetchDashboard(payload: any = "") {
  try {
    yield put(getDashboardListPending());

    const response: any = yield filterDashboard(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(dashboardActivity(response?.data?.totals));
      yield put(dashboardJobs(response?.data?.data));
      yield put(getDashboardHeaderSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(dashboardActivity([]));
        yield put(dashboardJobs([]));
        yield put(getDashboardHeaderFail());
      }
    }
  } catch (error: any) {
    yield put(dashboardActivity([]));
    yield put(dashboardJobs([]));
    yield put(getDashboardHeaderFail());
  }
}

function* fetchDashboardCalendar(payload: any = "") {
  try {
    yield put(getDashboardCalendarPending());

    const response: any = yield dashboardCalender(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getDashboardCalendarSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getDashboardCalendarFail());
      }
    }
  } catch (error: any) {
    yield put(getDashboardCalendarFail());
  }
}

function* fetchFilterDashboard() {
  try {
    yield put(getDashboardFilterPending());

    const response: any = yield getCountAndFilterData();
    if (!!response && response?.status === Status.Success) {
      yield put(getDashboardHeaderSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getDashboardHeaderFail());
      }
    }
  } catch (error: any) {
    yield put(getDashboardHeaderFail());
  }
}

function* fetchPublicHolidayList(payload: any = "") {
  try {
    yield put(setHolidayListLoading(true));
    const response: any = yield getPublicHolidayList(payload.payload);

    if (response?.data) {
      yield put(setPublicHolidays({ key: payload.payload, data: response?.data }));
    }
  } catch (error: any) {
    console.error("Error fetching public holiday list:", error);
  } finally {
    yield put(setHolidayListLoading(false));
  }
}

export default function* watcherSaga() {
  yield all([
    yield takeLatest(sagaActions.FETCH_DASHBOARD_DATA, fetchDashboard),
    yield takeLatest(
      sagaActions.FETCH_DASHBOARD_CALENDAR,
      fetchDashboardCalendar
    ),
    yield takeLatest(sagaActions.FETCH_DASHBOARD_FILTER, fetchFilterDashboard),
    yield takeLatest(sagaActions.FETCH_PUBLIC_HOLIDAY_LIST, fetchPublicHolidayList),
  ]);
}
