import { createSlice } from "@reduxjs/toolkit";
import { rowPerPage } from "../../Constants";

const initialState = {
  totalPending: 0,
  totalPendingAmount: 0,
  isPendingInvoiceLoading: false,
  pendingInvoices: [],
  pendingError: "",
  isPaging: false,
  pendingPage: 0,
  pendingCurrentPage: 0,
  pendingPageSize: rowPerPage,
  isPaidInvoiceLoading: false,
  paidInvoices: [],
  paidError: "",
  isPaidPaging: false,
  paidPage: 0,
  paidCurrentPage: 0,
  paidPageSize: rowPerPage,
  isDetailInvoiceLoading: false,
  invoiceDetails: {},
  isInvoicePreviewLoading: false,
  invoicePreview: "",
  isInvoiceDownloadLoading: false,
  invoiceDownloadingSuccessMessage: "",
  invoiceDownloadingError: "",
  isInvoiceGraphLoading: false,
  invoiceGraph: [],
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    setTotalPendingAmount: (state, { payload }) => {
      state.totalPendingAmount = payload.totalPendingAmount;
      state.totalPending = payload.totalPending;
    },
    pendingInvoiceLoading: (state) => {
      state.isPendingInvoiceLoading = true;
    },
    pendingInvoiceSuccess: (state, { payload }) => {
      state.isPendingInvoiceLoading = false;
      state.pendingInvoices = payload.invoices;
    },
    pendingInvoiceFail: (state, { payload }) => {
      state.isPendingInvoiceLoading = false;
      state.pendingError = payload;
    },
    paging: (state, { payload }) => {
      state.isPaging = payload.isPaging;
      state.pendingPage = payload.page;
      state.pendingPageSize = payload.pageSize;
      state.pendingCurrentPage = payload.currentPage;
    },
    paidInvoiceLoading: (state) => {
      state.isPaidInvoiceLoading = true;
    },
    paidInvoiceSuccess: (state, { payload }) => {
      state.isPaidInvoiceLoading = false;
      state.paidInvoices = payload.invoices;
    },
    paidInvoiceFail: (state, { payload }) => {
      state.isPaidInvoiceLoading = false;
      state.paidError = payload;
    },
    paidPaging: (state, { payload }) => {
      state.isPaidPaging = payload.isPaidPaging;
      state.paidPage = payload.paidPage;
      state.paidCurrentPage = payload.paidCurrentPage;
      state.paidPageSize = payload.paidPageSize;
    },
    invoiceDetailspending: (state) => {
      state.isDetailInvoiceLoading = true;
    },
    invoiceDetails: (state, { payload }) => {
      state.invoiceDetails = payload;
      state.isDetailInvoiceLoading = false;
    },
    invoiceDetailsFail: (state, { payload }) => {
      state.isDetailInvoiceLoading = false;
    },
    invoicePreviewpending: (state) => {
      state.isInvoicePreviewLoading = true;
    },
    invoicePreview: (state, { payload }) => {
      state.invoicePreview = payload;
      state.isInvoicePreviewLoading = false;
    },
    invoicePreviewFail: (state, { payload }) => {
      state.isInvoicePreviewLoading = false;
    },
    invoiceDownloadpending: (state) => {
      state.isInvoiceDownloadLoading = true;
    },
    invoiceDownloadSuccess: (state) => {
      state.isInvoiceDownloadLoading = false;
      state.invoiceDownloadingSuccessMessage = "Download successfully done";
      state.invoiceDownloadingError = "";
    },
    invoiceDownloadFail: (state, { payload }) => {
      state.isInvoiceDownloadLoading = false;
      state.invoiceDownloadingSuccessMessage = "";
      state.invoiceDownloadingError = payload;
    },
    invoiceDownloadComplete: (state) => {
      state.isInvoiceDownloadLoading = false;
      state.invoiceDownloadingSuccessMessage = "";
      state.invoiceDownloadingError = "";
    },
    isInvoiceGraphpending: (state) => {
      state.isInvoiceGraphLoading = true;
    },
    invoiceGraphSuccess: (state, { payload }) => {
      state.isInvoiceGraphLoading = false;
      state.invoiceGraph = payload;
    },
    invoiceGraphFail: (state) => {
      state.isInvoiceGraphLoading = false;
      state.invoiceGraph = [];
    },
  },
});

const { reducer, actions } = invoiceSlice;

export const {
  isInvoiceGraphpending,
  invoiceGraphSuccess,
  invoiceGraphFail,
  invoiceDownloadSuccess,
  invoiceDownloadFail,
  invoiceDownloadComplete,
  invoiceDownloadpending,
  invoicePreviewFail,
  invoicePreview,
  invoicePreviewpending,
  setTotalPendingAmount,
  paidInvoiceLoading,
  paidInvoiceSuccess,
  paidInvoiceFail,
  paidPaging,
  invoiceDetailsFail,
  invoiceDetails,
  invoiceDetailspending,
  paging,
  pendingInvoiceFail,
  pendingInvoiceSuccess,
  pendingInvoiceLoading,
} = actions;

export default reducer;
