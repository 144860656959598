/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { styles } from "./InvoiceStyle";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Typography,
  Grid,
  Box,
  Card,
  Container,
  useMediaQuery,
  Modal,
} from "@mui/material";

import {
  getInvoiceDownloadingById,
  getInvoicePreviewById,
  invoiceDetailHeader,
  invoiceDetailRow,
} from "./Invoice.helper";
import TextSkeleton from "../../shared/components/Skeletons/TextSkeleton";
import { TabPanel } from "../../shared/components/TabPanel/TabPanel";
import DataGrid from "../../shared/components/DataGrid/DataGrid";
import { useDispatch, useSelector } from "react-redux";
import MobileSkeleton from "../../shared/components/Skeletons/MobileSkeleton";
import TableSkeleton from "../../shared/components/Skeletons/TableSkeleton";
import _ from "lodash";
import { userAccess } from "../../utils/CommonFunction";
import moment from "moment";
import { invoiceDownloadComplete } from "../../redux/Invoice/invoiceSlice";
import { invoiceStatus } from "../../services/Invoice";

const ClientsInvoices: React.FunctionComponent<any> = (props) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const style = {
    position: "absolute" as "absolute",
    overflow: "scroll",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "70%" : "70%",
    maxHeight: "80%",
    bgcolor: "background.paper",
    border: "1px solid #aeaeae",

    boxShadow: "1px 4px 20px rgba(132, 132, 132, 0.2)",
    borderRadius: "10px",
  };
  const classes = styles();

  const {
    isDetailInvoiceLoading,
    invoiceDetails,
    isInvoicePreviewLoading,
    invoicePreview,
    isInvoiceDownloadLoading,
    invoiceDownloadingSuccessMessage,
    invoiceDownloadingError,
  } = useSelector(
    //@ts-ignore
    (state) => state.invoice
  );
  const alert = useAlert();
  const skeleton = isMobile ? <MobileSkeleton /> : <TableSkeleton />;
  const [tabViewMemberValue, setTabViewMemberValue] = useState(0);
  const dispatch = useDispatch();

  const [openPreview, setOpenPreview] = React.useState<boolean>(false);

  useEffect(() => {
    if (!_.isEmpty(invoiceDownloadingSuccessMessage)) {
      alert.success(invoiceDownloadingSuccessMessage);
      dispatch(invoiceDownloadComplete());
    }
  }, [invoiceDownloadingSuccessMessage]);

  useEffect(() => {
    if (!_.isEmpty(invoiceDownloadingError)) {
      alert.error(invoiceDownloadingError);
      dispatch(invoiceDownloadComplete());
    }
  }, [invoiceDownloadingError]);

  return (
    <>
      <Grid container component="main">
        <Container maxWidth="xl">
          <>
            <>
              <Box className={classes.company_panel} sx={{ display: "flex" }}>
                <Grid item md={8}>
                  {isDetailInvoiceLoading ? (
                    <TextSkeleton />
                  ) : (
                    <Typography
                      variant="h4"
                      sx={{
                        display: "flex",
                        textTransform: "capitalize",
                        alignItems: "center",
                      }}>
                      <Link className={classes.back_icon} to="/invoices">
                        <ArrowBackIosNewIcon />
                      </Link>
                      {`${invoiceDetails.InvoiceNumber}`}
                    </Typography>
                  )}
                </Grid>
              </Box>
              <Card
                className="tbl-card"
                sx={{
                  minWidth: 275,
                  marginBottom: 3,
                  marginTop: 2,
                  boxShadow: 10,
                }}>
                <TabPanel value={tabViewMemberValue} index={0}>
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 3,
                      }}>
                      <Typography
                        variant="h4"
                        sx={{
                          color:
                            invoiceDetails?.Status === invoiceStatus.AUTHORISED
                              ? "#E61A1A"
                              : "#00C97E",
                        }}>
                        {invoiceDetails?.Status === invoiceStatus.AUTHORISED
                          ? "Invoice awaiting payment"
                          : "Invoice Paid"}
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{
                          color:
                            invoiceDetails?.Status === invoiceStatus.AUTHORISED
                              ? "#E61A1A"
                              : "#00C97E",
                        }}>
                        {invoiceDetails?.Status === invoiceStatus.AUTHORISED
                          ? `$${invoiceDetails?.AmountDue}`
                          : `$${invoiceDetails?.AmountPaid}`}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <Typography variant="subtitle1">
                        {`Issue date- ${moment(invoiceDetails?.Date).format(
                          "DD-MM-YYYY"
                        )} `}
                        &nbsp;&nbsp;&nbsp;
                        {`Status- ${
                          invoiceDetails?.Status === invoiceStatus.AUTHORISED
                            ? "Overdue"
                            : "Paid"
                        }`}
                      </Typography>

                      <Typography variant="subtitle1">
                        {invoiceDetails?.Status === invoiceStatus.AUTHORISED
                          ? `Due date- ${moment(invoiceDetails?.DueDate).format(
                              "DD-MM-YYYY"
                            )}`
                          : `Paid date- ${moment(
                              invoiceDetails?.FullyPaidOnDate
                            ).format("DD-MM-YYYY")}`}
                      </Typography>
                    </Box>
                    {isDetailInvoiceLoading ? (
                      skeleton
                    ) : (
                      <DataGrid
                        header={invoiceDetailHeader(userAccess().client)}
                        row={invoiceDetailRow(
                          invoiceDetails,
                          userAccess().client
                        )}
                        getDetails={(id, edit, details) => {
                          if (!_.isEmpty(details)) {
                          }
                        }}
                        textAction={(data) => {
                          getInvoicePreviewById(data.id, dispatch);
                          setOpenPreview(true);
                        }}
                        cellClick={(data) => {
                          getInvoiceDownloadingById(data.id, dispatch);
                        }}
                      />
                    )}
                  </>
                </TabPanel>
              </Card>
            </>

            <Modal
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              open={openPreview}
              onClose={() => {
                setOpenPreview(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <Box sx={style}>
                <Box className={classes.detailPopUp}>
                  <Typography>{"Preview"}</Typography>
                  <CloseIcon onClick={() => setOpenPreview(false)} />
                </Box>
                <Box>
                  {isInvoicePreviewLoading ? (
                    skeleton
                  ) : (
                    <object
                      data={`data:application/pdf;base64,${invoicePreview}`}
                      type="application/pdf"
                      height="700px"
                      width="100%"></object>
                  )}
                </Box>
              </Box>
            </Modal>
          </>
        </Container>
      </Grid>
    </>
  );
};

export default ClientsInvoices;
