import * as Yup from "yup";
import { checkEmailExist, checkEmployIdExist } from "../services/People";
import { userAccess } from "../utils/CommonFunction";
import _ from "lodash";

export const addMemberSchema = Yup.object().shape({
  EmailAddress: Yup.string()
    .email()
    .test(
      "Cann't use Patona email ID",
      "Cann't use Patona email ID",
      function (value) {
        if (!_.isEmpty(value)) {
          return containsDeviceId(value);
        } else {
          return true;
        }
      }
    )
    .test("Unique Email", "Email already in use", function (value) {
      if (!_.isEmpty(value)) {
        return new Promise((resolve, reject) => {
          checkEmailExist({ emailAddress: value }).then((res) => {
            resolve(!res?.data);
          });
        });
      } else {
        return true;
      }
    })
    .required("Please enter your email"),
  EmployeeId: Yup.string()
    .test("Unique Employ", "Employ id already in use", function (value) {
      return new Promise((resolve, reject) => {
        checkEmployIdExist({ employeeId: value }).then((res) => {
          resolve(!res?.data);
        });
      });
    })
    .required("Please enter employee id"),
  ClientId: Yup.string().required("Please select client"),
});

const containsDeviceId = async (value) => {
  if (
    userAccess().client &&
    (/@patona.com.au\s*$/.test(value) || /@patona.com\s*$/.test(value))
  ) {
    return false;
  }
  return true;
};
