import Moment from "moment";
import { AlertContainer } from "react-alert";
import { Dispatch } from "@reduxjs/toolkit";
import { sagaActions } from "../../redux/sagaActions";
import _ from "lodash";
import { dateFormat } from "../../Constants";

export const timesheetAction = (dispatch: Dispatch<any>, request) => {
  try {
    dispatch({ type: sagaActions.TIMESHEET_ACTION, payload: request });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getTimesheetWeeklyList = (
  dispatch: Dispatch<any>,
  payload = {}
) => {
  try {
    let request = {};
    if (!_.isEmpty(payload)) {
      request = { ...request, ...payload };
    }
    dispatch({
      type: sagaActions.GET_TIMESHEETS_WEEKLY_DETAIL,
      payload: request,
    });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getTimesheetMonthlyList = (
  dispatch: Dispatch<any>,
  payload = {}
) => {
  try {
    let request = {};
    if (!_.isEmpty(payload)) {
      request = { ...request, ...payload };
    }
    dispatch({
      type: sagaActions.GET_TIMESHEETS_MONTHLY_DETAIL,
      payload: request,
    });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getTimesheetList = (dispatch: Dispatch<any>, payload = {}) => {
  try {
    let request = {};
    if (!_.isEmpty(payload)) {
      request = { ...request, ...payload };
    }
    dispatch({ type: sagaActions.GET_TIMESHEETS_LIST, payload: request });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getCalenderApporovalList = (
  dispatch: Dispatch<any>,
  payload = {}
) => {
  try {
    let request = {
      load: 0,
    };
    if (!_.isEmpty(payload)) {
      request = { ...request, ...payload };
    }
    dispatch({
      type: sagaActions.GET_CALENDER_APPOROVALS_LIST,
      payload: request,
    });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getLeaveRequestsShortInfo = (
  dispatch: Dispatch<any>,
  payload = {}
) => {
  try {
    let request = {
      load: 0,
    };
    if (!_.isEmpty(payload)) {
      request = { ...request, ...payload };
    }
    dispatch({
      type: sagaActions.GET_LEAVE_INFO,
      payload: request,
    });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getTimeSheetDetails = (dispatch: Dispatch<any>, request) => {
  try {
    dispatch({ type: sagaActions.GET_TIMESHEETS_DETAIL, payload: request });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const copyToClicpBoard = (alert: AlertContainer) => {
  navigator.clipboard.writeText(window.location.href);
  alert.success("Link copied to clipboard");
};

export const MemberDetailTimesheetColumns = [
  {
    id: 1,
    name: "Timesheet Name",
    soryKey: "timesheetName",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 2,
    name: "Client",
    soryKey: "Client",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 3,
    name: "Submitted Hours",
    soryKey: "submittedHours",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 4,
    name: "Status",
    soryKey: "status",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
];

export const memberDetailTimesheetColumnsRow = (rowValue: Array<any>) => {
  const row = rowValue?.map((rowV) => {
    let columns: any = [];
    columns.push(
      {
        id: rowV.id,
        name: rowV?.timesheetName ? rowV?.timesheetName : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.id,
        name: rowV?.client ? rowV?.client : "-",
        type: "text",
        avatar: true,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.id,
        name: rowV.billableHours ? rowV.billableHours : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.id,
        name: rowV?.status ? rowV?.status : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: leaveStatusColor(rowV?.status),
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        peopleId: rowV?.timesheetId,
        disable: false,
      }
    );
    return {
      ...rowV,
      columns,
      details: rowV,
      teamMemberId: rowV.timesheetId,
    };
  });

  return row;
};

export const Columns = [
  {
    id: 1,
    name: "Employee Name",
    soryKey: "employeeName",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 2,
    name: "Timesheet Name",
    soryKey: "timesheetName",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 3,
    name: "Client",
    soryKey: "Client",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 4,
    name: "Submitted Hours",
    soryKey: "submittedHours",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 5,
    name: "Status",
    soryKey: "status",
    type: "headingAndSearch",
    sortable: true,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
];

export const timesheetRow = (rowValue: Array<any>) => {
  const row = rowValue?.map((rowV) => {
    let columns: any = [];
    columns.push(
      {
        id: rowV.id,
        name: rowV.employeeName ? rowV.employeeName : "-",
        type: "title",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.id,
        name: rowV?.timesheetName ? rowV?.timesheetName : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.id,
        name: rowV?.client ? rowV?.client : "-",
        type: "text",
        avatar: true,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.id,
        name: rowV.billableHours ? rowV.billableHours : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.id,
        name: rowV?.status ? rowV?.status : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: leaveStatusColor(rowV?.status),
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        peopleId: rowV?.timesheetRecordId,
        disable: false,
      }
    );
    return {
      ...rowV,
      columns,
      details: rowV,
      teamMemberId: rowV.timesheetRecordId,
    };
  });

  return row;
};

export function leaveStatusColor(color: string) {
  let textColor = "#000000";
  switch (color?.toLocaleLowerCase()) {
    case "approved":
      textColor = "green";
      break;
    case "rejected":
      textColor = "red";
      break;
    case "cancelled":
      textColor = "red";
      break;

    case "pending":
      textColor = "orange";
      break;
  }
  return textColor;
}

export function leaveTypeColor(type: string) {
  let textColor = "#6781F6";
  switch (type?.toLocaleLowerCase()) {
    case "causal leave":
      textColor = "#6781F6";
      break;
    case "earned leave":
      textColor = "#FFC315";
      break;
    case "optional leave":
      textColor = "#4CE9AE";
      break;

    case "sick leave":
      textColor = "#6781F6";
      break;

    case "bereavement leave":
      textColor = "#4D5666";
      break;
    case "paternity leave":
      textColor = "#DB9BF3";
      break;
    case "maternity leave":
      textColor = "#DB9BF3";
      break;
    case "leave without pay":
      textColor = "#F05E4F";
      break;
  }
  return textColor;
}

export const Rows = [
  {
    columns: [
      {
        id: "1",
        name: "01-10-2023",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "2",
        name: "02-10-2023",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "3",
        name: "Mahesh Thumar",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "4",
        name: "Casual Leave",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "5",
        name: "Casual",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "6",
        name: "Dhaval S",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "6",
        name: "Approved",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "green",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
    ],
  },
  {
    columns: [
      {
        id: "1",
        name: "02-11-2022",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "2",
        name: "04-11-2023",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "3",
        name: "Raju K",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "4",
        name: "Annual Leave",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "5",
        name: "Vacation",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "6",
        name: "Dhaval S",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "6",
        name: "Rejected",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "red",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
    ],
  },
];
