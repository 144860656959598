import api from "../services/BaseApi";
import { userAccess } from "../utils/CommonFunction";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const WORKABLE_BASE_URL = process.env.REACT_APP_DASHBOARD_URL;

const getAllCandidates = BASE_URL + "GetAllCandidates";
const getAllMembers = BASE_URL + "getAllTeamMembers";
const addTeamMember = BASE_URL + "addTeamMember";
const getAllSkillsUrl = BASE_URL + "getAllSkills";
const searchTeamMembersByName = BASE_URL + "searchTeamMembersByName";
const getTeamMemberById = BASE_URL + "GetTeamMemberById";
const updatePatonaTeamMember = BASE_URL + "updatePatonaTeamMember"; //updateTeamMember
const updateTeamMember = BASE_URL + "updateTeamMember"; //updateTeamMember
const deleteTeamMember = BASE_URL + "MarkTeamMemberAsDeleted";
const restoreTeamMember = BASE_URL + "RestoreDeletedTeamMember";
const getFilteredTeamMember = BASE_URL + "GetFilteredTeamMember";
const checkIfEmailExist = BASE_URL + "checkIfEmailExist";
const checkIfEmployeeIdExist = BASE_URL + "checkIfEmployeeIdExist";
const markTeamMemberAsDisabled = BASE_URL + "markTeamMemberAsDisabled";
const markTeamMemberAsEnabled = BASE_URL + "restoreDisabledTeamMember";
const getLeaveApprovalDataForTeamMember =
  BASE_URL + "getLeaveApprovalDataForTeamMember";

const uploadProfilePhotoForPatonaTeamMember =
  BASE_URL + "uploadProfilePhotoForPatonaTeamMember";
const getZohoDesignationsURL = BASE_URL + "getZohoDesignations";
const getTeamMembersInvoices = WORKABLE_BASE_URL + "getTeamMembersInvoices";
const getTeamMemberTimesheets = BASE_URL + "GetTeamMemberTimesheets";

export const getCandidates = async () => {
  try {
    const res = await api.post(getAllCandidates, {
      PageNumber: "1",
      PageSize: "15",
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getMembers = async (payload: any = "") => {
  try {
    let request: any = "";
    if (payload.payload) {
      request = payload.payload;
    } else {
      request = {
        PageNumber: "1",
        PageSize: "10",
      };
    }
    const res = await api.post(getAllMembers, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getTeamMembersByName = async (payload: any = "") => {
  try {
    const res = await api.post(searchTeamMembersByName, payload.payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getTeamMembersByID = async (payload: any = "") => {
  try {
    const res = await api.post(getTeamMemberById, payload.payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const saveMember = async (payload) => {
  try {
    let request: any = {
      uId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.uId = userAccess().userId;
    const res = await api.post(addTeamMember, request);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const uploadProfilePhoto = async (payload) => {
  try {
    let request: any = {
      uId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.uId = userAccess().userId;
    const res = await api.post(uploadProfilePhotoForPatonaTeamMember, request);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const editMember = async (payload) => {
  try {
    let request: any = {
      uId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.uId = userAccess().userId;
    const urlUpdateMember = userAccess().client
      ? updateTeamMember
      : updatePatonaTeamMember;

    const res = await api.post(urlUpdateMember, request);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteMember = async (payload) => {
  try {
    let request: any = {
      uId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.uId = userAccess().userId;
    const res = await api.post(deleteTeamMember, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const restoreMember = async (payload) => {
  try {
    let request: any = {
      uId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.uId = userAccess().userId;
    const res = await api.post(restoreTeamMember, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAllSkills = async () => {
  try {
    const res = await api.get(getAllSkillsUrl);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getZohoDesignations = async () => {
  try {
    const res = await api.get(getZohoDesignationsURL);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getTimesheetMembers = async (payload: any = "") => {
  try {
    const res = await api.post(getTeamMemberTimesheets, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getInvoicesMembers = async (payload: any = "") => {
  try {
    const res = await api.post(getTeamMembersInvoices, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getDeletedMembers = async (payload: any = "") => {
  try {
    let request: any = {
      pageNumber: 1,
      pageSize: 10,
      clientId: 0,
      isDeleted: false,
      searchValue: "",
      isDesc: true,
      columnName: "",
      role: "Patona_Admin",
      isCompany: false,
      isPatona: false,
      employeeType: "",
    };

    if (payload.payload) {
      request = { ...request, ...payload.payload };
    }
    request.clientId = userAccess().clientCode;
    request.role = userAccess().role;
    const res = await api.post(getFilteredTeamMember, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const checkEmailExist = async (payload) => {
  try {
    const res = await api.post(checkIfEmailExist, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const checkEmployIdExist = async (payload) => {
  try {
    const res = await api.post(checkIfEmployeeIdExist, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const disableMember = async (payload) => {
  try {
    let request: any = {
      uId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.uId = userAccess().userId;
    const res = await api.post(markTeamMemberAsDisabled, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const enableMember = async (payload) => {
  try {
    let request: any = {
      uId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    request.uId = userAccess().userId;
    const res = await api.post(markTeamMemberAsEnabled, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getLeaveApproval = async (payload: any = "") => {
  try {
    let request: any = {
      email: "",
      zohoId: "",
    };

    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(getLeaveApprovalDataForTeamMember, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getResumeByUrl = async (payload: any = "") => {
  try {
    const url = payload?.url?.replace("https://people.zoho.in", ``);

    const response = await fetch(url, {
      mode: "no-cors",
      headers: {
        "Content-Type": "application/download;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, OPTIONS",
        Authorization: payload.token,
      },
    });
    const res = await response.blob();

    // .then((data) => data.blob())
    // .then((data) => {
    //   let reader = new FileReader();
    //   reader.readAsDataURL(data);
    //   reader.onloadend = () => {
    //     base64String = reader.result;
    //     return base64String;
    //   };
    // });
    // const api = axios.create({
    //   proxy: {
    //     host: "https://devteamified.azurewebsites.net",
    //     port: 3000,
    //   },
    //   timeout: 60000,
    // });
    // const res = await api.get(url, {
    //   data: {},
    //   //responseType: "blob",
    //   headers: {
    //     "Content-Type": "application/download;charset=UTF-8",
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Methods": "GET, PUT, POST, OPTIONS",
    //     Authorization: payload.token,
    //   },
    // });
    // const res = await axios.get(url, {
    //   data: {},
    //   responseType: "blob",
    //   headers: {
    //     "Content-Type": "application/download;charset=UTF-8",
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Methods": "GET, PUT, POST, OPTIONS",
    //     Authorization: payload.token,
    //   },
    // });
    //console.log(res, "+++++res");
    return res;
  } catch (error) {
    console.log(error);
  }
};
