import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const TextSkeleton: React.FunctionComponent = () => {
  return (
    <Box>
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
    </Box>
  );
};

export default TextSkeleton;
