import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  display_card: {
    backgroundColor: "#FFFFFF",
    borderRadius: "0px 0px 16px 16px",
    boxShadow: "5px 4px 20px rgba(132, 132, 132, 0.2)",
  },
  company_panel: {
    backgroundColor: "#F7EBFC",
    borderRadius: "10px",
    padding: "20px 40px",
    color: "#000000",
  },
}));

export { useStyles };
