import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  company_panel: {
    backgroundColor: "#CCD5FC",
    borderRadius: "10px",
    padding: "20px 40px",
    color: "#000000",
  },
  holidayCard: {
    "&.MuiPaper-root": {
      boxSizing: "border-box",
      height: 92,
      backgroundColor: "#FFFAEA",
      cursor: "pointer",
      padding: 12,
      ["@media (max-width:800px)"]: {
        marginLeft: 0,
        padding: 8,
      },
    },
  },
  holidayCardContent: {
    "&.MuiCardContent-root": {
      padding: 0,

      "& .MuiGrid-item:last-child": {
        textAlign: "right",
      },
    },
  },
  tableCellColor: {
    "&.MuiTableCell-root": {
      backgroundColor: "#EDEFFC",
      height: "30px",
      fontSize: "16px",
      fontFamily: "Plus Jakarta Sans",
      color: "#000000",
      fontWeight: "500",
    },
  },
  tabFonts: {
    "&.MuiTab-root": {
      fontSize: "18px",
      fontFamily: "Plus Jakarta Sans",
      fontWeight: "400",
      borderBottom:'2px solid #CCD3D5'
    },
  },
  leavePopUpFonts: {
    fontWeight: "700",
    fontSize: "16px",
  },
  leavePopUpHeading: {
    fontWeight: "500",
    fontSize: "32px",
  },
  leaveCountFonts: {
    fontWeight: "700 !important",
    fontSize: "16px !important",
  },
  blackColor: {
    color: "#000000",
    '&.Mui-focused': {
      color: "#000 !important",
    },
  },
  datePickerColor: {
    color: "#000",
    '&.Mui-focused': {
      borderColor: "#000 !important",
    },
  },
  leaveInfoHeading: {
    display:"flex",
    flexGrow:'8px',
    marginLeft:'40px',
    marginTop:'16px'
  },
  textFieldBox: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black !important',
    },
  },
}));

export { useStyles };
