import axios from "axios";
import isEmpty from "lodash/isEmpty";
import { clearClientCode } from "../redux/Client/clientSlice";
import { clearCompany } from "../redux/Company/companySlice";
import {
  clearAllWorkableClients,
  clearHireCandidateStaging,
  clearHireList,
} from "../redux/Hire/hireSlice";
import { logOut, setRefreshToken } from "../redux/Login/loginSlice";
import { clearMemberList } from "../redux/People/peopleSlice";
import { Status } from "./ApiStatus";
import { getAuthToken, getRefreshTokenBaseApi } from "./OnBoarding";

interface FailedRequest {
  resolve: (value: unknown) => void;
  reject: (reason?: any) => void;
  config: any;
  identifier: string;
}

let store: any = {};

export function setStore(value) {
  store = value;
}
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 60000,
});

export async function setAuthorizationToken(token) {
  api.defaults.headers.common['Content-Type'] = 'application/json';
  api.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';
}

export function setClientHeader(clientId: number) {
  api.defaults.headers.common.Content = "application/json";
  if (clientId) {
    delete api.defaults.headers.common.clientId;
    api.defaults.headers.common.clientId = `${clientId}`;
  } else {
    api.defaults.headers.common.clientId = "";
    delete api.defaults.headers.common.clientId;
  }
}

api.interceptors.request.use(async (req) => {
  //@ts-ignore
  const url = new URL(req.url);
  //@ts-ignore
  const { accessToken } = store.getState().login;
  if (
    accessToken?.length > 0 &&
    url?.pathname !== "/api/refreshtoken" &&
    url?.pathname !== "/api/auth"
  ) {
    req.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return req;
});

// Global state for token refresh management
let isRefreshing = false;
let failedQueue: FailedRequest[] = [];
let retryCount = 0;

const processQueue = (error, token = null) => {
  try {
    failedQueue.forEach(async (prom: any) => {
      if (error) {
        prom.reject(error);
      } else if (token) {
        prom.config.headers['Authorization'] = `Bearer ${token}`;
        await prom.resolve(api(prom.config));
      }
    });
    failedQueue = [];
  } catch (error) {
    console.log("processQueue error", error);
  }
};

function clearAppStateAndRedirect() {
  failedQueue = [];
  store.dispatch(logOut());
  store.dispatch(clearCompany());
  store.dispatch(clearHireList());
  store.dispatch(clearAllWorkableClients());
  store.dispatch(clearHireCandidateStaging());
  store.dispatch(clearMemberList());
  store.dispatch(clearClientCode());

  // Redirect to the login page or home page
  // window.location.href = "/";
}

async function refreshTokenAndRetryRequest(originalRequest) {
  try {
    const { accessToken, refreshToken } = store.getState().login;
    const authTokenResponse = await getAuthToken();
    if (authTokenResponse?.status === Status.Success) {
      const payload = { token: accessToken, refreshToken };
      const refreshTokenResponse = await getRefreshTokenBaseApi(payload, authTokenResponse.data.token);
      if (refreshTokenResponse?.status === Status.Success) {
        const newToken = refreshTokenResponse.data.data.token;
        setAuthorizationToken(newToken);
        store.dispatch(setRefreshToken(refreshTokenResponse.data.data));
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        processQueue(null, newToken);
        return newToken;
      }
    }
  } catch (error) {
    processQueue(error, null);
    clearAppStateAndRedirect();
    throw error;
  }
}

// Function to generate an identifier for a request
function getRequestIdentifier(config) {
  return `${config.method}:${config.url}:${JSON.stringify(config.data)}`;
}

api.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const originalRequest = error.config;

    // Check if the request is for the refresh token or authentication endpoint
    if (originalRequest.url.includes("/api/refreshtoken") || originalRequest.url.includes("/api/auth")) {
      return Promise.reject(error);
    }
    
    if (error.response?.status === Status.Unauthorized && !originalRequest._retry) {
      originalRequest._retry = true;
      const { accessToken } = store.getState().login;

      if (retryCount >= 1 && isEmpty(accessToken)) {
        clearAppStateAndRedirect();
        throw new Error("Unauthorized");
      }

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          await refreshTokenAndRetryRequest(originalRequest);
          isRefreshing = false;
          retryCount++;
          return api(originalRequest);
        } catch (refreshError) {
          isRefreshing = false;
          return Promise.reject(refreshError);
        }
      } else {
        // Push to failedQueue if already refreshing
        return new Promise((resolve, reject) => {
          const requestIdentifier = getRequestIdentifier(originalRequest);
          if (!failedQueue.find(req => req.identifier === requestIdentifier)) {
            failedQueue.push({
              resolve: (newConfig: any) => resolve(api(newConfig)),
              reject,
              config: originalRequest,
              identifier: requestIdentifier,
            });
          } else {
            // If the request is already in the queue, reject or resolve accordingly
            reject(error);
          }
        });
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;
