import { combineReducers } from "@reduxjs/toolkit";
import allEmployeesReducer from "./slices/allEmployeesSlice";
import employeeReducer from "./slices/employeeSlice";
import multipleEmployeesReducer from "./slices/multipleEmployeesSlice";
import annualQuestionsSlice from "./slices/annualQuestionsSlice";

const performanceReducer = combineReducers({
  allEmployees: allEmployeesReducer,
  employee: employeeReducer,
  multipleEmployees: multipleEmployeesReducer,
  annualQuestions: annualQuestionsSlice,
});

export default performanceReducer;
