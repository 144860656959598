import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";

import moment from "moment";
import CustomRatings from "../../../../../shared/components/Ratings/CustomRatings";
import { userAccess } from "../../../../../utils/CommonFunction";
import { useStyles } from "../styles";

const MonthlyFeedbackContent = ({ details, onClose }) => {
  const classes = useStyles();
  const { ClientId } = userAccess();

  const monthStart = moment(details.month, "MMMM").startOf("month").format("DD MMM YYYY");
  const monthEnd = moment(details.month, "MMMM").endOf("month").format("DD MMM YYYY");
  const dateRange = `${monthStart} to ${monthEnd}`;

  return (
    <>
      <Box className={classes.modalHeader}>
        <Typography variant="h5" className={classes.customTypography}>
          Monthly feedback
        </Typography>

        <IconButton onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </Box>
      <Box className={classes.modalBody}>
        <Typography variant="h6">{details.month} month feedback</Typography>
        
        <Typography variant="body1">({dateRange})</Typography>
        
        <CustomRatings disabled rating={details.score} color={ClientId === 0 ? "#6781F6" : "#DB9BF3"}/>

        {details.comment && <Box className={classes.commentBox}>
          <Typography variant="body2" color="#707D84" mb={1}>
            Comment
          </Typography>

          <Typography variant="caption">{details.comment}</Typography>
        </Box>}

        <Typography variant="caption" fontWeight={600}>
          Feedback shared with {details.isShareOn ? "team member" : "Patona"}
        </Typography>
        
        <Typography variant="caption" fontWeight={600}>
          Feedback from: {details.from}
        </Typography>
      </Box>
    </>
  );
};

export default MonthlyFeedbackContent;
