import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomRatings from "../../../shared/components/Ratings/CustomRatings";

const useStyles = makeStyles({
  customOutline: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
});

const EmployeeFeedback = ({
  employee,
  value,
  onBlur,
  setFieldValue,
  errors,
  clientId,
}) => {
  const classes = useStyles();
  const { score, comment, shareFeedback } = value;

  const scoreFieldName = `[${employee.employeeId}].score`;
  const commentFieldName = `[${employee.employeeId}].comment`;
  const shareFeedbackFieldName = `[${employee.employeeId}].shareFeedback`;

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        mb: 2,
        p: 2,
        borderRadius: 0,
        "@media (max-width:600px)": {
          background: "none",
          p: 0,
          mb: 3,
        },
      }}
    >
      <Typography variant="h6">Team member name: {employee.name}</Typography>

      <Typography variant="subtitle1">
        How did the team member perform this month?
      </Typography>

      <Box>
        <CustomRatings
          rating={score}
          onChange={(newValue) => {
            setFieldValue(scoreFieldName, newValue);
          }}
          color={clientId == 0 ? "#6781F6" : "#DB9BF3"}
        />
      </Box>
      {errors?.score && <FormHelperText error>*{errors.score}</FormHelperText>}

      <TextField
        label="Comment"
        name={commentFieldName}
        value={comment || ""}
        onChange={(event) =>
          setFieldValue(commentFieldName, event.target.value)
        }
        onBlur={onBlur}
        margin="normal"
        fullWidth
        multiline
        rows={3}
        className={classes.customOutline}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={shareFeedback}
            onChange={(event) => setFieldValue(shareFeedbackFieldName, event.target.checked)}
            name="shareFeedback"
            color="primary"
          />
        }
        label="Share the feedback with team member?"
        sx={{
          "& .MuiFormControlLabel-label": {
            fontSize: "12px",
          },
        }}
      />
    </Paper>
  );
};

export default EmployeeFeedback;
