import _ from "lodash";
import { all, delay, put, takeLatest } from "redux-saga/effects";
import { STRINGS } from "../../Strings";
import { Peoples } from "../../Types/People/People";
import { Status } from "../../services/ApiStatus";
import {
  deleteMember,
  disableMember,
  editMember,
  enableMember,
  getAllSkills,
  getDeletedMembers,
  getInvoicesMembers,
  getLeaveApproval,
  getTeamMembersByID,
  getTimesheetMembers,
  getZohoDesignations,
  restoreMember,
  saveMember
} from "../../services/People";
import { sagaActions } from "../sagaActions";
import {
  deletedFail,
  deletedMemberCount,
  deletedPending,
  deletedSuccess,
  deletedpaging,
  designationFail,
  designationSuccess,
  isLeaveRecordPending,
  leaveRecordFail,
  leaveRecordSuccess,
  memberAdded,
  memberAddedUpdateError,
  memberCount,
  memberDetails,
  memberFail,
  memberInvoiceFail,
  memberInvoicePending,
  memberInvoiceSuccess,
  memberPending,
  memberSuccess,
  memberTimesheetFail,
  memberTimesheetPending,
  memberTimesheetSuccess,
  paging,
  updateSkills,
} from "./peopleSlice";

function* fetchZohoDesignation() {
  try {
    const response: Peoples = yield getZohoDesignations();
    if (!!response && response?.status === Status.Success) {
      yield put(designationSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(designationFail());
      }
    }
  } catch (error: any) {
    yield put(designationFail());
  }
}

function* fetchMemberTimesheet(payload: any = "") {
  try {
    yield put(memberTimesheetPending());
    const response: Peoples = yield getTimesheetMembers(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(memberTimesheetSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(memberTimesheetFail("Error while fetching record"));
      }
    }
  } catch (error: any) {
    yield put(memberTimesheetFail("Error while fetching record"));
  }
}

function* fetchMemberInvoices(payload: any = "") {
  try {
    yield put(memberInvoicePending());
    const response: Peoples = yield getInvoicesMembers(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(memberInvoiceSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(memberInvoiceFail("Error while fetching record"));
      }
    }
  } catch (error: any) {
    yield put(memberInvoiceFail("Error while fetching record"));
  }
}

function* fetchMember(payload: any = "") {
  try {
    if (!_.isUndefined(payload?.payload?.load)) {
      yield put(memberPending());
    }

    const response: Peoples = yield getDeletedMembers(payload);
    if (!!response && response?.status === Status.Success) {
      const { paginationFilter } = response.data;
      const page = Math.ceil(
        paginationFilter.count / paginationFilter.pageSize
      );
      const isPaging = page > 1 ? true : false;
      yield put(
        paging({
          page: page,
          isPaging: isPaging,
          pageSize: paginationFilter.pageSize,
          currentPage: paginationFilter.pageNumber,
        })
      );
      yield put(memberCount(paginationFilter.count));
      yield put(memberSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(memberFail(response?.message));
        yield put(paging({ page: 0, isPaging: false }));
        yield put(memberCount(0));
      }
    }
  } catch (error: any) {
    yield put(memberFail(error?.message));
    yield put(paging({ page: 0, isPaging: false }));
    yield put(memberCount(0));
  }
}

function* addMember(payload) {
  try {
    yield put(memberAddedUpdateError(false));
    const response = yield saveMember(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(memberAdded(true));
      const request = {
        payload: {
          pageNumber: "1",
          pageSize: "10",
          searchValue: "",
          clientId: 0,
          isDeleted: false,
        },
      };
      yield fetchMember(request);
    }
  } catch (error: any) {
    yield put(memberAddedUpdateError(error.message || STRINGS.ErrorOnUpdte));
    yield put(memberAdded(false));
  }
}

function* fetchSkills(payload) {
  try {
    const response = yield getAllSkills();
    if (!!response && response?.status === Status.Success) {
      yield put(updateSkills(response.data));
    }
  } catch (error: any) {
    yield put(memberFail(error?.message));
  }
}

function* fetchTeamMembersByName(payload) {
  try {
    yield put(memberPending());
    const response: Peoples = yield getDeletedMembers(payload);
    if (!!response && response?.status === Status.Success) {
      const { paginationFilter } = response.data;
      const page = Math.ceil(
        paginationFilter.count / paginationFilter.pageSize
      );
      const isPaging = page > 1 ? true : false;
      yield put(paging({ page: page, isPaging: isPaging }));
      yield put(memberCount(paginationFilter.count));
      yield put(memberSuccess(response.data));
    } else {
      yield put(memberFail(response?.message));
      yield put(paging({ page: 0, isPaging: false }));
      yield put(memberCount(0));
    }
  } catch (error: any) {
    yield put(memberFail(error?.message));
    yield put(paging({ page: 0, isPaging: false }));
    yield put(memberCount(0));
  }
}

function* fetchTeamMembersByID(payload) {
  try {
    yield put(memberPending());
    const response: Peoples = yield getTeamMembersByID(payload);
    if (!!response && response?.status === Status.Success) {
      yield put(memberDetails(response.data));
    } else {
      yield put(memberFail(response?.message));
    }
  } catch (error: any) {
    yield put(memberFail(error?.message));
  }
}

function* updateMember(payload) {
  try {
    yield put(memberAddedUpdateError(false));
    const response = yield editMember(payload.payload);    
    if (!!response && response?.status === Status.Success) {
      yield delay(3000);
      yield put(memberAdded(true));
      const request = {
        payload: {
          id: payload.payload.TeamMemberId,
          removedDescription: "",
          removedReason: "",
          isPatonaTeamMember: payload.payload.IsPatonaTeamMember,
        },
      };
      yield fetchTeamMembersByID(request);
    } else {
      yield put(memberAdded(false));
      yield put(memberAddedUpdateError(STRINGS.ErrorOnUpdte));
    }
  } catch (error: any) {
    console.error("update fail: ", error);
    yield put(memberAdded(false));
    yield put(memberAddedUpdateError(error.message || STRINGS.ErrorOnUpdte));
  }
}

function* removeMember(payload) {
  try {
    const response = yield deleteMember(payload.payload);
    if (!!response && response?.status === Status.Success) {
      const request = {
        payload: {
          pageNumber: "1",
          pageSize: "10",
          searchValue: "",
          clientId: 0,
          isDeleted: false,
        },
      };
      yield fetchMember(request);
      yield fetchDeleteMember();
    }
  } catch (error: any) {
    yield put(memberAdded(false));
  }
}

function* restoteTeamMember(payload) {
  try {
    const response = yield restoreMember(payload.payload);
    if (!!response && response?.status === Status.Success) {
      const request = {
        payload: {
          pageNumber: "1",
          pageSize: "10",
          searchValue: "",
          clientId: 0,
          isDeleted: false,
        },
      };
      yield fetchMember(request);
      yield fetchDeleteMember();
    }
  } catch (error: any) {
    yield put(memberAdded(false));
  }
}

function* fetchDeleteMember(payload: any = "") {
  try {
    if (!_.isUndefined(payload?.payload?.load)) {
      yield put(deletedPending());
    }
    const response: Peoples = yield getDeletedMembers(payload);
    if (!!response && response?.status === Status.Success) {
      const { paginationFilter } = response.data;
      const page = Math.ceil(
        paginationFilter.count / paginationFilter.pageSize
      );
      const isPaging = page > 1 ? true : false;
      yield put(
        deletedpaging({
          page: page,
          isPaging: isPaging,
          pageSize: paginationFilter.pageSize,
          currentPage: paginationFilter.pageNumber,
        })
      );
      yield put(deletedMemberCount(paginationFilter.count));
      yield put(deletedSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(deletedFail(response?.message));
        yield put(deletedpaging({ page: 0, isPaging: false }));
        yield put(deletedMemberCount(0));
      }
    }
  } catch (error: any) {
    yield put(deletedFail(error?.message));
    yield put(deletedpaging({ page: 0, isPaging: false }));
    yield put(deletedMemberCount(0));
  }
}

function* disableTeamMember(payload) {
  try {
    const response = yield disableMember(payload.payload);
    if (!!response && response?.status === Status.Success) {
      const request = {
        payload: {
          pageNumber: "1",
          pageSize: "10",
          searchValue: "",
          clientId: 0,
          isDeleted: false,
        },
      };
      yield fetchMember(request);
      yield fetchDeleteMember();
    }
  } catch (error: any) {
    yield put(memberFail(error?.message));
  }
}

function* enableTeamMember(payload) {
  try {
    const response = yield enableMember(payload.payload);
    if (!!response && response?.status === Status.Success) {
      const request = {
        payload: {
          pageNumber: "1",
          pageSize: "10",
          searchValue: "",
          clientId: 0,
          isDeleted: false,
        },
      };
      yield fetchMember(request);
      yield fetchDeleteMember();
    }
  } catch (error: any) {
    yield put(memberFail(error?.message));
  }
}

function* leaveApprovalRecordTeamMember(payload) {
  try {
    yield put(isLeaveRecordPending());

    const response = yield getLeaveApproval(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(leaveRecordSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(leaveRecordFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(leaveRecordFail(error?.message));
  }
}

export default function* watcherSaga() {
  yield all([
    yield takeLatest(sagaActions.FETCH_MEMBER, fetchMember),
    yield takeLatest(sagaActions.FETCH_DELETE_MEMBER, fetchDeleteMember),
    yield takeLatest(sagaActions.SAVE_MEMBER, addMember),
    yield takeLatest(sagaActions.GET_SKILLS, fetchSkills),
    yield takeLatest(sagaActions.SEARCH_MEMBER_BY_NAME, fetchTeamMembersByName),
    yield takeLatest(sagaActions.GET_MEMBER_BY_ID, fetchTeamMembersByID),
    yield takeLatest(sagaActions.UPDATE_MEMBER, updateMember),
    yield takeLatest(sagaActions.REMOVE_MEMBER, removeMember),
    yield takeLatest(sagaActions.RESTORE_MEMBER, restoteTeamMember),
    yield takeLatest(sagaActions.DISABLE_MEMBER, disableTeamMember),
    yield takeLatest(sagaActions.ENABLE_MEMBER, enableTeamMember),
    yield takeLatest(
      sagaActions.GET_APPROVAL_DATA,
      leaveApprovalRecordTeamMember
    ),
    yield takeLatest(sagaActions.FETCH_ZOHO_DESIGNATION, fetchZohoDesignation),
    yield takeLatest(sagaActions.MEMBER_TIMESHEET_ACTION, fetchMemberTimesheet),
    yield takeLatest(sagaActions.MEMBER_INVOICE_ACTION, fetchMemberInvoices),
  ]);
}
