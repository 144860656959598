import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { DefaultLayout } from "../../shared/components/Layouts/Partials/defaultlayout";

export const PrivateRoute = ({ children }) => {
  //@ts-ignore
  const { isAuth } = useSelector((state) => state.login);

  if (!isAuth) {
    return <Navigate to="/" replace state={{ from: window.location.pathname }} />;
  } else {
    return <DefaultLayout>{children}</DefaultLayout>;
  }
};
