import ClearIcon from "@mui/icons-material/Clear";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { STRINGS } from "../../Strings";
import MoveCandidate from "../../shared/components/MoveCandidate/MoveCandidate";
import TextSkeleton from "../../shared/components/Skeletons/TextSkeleton";
import Timeline from "../../shared/components/Timeline/Timeline";
import { userAccess } from "../../utils/CommonFunction";
import { disQualifiedCandidate } from "./Hire.helper";
import { styles } from "./HireStyle";

const CandidateListMobile = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = styles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showComment, setShowComment] = useState<boolean>(false);
  const [showCommentBox, setShowCommentBox] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    console.log("candidate data", location.state);
  }, [location.state]);

  const dispatch = useDispatch();

  const goBackPage = () => {
    navigate(-1);
  };

  return (
    <>
      {location.state.candidate && (
        <Box className={classes.candidate_details} mt={4}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2}>
              <Avatar
                alt={"candidate image"}
                src="https://images.pexels.com/photos/5324927/pexels-photo-5324927.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" component="div">
                {location.state.candidate.name}
              </Typography>
              <Typography variant="body2" component="div">
                {location.state.candidate.jobTitle}
              </Typography>
            </Grid>
            <Grid item xs={4} display={"flex"} alignItems={"center"}>
              <IconButton
                disableRipple={true}
                onClick={(e) => {
                  e.stopPropagation();
                  const payload = {
                    apiid: location.state.apiId,
                    role:
                      userAccess().role === "TMF_Admin"
                        ? "Patona_Admin"
                        : userAccess().role,
                    userName: userAccess().firstName,
                    email: userAccess().email,
                  };
                  disQualifiedCandidate(payload, dispatch);
                }}>
                <img
                  className={classes.qualifiedCon}
                  src="/images/Disqualify.png"
                  alt="icon"
                />
              </IconButton>
              <IconButton
                disableRipple={true}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(e);
                }}>
                <MoreVertIcon />
              </IconButton>
              <ClearIcon onClick={() => goBackPage()} />
            </Grid>
          </Grid>
        </Box>
      )}

      <MoveCandidate
        anchorEl={anchorEl}
        handleClose={handleClose}
        selectedCandidate={location.state.candidate}
        showCommentBox={showCommentBox}
        setShowCommentBox={setShowCommentBox}></MoveCandidate>

      {/* timeline */}
      <Timeline
        showComment={showComment}
        setShowComment={setShowComment}
        selectedCandidate={location.state.candidate}></Timeline>

      <Grid container component="main">
        <Container maxWidth="xl">
          {location.state.details && (
            <Box className={classes.candidate_card}>
              <Typography className={classes.candidate_section} variant="h6">
                {STRINGS.WorkInformation}
              </Typography>

              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <Typography variant="body2">{STRINGS.JobTitle}</Typography>
                </Grid>
                <Grid item xs={6}>
                  {location.state.isMemLoading ? (
                    <TextSkeleton />
                  ) : (
                    <Typography variant="body2">
                      {" "}
                      {location.state.details.jobTitle}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    {STRINGS.ExperienceLevel}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {location.state.isMemLoading ? (
                    <TextSkeleton />
                  ) : (
                    <Typography variant="body2">
                      {" "}
                      {location.state.details.experience}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <Typography variant="body2">{STRINGS.Salary}</Typography>
                </Grid>
                <Grid item xs={6}>
                  {location.state.isMemLoading ? (
                    <TextSkeleton />
                  ) : (
                    <Typography variant="body2">
                      {" "}
                      {location.state.details.salaryPA}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <Typography variant="body2">{STRINGS.JoiningDate}</Typography>
                </Grid>
                <Grid item xs={6}>
                  {location.state.isMemLoading ? (
                    <TextSkeleton />
                  ) : (
                    <Typography variant="body2"> { }</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <Typography variant="body2">{STRINGS.Skills}</Typography>
                </Grid>
                <Grid item xs={6}>
                  {location.state.isMemLoading ? (
                    <TextSkeleton />
                  ) : (
                    <Typography variant="body2"> {""}</Typography>
                  )}
                </Grid>
              </Grid>
              {/* <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <Typography variant="body2">{STRINGS.Resume}</Typography>
                </Grid>
                <Grid item xs={6}>
                  {location.state.isMemLoading ? (
                    <TextSkeleton />
                  ) : (
                    <Typography variant="body2">
                      {" "}
                      {location.state.details.fileName}
                      <a
                        href={location.state.details.file}
                        download="MyExampleDoc"
                        target="_blank">
                        <FileDownloadOutlinedIcon
                          style={{ color: "#002DFF" }}
                        />
                      </a>
                    </Typography>
                  )}
                </Grid>
              </Grid> */}
            </Box>
          )}

          {location.state.experience && (
            <Box className={classes.candidate_card}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.candidate_section}
                    variant="h6">
                    {STRINGS.WorkExperience}
                    {location.state.isMemLoading ? (
                      <TextSkeleton />
                    ) : (
                      <Typography
                        align="right"
                        variant="body1"
                        component="span"
                        sx={{ float: "right" }}>
                        {_.isNull(location.state.totalExperience)
                          ? `Fresher`
                          : location.state.totalExperience > 2
                            ? `Almost ${location.state.totalExperience} years`
                            : `Almost ${location.state.totalExperience} year`}
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Box
                className="tbl-body"
                sx={{
                  padding: "1.5rem",
                }}>
                {!_.isNull(location.state.experience) &&
                  location.state.experience.length > 0 &&
                  location.state.experience?.map((exe, exeIndex) => {
                    return (
                      <Grid
                        container
                        spacing={2}
                        sx={{ display: "flex", mt: exeIndex > 0 ? 2 : 0 }}
                        key={exeIndex}>
                        <Grid item xs={6}>
                          <Typography variant="body2" component="div">
                            {exe.dateRange ? exe.dateRange : "N/A"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          {location.state.isMemLoading ? (
                            <TextSkeleton />
                          ) : (
                            <Typography variant="body2" component="div">
                              {exe.expDetail}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
              </Box>
            </Box>
          )}

          {location.state.resume && (
            <Box className={classes.candidate_card}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <iframe
                    src={location.state.resume}
                    width="100%"
                    height="500px"
                    title="resume"
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Container>
      </Grid>
    </>
  );
};

export default CandidateListMobile;
