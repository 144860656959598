import React, { useEffect } from "react";
import { Field, useField, useFormik, useFormikContext } from "formik";
import { useAlert } from "react-alert";
import {
  fileSet,
  patonaMember,
  saveMember,
  saveSuccessFully,
  resetMemberError,
} from "./People.helper";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Skills from "../../shared/components/Skills/Skills";
import { useDispatch, useSelector } from "react-redux";
import { addMemberSchema } from "../../schemas/add-member";
import { userAccess } from "../../utils/CommonFunction";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { styles } from "./PeopleStyle";
import { getBase64File, imageUpload } from "../Client/UserDetails/User.helper";
import { STRINGS } from "../../Strings";

const initialValues = {
  Name: "",
  JobTitle: "",
  Experience: "",
  SalaryPA: "",
  ClientId: userAccess().clientCode,
  SkillSet: [],
  JoiningDate: "",
  CompanyName: "",
  FirstName: "",
  LastName: "",
  EmailAddress: "",
  EmployeeId: "",
  IsResumeEdited: false,
  isPhotoEdited: false,
  employeeType: "",
  TeamMemberId: 0,
  IsPatonaTeamMember: false,
  PersonalMobileNumber: "",
  resumeFileName: "",
  resumeFile: "",
  profilePhotoFileName: "",
  profilePhotoFile: "",
  personalEmailID: "",
};
const label = { inputProps: { "aria-label": "controlled" } };

const AddMember = (props) => {
  const { clients } = props;
  const classes = styles();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [skills, setSkills] = React.useState<any>([]);
  const [photoName, setPhotoName] = React.useState<any>("");
  const [addMemberLoading, setAddMemberLoading] =
    React.useState<boolean>(false);
  const [isResumeUpdated, setIsResumeUpdated] = React.useState<boolean>(false);
  const [selectPhoto, setPhoto] = React.useState<any>("");
  const [uploadPhoto, setUploadPhoto] = React.useState<any>("");
  const [isPhotoUpdated, setIsPhotoUpdated] = React.useState<boolean>(false);
  const [formAction, setFormAction] = React.useState<any>();
  const [fileName, setFileName] = React.useState<any>("");
  const [selectFile, setFile] = React.useState<any>("");
  const [selectedExperience, setSelectedExperience] = React.useState<any>("");
  const [selectedEmploymentType, setSelectedEmploymentType] =
    React.useState<any>("Permanent");
  const [companyName, setCompanyName] = React.useState<any>("");
  const [dojValue, setDOJValue] = React.useState<any>(new Date());
  const navigate = useNavigate();

  useEffect(() => {
    resetMemberError(dispatch);
  }, []);

  const handleDateChange = (newValue) => {
    setDOJValue(newValue);
  };

  const { isMemberAdded, memberAddUpdateError } = useSelector(
    //@ts-ignore
    (state) => state.people
  );

  useEffect(() => {
    if (memberAddUpdateError) {
      alert.error(memberAddUpdateError);
      setAddMemberLoading(false);
    }
  }, [memberAddUpdateError]);

  useEffect(() => {
    if (isMemberAdded) {
      alert.success(STRINGS.MemberAddSuccess);
      setAddMemberLoading(false);
      if (formAction) {
        formAction.resetForm();
      }
      navigate("/team-members");
      saveSuccessFully(dispatch);
    }
  }, [isMemberAdded]);

  const {
    values,
    errors,
    touched,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addMemberSchema,
    onSubmit: (values, action) => {
      values.CompanyName = userAccess().clientName;
      values.ClientId = userAccess().clientCode;
      values.TeamMemberId = userAccess().clientCode;
      values.SkillSet = skills;
      values.resumeFile = selectFile;
      values.resumeFileName = fileName;
      values.JoiningDate = dojValue;
      values.Experience = selectedExperience;
      values.employeeType = selectedEmploymentType;
      values.IsPatonaTeamMember = false;
      values.profilePhotoFileName = photoName;
      values.profilePhotoFile = uploadPhoto;
      setAddMemberLoading(true);
      saveMember(values, dispatch);
      setFormAction(action);
    },
  });

  return (
    <Box
      justifyContent={"center"}
      display={"block"}
      alignContent={"center"}
      alignItems={"center"}
      alignSelf={"center"}>
      <Box className={classes.add_member_Header} sx={{ display: "flex" }}>
        <Grid item md={8}>
          <Typography
            variant="h4"
            sx={{ display: "flex", textTransform: "capitalize" }}>
            <Link className={classes.back_icon} to="/team-members">
              <ArrowBackIosNewIcon />
            </Link>
            {STRINGS.AddNewMember}
          </Typography>
        </Grid>
      </Box>

      <Box
        sx={{
          bgcolor: "background.paper",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          boxShadow: 24,
          p: 4,
          pointerEvents: addMemberLoading ? "none" : "auto",
        }}
        component="form"
        noValidate
        onSubmit={handleSubmit}>
        <Typography
          variant="h5"
          sx={{ display: "flex", textTransform: "capitalize" }}>
          {STRINGS.PersonalInformation}
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Box display="flex" justifyContent="flex-start">
              <Box className={classes.companyLogo}>
                {photoName ? (
                  <img
                    className={classes.user_pic}
                    src={getBase64File(photoName, uploadPhoto)}
                    alt={photoName}
                  />
                ) : (
                  <Typography variant="h6" className={classes.companyLogoText}>
                    {STRINGS.Image}
                  </Typography>
                )}
              </Box>
              <Grid ml={2}>
                <Typography variant="h6">{"Employee photo"}</Typography>
                <Typography variant="caption">({STRINGS.ImageSize})</Typography>
                <Box
                  display={"flex"}
                  sx={{ mt: 2 }}
                  className="upload-btn-wrapper">
                  <button className="profile_btn">
                    <FileUploadOutlinedIcon
                      sx={{
                        mr: 1,
                        width: 18,
                        height: 18,
                      }}></FileUploadOutlinedIcon>
                    <Typography variant="caption">{"Upload"}</Typography>
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    onChange={(e) =>
                      imageUpload(
                        e,
                        setPhotoName,
                        setPhoto,
                        alert,
                        setIsPhotoUpdated,
                        setUploadPhoto
                      )
                    }
                  />
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              error={Boolean(touched.FirstName && errors.FirstName)}
              helperText={touched.FirstName && errors.FirstName}
              margin="normal"
              fullWidth
              id="FirstName"
              label={`${STRINGS.FirstName}*`}
              name="FirstName"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 25 }}
              value={values.FirstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              error={Boolean(touched.LastName && errors.LastName)}
              helperText={touched.LastName && errors.LastName}
              margin="normal"
              fullWidth
              id="LastName"
              label={`${STRINGS.LastName}*`}
              name="LastName"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 25 }}
              value={values.LastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={-3}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              error={Boolean(
                touched.PersonalMobileNumber && errors.PersonalMobileNumber
              )}
              helperText={
                touched.PersonalMobileNumber && errors.PersonalMobileNumber
              }
              margin="normal"
              type={"number"}
              fullWidth
              id="PersonalMobileNumber"
              label={`${STRINGS.PersonalNumber}*`}
              name="PersonalMobileNumber"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 25 }}
              value={values.PersonalMobileNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              error={Boolean(touched.personalEmailID && errors.personalEmailID)}
              helperText={touched.personalEmailID && errors.personalEmailID}
              margin="normal"
              fullWidth
              id="personalEmailID"
              label={`${STRINGS.PersonalEmailId}*`}
              name="personalEmailID"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 50 }}
              value={values.personalEmailID}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>

        <Typography
          variant="h5"
          sx={{ display: "flex", textTransform: "capitalize", mt: 2 }}>
          {STRINGS.WorkInformation}
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              error={Boolean(touched.JobTitle && errors.JobTitle)}
              helperText={touched.JobTitle && errors.JobTitle}
              margin="normal"
              fullWidth
              id="JobTitle"
              label={`${STRINGS.JobTitle}*`}
              name="JobTitle"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 25 }}
              value={values.JobTitle}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              error={Boolean(touched.EmployeeId && errors.EmployeeId)}
              helperText={touched.EmployeeId && errors.EmployeeId}
              margin="normal"
              fullWidth
              id="EmployeeId"
              label={`${STRINGS.EmployeeId}*`}
              name="EmployeeId"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 25 }}
              value={values.EmployeeId}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>{STRINGS.Experience}</InputLabel>
              <Select
                id="Experience"
                name="Experience"
                label={STRINGS.Experience}
                value={selectedExperience}
                onChange={(e) => {
                  setSelectedExperience(e.target.value);
                }}>
                {experienceArray?.map((e, key) => {
                  return (
                    <MenuItem value={e.name} key={key}>
                      {e.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>{STRINGS.EmploymentType}</InputLabel>
              <Select
                id="EmploymentType"
                name="EmploymentType"
                label={STRINGS.EmploymentType}
                value={selectedEmploymentType}
                onChange={(e) => {
                  setSelectedEmploymentType(e.target.value);
                }}>
                <MenuItem value={"On Contract"}>{"On Contract"}</MenuItem>
                <MenuItem value={"Permanent"}>{"Permanent"}</MenuItem>
                <MenuItem value={"Probation"}>{"Probation"}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={STRINGS.JoiningDate}
                  inputFormat="MM/DD/YYYY"
                  value={dojValue}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6} md={6} sx={{ marginTop: -2 }}>
            <TextField
              error={Boolean(touched.SalaryPA && errors.SalaryPA)}
              helperText={touched.SalaryPA && errors.SalaryPA}
              margin="normal"
              InputProps={{
                startAdornment: values.SalaryPA ? "$" : "",
              }}
              type={"number"}
              fullWidth
              id="SalaryPA"
              label={STRINGS.Salary}
              name="SalaryPA"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 50 }}
              value={values.SalaryPA}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} sx={{ marginTop: -2 }}>
            <TextField
              error={Boolean(touched.EmailAddress && errors.EmailAddress)}
              helperText={touched.EmailAddress && errors.EmailAddress}
              margin="normal"
              fullWidth
              id="EmailAddress"
              label={`${STRINGS.WorkEmailId}*`}
              name="EmailAddress"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 50 }}
              value={values.EmailAddress}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Skills setSkill={setSkills} />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              error={Boolean(touched.resumeFile && errors.resumeFile)}
              helperText={touched.resumeFile && errors.resumeFile}
              margin="normal"
              fullWidth
              variant="outlined"
              type={"file"}
              inputProps={{ maxLength: 50 }}
              value={undefined}
              onChange={(e) =>
                fileSet(e, setFileName, setFile, alert, setIsResumeUpdated)
              }
              onBlur={handleBlur}
            />
            <span>{fileName}</span>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end">
          <Button variant="text" sx={{ mt: 2 }} onClick={props.close}>
            {STRINGS.Cancel}
          </Button>
          <Button
            disabled={!dirty}
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}>
            {addMemberLoading && <CircularProgress size={18} color="warning" />}
            {!addMemberLoading && STRINGS.Save}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddMember;

export const experienceArray = [
  { name: "Associate", id: 1 },
  { name: "Mid level senior", id: 2 },
  { name: "Senior level", id: 3 },
];
