import { rowPerPage } from "../Constants";
import api from "../services/BaseApi";
const BASE_URL = process.env.REACT_APP_RECRUITLY_URL;

const getOpportunitiesForTeammember =
  BASE_URL + "getOppurtunitiesForTeammember";

const getJobRequestDetails=BASE_URL+"getJobRequestDetails"

export const opportunities = async (payload) => {
  try {
    let request: any = {
      PageNumber: 1,
      PageSize: rowPerPage,
    };

    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(getOpportunitiesForTeammember, request);
    return res;
  } catch (error) {
    console.log(error, "===== getOpportunitiesForTeammember list API Error");
    //return error;
  }
};

export const fetchJobDetailById = async (payload) => {
  try {
    let request: any = {
      Id:'0'
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(getJobRequestDetails, request);
    return res;
  } catch (error) {
    console.log(error, "===== getOpportunitiesForTeammember list API Error");
    //return error;
  }
};
