import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useAlert } from "react-alert";
import { useMediaQuery, Box } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { resentInvite, saveSuccessFully } from "./User.helper";
import { CircularProgress } from "@mui/material";
import { resendUpdated } from "../../redux/User/userSlice";
import { STRINGS } from "../../Strings";

const initialValues = {
  email: "",
  emailTokenId: "",
  fetchRole: "",
};

type SendInvitesProps = {
  show: boolean;
  close: () => void;
  type: string;
  data: any;
  invitationSent: () => void;
};

const ResendInvite: React.FunctionComponent<SendInvitesProps> = (props) => {
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const style = {
    width: isMobile ? "70%" : "40%",
    overflowY: "scroll",
    height: "25%",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };
  const alert = useAlert();
  const dispatch = useDispatch();
  const [formAction, setFormAction] = useState<any>("");

  const { isResendInProgress, isResendSent } = useSelector(
    //@ts-ignore
    (state) => state.user
  );

  useEffect(
    function updateInitialValues() {
      if (props.data) {
        setFieldValue("email", props.data.email);
        setFieldValue("emailTokenId", props.data.id);
        setFieldValue("fetchRole", props.type);
      }
    },
    [props.data]
  );

  useEffect(() => {
    if (isResendSent) {
      props.close();
      dispatch(resendUpdated());
      formAction.resetForm();
      //props.invitationSent();
    }
  }, [isResendSent]);

  const {
    values,
    errors,
    touched,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      resentInvite(values, dispatch);
      setFormAction(action);
    },
  });

  return (
    <>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={props.show}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            {props.data && props.data.companyName}
          </Typography>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ pointerEvents: isResendInProgress ? "none" : "auto" }}>
            <TextField
              disabled={true}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              margin="normal"
              fullWidth
              id="email"
              label={STRINGS.EmailAddress}
              name="email"
              variant="outlined"
              autoComplete="off"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Box display="flex" justifyContent="flex-end">
              <Button variant="text" sx={{ mt: 2 }} onClick={props.close}>
                Cancel
              </Button>
              <Button
                disabled={!dirty}
                type="submit"
                variant="outlined"
                sx={{ mt: 2 }}>
                {isResendInProgress && (
                  <CircularProgress size={18} color="warning" />
                )}
                {!isResendInProgress && STRINGS.ResendInvite}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ResendInvite;
