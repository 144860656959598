import { Grid } from "@mui/material";
import AttendaceHeader from "./AttendanceHeader/AttendanceHeader";

const Attendance = ({}) => {
  return (
    <Grid sm={12} xs={12}>
      <AttendaceHeader />
    </Grid>
  );
};
export default Attendance;
