import _ from "lodash";
import { all, put, takeLatest } from "redux-saga/effects";
import { STRINGS } from "../../Strings";
import { UserList } from "../../Types/Users/User";
import { Status } from "../../services/ApiStatus";
import {
  cancelJobRequest,
  changeCandidateStage,
  disQualifyCandidate,
  editPostJob,
  generateChatGptJobDescription,
  getCandidateDetail,
  getCandidateList,
  getHireCandidateStagingApi,
  getJobDetails,
  getJobs,
  jobDetailByJobId,
  postComment,
  postJob,
  restoreJobRequest,
} from "../../services/Hire";
import { sagaActions } from "../sagaActions";
import {
  cancelJobError,
  candidateCount,
  candidateDetailError,
  candidateDetailPending,
  candidateDetails,
  candidatePaging,
  chatGptJobError,
  chatGptJobPending,
  chatGptJobSuccess,
  clearCandidateList,
  commentAdded,
  commentAddedError,
  disCandidateSuccess,
  errorCandidateSuccess,
  errordisCandidateSuccess,
  getCandidateListFail,
  getCandidateListPending,
  getCandidateListSuccess,
  getHireCandidateStaging,
  getHireDetailFail,
  getHireDetailPending,
  getHireDetailSuccess,
  getHireListFail,
  getHireListPending,
  getHireListSuccess,
  jobAdded,
  jobAddedUpdateError,
  jobCount,
  moreCandidateListPending,
  moveCandidateSuccess,
  paging,
  setHireCandidateStaging,
  stopMoreCandidateListPending,
  updatedMoreCandidateList
} from "./hireSlice";

function* fetchJobsList(payload: any = "") {
  try {
    if (!_.isUndefined(payload?.payload?.load)) {
      yield put(getHireListPending());
    }

    if (!payload.payload.pageNumber || payload.payload.pageNumber == 1) {
      //do not show paging/load more while loading the first page
      yield put(paging({ page: 0, isPaging: false, currentPage: 0 }));
    }

    const existingList = payload.payload.appendTo || [];
    delete payload.payload.appendTo;
    const response: any = yield getJobs(payload.payload);
    if (!!response && response?.status === Status.Success) {
      const { paginationFilter } = response.data;
      const page = Math.ceil(
        paginationFilter.count / paginationFilter.pageSize
      );
      const isPaging = page > 1 ? true : false;
      yield put(
        paging({
          page: page,
          isPaging: isPaging,
          currentPage: paginationFilter.pageNumber,
          pageSize: paginationFilter.pageSize,
        })
      );
      yield put(jobCount(paginationFilter.count));
      yield put(
        getHireListSuccess({
          ...response.data,
          filteredJobs: [
            ...existingList,
            ...response.data.filteredJobs,
          ],
        })
      );
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getHireListFail(response?.message));
        yield put(paging({ page: 0, isPaging: false, currentPage: 0 }));
        yield put(jobCount(0));
      }
    }
  } catch (error: any) {
    yield put(getHireListFail(error?.message));
    yield put(paging({ page: 0, isPaging: false, currentPage: 0 }));
    yield put(jobCount(0));
  }
}

function* fetchJobDetails(payload: any = "") {
  try {
    yield put(getHireDetailPending());
    const response: UserList = yield getJobDetails(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getHireDetailSuccess(response.data));
    } else {
      yield put(getHireDetailFail());
    }
  } catch (error: any) {
    yield put(getHireDetailFail());
  }
}

function* fetchCandidateList(payload: any = "") {
  try {
    if (!_.isUndefined(payload?.payload?.load)) {
      yield put(getCandidateListPending());
    }

    yield put(clearCandidateList());
    yield put(stopMoreCandidateListPending());

    const response: any = yield getCandidateList(payload.payload);
    if (!!response && response?.status === Status.Success) {
      const qualifiedCount = Math.ceil(
        response.data?.totalCount / 10
      );
      const disQualifiedCount = 0

      yield put(
        candidatePaging({
          qualified: { page: qualifiedCount, isPaging: qualifiedCount >= 1 },
          disQualified: {
            page: disQualifiedCount,
            isPaging: disQualifiedCount > 1,
          },
        })
      );
      yield put(
        candidateCount({
          qualified: response.data?.data.length,
          disQualified: 0,
        })
      );
      yield put(
        getCandidateListSuccess({
          qualified: response.data?.data,
          disQualified: []
        })
      );
    } else {
      yield put(getCandidateListFail(response?.message));
      yield put(
        candidatePaging({
          qualified: { page: 0, isPaging: false },
          disQualified: { page: 0, isPaging: false },
        })
      );
      yield put(candidateCount({ qualified: 0, disQualified: 0 }));
    }
  } catch (error: any) {
    yield put(getCandidateListFail(error?.message));
    yield put(
      candidatePaging({
        qualified: { page: 0, isPaging: false },
        disQualified: { page: 0, isPaging: false },
      })
    );
    yield put(candidateCount({ qualified: 0, disQualified: 0 }));
  }
}

function* moreCandidateList(payload: any = "") {
  try {
    yield put(moreCandidateListPending());

    yield put(clearCandidateList());

    const response: any = yield getCandidateList(payload.payload);
    if (!!response && response?.status === Status.Success) {
      const qualifiedCount = Math.ceil(
        response.data?.totalCount / 10
      );
      const disQualifiedCount = 0
      yield put(
        candidatePaging({
          qualified: { page: qualifiedCount, isPaging: qualifiedCount >= 1 },
          disQualified: {
            page: disQualifiedCount,
            isPaging: disQualifiedCount > 1,
          },
        })
      );
      yield put(
        candidateCount({
          qualified: response.data?.data.length,
          disQualified: 0
        })
      );
      yield put(
        getCandidateListSuccess({
          qualified: response.data?.data,
          disQualified: []
        })
      );
      yield put(updatedMoreCandidateList(true));
    }
  } catch (error: any) {
    yield put(getCandidateListFail(error?.message));
    yield put(
      candidatePaging({
        qualified: { page: 0, isPaging: false },
        disQualified: { page: 0, isPaging: false },
      })
    );
    yield put(candidateCount({ qualified: 0, disQualified: 0 }));
    yield put(updatedMoreCandidateList(false));
  }
}

function* candidateDetail(payload: any = "") {
  try {
    yield put(candidateDetailPending());
    const response: any = yield getCandidateDetail(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(candidateDetails(response.data));
    } else {
      yield put(candidateDetailError("Something went wrong"));
    }
  } catch (error: any) {
    yield put(candidateDetailError(error?.message || "Something went wrong"));
  }
}

function* postNewJob(payload) {
  try {
    yield put(jobAddedUpdateError(false));
    yield put(jobAdded(false));
    const response = yield postJob(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(jobAdded(true));
      const request = {
        payload: {
          load: 0,
        },
      };
      yield fetchJobsList(request);
    }
  } catch (error: any) {
    yield put(jobAddedUpdateError(error.message || STRINGS.ErrorOnUpdte));
    yield put(jobAdded(false));
  }
}

function* postNewComment(payload) {
  try {
    yield put(commentAddedError(false));
    const response = yield postComment(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(commentAdded(true));
    } else {
      yield put(commentAdded(false));
      yield put(commentAddedError(STRINGS.ErrorOnComments));
    }
  } catch (error: any) {
    yield put(commentAddedError(error.message || STRINGS.ErrorOnComments));
    yield put(commentAdded(false));
  }
}

function* updatePostJob(payload) {
  try {
    yield put(jobAddedUpdateError(false));
    const response = yield editPostJob(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(jobAdded(true));
      const request = {
        payload: {
          load: 0,
        },
      };
      yield fetchJobsList(request);
    }
  } catch (error: any) {
    yield put(jobAddedUpdateError(error.message || STRINGS.ErrorOnUpdte));
    yield put(jobAdded(false));
  }
}

function* cancelJob(payload) {
  try {
    yield put(getHireListPending());
    const response = yield cancelJobRequest(payload.payload);
    if (!!response && response?.status === Status.Success) {
      const request = {
        payload: {
          load: 0,
          requestType: payload.payload.requestType,
        },
      };
      yield fetchJobsList(request);
    }
  } catch (error: any) {
    yield put(cancelJobError(error.message || STRINGS.ErrorOnUpdte));
  }
}

function* resJob(payload) {
  try {
    yield put(getHireListPending());
    const response = yield restoreJobRequest(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(jobAdded(true));
      const request = {
        payload: {
          load: 0,
          requestType: payload.payload.requestType,
        },
      };
      yield fetchJobsList(request);
    }
  } catch (error: any) {
    yield put(cancelJobError(error.message || STRINGS.ErrorOnUpdte));
    yield put(jobAdded(false));
  }
}

function* fetchJobDetailById(payload: any = "") {
  try {
    yield put(getHireDetailPending());
    const response: UserList = yield jobDetailByJobId(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getHireDetailSuccess(response.data));
    } else {
      yield put(getHireDetailFail());
    }
  } catch (error: any) {
    yield put(getHireDetailFail());
  }
}

function* moveCandidate(payload) {
  try {
    yield put(getCandidateListPending());
    const response = yield changeCandidateStage(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(moveCandidateSuccess());
    } else {
      yield put(errorCandidateSuccess(STRINGS.ErrorInMovingCandidate));
    }
  } catch (error: any) {
    yield put(
      errorCandidateSuccess(error.message || STRINGS.ErrorInMovingCandidate)
    );
  }
}

function* disCandidate(payload) {
  try {
    const response = yield disQualifyCandidate(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(disCandidateSuccess());
    } else {
      yield put(errordisCandidateSuccess(STRINGS.ErrorInDisQualifiedCandidate));
    }
  } catch (error: any) {
    yield put(
      errordisCandidateSuccess(
        error.message || STRINGS.ErrorInDisQualifiedCandidate
      )
    );
  }
}

function* generateChatGptDescription(payload) {
  try {
    yield put(chatGptJobPending());
    const response = yield generateChatGptJobDescription(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(chatGptJobSuccess(response?.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(chatGptJobError());
      }
    }
  } catch (error: any) {
    yield put(chatGptJobError());
  }
}

function* fetchHireCandidateStagin(payload){
  try{
    yield put(getHireCandidateStaging())
    const response = yield getHireCandidateStagingApi(payload.payload)
    if (!!response && response?.status === Status.Success) {
      yield put(setHireCandidateStaging(response?.data));
    } else {
      console.log(response)
    }
  }catch(error: any){
    console.log(error)
  }
}

export default function* watcherSaga() {
  yield all([
    yield takeLatest(sagaActions.JOBS_LIST, fetchJobsList),
    yield takeLatest(sagaActions.JOBS_DETAILS, fetchJobDetails),
    yield takeLatest(sagaActions.CANDIDATE_LIST, fetchCandidateList),
    yield takeLatest(sagaActions.MORE_CANDIDATE_LIST, moreCandidateList),
    yield takeLatest(sagaActions.POST_JOB, postNewJob),
    yield takeLatest(sagaActions.POST_COMMENT, postNewComment),
    yield takeLatest(sagaActions.UPDATE_POST_JOB, updatePostJob),
    yield takeLatest(sagaActions.JOB_DETAILS_BY_JOBID, fetchJobDetailById),
    yield takeLatest(sagaActions.CANCEL_JOB, cancelJob),
    yield takeLatest(sagaActions.RESTORE_JOB, resJob),
    yield takeLatest(sagaActions.CANDIDATE_DETAIL, candidateDetail),
    yield takeLatest(sagaActions.MOVE_CANDIDATE, moveCandidate),
    yield takeLatest(sagaActions.DISQUALIFIED_CANDIDATE, disCandidate),
    yield takeLatest(
      sagaActions.CHAT_GPT_DESCRIPTION,
      generateChatGptDescription
      ),
    yield takeLatest(sagaActions.GET_HIRE_CANDIDATES_STAGING, fetchHireCandidateStagin)
    ]);
}
