import * as Yup from "yup";

export const userSchema = Yup.object({
  firstName: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your first name"),
  lastName: Yup.string().min(2).max(25).required("Please enter your last name"),
  country: Yup.string().required("Please select your country"),
  timeZone: Yup.string().required("Please select your time zone"),
  designation: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your designation"),
  role: Yup.string().required("Please select your role"),
});
