import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isRegisterLoading: false,
  status: "",
  message: "",
};

const userRegistrationSlice = createSlice({
  name: "userRegistration",
  initialState,
  reducers: {
    registrationPending: (state) => {
      state.isRegisterLoading = true;
    },
    registrationSuccess: (state, { payload }) => {
      state.isRegisterLoading = false;
      state.status = "success";
    },
    registrationError: (state, { payload }) => {
      state.isRegisterLoading = false;
      state.status = "error";
      state.message = payload;
    },
  },
});

const { reducer, actions } = userRegistrationSlice;

export const { registrationPending, registrationSuccess, registrationError } =
  actions;

export default reducer;
