import api from "../services/BaseApi";
import { getCurrentFiscalYear, userAccess } from "../utils/CommonFunction";
const BASE_URL_WORKABLE = process.env.REACT_APP_DASHBOARD_URL;
const getInvoicesURL = BASE_URL_WORKABLE + "getFilteredInvoiceData";
const getInvoiceDataById = BASE_URL_WORKABLE + "getInvoiceDataById";
const previewordownloadInvoice = BASE_URL_WORKABLE + "previewordownloadInvoice";
const getFilteredInvoiceGraphData =
  BASE_URL_WORKABLE + "getFilteredInvoiceGraphData";

export enum invoiceStatus {
  AUTHORISED = "AUTHORISED",
  PAID = "PAID",
  DRAFT = "DRAFT",
  DELETED = "DELETED",
  VOIDED = "VOIDED",
}

export const getInvoices = async (payload: any = "") => {
  try {
    let request: any = {
      clientId: 0,
      clientName: "",
      statusList: [invoiceStatus.AUTHORISED],
      orderBy: "",
      isDesc: true,
      dateFrom: getCurrentFiscalYear()?.startDate,
      dateTo: getCurrentFiscalYear()?.endDate,
      page: 1,
      unitsPerPage: 3,
      searchText: "",
    };

    if (payload.payload) {
      request = { ...request, ...payload.payload };
    }
    request.clientId = userAccess().clientCode;
    request.clientName =
      userAccess().clientCode > 0 ? userAccess().clientName : "";
    //request.role = userAccess().role;
    const res = await api.post(getInvoicesURL, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getInvoiceDetail = async (payload: any = "") => {
  try {
    let request: any = {
      id: "",
    };
    if (payload.payload) {
      request = { ...request, ...payload.payload };
    }
    const res = await api.post(getInvoiceDataById, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getInvoicePreview = async (payload: any = "") => {
  try {
    let request: any = {
      id: "",
    };
    if (payload.payload) {
      request = { ...request, ...payload.payload };
    }
    const res = await api.post(previewordownloadInvoice, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getInvoicesGraphData = async (payload: any = "") => {
  try {
    let request: any = {
      clientId: 0,
      clientName: "",
    };
    request.clientId = userAccess().clientCode;
    request.clientName =
      userAccess().clientCode > 0 ? userAccess().companyName : "";

    if (payload.payload) {
      request = { ...request, ...payload.payload };
    }
    const res = await api.post(getFilteredInvoiceGraphData, request);
    return res;
  } catch (error) {
    console.log(error);
  }
};
