import { all, put, takeLatest } from "redux-saga/effects";
import { Company } from "../../Types/Company/Company";
import { Status } from "../../services/ApiStatus";
import { editCompanyInfo, getCompanyInfo } from "../../services/Company";
import { userAccess } from "../../utils/CommonFunction";
import { sagaActions } from "../sagaActions";
import {
  companyUpdated,
  companyUpdatedError,
  getCompanyFail,
  getCompanyPending,
  getCompanySuccess,
  getUpdatePending,
} from "./companySlice";

function* fetchCompanyDetails(payload: any = "") {
  try {
    yield put(getCompanyPending());
    const response: Company = yield getCompanyInfo(payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getCompanySuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getCompanyFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(getCompanyFail(error?.message));
  }
}

function* updateCompanyDetails(payload) {
  try {
    yield put(getUpdatePending());
    const response = yield editCompanyInfo(payload.payload);
    if (!!response && response?.status === Status.Success) {
      const request = {
        payload: {
          Id: userAccess().clientCode,
        },
      };
      yield fetchCompanyDetails(request);
      yield put(companyUpdated(true));
    } else {
      yield put(companyUpdatedError());
    }
  } catch (error: any) {
    yield put(companyUpdatedError());
  }
}

export default function* watcherSaga() {
  yield all([
    yield takeLatest(sagaActions.COMPANY_DETAILS, fetchCompanyDetails),
  ]);
  yield all([
    yield takeLatest(sagaActions.UPDATE_COMPANY_DETAILS, updateCompanyDetails),
  ]);
}
