import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "./styles";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { patonaAdmin } from "../../../Constants";
import TextSkeleton from "../../../shared/components/Skeletons/TextSkeleton";
import { STRINGS } from "../../../Strings";
import { getList } from "../../Users/User.helper";
import { getCompanyDetails } from "./Profile.helper";

type ProfileDetailsProps = {
  editCompany?: (boolean) => void;
};

const ProfileDetails: React.FunctionComponent<ProfileDetailsProps> = (
  props
) => {
  const { editCompany = () => null } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();



  const {
    isUserListLoading,
    userList,

  } = useSelector((state) =>
    //@ts-ignore
    state.user
  )





  useEffect(() => {
    getList(dispatch, 'getActiveUsers', 1, patonaAdmin, 50)
    getCompanyDetails(dispatch);
  }, []);

  const { isLoading, company } = useSelector(
    //@ts-ignore
    (state) => state.company
  );

  const accountManager = userList.find(user => user.userId === company?.accountManagerId)


  return (
    <>
      <Box className={classes.display_card}>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={6}>
            <Typography className={classes.company_title_text}>
              {STRINGS.CompanyName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {isLoading ? (
              <TextSkeleton />
            ) : (
              <Typography variant="body2"> {company?.name}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={6}>
            <Typography className={classes.company_title_text}>
              {STRINGS.CompanyAddress}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {isLoading ? (
              <TextSkeleton />
            ) : (
              <Typography variant="body2">{company?.address}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={6}>
            <Typography className={classes.company_title_text}>
              {STRINGS.Website}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {isLoading ? (
              <TextSkeleton />
            ) : (
              <Typography variant="body2">{company?.webSite}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={6}>
            <Typography className={classes.company_title_text}>{STRINGS.ACN}</Typography>
          </Grid>
          <Grid item xs={6}>
            {isLoading ? (
              <TextSkeleton />
            ) : (
              <Typography variant="body2">{company?.acn}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={6}>
            <Typography className={classes.company_title_text}>
              Account Manager
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {isUserListLoading || isLoading ? (
              <TextSkeleton />
            ) : (
              <Typography variant="body2">
                {accountManager ? `${accountManager?.userName} (${accountManager?.email})` : null}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12}>
            <Typography className={classes.company_title_text}>
              {STRINGS.AboutCompany}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {isLoading ? (
              <TextSkeleton />
            ) : (
              <Typography variant="body2">{company?.description}</Typography>
            )}
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            variant="outlined"
            onClick={() => editCompany(true)}
            sx={{ mt: 2 }}>
            {STRINGS.Edit}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ProfileDetails;
