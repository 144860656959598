import { Box, Typography } from "@mui/material";

const NoRecordFound = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        justifyContent: "center",
        justifySelf: "center",
        alignContent: "center",
        alignSelf: "center",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          justifyItems: "center",
          alignItems: "center",
          justifyContent: "center",
          justifySelf: "center",
          alignContent: "center",
          alignSelf: "center",
          textAlign: "center",
        }}
      >
        {"No Record found"}
      </Typography>
    </Box>
  );
};

export default NoRecordFound;
