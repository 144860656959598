import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NavItems from "./navigation";

import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

import { clearCompany } from "../../../../redux/Company/companySlice";
import { logOut } from "../../../../redux/Login/loginSlice";
import { userLogout } from "../../../../services/OnBoarding";

import { clientAdmin } from "../../../../Constants";
import {
  getApporovalList,
  getCalenderApporovalList,
} from "../../../../modules/Approvals/Approvals.helper";
import { getAllClientList } from "../../../../modules/Client/Client.helper";
import {
  fetchDashboardCalendar,
  fetchDashboardData,
} from "../../../../modules/Dashboard/Dashboard.helper";
import {
  getInvoiceGraphList,
  getPaidnvoiceList,
  getPendingInvoiceList,
} from "../../../../modules/Invoice/Invoice.helper";
import { getAllMemberList } from "../../../../modules/People/People.helper";
import { getUsersListList } from "../../../../modules/Users/User.helper";
import {
  clearClientCode,
  setClientCode,
  setClientName,
} from "../../../../redux/Client/clientSlice";
import {
  clearAllWorkableClients,
  clearHireCandidateStaging,
  clearHireList,
} from "../../../../redux/Hire/hireSlice";
import { clearMemberList } from "../../../../redux/People/peopleSlice";
import { Status } from "../../../../services/ApiStatus";
import { setClientHeader } from "../../../../services/BaseApi";
import { userAccess } from "../../../../utils/CommonFunction";
import LogoutSpinner from "../../LoadingSpinner/LogoutSpinner";

const drawerWidth = 240;
const settings = [
  {
    settingName: "Profile",
    settingType: "Label",
  },
  {
    settingName: "Settings",
    settingType: "Label",
  },
  {
    settingName: "Logout",
    settingType: "Label",
  },
];
function SideNav(props) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [variant, setVariant] = React.useState("temporary");
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userProfile, setUserProfile] = useState({});

  const { accessToken, refreshToken } = useSelector((state) => state.login);
  const { userInfo } = useSelector((state) => state.login);
  const { bodyPayload } = useSelector((state) => state.approval);
  const [loading, setLoading] = useState(false);
  const [selectedClients, setSelectedClients] = React.useState("All");

  const { allWorkableClients } = useSelector(
    //@ts-ignore
    (state) => state.hire
  );

  const { clientCode } = useSelector(
    //@ts-ignore
    (state) => state.client
  );

  useEffect(() => {
    if (!isMobile) {
      openDrawer();
      setMobileOpen(true);
      setVariant("persistent");
    } else {
      setMobileOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    setUserProfile(userInfo);
  }, [userInfo]);

  useEffect(() => {
    if (clientCode > 0) {
      setSelectedClients(clientCode);
    }
  }, [clientCode]);

  const handleDrawerToggle = () => {
    if (!isMobile) {
      setMobileOpen(true);
    } else {
      setMobileOpen(false);
    }
  };

  const openDrawer = () => {
    setMobileOpen(true);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
    if (event.target.innerText === "Logout") {
      logOutUser();
    } else if (event.target.innerText === "Profile") {
      navigate("/profile");
    }
  };

  const logOutUser = async () => {
    setLoading(true);
    try {
      let dataObj = { Token: accessToken, refreshToken: refreshToken };
      const response = await userLogout(dataObj);
      if (response?.status === Status.Success) {
        dispatch(logOut());
        dispatch(clearCompany());
        dispatch(clearHireList());
        dispatch(clearAllWorkableClients());
        dispatch(clearHireCandidateStaging());
        dispatch(clearMemberList());
        dispatch(clearClientCode());
        setClientHeader(0);
        setLoading(false);
        navigate("/");
      } else {
        setLoading(false);
        alert.error(response.data.errorMessage);
      }
    } catch (error) {
      setLoading(false);
      alert.error(error);
    }
  };

  const drawer = (
    <Box
      sx={{
        textAlign: "left",
        minHeight: "100vh"
      }}>
      <Box sx={{ py: 2, pl: 2, display: "flex", mt: 1,px: 2, justifyContent: 'center' }}>
        <img
          src="/images/Teamified-black-logo.png"
          style={{ width:'70%',height: '100%' }}
          alt="reset"
        />
      </Box>
      <Divider />
      <Box sx={{ mt: 1 }}>
        <NavItems close={handleDrawerToggle} />
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const params = useParams();

  let path =
    location.pathname.replace("/", "").charAt(0).toUpperCase() +
    location.pathname.slice(2).replaceAll("-", " ");

  path = !_.isEmpty(params) ? path.slice(0, path.lastIndexOf("/")) : path;

  path = path.substring(path.lastIndexOf("/") + 1);

  return (
    <>
      {loading && <LogoutSpinner />}

      <Box sx={{ display: "flex" }}>
        <AppBar component="nav">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={openDrawer}
              sx={{ mr: 2, display: { sm: "none" } }}>
              <MenuIcon />
            </IconButton>

            <Box
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>

            <Typography
              variant="h5"
              sx={{
                mr: 2,
                ml: { xs: 0, md: "240px" },
                display: { xs: "flex", md: "block" },
                flexGrow: 1,
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
                textTransform: "capitalize",
              }}>
              {path}
            </Typography>
            <Box sx={{ width: 250 }}>
              {!userAccess().client && (
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <InputLabel>Client</InputLabel>
                    <Select
                      label={"Client"}
                      value={selectedClients > 0 ? selectedClients : "All"}
                      onChange={(e) => {
                        const val =
                          e.target.value === "All" ? 0 : e.target.value;
                        setSelectedClients(val);
                        dispatch(setClientCode(val));
                        setClientHeader(val);
                        const path = location.pathname
                          .replace("/", "")
                          .toLowerCase();

                        setTimeout(() => {
                          switch (path) {
                            case "hire":
                              break;
                            case "team-members":
                              getAllMemberList(dispatch);
                              break;
                            case "client":
                              getAllClientList(dispatch);
                              break;
                            case "users":
                              getUsersListList(dispatch, true, 1, clientAdmin);
                              break;
                            case "leave":
                              let approvalPayload = { load: 0 };
                              if (bodyPayload.period !== undefined) {
                                approvalPayload = bodyPayload;
                              }
                              getApporovalList(dispatch, approvalPayload);
                              const date = new Date();
                              const updatedYear = date.getFullYear();
                              const updatedMonth = date.getMonth() + 1;
                              const calenderPayload = {
                                year: updatedYear,
                                month: updatedMonth,
                                leaveTypeList: [],
                                leaveStatusList: [],
                                searchValue: "",
                              };
                              getCalenderApporovalList(
                                dispatch,
                                calenderPayload
                              );
                              break;
                            case "invoices":
                              getPendingInvoiceList(dispatch);
                              getInvoiceGraphList(dispatch);
                              getPaidnvoiceList(dispatch);

                              break;

                            default:
                              const payload = {
                                clientId: val,
                              };
                              fetchDashboardData(payload, dispatch);
                              getPendingInvoiceList(dispatch);
                              getInvoiceGraphList(dispatch);
                              getPaidnvoiceList(dispatch);
                              setTimeout(() => {
                                fetchDashboardCalendar({}, dispatch);
                              }, 1000);
                            //fetchCountAndFilterData(dispatch);
                          }
                        }, 1000);
                      }}
                      sx={{ height: 50 }}>
                      <MenuItem value={"All"} key={0}>
                        {"All"}
                      </MenuItem>
                      {allWorkableClients?.map((e, key) => {
                        return (
                          <MenuItem
                            value={e.id}
                            key={key}
                            disabled={!e.isActive}
                            onClick={() => {
                              dispatch(setClientName(e.name));
                            }}>
                            <Box sx={{ display: "flex" }}>
                              <Avatar
                                alt={e?.name}
                                src={
                                  e?.logoImageURL
                                    ? e?.logoImageURL
                                    : "/static/images/avatar/1.jpg"
                                }
                                className="company_logo"
                              />

                              {e.name}
                            </Box>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton
                  onClick={(e) => {
                    handleOpenUserMenu(e);
                  }}
                  sx={{ pr: 0, backgroundColor: "transparent !important" }}>
                  <Typography
                    variant="h6"
                    sx={{ mr: 1, display: { xs: "none", md: "flex" } }}>
                    {userProfile.firstName} {userProfile.lastName}
                  </Typography>

                  <Avatar
                    alt={userProfile.companyName}
                    src="/static/images/avatar/2.jpg"
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                {settings?.map((settings, index) => (
                  <MenuItem key={index} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">
                      {settings.settingName}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant={isMobile ? "temporary" : "persistent"}
            open={mobileOpen}
            onClick={(event) => {
              event.stopPropagation();
            }}
            onClose={handleDrawerToggle}
            ModalProps={{
              onBackdropClick: handleDrawerToggle,
              onEscapeKeyDown: handleDrawerToggle,
              keepMounted: true, //Better open performance on mobile.
            }}
            sx={{
              display: {
                xs: mobileOpen ? "block" : "none",
                sm: mobileOpen ? "block" : "none",
              },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                boxShadow: "6px 4px 6px 0px #0000000D"
              },
            }}>
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </>
  );
}

SideNav.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SideNav;
