import Moment from "moment";
import { Dispatch } from "@reduxjs/toolkit";
import { sagaActions } from "../../redux/sagaActions";
import { postAdded } from "../../redux/Hire/hireSlice";
import { rowPerPage } from "../../Constants";

export const postJob = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.POST_JOB, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const postComment = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.POST_COMMENT, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const moveCandidateStage = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.MOVE_CANDIDATE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const disQualifiedCandidate = async (
  payload,
  dispatch: Dispatch<any>
) => {
  try {
    dispatch({ type: sagaActions.DISQUALIFIED_CANDIDATE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const updatePostJob = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.UPDATE_POST_JOB, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const saveSuccessFully = (dispatch: Dispatch<any>) => {
  dispatch(postAdded(false));
};

export const cancelJob = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.CANCEL_JOB, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const restoreJob = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.RESTORE_JOB, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getPageList = async (
  selectedRequestType,
  page,
  dispatch: Dispatch<any>,
  appendTo: any = [],
  pageSize = rowPerPage
) => {
  try {
    const payload = {
      load: 0,
      pageNumber: page,
      requestType: selectedRequestType,
      appendTo,
      pageSize,
    };
    dispatch({ type: sagaActions.JOBS_LIST, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getHire = async (dispatch: Dispatch<any>) => {
  try {
    const payload = {
      load: 0,
    };
    dispatch({ type: sagaActions.JOBS_LIST, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const sortClient = async (
  selectedRequestType,
  sort,
  key,
  dispatch: Dispatch<any>,
  pageSize = rowPerPage
) => {
  try {
    const payload = {
      requestType: selectedRequestType,
      isDesc: sort,
      columnName: key,
      pageSize,
    };
    dispatch({ type: sagaActions.JOBS_LIST, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const searchHire = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.JOBS_LIST, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getJobDetail = async (location, dispatch: Dispatch<any>) => {
  try {
    const payload = {
      jobShortCode: location?.state?.jobShortCode,
      stage: location?.state?.stage,
    };
    dispatch({ type: sagaActions.JOBS_DETAILS, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getJobDetailByJobRequestId = async (
  id,
  dispatch: Dispatch<any>
) => {
  try {
    const payload = {
     Id: id
    };
    dispatch({ type: sagaActions.JOB_DETAILS_BY_JOBID, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getJobDetailById = async (id, dispatch: Dispatch<any>) => {
  try {
    const payload = {
      jobRequestId: id,
      stage: "Applied",
    };
    dispatch({ type: sagaActions.JOB_DETAILS_BY_JOBID, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getHireCandidateStagingHelper = async (id: string, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.GET_HIRE_CANDIDATES_STAGING, payload: id });
  }catch (error: any){
    if(error){
      console.log(error)
    }
  }
}

export const generateChatGptJobDescription = async (
  { ...params },
  dispatch: Dispatch<any>
) => {
  try {
    const payload = {
      jobTitle: params.jobTitle,
      clientId: params.clientId,
      skills: params.skills,
      expLevel: params.expLevel,
      employmentType: params.employmentType,
      salaryFrom: params.salaryFrom,
      salaryTo: params.salaryTo,
      location: params.location,
      noticePeriod: params.noticePeriod,
    };
    dispatch({ type: sagaActions.CHAT_GPT_DESCRIPTION, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getCandidateList = async (location, dispatch: Dispatch<any>) => {
  try {
    const payload = {
      jobShortCode: location?.state?.jobShortCode,
      stage: location?.state?.stage,
      load: 0,
    };
    dispatch({ type: sagaActions.CANDIDATE_LIST, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getCandidateDataByApiId = async (
  payload,
  dispatch: Dispatch<any>
) => {
  try {
    dispatch({ type: sagaActions.CANDIDATE_DETAIL, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getStageCandidateList = async (
  payload,
  dispatch: Dispatch<any>
) => {
  try {
    dispatch({ type: sagaActions.CANDIDATE_LIST, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getMoreCandidateList = async (
  payload,
  dispatch: Dispatch<any>
) => {
  try {
    dispatch({ type: sagaActions.MORE_CANDIDATE_LIST, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const filerHire = async (
  request,
  searchValue,
  dispatch: Dispatch<any>
) => {
  try {
    const payload = {
      load: 0,
      requestType: request,
      searchValue,
    };
    dispatch({ type: sagaActions.JOBS_LIST, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const hireGridCancelledHeader = () => {
  const columns = [
    {
      id: 1,
      name: "Request for",
      soryKey: "requestfor",
      type: "heading",
      sortable: true,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 2,
      name: "Applied",
      soryKey: "Applied",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 3,
      name: "Assessment",
      soryKey: "Assessment",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 4,
      name: "Hired",
      soryKey: "Hired",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 5,
      name: "Hiring Approval",
      soryKey: "hiringapproval",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 6,
      name: "Interview",
      soryKey: "Interview",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 7,
      name: "Offer",
      soryKey: "Offer",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 8,
      name: "Partner Interview",
      soryKey: "partnerinterview",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 9,
      name: "Sourced",
      soryKey: "Sourced",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 10,
      name: "Screening",
      soryKey: "screening",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 11,
      name: "Video interview",
      soryKey: "videointerview",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },

    {
      id: 12,
      name: "",
      soryKey: "Disqualify",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 13,
      name: "",
      soryKey: "Disqualify",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
  ];
  return columns;
};

export const hireGridCompletedHeader = () => {
  const columns = [
    {
      id: Math.floor(Math.random() * 1000000),
      name: "Request for",
      soryKey: "requestfor",
      type: "heading",
      sortable: true,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: Math.floor(Math.random() * 1000000),
      name: "Experience",
      soryKey: "Experience",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: Math.floor(Math.random() * 1000000),
      name: "Candidate name",
      soryKey: "candidatename",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: Math.floor(Math.random() * 1000000),
      name: "Completed date",
      soryKey: "completeddate",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: Math.floor(Math.random() * 1000000),
      name: "Client",
      soryKey: "Client",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: Math.floor(Math.random() * 1000000),
      name: "Location",
      soryKey: "Location",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: Math.floor(Math.random() * 1000000),
      name: "Salary",
      soryKey: "Salary",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: Math.floor(Math.random() * 1000000),
      name: "",
      soryKey: "action",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: Math.floor(Math.random() * 1000000),
      name: "",
      soryKey: "action",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: Math.floor(Math.random() * 1000000),
      name: "",
      soryKey: "action",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
  ];
  return columns;
};

export const hireGridHeader = (rowValue: Array<any>) => {
  let columnsHeader: any = [];
  columnsHeader.push({
    id: Math.floor(Math.random() * 1000000),
    name: "Request for",
    soryKey: "requestfor",
    type: "heading",
    sortable: true,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  });

  rowValue[0]?.reqTypeData[0]?.stages?.map((stage, index) => {
    return columnsHeader.push({
      id: Math.floor(Math.random() * 1000000),
      name: stage?.current_Stage_Name,
      soryKey: stage?.current_Stage_Name,
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    });
  });
  columnsHeader.push(
    {
      id: Math.floor(Math.random() * 1000000),
      name: "",
      soryKey: "Disqualify",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: Math.floor(Math.random() * 1000000),
      name: "",
      soryKey: "Disqualify",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    }
  );
  return columnsHeader;
};

export const invitationStatus = (rowV) => {
  let chipColor = "info";
  let chipText = "";
  let chip = "";
  if (!rowV.isRegistered) {
    if (rowV.isInvitationNotAccepted) {
      if (rowV.isInvitationExpired) {
        chipText = "Invitation expired";
        chipColor = "error";
        chip = "chip";
      } else {
        chipText = "Invitation not accepted";
        chipColor = "warning";
        chip = "chip";
      }
    } else if (rowV.isInvitationExpired) {
      chipText = "Invitation expired";
      chipColor = "error";
      chip = "chip";
    }
  }
  return { chipColor: chipColor, chipText: chipText, chip: chip };
};

export const hireGridCompletedRow = (rowValue: Array<any>) => {
  const row = rowValue?.map((rowV, index) => {
    let columns: any = [];
    let subRow: any = [];
    columns.push({
      id: Math.floor(Math.random() * 1000000),
      name: rowV.reqTypeData[0].workableJobResponse.title,
      type: "text",
      experience: false,
      avatar: false,
      editable: false,
      display: true,
      color: "#000000",
      fontSize: 12,
      fontWeight: "400",
      textTransform: "capitalize",
    });
    rowV.reqTypeData[0].stages?.map((stage, index) => {
      return subRow.push({
        id: Math.floor(Math.random() * 1000000) + index + 1,
        list: { name: stage?.current_Stage_Name, value: stage?.stage_Count },
        type: "object",
        experience: false,
        avatar: false,
        editable: false,
        display: true,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      });
    });

    columns.push(
      {
        id:
          Math.floor(Math.random() * 1000000) +
          rowV.reqTypeData[0].workableJobResponse.shortcode,
        name: rowV.reqTypeData[0].workableJobResponse.experience
          ? `${rowV.reqTypeData[0].workableJobResponse.experience}`
          : "",
        type: "text",
        experience: false,
        avatar: false,
        editable: false,
        display: true,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id:
          Math.floor(Math.random() * 1000000) +
          rowV.reqTypeData[0].workableJobResponse.shortcode,
        list: rowV.reqTypeData[0].workableJobResponse.candidates,
        type: "array",
        experience: false,
        avatar: false,
        editable: false,
        display: true,
        color: "#002DFF",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id:
          Math.floor(Math.random() * 1000000) +
          rowV.reqTypeData[0].workableJobResponse.shortcode,
        name: rowV.reqTypeData[0].workableJobResponse.completedDate
          ? `${Moment(
              rowV.reqTypeData[0].workableJobResponse.completedDate
            ).format("DD-MM-YYYY")}`
          : "",
        type: "text",
        experience: false,
        avatar: false,
        editable: false,
        display: true,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id:
          Math.floor(Math.random() * 1000000) +
          rowV.reqTypeData[0].workableJobResponse.shortcode,
        name: `${rowV.reqTypeData[0].workableJobResponse.client}`,
        type: "text",
        experience: false,
        avatar: false,
        editable: false,
        display: true,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id:
          Math.floor(Math.random() * 1000000) +
          rowV.reqTypeData[0].workableJobResponse.shortcode,
        name: `${rowV.reqTypeData[0].workableJobResponse.countryCode}`,
        type: "text",
        experience: false,
        avatar: false,
        editable: false,
        display: true,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id:
          Math.floor(Math.random() * 1000000) +
          rowV.reqTypeData[0].workableJobResponse.shortcode,
        name: rowV.reqTypeData[0].workableJobResponse.expectedSalary
          ? `$${rowV.reqTypeData[0].workableJobResponse.expectedSalary}`
          : "",
        type: "text",
        experience: false,
        avatar: false,
        editable: false,
        display: true,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id:
          Math.floor(Math.random() * 1000000) +
          rowV.reqTypeData[0].workableJobResponse.shortcode,
        name: "",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        peopleId: rowV?.reqTypeData[0]?.workableJobResponse?.isPostedFromTMF
          ? rowV.reqTypeData[0].workableJobResponse.jobRequestId
          : rowV.reqTypeData[0].workableJobResponse.shortcode,
        details: rowV.reqTypeData[0],
        disable: rowV?.status,
      },
      {
        id:
          Math.floor(Math.random() * 1000000) +
          rowV.reqTypeData[0].workableJobResponse.shortcode,
        name: "",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        peopleId: rowV?.reqTypeData[0]?.workableJobResponse?.isPostedFromTMF
          ? rowV.reqTypeData[0].workableJobResponse.jobRequestId
          : rowV.reqTypeData[0].workableJobResponse.shortcode,
        details: rowV.reqTypeData[0],
        disable: rowV?.status,
      },

      {
        id:
          Math.floor(Math.random() * 1000000) +
          rowV.reqTypeData[0].workableJobResponse.shortcode,
        name: "",
        type: "action",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        peopleId: rowV?.reqTypeData[0]?.workableJobResponse?.isPostedFromTMF
          ? rowV.reqTypeData[0].workableJobResponse.jobRequestId
          : rowV.reqTypeData[0].workableJobResponse.shortcode,
        details: rowV.reqTypeData[0],
        disable: rowV?.status,
      }
    );
    return {
      ...rowV,
      subRow,
      columns,
      teamMemberId: rowV?.reqTypeData[0]?.workableJobResponse?.isPostedFromTMF
        ? rowV.reqTypeData[0].workableJobResponse.jobRequestId
        : rowV.reqTypeData[0].workableJobResponse.shortcode,
      details: rowV.reqTypeData[0],
    };
  });

  return row;
};

export const hireGridRow = (rowValue: Array<any>) => {
  const row = rowValue?.map((rowV, index) => {
    let columns: any = [];
    columns.push({
      id: Math.floor(Math.random() * 1000000),
      name: rowV.reqTypeData[0].workableJobResponse.title,
      type: "title",
      experience: false,
      avatar: false,
      editable: false,
      display: true,
      color: "#000000",
      fontSize: 12,
      fontWeight: "400",
      textTransform: "capitalize",
    });
    rowV.reqTypeData[0].stages?.map((stage, index) => {
      return columns.push({
        id: Math.floor(Math.random() * 1000000),
        name: stage?.stage_Count,
        type: "text",
        experience: false,
        avatar: false,
        editable: false,
        display: true,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      });
    });
    columns.push(
      {
        id:
          Math.floor(Math.random() * 1000000) +
          rowV.reqTypeData[0].workableJobResponse.shortcode,
        name: "",
        type: "action",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        peopleId: rowV?.reqTypeData[0]?.workableJobResponse?.isPostedFromTMF
          ? rowV.reqTypeData[0].workableJobResponse.jobRequestId
          : rowV.reqTypeData[0].workableJobResponse.shortcode,
        details: rowV.reqTypeData[0],
        disable: rowV?.status,
      },
      {
        id:
          Math.floor(Math.random() * 1000000) +
          rowV.reqTypeData[0].workableJobResponse.shortcode,
        name: "",
        type: "info",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        peopleId: rowV?.reqTypeData[0]?.workableJobResponse?.isPostedFromTMF
          ? rowV.reqTypeData[0].workableJobResponse.jobRequestId
          : rowV.reqTypeData[0].workableJobResponse.shortcode,
        details: rowV.reqTypeData[0],
        disable: rowV?.status,
        toolTipInfo: rowV.reqTypeData[0].workableJobResponse,
      }
    );

    let subRow: any = [];
    subRow.push(
      {
        id: Math.floor(Math.random() * 1000000) + rowV.userId,
        name: `Client - ${rowV.reqTypeData[0].workableJobResponse.client}`,
        type: "title",
        experience: false,
        avatar: false,
        editable: false,
        display: true,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: Math.floor(Math.random() * 1000000) + rowV.userId,
        name: `Location - ${rowV.reqTypeData[0].workableJobResponse.countryCode}`,
        type: "text",
        experience: false,
        avatar: false,
        editable: false,
        display: true,
        color: "#000000",
        fontSize: 12,
        colSpan: 2,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: Math.floor(Math.random() * 1000000) + rowV.userId,
        name: `Request date - ${
          rowV.reqTypeData[0].workableJobResponse.createdDate
            ? Moment(
                rowV.reqTypeData[0].workableJobResponse.createdDate
              ).format("DD-MM-YYYY")
            : ""
        }`,
        type: "text",
        experience: false,
        avatar: false,
        editable: false,
        display: true,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        colSpan: 2,
        textTransform: "capitalize",
      },
      {
        id: Math.floor(Math.random() * 1000000) + rowV.userId,
        name: `${
          rowV.reqTypeData[0].workableJobResponse.expectedSalary
            ? `Ex. salary - ${rowV.reqTypeData[0].workableJobResponse.expectedSalary}`
            : ""
        }`,
        type: "text",
        experience: false,
        avatar: false,
        editable: false,
        display: true,
        color: "#000000",
        fontSize: 12,
        colSpan: 2,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: Math.floor(Math.random() * 1000000) + rowV.userId,
        name: rowV?.reqTypeData[0]?.workableJobResponse?.isPostedFromTMF
          ? `Request raised`
          : rowV?.reqTypeData[0]?.workableJobResponse
              ?.isRequestLiveOnWorkableFromTMF
          ? `Request is live`
          : ``,
        type: "text",
        experience: false,
        avatar: false,
        editable: false,
        display: true,
        colSpan: 2,
        color: rowV?.reqTypeData[0]?.workableJobResponse?.isPostedFromTMF
          ? "#F27F0D"
          : rowV?.reqTypeData[0]?.workableJobResponse
              ?.isRequestLiveOnWorkableFromTMF
          ? "#00E08C"
          : "#F27F0D",
        fontSize: 14,
        fontWeight: "500",
        textTransform: "capitalize",
      }
    );

    return {
      ...rowV,
      subRow,
      columns,
      teamMemberId: rowV?.reqTypeData[0]?.workableJobResponse?.isPostedFromTMF
        ? rowV.reqTypeData[0].workableJobResponse.jobRequestId
        : rowV.reqTypeData[0].workableJobResponse.shortcode,
      details: rowV.reqTypeData[0],
    };
  });

  return row;
};

export const removeByAttr = function (arr, attr, value) {
  var i = arr.length;
  while (i--) {
    if (
      arr[i] &&
      arr[i].hasOwnProperty(attr) &&
      arguments.length > 2 &&
      arr[i][attr] === value
    ) {
      arr.splice(i, 1);
    }
  }
  return arr;
};
