import _ from "lodash";
import { all, put, takeLatest } from "redux-saga/effects";
import { STRINGS } from "../../Strings";
import { User, UserList } from "../../Types/Users/User";
import { Status } from "../../services/ApiStatus";
import {
  editProfile,
  getClients,
  getProfile,
  getTimeZone,
  saveClientUsers,
  saveResendInvite,
  updatePassword,
  userDisable,
  users,
} from "../../services/Users";
import { userAccess } from "../../utils/CommonFunction";
import { sagaActions } from "../sagaActions";
import {
  clientAddingInProgress,
  clientUserAdded,
  failAllClients,
  getUpdatePending,
  getUserFail,
  getUserListFail,
  getUserListPending,
  getUserListSuccess,
  getUserPending,
  getUserSuccess,
  paging,
  passwordChangePending,
  passwordChangeSuccess,
  resendInProgress,
  resendSent,
  setTimeZone,
  setTimeZoneFail,
  successAllClients,
  userCount,
  userUpdated,
  userUpdatedFail,
} from "./userSlice";

function* fetchProfile(payload: any = "") {
  try {
    yield put(getUserPending());
    const response: User = yield getProfile(payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getUserSuccess(response.data));
    } else {
      yield put(getUserFail(response?.message));
    }
  } catch (error: any) {
    yield put(getUserFail(error?.message));
  }
}

function* fetchTimeZone() {
  try {
    const response: User = yield getTimeZone();
    if (!!response && response?.status === Status.Success) {
      yield put(setTimeZone(response.data));
    } else {
      yield put(setTimeZoneFail({}));
    }
  } catch (error: any) {
    yield put(setTimeZoneFail({}));
  }
}

function* updateProfile(payload) {
  try {
    yield put(getUpdatePending());
    const response = yield editProfile(payload.payload);
    if (!!response && response?.status === Status.Success) {
      const request = { payload: { userId: userAccess().userId } };
      yield fetchProfile(request);
      yield put(userUpdated(true));
    } else {
      yield put(userUpdated(false));
      yield put(userUpdatedFail(STRINGS.ErrorOnUpdte));
    }
  } catch (error: any) {
    yield put(userUpdated(false));
    yield put(userUpdatedFail(error.message || STRINGS.ErrorOnUpdte));
  }
}

function* changeUserPassword(payload) {
  try {
    yield put(passwordChangePending(true));
    const response = yield updatePassword(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(passwordChangeSuccess(response.data));
      yield put(passwordChangePending(false));
    }
  } catch (error: any) {
    yield put(passwordChangeSuccess(false));
    yield put(passwordChangePending(false));
  }
}

function* fetchUsersList(payload: any = "") {
  try {
    if (!_.isUndefined(payload?.payload?.load)) {
      yield put(getUserListPending());
    }

    const response: UserList = yield users(payload.payload);
    if (!!response && response?.status === Status.Success) {
      const { paginationFilter } = response.data;
      const page = Math.ceil(
        paginationFilter.count / paginationFilter.pageSize
      );
      const isPaging = page > 1 ? true : false;
      yield put(
        paging({
          page: page,
          isPaging: isPaging,
          pageSize: paginationFilter.pageSize,
          currentPage: paginationFilter.pageNumber,
        })
      );
      yield put(userCount(paginationFilter.count));
      yield put(getUserListSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getUserListFail(response?.message));
        yield put(paging({ page: 0, isPaging: false }));
        yield put(userCount(0));
      }
    }
  } catch (error: any) {
    yield put(getUserListFail(error?.message));
    yield put(paging({ page: 0, isPaging: false }));
    yield put(userCount(0));
  }
}

function* getAllClientsList() {
  try {
    const response: UserList = yield getClients();
    if (!!response && response?.status === Status.Success) {
      yield put(successAllClients(response.data));
    } else {
      yield put(failAllClients());
    }
  } catch (error: any) {
    yield put(failAllClients());
  }
}

function* addClientUser(payload) {
  try {
    yield put(clientAddingInProgress(true));
    const response = yield saveClientUsers(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(clientUserAdded(true));
      const request = {
        payload: {
          load: 0,
          pageNumber: 1,
          pageSize: "10",
          clientId: userAccess().ClientId,
          isFilter: false,
          searchValue: "",
          isActive: true,
          isInActive: true,
          isCurrentMonth: false,
          isDesc: false,
          columnName: "",
          role: payload.payload.fetchRole,
        },
      };

      yield fetchUsersList(request);
    } else {
      yield put(clientAddingInProgress(false));
      yield put(clientUserAdded(false));
    }
  } catch (error: any) {}
}

function* resendInvite(payload) {
  try {
    yield put(resendInProgress(true));
    const response = yield saveResendInvite(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(resendSent(true));
      const request = {
        payload: {
          load: 0,
          pageNumber: 1,
          pageSize: "10",
          clientId: userAccess().ClientId,
          isFilter: false,
          searchValue: "",
          isActive: true,
          isInActive: true,
          isCurrentMonth: false,
          isDesc: false,
          columnName: "",
          role: payload.payload.fetchRole,
        },
      };

      yield fetchUsersList(request);
    } else {
      yield put(resendInProgress(false));
      yield put(resendSent(false));
    }
  } catch (error: any) {}
}

function* disableEnableUser(payload) {
  try {
    const response = yield userDisable(payload.payload);
    if (!!response && response?.status === Status.Success) {
      const request = {
        payload: {
          load: 0,
          pageNumber: 1,
          pageSize: "10",
          clientId: userAccess().ClientId,
          isFilter: false,
          searchValue: "",
          isActive: true,
          isInActive: true,
          isCurrentMonth: false,
          isDesc: false,
          columnName: "",
          role: payload.payload.fetchRole,
        },
      };

      yield fetchUsersList(request);
    }
  } catch (error: any) {
    //yield put(memberFail(error?.message));
  }
}

export default function* watcherSaga() {
  yield all([yield takeLatest(sagaActions.GET_PROFILE, fetchProfile)]);
  yield all([yield takeLatest(sagaActions.GET_TIMEZONE, fetchTimeZone)]);
  yield all([yield takeLatest(sagaActions.UPDATE_PROFILE, updateProfile)]);
  yield all([
    yield takeLatest(sagaActions.CHANGE_PASSWORD, changeUserPassword),
  ]);
  yield all([yield takeLatest(sagaActions.USERS_LIST, fetchUsersList)]);
  yield all([yield takeLatest(sagaActions.COMPANY_LIST, getAllClientsList)]);
  yield all([yield takeLatest(sagaActions.SAVE_USER_CLIENT, addClientUser)]);
  yield all([yield takeLatest(sagaActions.RESEND_INVITE, resendInvite)]);
  yield takeLatest(sagaActions.DISABLE_ENABLE_USER, disableEnableUser);
}
