import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";

import LogTimeModal from "../Modal/LogTimeModal";
import CreateTimeSheetModal from "../Modal/CreateTimeSheetModal";

const TimeSheetHeader = ({
  timeSheetView,
  setTimeSheetView,
  openLogTime,
  setOpenLogTime,
  handleLogTimeOpenClose,
  logTimeDate,
  setLogTimeDate,
  tabIndex,
}) => {
  const isMobile = useMediaQuery(`(max-width: 760px)`);

  return (
    <Grid>
      <Grid
        gap={3}
        pt={3}
        pb={3}
        container
        className={"page-header-blue-timesheet"}
        justifyContent={"space-between"}
        bgcolor={isMobile ? "white" : ""}
      >
        <Grid item xs={12} sm={3} display={"flex"} alignItems={"center"}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={timeSheetView}
              onChange={(e: any) => {
                setTimeSheetView(e.target.value);
              }}
            >
              <FormControlLabel
                value="List"
                control={<Radio />}
                label="List view"
              />
              <FormControlLabel
                value="Calendar"
                control={<Radio />}
                label="Calendar view"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Button
          variant="contained"
          size="small"
          onClick={handleLogTimeOpenClose}
        >
          {tabIndex ? "Create time sheet" : "Log Time"}
        </Button>
        {tabIndex ? (
          <CreateTimeSheetModal
            open={openLogTime}
            setOpenLogTime={setOpenLogTime}
            logTimeDate={logTimeDate}
            setLogTimeDate={setLogTimeDate}
          />
        ) : (
          <LogTimeModal
            open={openLogTime}
            setOpenLogTime={setOpenLogTime}
            logTimeDate={logTimeDate}
            setLogTimeDate={setLogTimeDate}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default TimeSheetHeader;
