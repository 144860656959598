const TimeIcon = ({ active }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.57143 3.00639C7.17518 3.04487 6.85876 3.08926 6.61628 3.13958C3.73602 3.7286 1.50633 6.06395 1.0805 8.93799C0.820274 10.6902 1.19583 12.4366 2.1362 13.8455C2.43783 14.2983 2.67145 14.5795 3.08249 14.9791C3.46396 15.3521 3.71828 15.5563 4.14706 15.8345C5.04308 16.4206 6.04259 16.7935 7.12786 16.9474C7.60101 17.0155 8.50885 17.0185 8.97903 16.9504C10.499 16.7284 11.8268 16.0624 12.9268 14.9614C15.121 12.7681 15.6149 9.65725 14.2102 6.86312C13.8494 6.14682 13.3556 5.43349 13.1397 5.32102C12.9564 5.2263 12.7169 5.22926 12.566 5.3299C12.4862 5.38317 11.7262 6.32442 10.2476 8.19506C9.03818 9.72828 8.03866 10.9774 8.02683 10.9744C8.01205 10.9685 7.44132 10.2522 6.7523 9.38198C5.68478 8.02931 5.48073 7.78956 5.36836 7.73036C4.92183 7.49949 4.36293 7.99675 4.5581 8.45257C4.61133 8.57985 7.44428 12.1998 7.63649 12.3892C7.74295 12.4958 7.97952 12.5668 8.12146 12.5372C8.36099 12.4899 8.35212 12.5017 10.6202 9.63061C11.8356 8.09443 12.8381 6.83648 12.8529 6.83648C12.915 6.83648 13.2876 7.55869 13.4502 7.99379C13.7223 8.71896 13.811 9.28726 13.7873 10.1515C13.7666 10.9211 13.675 11.3917 13.4236 12.0518C12.98 13.218 12.1846 14.2125 11.1496 14.8962C10.4606 15.3521 9.58525 15.6688 8.72768 15.7753C8.39352 15.8168 7.60988 15.8168 7.27276 15.7753C6.10765 15.6332 5.04012 15.1537 4.16776 14.3871C2.9435 13.3127 2.17465 11.593 2.17465 9.93252C2.17465 9.61877 2.24857 8.98831 2.3225 8.67161C2.7779 6.73584 4.24465 5.13158 6.14609 4.49225C7.3526 4.08675 8.58869 4.08379 9.83069 4.48337C10.0554 4.55737 10.2831 4.61656 10.3393 4.61656C10.6883 4.61656 10.9751 4.1933 10.8686 3.83812C10.8125 3.64572 10.6557 3.51845 10.3482 3.40893C9.83661 3.22838 9.45809 3.13662 8.92581 3.06263C8.61826 3.01823 7.79026 2.98567 7.57143 3.00639Z"
        className="fill-color"
      />
    </svg>
  );
};

export default TimeIcon;
