import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useAlert } from "react-alert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Box,
  Grid,
  Button,
  Typography,
  Modal,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { TextField, FormControl, Select, InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { userAccess } from "../../utils/CommonFunction";
import { Link } from "react-router-dom";
import { Country } from "country-state-city";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { styles } from "./HireStyle";
import { STRINGS } from "../../Strings";
import { fileSet } from "../People/People.helper";
import { addHirePostSchema } from "../../schemas/post-hire";
import {
  generateChatGptJobDescription,
  postJob,
  saveSuccessFully,
  updatePostJob,
} from "./Hire.helper";
import _ from "lodash";
import { reactQuillFormats, reactQuillModules } from "../../Constants";
import TableSkeleton from "../../shared/components/Skeletons/TableSkeleton";
import { chatGptJobError } from "../../redux/Hire/hireSlice";

let initialValues = {
  jobRequestId: 0,
  clientId: !userAccess().client ? 0 : userAccess().clientCode,
  workableInternalCode: "",
  title: "",
  employeeType: "",
  experienceLevel: "",
  location: "",
  client: "",
  expectedJoiningDate: Moment(new Date()).format("MM/DD/YYYY"),
  expectedSalaryFrom: "",
  expectedSalaryTo: "",
  aboutTheRole: "",
  otherRequirement: "",
  fileName: "",
  file: "",
  isFileUpdated: false,
};
const label = { inputProps: { "aria-label": "controlled" } };

const EditJobPost = (props) => {
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "70%" : "50%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    p: 4,
    boxShadow: "1px 4px 20px rgba(132, 132, 132, 0.2)",
    borderRadius: "10px",
  };
  const {
    isHireDetailLoading,
    hireJobsDetail,
    allWorkableClients,
    isJobAdded,
    jobAddUpdateError,
    chatGptJobPending,
    chatGptJobData,
  } = useSelector(
    //@ts-ignore
    (state) => state.hire
  );
  const classes = styles();
  const dispatch = useDispatch();
  const location = useLocation();
  const alert = useAlert();
  const [content, setContent] = React.useState("");

  const [selectFileName, setFileName] = React.useState<any>("");
  const [selectFile, setFile] = React.useState<any>("");
  const [formAction, setFormAction] = React.useState<any>();
  const [postJobLoading, setPostJobLoading] = React.useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = React.useState("");
  const [selectedClients, setSelectedClients] = React.useState<any>(
    userAccess().clientCode
  );
  const [selectedCountryName, setSelectedCountryName] = React.useState("");
  const [inputSkills, setInputSkills] = React.useState<any>("");
  const [inputNoticePeriods, setInputNoticePeriods] = React.useState<any>("");
  const [generateDes, setGenerateDes] = React.useState<boolean>(true);
  const [selectedAiIndex, setSelectedAiIndex] = React.useState<number>(0);
  const [showAiGenerateJob, setShowAiGenerateJob] = React.useState(false);
  const [isResumeUpdated, setIsResumeUpdated] = React.useState<boolean>(false);
  const [selectedExperience, setSelectedExperience] = React.useState<any>("");
  let [formValues, setFormValues] = React.useState(null);
  const [selectedEmploymentType, setSelectedEmploymentType] =
    React.useState<any>("Permanent");
  const [companyName, setCompanyName] = React.useState<any>("");
  const [dojValue, setDOJValue] = React.useState<any>(
    Moment(new Date()).format("MM/DD/YYYY")
  );
  const navigate = useNavigate();

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const handleDateChange = (newValue) => {
    const changedDate = Moment(new Date(newValue))
      .format("MM/DD/YYYY")
      .toString();
    setDOJValue(changedDate);
  };

  const { jobdetail = null } = location?.state;

  useEffect(() => {
    if (!_.isNull(jobdetail)) {
      initialValues = {
        jobRequestId: jobdetail?.jobId,
        clientId: jobdetail?.clientId,
        workableInternalCode: jobdetail?.workableInternalCode,
        title: jobdetail?.title,
        employeeType: jobdetail?.employeeType,
        experienceLevel: jobdetail?.experienceLevel,
        location: jobdetail?.location,
        client: jobdetail?.client,
        expectedJoiningDate: Moment(new Date()).format("MM/DD/YYYY"),
        expectedSalaryFrom: jobdetail?.expectedSalary?.split("-")[0] || "",
        expectedSalaryTo: jobdetail?.expectedSalary?.split("-")[1] || "",
        aboutTheRole: jobdetail?.aboutTheRole,
        otherRequirement: jobdetail?.additionalRequirements,
        fileName: jobdetail?.fileName,
        file: jobdetail?.fileURL,
        isFileUpdated: false,
      };
      setFieldValue(
        "expectedJoiningDate",
        Moment(new Date()).format("MM/DD/YYYY")
      );
      setFieldValue("jobRequestId", jobdetail?.jobId);
      setFieldValue("aboutTheRole", jobdetail?.aboutTheRole);
      setFieldValue("otherRequirement", jobdetail?.additionalRequirements);
      setFieldValue(
        "expectedSalaryFrom",
        jobdetail?.expectedSalary?.split("-")[0] || ""
      );
      setFieldValue(
        "expectedSalaryTo",
        jobdetail?.expectedSalary?.split("-")[1] || ""
      );
      setFieldValue("title", jobdetail?.title);
      setFieldValue("clientId", jobdetail?.clientId);
      setSelectedClients(jobdetail?.clientId);
      setSelectedEmploymentType(jobdetail?.employeeType);
      setSelectedExperience(jobdetail?.experienceLevel);
      setFieldValue("experienceLevel", jobdetail?.experienceLevel);
      setSelectedCountry(jobdetail?.location);
      setFieldValue("location", jobdetail?.location);
      setFileName(jobdetail?.fileName);
      setFile(jobdetail?.fileURL);
      setCompanyName(jobdetail?.client);
      //@ts-ignore
      setFormValues(initialValues);
    }
  }, [jobdetail]);

  useEffect(() => {
    if (jobAddUpdateError) {
      alert.error(jobAddUpdateError);
      setPostJobLoading(false);
    }
  }, [jobAddUpdateError]);

  useEffect(() => {
    if (isJobAdded) {
      alert.success(STRINGS.JobRequestUpdateSuccess);
      setPostJobLoading(false);
      if (formAction) {
        formAction.resetForm();
      }
      navigate("/hire");
      saveSuccessFully(dispatch);
    }
  }, [isJobAdded]);

  useEffect(() => {
    if (userAccess().client) {
      setFieldValue("clientId", userAccess().clientCode);
    }
  }, [userAccess().client]);

  const {
    values,
    errors,
    touched,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: formValues || initialValues,
    validationSchema: addHirePostSchema,
    onSubmit: (values, action) => {
      values.expectedJoiningDate = dojValue;
      values.employeeType = selectedEmploymentType;
      values.client = companyName;
      //   if (userAccess().client) {
      //     values.clientId = userAccess().clientCode;
      //     values.client = userAccess().clientName;
      //   }
      values.fileName = selectFileName;
      values.file = selectFile;
      values.isFileUpdated = isResumeUpdated;
      setPostJobLoading(true);
      updatePostJob(values, dispatch);
      setFormAction(action);
    },
  });

  useEffect(() => {
    if (values?.title !== "" && selectedClients !== "" && selectedClients > 0) {
      setGenerateDes(false);
    } else {
      setGenerateDes(true);
    }
  }, [values?.title, selectedClients]);

  return (
    <Box
      justifyContent={"center"}
      display={"block"}
      alignContent={"center"}
      alignItems={"center"}
      alignSelf={"center"}>
      <Box className={classes.add_member_Header} sx={{ display: "flex" }}>
        <Grid item md={8}>
          <Typography
            variant="h4"
            sx={{ display: "flex", textTransform: "capitalize" }}>
            <Link className={classes.back_icon} to="/hire">
              <ArrowBackIosNewIcon />
            </Link>
            {STRINGS.HireNow}
          </Typography>
        </Grid>
      </Box>

      <Box
        sx={{
          bgcolor: "background.paper",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          boxShadow: 24,
          p: 4,
          pointerEvents: postJobLoading ? "none" : "auto",
        }}
        component="form"
        noValidate
        onSubmit={handleSubmit}>
        <Typography
          variant="h5"
          sx={{ display: "flex", textTransform: "capitalize" }}>
          {STRINGS.EditJob}
        </Typography>

        <Grid container spacing={2} sx={{ mt: 1 }}>
          {!userAccess().client && (
            <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel>Client*</InputLabel>
                <Select
                  error={Boolean(touched.clientId && errors.clientId)}
                  id="clientId"
                  name="clientId"
                  label="Client*"
                  value={selectedClients}
                  onChange={(e) => {
                    setSelectedClients(e.target.value);
                    setFieldValue("clientId", e.target.value);
                  }}>
                  {allWorkableClients?.map((e, key) => {
                    return (
                      <MenuItem
                        value={e.id}
                        onClick={(event) => {
                          event.stopPropagation();
                          setCompanyName(e.name);
                        }}
                        key={key}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <div style={{ marginTop: 10, marginLeft: 5, color: "#D14343" }}>
                  {touched.clientId && errors.clientId ? errors.clientId : null}
                </div>
              </FormControl>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={!userAccess().client ? 6 : 12}
            md={!userAccess().client ? 6 : 12}>
            <TextField
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
              margin="normal"
              fullWidth
              id="title"
              label={`${STRINGS.JobTitle}*`}
              name="title"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 25 }}
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={2} mt={2}>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <InputLabel>{STRINGS.EmploymentType}</InputLabel>
              <Select
                id="employeeType"
                name="employeeType"
                label={STRINGS.EmploymentType}
                value={selectedEmploymentType}
                onChange={(e) => {
                  setSelectedEmploymentType(e.target.value);
                  setFieldValue("employeeType", e.target.value);
                }}>
                <MenuItem value={"On Contract"}>{"On Contract"}</MenuItem>
                <MenuItem value={"Permanent"}>{"Permanent"}</MenuItem>
                <MenuItem value={"Probation"}>{"Probation"}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <InputLabel>{STRINGS.Experience}</InputLabel>
              <Select
                error={Boolean(
                  touched.experienceLevel && errors.experienceLevel
                )}
                id="experienceLevel"
                name="experienceLevel"
                label={STRINGS.Experience}
                value={selectedExperience}
                onChange={(e) => {
                  setSelectedExperience(e.target.value);
                  setFieldValue("experienceLevel", e.target.value);
                }}>
                {experienceArray?.map((e, key) => {
                  return (
                    <MenuItem value={e.name} key={key}>
                      {e.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <div style={{ marginTop: 10, marginLeft: 5, color: "#D14343" }}>
                {touched.experienceLevel && errors.experienceLevel
                  ? errors.experienceLevel
                  : null}
              </div>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container columnSpacing={2} mt={1}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              error={Boolean(
                touched.expectedSalaryFrom && errors.expectedSalaryFrom
              )}
              helperText={
                touched.expectedSalaryFrom && errors.expectedSalaryFrom
              }
              margin="normal"
              InputProps={{
                startAdornment: values.expectedSalaryFrom ? "$" : "",
              }}
              type={"number"}
              fullWidth
              id="expectedSalaryFrom"
              label={STRINGS.ExpectedSalaryFrom}
              name="expectedSalaryFrom"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 50 }}
              value={values.expectedSalaryFrom}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              error={Boolean(
                touched.expectedSalaryTo && errors.expectedSalaryTo
              )}
              helperText={touched.expectedSalaryTo && errors.expectedSalaryTo}
              margin="normal"
              InputProps={{
                startAdornment: values.expectedSalaryTo ? "$" : "",
              }}
              type={"number"}
              fullWidth
              id="expectedSalaryTo"
              label={STRINGS.ExpectedSalaryTo}
              name="expectedSalaryTo"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 50 }}
              value={values.expectedSalaryTo}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={STRINGS.ExpectedJoiningDateLabel}
                  inputFormat="MM/DD/YYYY"
                  value={dojValue}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <InputLabel>{STRINGS.Location}</InputLabel>
              <Select
                id="location"
                name="location"
                label={STRINGS.Location}
                value={selectedCountry}
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                  setFieldValue("location", e.target.value);
                }}>
                {Country.getAllCountries()?.map((value, key) => {
                  return (
                    <MenuItem
                      value={value.isoCode}
                      key={key}
                      onClick={() => {
                        setSelectedCountryName(value.name);
                      }}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}>
            <Button
              disabled={generateDes}
              onClick={() => {
                const payload = {
                  jobTitle: values?.title,
                  clientId: selectedClients,
                  skills: inputSkills,
                  expLevel: selectedExperience,
                  employmentType: selectedEmploymentType,
                  salaryFrom: values.expectedSalaryFrom,
                  salaryTo: values.expectedSalaryTo,
                  location: selectedCountryName,
                  noticePeriod: "",
                };
                generateChatGptJobDescription(payload, dispatch);
                setShowAiGenerateJob(true);
              }}
              variant="contained"
              sx={{ mt: 2 }}>
              {"AI Generated job description"}
            </Button>
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                mt: 2,
              }}>
              <InfoOutlinedIcon sx={{ ml: 1 }} />{" "}
              <Typography variant="caption" sx={{ pl: 1, color: "#aeaeae" }}>
                {!userAccess().client
                  ? `Client and Job title is required to generate AI job description`
                  : `Job title is required to generate AI job description`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            {/* <TextField
              error={Boolean(touched.aboutTheRole && errors.aboutTheRole)}
              helperText={touched.aboutTheRole && errors.aboutTheRole}
              margin="normal"
              fullWidth
              multiline
              id="aboutTheRole"
              label={`${STRINGS.AboutTheRole}`}
              name="aboutTheRole"
              variant="outlined"
              autoComplete="off"
              value={values.aboutTheRole}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{
                style: {
                  maxHeight: 150,
                },
              }}
            /> */}
            <ReactQuill
              theme="snow"
              id="aboutTheRole"
              modules={reactQuillModules}
              formats={reactQuillFormats}
              placeholder={`${STRINGS.AboutTheRole}`}
              value={values.aboutTheRole}
              onChange={(e) => {
                setFieldValue("aboutTheRole", e);
              }}
              // onBlur={(e) => {
              //   console.log(e);
              // }}
              style={{
                height: 250,
                marginBottom: 50,
                marginTop: 20,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              error={Boolean(
                touched.otherRequirement && errors.otherRequirement
              )}
              helperText={touched.otherRequirement && errors.otherRequirement}
              margin="normal"
              fullWidth
              multiline
              id="otherRequirement"
              label={`${STRINGS.OtherRequirement}`}
              name="otherRequirement"
              variant="outlined"
              autoComplete="off"
              value={values.otherRequirement}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{
                style: {
                  minHeight: 150,
                  maxHeight: 150,
                },
              }}
            />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end">
          <Button variant="text" sx={{ mt: 2 }} onClick={props.close}>
            {STRINGS.Cancel}
          </Button>
          <Button
            disabled={!dirty}
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}>
            {postJobLoading && <CircularProgress size={18} color="warning" />}
            {!postJobLoading && STRINGS.Save}
          </Button>
        </Box>
      </Box>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={showAiGenerateJob}
        onClose={() => {
          setShowAiGenerateJob(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            mb={1}
            sx={{ textAlign: "center" }}>
            Job description
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="body2"
            component="h2"
            sx={{ textAlign: "center", mb: 4 }}>
            We have crafted job description templates as per provided
            information. Select any from many and even you can add any more
            specification in the requirement box .
          </Typography>
          {chatGptJobPending && <TableSkeleton rowCount={[1, 2, 3, 4, 5]} />}

          {!chatGptJobPending && (
            <Grid container xs={12}>
              <Grid
                container
                xs={12}
                spacing={2}
                sx={{ backgroundColor: "#f7f9ff", ml: 1 }}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Grid item xs={10}>
                      <Typography variant="body2">
                        {"Job describe template"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1.5}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                        pr: 1,
                      }}>
                      <Typography variant="body2">
                        {`${selectedAiIndex + 1}/${chatGptJobData?.length}`}
                      </Typography>{" "}
                      <ArrowBackIosOutlinedIcon
                        onClick={() => {
                          if (selectedAiIndex > 0) {
                            setSelectedAiIndex(
                              (selectedAiIndex) => selectedAiIndex - 1
                            );
                          }
                        }}
                        sx={{
                          height: 15,
                          cursor: "pointer",
                          color: selectedAiIndex > 0 ? "#000000" : "#aeaeae",
                        }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          height: 20,
                          width: 20,
                          borderRadius: 10,
                          backgroundColor: "#ffffff",
                          textAlign: "center",
                        }}>
                        {`${selectedAiIndex + 1}`}
                      </Typography>
                      <ArrowForwardIosOutlinedIcon
                        onClick={() => {
                          if (selectedAiIndex !== chatGptJobData?.length - 1) {
                            setSelectedAiIndex(
                              (selectedAiIndex) => selectedAiIndex + 1
                            );
                          }
                        }}
                        sx={{
                          height: 15,
                          cursor: "pointer",
                          color:
                            selectedAiIndex !== chatGptJobData?.length - 1
                              ? "#000000"
                              : "#aeaeae",
                        }}
                      />
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ maxHeight: 300, overflow: "scroll", p: 5, mb: 2 }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        chatGptJobData[selectedAiIndex]?.content.replaceAll(
                          "\n",
                          "<br/>"
                        ) || "",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                sx={{ justifyContent: "space-between", display: "flex" }}>
                <Grid item xs={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    multiline
                    id="skillstogeneratedescription"
                    label={`Skills`}
                    name="skillstogeneratedescription"
                    variant="outlined"
                    autoComplete="off"
                    value={inputSkills}
                    onChange={(e) => {
                      setInputSkills(e.target.value);
                    }}
                    onBlur={handleBlur}
                    inputProps={{
                      style: {
                        minHeight: 10,
                        maxHeight: 50,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin="normal"
                    fullWidth
                    multiline
                    label={`Notice period`}
                    variant="outlined"
                    autoComplete="off"
                    value={inputNoticePeriods}
                    onChange={(e) => {
                      setInputNoticePeriods(e.target.value);
                    }}
                    onBlur={handleBlur}
                    inputProps={{
                      style: {
                        minHeight: 10,
                        maxHeight: 50,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ mt: 1, textAlign: "right" }}>
                  <Button
                    onClick={() => {
                      const payload = {
                        jobTitle: values?.title,
                        clientId: selectedClients,
                        skills: inputSkills,
                        expLevel: selectedExperience,
                        employmentType: selectedEmploymentType,
                        salaryFrom: values.expectedSalaryFrom,
                        salaryTo: values.expectedSalaryTo,
                        location: selectedCountryName,
                        noticePeriod: inputNoticePeriods,
                      };
                      generateChatGptJobDescription(payload, dispatch);
                    }}
                    variant="outlined"
                    sx={{ mt: 2 }}>
                    {"Generate"}
                  </Button>
                </Grid>
              </Grid>
              <Grid container xs={12}>
                <Grid item xs={9}>
                  <Typography></Typography>
                </Grid>
                <Grid item xs={3} sx={{ mt: 1, textAlign: "right" }}>
                  <Button
                    onClick={() => setShowAiGenerateJob(false)}
                    variant="text"
                    sx={{ mt: 2 }}>
                    {"Cancel"}
                  </Button>
                  <Button
                    onClick={() => {
                      const aiResponse = chatGptJobData[
                        selectedAiIndex
                      ]?.content.replaceAll("\n", "<br/>");
                      setShowAiGenerateJob(false);
                      setFieldValue("aboutTheRole", aiResponse);
                      dispatch(chatGptJobError());
                    }}
                    variant="contained"
                    sx={{ mt: 2 }}>
                    {"Done"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default EditJobPost;

export const experienceArray = [
  { name: "Associate", id: 1 },
  { name: "Mid level senior", id: 2 },
  { name: "Senior level", id: 3 },
];
