import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getJobRequestDetails, getPatonaJobRequestDetails } from "../../../services/HireV2";
import Layout from "../../../shared/components/LayoutV2";
import "../index.scss";
import NewJobForm from "./NewJobForm";
import Header from "./NewJobHeader";

const NewJobContainer = () => {
  const location = useLocation();
  const jobId = location.state?.jobId;
  const isPatonaRaised = location.state?.isPatonaRaised;

  const [jobDetails, setJobDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!jobId) return;

    if (isPatonaRaised) {
      fetchPatonaJobDetails();
      return;
    }

    fetchJobDetails();
  }, [jobId]);

  const fetchJobDetails = async () => {
    setIsLoading(true);
    const response = await getJobRequestDetails(jobId);

    if (response) {
      setJobDetails(response);
      setIsLoading(false);
    }
  };

  const fetchPatonaJobDetails = async () => {
    setIsLoading(true);
    const response = await getPatonaJobRequestDetails(jobId);

    if (response) {
      setJobDetails(response);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Layout header={<Header />} body={<div>Loading...</div>} />;
  }

  return (
    <Layout
      header={<Header />}
      body={<NewJobForm jobDetails={jobDetails} />}
    />
  );
};

export default NewJobContainer;
