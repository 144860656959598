import { Box, Button, CircularProgress } from "@mui/material"
import { memo, useState } from "react"
import { useAlert } from "react-alert"
import { updateTask } from "../../../services/Tasks"
import { userAccess } from "../../../utils/CommonFunction"
const BTN_STYLE = {
    minWidth: 150,
    textTransform: 'none',
    color: '#fff',
    borderRadius: '10px',
    border: 'none',
    boxShadow: 'none'
}
const TaskButton = ({ task, action, refetchTasks = () =>{} }) => {
    const [loading, setLoading] = useState(false)
    const alert = useAlert()

    const updateTaskApi = async () => {
        setLoading(true)
          const request = {
            ...task,
        }
        request.completedAction = action.id
        request.completedBy = userAccess().userId
        try{
            await updateTask(request)
            refetchTasks()

        } catch(e) {
            alert.error('Error Updating task')
        }
        setLoading(false)
    }
    return (<Box>
        <Button variant="contained" size="small" disabled={loading} sx={[BTN_STYLE, {
            backgroundColor: action.colour,
            ":hover": {
                backgroundColor: action.colour,
            }
 }]} onClick={(e) => {
            e.stopPropagation()
            updateTaskApi()
        }}>
            {loading ? <CircularProgress color="inherit" size={20}></CircularProgress>: action.text}
        </Button>
    </Box>)
}

export default memo(TaskButton)