import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  Grid,
  Pagination,
  Skeleton,
  Typography,
} from "@mui/material";

import { getOpportunities } from "./opportunities.helper";
import { useStyles } from "./style";
import { fetchJobDetailById } from "../../services/Opportunities";
import Search from "../../shared/components/Search/Search";
import { useNavigate } from "react-router-dom";
import ApplyJobModal from "./ApplyJobModal";
import ReferModal from "./ReferModal";

const Opportunities = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [pageCount, setPageCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate()
  const [openApply, setOpenApply] = useState(false);
  const [openRefer, setOpenRefer] = useState(false);

  const handleApplyOpenClose = (e) => {
    e.stopPropagation()
    setOpenApply(!openApply);
  };

  const handleReferOpenClose = (e) => {
    e.stopPropagation()
    setOpenRefer(!openRefer);
  };

  const { jobs, jobsLoading } = useSelector(
    //@ts-ignore
    (state) => state.opportunities
  );

  useEffect(() => {
    getOpportunities(dispatch, {
      PageNumber: page.toString(),
      PageSize: "6",
    });
  }, [page]);

  useEffect(() => {
    const initialCount = Math.ceil(
      jobs?.paginationFilter?.count / jobs?.paginationFilter?.pageSize
    );
    setPageCount(initialCount);
  }, []);

  const handlePageCountChange = (event, newPageCount) => {
    setPage(newPageCount);
  };

  return (
    <Container maxWidth="xl">
      <Grid
        container
       sx={{pt:2}}
        
        justifyContent={"space-between"}
      >
        <Grid item xs={4}>
          <Search />
        </Grid>
        <Grid item>
          <Button variant="contained">Filter</Button>
        </Grid>
      </Grid>
      {!jobsLoading ? (
        <Grid container spacing={2} sx={{ mt: 0.5 }}>
          {jobs.filteredJobs?.map((item, index) => (
            <Grid
              item
              sm={4}
              md={4}
              onClick={() => {
                fetchJobDetailById({ Id: item?.job_Id } );
                navigate(`/opportunities/${item?.job_Id}`);
              }}
              sx={{ cursor: 'pointer' }}
            >
              <Box className={classes.cardHeader}>
                <Typography ml={2} fontWeight={500} fontSize={"16px"}>
                {item.company_Name}
                </Typography>
              </Box>
              <div className={classes.card}>
                <div className={classes.cardDetail}>
                  <Typography>Job Title-{item.job_Title}</Typography>
                  <div
                    className={classes.container}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
                <Grid>
                  <Button
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={handleApplyOpenClose}
                  >
                    Apply now
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ mt: 2, ml: 1 }}
                    onClick={handleReferOpenClose}
                  >
                    Refer
                  </Button>
                </Grid>
              </div>
            </Grid>
          ))}
          {pageCount && (
            <Grid
              item
              sm={12}
              md={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={2}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageCountChange}
                size="large"
                color="primary"
              />
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container spacing={3}>
          {Array.from({ length: 6 }).map((item) => (
            <Grid item sm={4} md={4}>
              <Skeleton variant="text" sx={{ fontSize: "4rem" }} />
              {Array.from({ length: 7 }).map((_, index) => (
                <Box display="flex" justifyContent="space-between">
                  <Skeleton
                    variant="text"
                    width={"150px"}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={"150px"}
                    sx={{ fontSize: "1rem" }}
                  />
                </Box>
              ))}
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            </Grid>
          ))}
        </Grid>
      )}
      <ApplyJobModal handleOpenClose={handleApplyOpenClose} open={openApply} />
      <ReferModal handleOpenClose={handleReferOpenClose} open={openRefer} />
    </Container>
  );
};
export default Opportunities;
