import { Dispatch } from "@reduxjs/toolkit";
import { sagaActions } from "../../../redux/sagaActions";
import { userAccess } from "../../../utils/CommonFunction";

export const getCompanyDetails = async (dispatch: Dispatch<any>) => {
  try {
    const payload = {
      Id: userAccess().clientCode,
    };
    dispatch({ type: sagaActions.COMPANY_DETAILS, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const updateCompanyDetails = async (
  payload,
  dispatch: Dispatch<any>
) => {
  try {
    dispatch({ type: sagaActions.UPDATE_COMPANY_DETAILS, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};
