import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { useStyles } from "../styles";

const REWARD_TYPE = {
  1: "Incentive of",
  2: "A day off",
  3: "Goodies",
  4: "Chocalates",
  5: "Amazon voucher",
};

const RewardFeedbackContent = ({ details, onClose }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.modalHeader}>
        <Typography variant="h5" className={classes.customTypography}>
          Reward
        </Typography>

        <IconButton onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </Box>
      <Box className={classes.modalBody}>
        <Typography variant="h6">Recognition for the achievement of</Typography>

        <Box className={classes.commentBox}>
          <Typography variant="body2" color="#707D84" mb={1}>
            Achievement
          </Typography>

          <Typography variant="caption">{details.achiement}</Typography>
        </Box>

        <Typography variant="h6">
          Appreciates by: {REWARD_TYPE[details.awardappreciation]}{" "}
          {details?.incentive}
        </Typography>

        <Typography variant="caption" fontWeight={600}>
          Feedback from: {details.from}
        </Typography>
      </Box>
    </>
  );
};

export default RewardFeedbackContent;
