import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);

export const changePasswordSchema = Yup.object({
  oldPassword: Yup.string()
    .min(8, "Passwords must be at least 8 characters.")
    .required("Please enter your old password"),
  newPassword: Yup.string()
    .min(8, "Passwords must be at least 8 characters.")
    .minLowercase(1, "password must contain at least 1 lower case letter")
    .minUppercase(1, "password must contain at least 1 upper case letter")
    .minNumbers(1, "password must contain at least 1 number")
    .minSymbols(1, "password must contain at least 1 special character")
    .required("Please enter new password"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .min(8, "Passwords must be at least 8 characters.")
    .required("Please enter your confirm password"),
});
