import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const SkeletonLoaderMobile = ({ rows = 6 }) => {
  return (
    <Box width="100%" role="tabpanel">
      {Array.from(new Array(rows))?.map((_, index) => (
        <Paper
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            mb: 2,
            p: 2,
          }}
        >
          <Box display="flex" justifyContent="space-between" gap={1}>
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="text" width={"40%"}/>
          </Box>
          <Skeleton variant="rounded" width="100%" height={60} />
        </Paper>
      ))}
    </Box>
  );
}

export default SkeletonLoaderMobile;
