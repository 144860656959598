import moment from "moment";

export function formatDate(input: string | null, inputFormat: string = "YYYY-MM-DD hh:mm A") {
    // Check if input is valid
    if (!input) return null;
    
    // Parse and format the date
    return moment(input, inputFormat).format("DD MMM YYYY");
}

export function getDateTime(input?: string | null) {
    // get today's date
    if (!input) return moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    
    // Parse and format the date
    return moment(input).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
}
