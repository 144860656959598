import api from "../services/BaseApi";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_URL_INVITE = process.env.REACT_APP_ONBAORDING_URL;
const getUserDetails = BASE_URL_INVITE + "GetProfile";
const getAllTimeZones = BASE_URL_INVITE + "getAllTimeZones";
const updateProfile = BASE_URL_INVITE + "UpdateProfile";
const changePassword = BASE_URL_INVITE + "changePassword";
const getFilteredAdminsUsers = BASE_URL_INVITE + "getFilteredAdminsUsers";
const getAllClients = BASE_URL + "getAllClients";
const checkIfUserEmailExist = BASE_URL_INVITE + "checkIfUserEmailExist";
const signUpAdminsUsersInvite = BASE_URL_INVITE + "signUpAdminsUsersInvite";
const resendUsersInvite = BASE_URL_INVITE + "resendSignUpAdminsUsersInvite";
const toggleActiveInactiveStatus =
  BASE_URL_INVITE + "toggleActiveInactiveForUserStatus";

export const getProfile = async (payload: any = "") => {
  try {
    const res = await api.post(getUserDetails, payload.payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getTimeZone = async () => {
  try {
    const res = await api.get(getAllTimeZones);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const editProfile = async (payload) => {
  try {
    const res = await api.post(updateProfile, payload);
    return res;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const updatePassword = async (payload) => {
  try {
    const res = await api.post(changePassword, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const users = async (payload) => {
  try {
    const res = await api.post(getFilteredAdminsUsers, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getClients = async () => {
  try {
    const res = await api.get(getAllClients);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const checkUserEmailExist = async (payload) => {
  try {
    const res = await api.post(checkIfUserEmailExist, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const saveClientUsers = async (payload) => {
  try {
    const res = await api.post(signUpAdminsUsersInvite, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const saveResendInvite = async (payload) => {
  try {
    const res = await api.post(resendUsersInvite, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const userDisable = async (payload) => {
  try {
    const res = await api.post(toggleActiveInactiveStatus, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};
