import { Box, Grid, Paper } from "@mui/material";
import "chart.js/auto";
import moment from "moment";
import { useEffect } from "react";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { sagaActions } from "../../../redux/sagaActions";

const options = {
  scales: {
    y: {
      min: 1, // Minimum value for y-axis
      max: 5, // Maximum value for y-axis
      ticks: {
        stepSize: 1,
        padding: 10,
        callback: function (value, index, values) {
          return `${value} star${value > 1 ? "s" : ""}`;
        },
      },
      grid: {
        drawBorder: false,
      },
    },
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false, // Hide the legend
    },
    tooltip: {
      enabled: true, // Show tooltips
    },
  },
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: "index" as const,
  },
};

const PerformanceGraph = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const { chartData = [] } = useSelector(
    (state: any) => state.performance.employee
  );

  const sortedChartData = [...chartData].sort((a, b) =>
    moment(a.date).diff(moment(b.date))
  );

  useEffect(() => {
    dispatch({
      type: sagaActions.FETCH_EMPLOYEE_PERFORMANCE_GRAPH,
      payload: {
        employeeId: id,
      },
    });
  }, []);

  const getData = () => {
    return {
      labels: sortedChartData?.map((item) => item.monthYear),
      datasets: [
        {
          label: "Performance",
          data: sortedChartData?.map((item) => item.score),
          fill: false,
          borderColor: "blue",
          tension: 0, // No curves, straight lines
          pointRadius: 5,
          pointHoverRadius: 7,
        },
      ],
    };
  };

  return (
    <Paper sx={{ padding: 1, marginBottom: 2 }}>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "#EBEDF7",
            padding: 1.5,
            borderRadius: "10px 10px 0 0",
          }}
          mb={2}
        >
          Performance
        </Box>
      </Grid>
      <Box style={{ position: "relative", height: 160, width: "100%" }}>
        <Line data={getData()} options={options} />
      </Box>
    </Paper>
  );
};

export default PerformanceGraph;
