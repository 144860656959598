import { createSlice } from "@reduxjs/toolkit";
import { rowPerPage } from "../../Constants";

const initialState = {
  attendanceList: [],
  attendanceLoading: false,
  checkIncheckOutMessage:'',
  checkIncheckOutLoading:false
};

const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {
    attendancePending: (state) => {
      state.attendanceLoading = true;
    },
    attendanceSuccess: (state, { payload }) => {
      state.attendanceLoading = false;
      state.attendanceList = payload;
    },
    attendanceFail: (state, { payload }) => {
      state.attendanceLoading = false;
      state.attendanceList = [];
    },
    checkIncheckOutPending: (state) => {
     state.checkIncheckOutLoading=true
    },
    checkIncheckOutSuccess: (state, { payload }) => {
      state.checkIncheckOutLoading = false;
      state.checkIncheckOutMessage = payload;
    },
    checkIncheckOutFail: (state, { payload }) => {
      state.checkIncheckOutLoading = false;
      state.checkIncheckOutMessage = ''
    },
  },
});

export const { attendancePending, attendanceSuccess, attendanceFail,checkIncheckOutPending,checkIncheckOutSuccess,checkIncheckOutFail } =
  attendanceSlice.actions;

export default attendanceSlice.reducer;
