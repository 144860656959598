import { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Provider as AlertProvider, positions, transitions } from "react-alert";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
//@ts-ignore//
import AlertTemplate from "react-alert-template-basic";
import theme from "./shared/components/Layouts/theme";
import Rountes from "./Routes/Route";
//styles
import "./App.scss";
import { userLogout } from "./services/OnBoarding";
import { Status } from "./services/ApiStatus";
import { useNavigate } from "react-router-dom";
import { logOut } from "./redux/Login/loginSlice";
import { clearCompany } from "./redux/Company/companySlice";
import {
  clearAllWorkableClients,
  clearHireCandidateStaging,
  clearHireList,
} from "./redux/Hire/hireSlice";
import { clearMemberList } from "./redux/People/peopleSlice";
import { clearClientCode } from "./redux/Client/clientSlice";
import { setClientHeader } from "./services/BaseApi";

const options = {
  position: positions.TOP_CENTER,
  timeout: 6000,
  offset: "30px",
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 10000,
  },
};

function App() {
  const dispatch = useDispatch();

  const { isAuth, accessToken, refreshToken } = useSelector(
    //@ts-ignore
    (state) => state.login
  );

  // useEffect(() => {
  //   const scriptSrc =
  //     "https://desk.zoho.in/portal/api/web/inapp/112988000000439003?orgId=60021580801";
  //   const scriptIntegrity = ``; // Replace with actual integrity value

  //   loadScriptWithASAP(scriptSrc, scriptIntegrity)
  //     .then(() => {
  //       console.log("Script loaded successfully with ASAP");
  //     })
  //     .catch((error) => {
  //       console.error("Error loading script:", error);
  //     });
  // }, []);

  function loadScriptWithASAP(src, integrity) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.integrity = integrity;
      script.crossOrigin = "anonymous";
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  const logOutUser = async () => {
    try {
      let dataObj = { Token: accessToken, refreshToken: refreshToken };
      const response = await userLogout(dataObj);
      if (response?.status === Status.Success) {
        dispatch(logOut());
        dispatch(clearCompany());
        dispatch(clearHireList());
        dispatch(clearAllWorkableClients());
        dispatch(clearHireCandidateStaging());
        dispatch(clearMemberList());
        dispatch(clearClientCode());
        setClientHeader(0);
        window.location.href = "/";
      }
    } catch (error) {
      console.log("=========Auto logout error", error);
    }
  };
  const handleOnIdle = (event) => {
    if (isAuth) {
      logOutUser();
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 59,
    onIdle: handleOnIdle,
    debounce: 500,
  });
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <ThemeProvider theme={theme}>
        <Rountes />
      </ThemeProvider>
    </AlertProvider>
  );
}

export default App;
