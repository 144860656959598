import { Divider, Grid, Typography } from "@mui/material"
import { memo } from "react"
import AssigneeUser from "./Components/AssigneeUser"
import ClientName from "./Components/ClientName"

const TaskInfo = ({ taskInfo }) => {
    return <Grid container item flexDirection={'column'}>
        <Grid>{taskInfo?.title}</Grid>
        <Grid>{taskInfo?.summary}</Grid>
        <Divider sx={{
            px: 0,
            my: '0.5rem'
        }}></Divider>
        <Grid container item justifyContent={'space-between'} wrap="nowrap">
            <Grid container  gap={'4px'} alignItems={'center'}>
                <Typography variant="body2">Assigned To:</Typography>
                <Typography><AssigneeUser assigneeUser={taskInfo?.assigneeUser}></AssigneeUser></Typography>
            </Grid>
            <Grid container  gap={'4px'} justifyContent={'flex-end'} alignItems={'center'}>
                <Typography variant="body2">Task By:</Typography>
                <Typography><ClientName id={taskInfo?.clientID}></ClientName></Typography>
            </Grid>
        </Grid>
    </Grid>
}

export default memo(TaskInfo)