import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import { userAccess } from "../../../utils/CommonFunction";
import { teamMember } from "../../../Constants";

interface LayoutProps {
  header: React.ReactNode;
  subHeader?: React.ReactNode;
  body: React.ReactNode;
  bodyClassName?: string;
  headerClassName?: string;
  subHeaderClassName?: string;
  layoutClassName?: string;
}

const Layout = ({
  header,
  subHeader,
  body,
  bodyClassName,
  headerClassName,
  subHeaderClassName,
  layoutClassName,
}: LayoutProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container
      maxWidth={false}
      disableGutters={isMobile}
      className={layoutClassName}
      sx={{
        height: "calc(100vh - 94px)",
        display: "flex",
        flexDirection: "column",
        padding: "0 12px",
      }}
    >
      {/* Header container that takes the size of its content */}
      <Box
        sx={{
          borderRadius: "10px 10px 0 0",
        }}
        className={headerClassName}
      >
        {header}
      </Box>
      {subHeader && <Box className={subHeaderClassName}>{subHeader}</Box>}

      {/* Body container that takes the remaining space */}
      <Box
        className={bodyClassName}
        sx={{ flexGrow: 1, overflow: "auto", mt: 2 }}
      >
        {body}
      </Box>
    </Container>
  );
};

export default Layout;
