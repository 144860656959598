import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { setLeaveBodyPayload } from "../../redux/Approval/approvalSlice";
import DataGrid from "../../shared/components/DataGrid/DataGrid";
import Search from "../../shared/components/Search/Search";
import { userAccess } from "../../utils/CommonFunction";
import CalendarView from "./CalendarView";

import Moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import {
  QuarterPeriods,
  clientAdmin,
  leaveStatusArray,
  leaveTypesArray,
  patonaAdmin,
  periods,
  rowPerPage,
} from "../../Constants";

import { GridMoreVertIcon, GridSearchIcon } from "@mui/x-data-grid";
import _ from "lodash";
import MobileSkeleton from "../../shared/components/Skeletons/MobileSkeleton";
import TableSkeleton from "../../shared/components/Skeletons/TableSkeleton";
import {
  Columns,
  approvalRow,
  copyToClicpBoard,
  getApporovalList,
} from "./Approvals.helper";
import ApprovalsListMobile from "./ApprovalsListMobile";

type ApprovalsProps = {
  getUsersType?: string;
  staticCompany?: string;
  back?: (boolean) => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

const Approvals: React.FunctionComponent<ApprovalsProps> = (props) => {
  const { getUsersType = patonaAdmin } = props;
  const alert = useAlert();
  const location: any = useLocation();
  const { dashboardFilter = "" } = location?.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const skeleton = isMobile ? <MobileSkeleton /> : <TableSkeleton />;
  const now = new Date();
  const maxYear = now.setFullYear(now.getFullYear() + 2);
  const minYear = now.setFullYear(now.getFullYear() - 10);

  const [leaveType, setLeaveType] = React.useState<string[]>(leaveTypesArray);
  const [leavePeriod, setLeavePeriod] = React.useState<string>("Custom");
  const [quarter, setLeaveQuarterPeriod] = React.useState<string>("1");
  const [filterDisabled, setFilterDisabled] = React.useState<boolean>(false);

  const [searched, setSearched] = useState<string>("");
  const [leaveStatus, setLeaveStatus] =
    React.useState<string[]>(leaveStatusArray);
  const [from, setFrom] = React.useState<string>("");
  const [to, setTo] = React.useState<string>("");
  const [year, setYear] = React.useState<string>("");
  const [month, setMonth] = React.useState<string>("");
  const [selectedMonth, setSelectedMonth] = React.useState<string>("");
  const [selectedYear, setSelectedYear] = React.useState<string>("");

  const [currentView, setCurrentView] = useState("list");
  const [anchorEditColumnEl, setAnchorEditColumnEl] =
    React.useState<null | HTMLElement>(null);
  const openEditCoulmn = Boolean(anchorEditColumnEl);
  const handleEditColumnClose = () => {
    setAnchorEditColumnEl(null);
  };

  const {
    isApprovalListLoading,
    approvalList,
    approvalListError,
    isPaging,
    page,
    approvalCurrentPage,
    approvalPageSize,
  } = useSelector(
    //@ts-ignore
    (state) => state.approval
  );
  useEffect(() => {
    if (!_.isEmpty(dashboardFilter)) {
      const fDate = new Date();
      const tDate = new Date();

      let payload = {
        load: 0,
        leaveTypeList: leaveType,
        leaveStatusList:
          dashboardFilter === "pending approvals" ? ["Pending"] : ["approved"],
        month: "",
        period: "Custom",
        year: "",
        quarter: "",
        from: Moment(fDate).format("YYYY-MM-DD"),
        to: Moment(tDate).format("YYYY-MM-DD"),
        pageSize: approvalPageSize ? approvalPageSize : rowPerPage,
        columnName: "from",
        isDesc: false,
      };

      if (dashboardFilter === "pending approvals") {
        setLeaveStatus(["Pending"]);
      }

      if (dashboardFilter === "approvals") {
        setLeaveStatus(["Approved"]);
      }

      if (dashboardFilter === "upcoming") {
        payload = { ...payload, period: "upcoming", from: "", to: "" };
      }
      if (
        dashboardFilter === "pending" ||
        dashboardFilter === "pending approvals"
      ) {
        setLeaveStatus(["Pending"]);
        payload = {
          ...payload,
          leaveStatusList: ["Pending"],
          from: "",
          to: "",
        };
      }

      dispatch(setLeaveBodyPayload(payload));

      getApporovalList(dispatch, payload);
    }
    return () => {
      dispatch(setLeaveBodyPayload({}));
    };
  }, [dashboardFilter]);

  useEffect(() => {
    if (location?.state?.filterItems !== undefined) {
      const filterItems = location?.state?.filterItems;
      setFrom(filterItems.from);
      setFrom(filterItems.to);
      setLeaveStatus(filterItems.leaveStatusList);
      setLeaveType(filterItems.leaveTypeList);
      setMonth(filterItems.month);
      setLeavePeriod(filterItems.period);
      setLeaveQuarterPeriod(filterItems.quarter);
      setSearched(filterItems.searchValue);
      setYear(filterItems.year);

      if (
        filterItems.period === "Upcoming" ||
        filterItems.period === "Today on leave"
      ) {
        setFilterDisabled(true);
      }
    }
  }, []);
  useEffect(() => {
    if (_.isEmpty(dashboardFilter)) {
      const payload = { load: 0 };
      getApporovalList(dispatch, payload);
    }
    return () => {
      setMobileUserList([]);
    };
  }, []);

  const handleLeaveTypeChange = (
    event: SelectChangeEvent<typeof leaveType>
  ) => {
    const {
      target: { value },
    } = event;
    setLeaveType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleLeaveStatusChange = (
    event: SelectChangeEvent<typeof leaveStatus>
  ) => {
    const {
      target: { value },
    } = event;
    setLeaveStatus(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleLeavePeriodChange = (
    event: SelectChangeEvent<typeof leavePeriod>
  ) => {
    setLeaveQuarterPeriod("");
    setFrom("");
    setTo("");
    setYear("");
    setSelectedYear("");
    setMonth("");
    setSelectedMonth("");
    setFilterDisabled(false);
    const {
      target: { value },
    } = event;

    if (value === "Upcoming" || value === "Today on leave") {
      setFilterDisabled(true);
    }
    // disabled={filterDisabled}
    setLeavePeriod(value);
  };

  const handleLeaveQuarterPeriodChange = (
    event: SelectChangeEvent<typeof quarter>
  ) => {
    const {
      target: { value },
    } = event;
    setLeaveQuarterPeriod(value);
  };

  const applyFilter = () => {
    const fDate = new Date(from);
    const tDate = new Date(to);

    const payload = {
      load: 0,
      leaveTypeList: leaveType,
      leaveStatusList: leaveStatus,
      month: selectedMonth,
      period: leavePeriod,
      year: selectedYear,
      quarter: quarter,
      from: from ? Moment(fDate).format("YYYY-MM-DD") : "",
      to: to ? Moment(tDate).format("YYYY-MM-DD") : "",
      pageSize: approvalPageSize ? approvalPageSize : rowPerPage,
      searchValue: searched,
    };

    dispatch(setLeaveBodyPayload(payload));

    getApporovalList(dispatch, payload);
  };

  const resetFilter = () => {
    setLeaveType(leaveTypesArray);
    setLeavePeriod("Custom");
    setLeaveStatus(leaveStatusArray);
    setLeaveQuarterPeriod("");
    setFrom("");
    setTo("");
    setYear("");
    setMonth("");
    setSelectedYear("");
    setSelectedMonth("");
    setFilterDisabled(false);
    getApporovalList(dispatch, {
      load: 0,
      leaveTypeList: leaveType,
      leaveStatusList: leaveStatus,
    });
  };

  const updateLeaveType = (leaves) => {
    setLeaveType(leaves);
  };

  const updateLeaveStatus = (status) => {
    setLeaveStatus(status);
  };

  //mobile
  const [Showfloat, setShowfloat] = React.useState<boolean>(false);
  const [mobileUserList, setMobileUserList] = React.useState<any>([]);
  const [mobilePageLoad, setMobilePageLoad] = React.useState<number>(1);

  useEffect(() => {
    if (mobilePageLoad === 1) {
      setMobileUserList(approvalList);
    } else {
      const newList = [...mobileUserList, ...approvalList];
      setMobileUserList(newList);
    }
  }, [approvalList]);

  const loadList = () => {
    const loadPage = mobilePageLoad + 1;
    const fDate = new Date(from);
    const tDate = new Date(to);

    const payload = {
      load: 0,
      leaveTypeList: leaveType,
      leaveStatusList: leaveStatus,
      month: selectedMonth,
      period: leavePeriod,
      year: selectedYear,
      quarter: quarter,
      from: from ? Moment(fDate).format("YYYY-MM-DD") : "",
      to: to ? Moment(tDate).format("YYYY-MM-DD") : "",
      pageSize: approvalPageSize ? approvalPageSize : rowPerPage,
      searchValue: searched,
      pageNumber: loadPage,
    };
    getApporovalList(dispatch, payload);
    setMobilePageLoad(loadPage);
    if (loadPage > 1) {
      setShowfloat(true);
    }
  };

  return (
    <>
      <Grid container component="main">
        <Container maxWidth="xl">
          {/* Search Bar Header */}

          {currentView === "list" ? (
            <>
              <Box
                mt={1}
                mb={1}
                className={
                  userAccess().client || getUsersType === clientAdmin
                    ? "page-header-pink"
                    : "page-header-blue"
                }
              >
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  sx={{
                    flexDirection: {
                      xs: "row-reverse",
                      md: "row",
                    },
                  }}
                >
                  <Grid item xs={8} md={6}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={10}
                      xl={10}
                      sx={{
                        display: "flex",
                        justifyContent: {
                          xs: "flex-end",
                          md: "flex-start",
                        },
                      }}
                    >
                      <IconButton
                        sx={{
                          display: {
                            xs: "flex",
                            md: "none",
                          },
                        }}
                      >
                        <GridSearchIcon />
                      </IconButton>
                      <Search
                        sx={{
                          display: {
                            xs: "none",
                            md: "flex",
                          },
                        }}
                        placeHolder={
                          "Search by name, leave type, status or period"
                        }
                        getSearch={(input) => {
                          const payload = {
                            load: 0,
                            searchValue: input,
                            pageSize: approvalPageSize,
                          };
                          setSearched(input);
                          getApporovalList(dispatch, payload);
                          setMobileUserList([]);
                        }}
                      />
                      <IconButton
                        sx={{
                          display: {
                            xs: "flex",
                            md: "none",
                          },
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setAnchorEditColumnEl(e.currentTarget);
                        }}
                      >
                        <GridMoreVertIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    md={6}
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xs: "flex-start",
                        md: "flex-end",
                      },
                    }}
                    justifyContent="flex-end"
                  >
                    <Button variant="contained" disabled>
                      Apply Leave
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={1} mb={1}>
                <Card>
                  <CardContent>
                    {/* Filter Header */}
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      xs={12}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={12} sm={6} md={2}>
                        <FormControl sx={{ m: 1 }} fullWidth>
                          <InputLabel id="Leave-Type">Leave type</InputLabel>
                          <Select
                            disabled={filterDisabled}
                            fullWidth
                            multiple
                            id="Leave-Type"
                            value={leaveType}
                            label={"Leave type"}
                            input={<OutlinedInput label="Leave type" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                            onChange={handleLeaveTypeChange}
                          >
                            {leaveTypesArray.map((leave, index) => {
                              return (
                                <MenuItem key={leave} value={leave}>
                                  <Checkbox
                                    checked={leaveType.indexOf(leave) > -1}
                                  />
                                  <ListItemText primary={leave} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={2}>
                        <FormControl sx={{ m: 1 }} fullWidth>
                          <InputLabel id="Leave-Status">
                            Leave status
                          </InputLabel>
                          <Select
                            disabled={filterDisabled}
                            fullWidth
                            id="Leave-Status"
                            multiple
                            value={leaveStatus}
                            label={"Leave status"}
                            input={<OutlinedInput label="Leave status" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                            onChange={handleLeaveStatusChange}
                          >
                            {leaveStatusArray.map((status, index) => {
                              return (
                                <MenuItem key={status} value={status}>
                                  <Checkbox
                                    checked={leaveStatus.indexOf(status) > -1}
                                  />
                                  <ListItemText primary={status} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={3} md={2}>
                        <FormControl sx={{ m: 1 }} fullWidth>
                          <InputLabel id="Period">Period</InputLabel>
                          <Select
                            fullWidth
                            id="Period"
                            value={leavePeriod}
                            label={"Period"}
                            onChange={handleLeavePeriodChange}
                          >
                            {periods.map((period) => {
                              return (
                                <MenuItem key={period} value={period}>
                                  {period}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      {leavePeriod === "Custom" && (
                        <>
                          <Grid item xs={12} sm={3} md={2}>
                            <FormControl sx={{ m: 1 }} fullWidth>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  disabled={filterDisabled}
                                  label="From"
                                  inputFormat="DD-MM-YYYY"
                                  maxDate={maxYear}
                                  minDate={minYear}
                                  value={from ?? ""}
                                  //@ts-ignore
                                  onChange={(fromDate) => setFrom(fromDate)}
                                  renderInput={(params) => (
                                    <TextField {...params} error={false} />
                                  )}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={3} md={2}>
                            <FormControl sx={{ m: 1 }} fullWidth>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  disabled={filterDisabled}
                                  label="To"
                                  inputFormat="DD-MM-YYYY"
                                  maxDate={maxYear}
                                  minDate={minYear}
                                  value={to ?? ""}
                                  //@ts-ignore
                                  onChange={(newTo) => setTo(newTo)}
                                  renderInput={(params) => (
                                    <TextField {...params} error={false} />
                                  )}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </Grid>
                        </>
                      )}

                      {leavePeriod === "Quarterly" && (
                        <>
                          <Grid item xs={12} sm={3} md={2}>
                            <FormControl sx={{ m: 1 }} fullWidth>
                              <InputLabel id="Quarter-period">
                                Quarter period
                              </InputLabel>
                              <Select
                                disabled={filterDisabled}
                                fullWidth
                                id="Quarter-period"
                                value={quarter}
                                label={"Quarter period"}
                                onChange={handleLeaveQuarterPeriodChange}
                              >
                                {QuarterPeriods.map((period, index) => {
                                  return (
                                    <MenuItem key={period.id} value={index + 1}>
                                      {period.value}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={3} md={2}>
                            <FormControl sx={{ m: 1 }} fullWidth>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  disabled={filterDisabled}
                                  label="Select year"
                                  views={["year"]}
                                  inputFormat="YYYY"
                                  maxDate={maxYear}
                                  minDate={minYear}
                                  value={year}
                                  //@ts-ignore
                                  onChange={(newTo) => {
                                    //@ts-ignore
                                    const Year = newTo
                                      ? new Date(newTo).getFullYear()
                                      : new Date().getFullYear();
                                    setSelectedYear(Year.toString());
                                    //@ts-ignore
                                    setYear(newTo);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} error={false} />
                                  )}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </Grid>
                        </>
                      )}

                      {leavePeriod === "Monthly" && (
                        <>
                          <Grid item xs={12} sm={3} md={2}>
                            <FormControl sx={{ m: 1 }} fullWidth>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  disabled={filterDisabled}
                                  label="Select month"
                                  views={["month"]}
                                  inputFormat="MM"
                                  maxDate={maxYear}
                                  minDate={minYear}
                                  value={month}
                                  onChange={(newYear) => {
                                    //@ts-ignore
                                    const month = newYear
                                      ? new Date(newYear).getMonth() + 1
                                      : 0;
                                    setSelectedMonth(month.toString());
                                    //@ts-ignore
                                    setMonth(newYear);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} error={false} />
                                  )}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={3} md={2}>
                            <FormControl sx={{ m: 1 }} fullWidth>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  disabled={filterDisabled}
                                  label="Select year"
                                  views={["year"]}
                                  maxDate={maxYear}
                                  minDate={minYear}
                                  value={year}
                                  //@ts-ignore
                                  onChange={(newTo) => {
                                    //@ts-ignore
                                    const Year = newTo
                                      ? new Date(newTo).getFullYear()
                                      : new Date().getFullYear();
                                    setSelectedYear(Year.toString());
                                    //@ts-ignore
                                    setYear(newTo);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} error={false} />
                                  )}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </Grid>
                        </>
                      )}

                      {leavePeriod === "Annual" && (
                        <>
                          <Grid item xs={12} sm={6} md={2}>
                            <FormControl sx={{ m: 1 }} fullWidth>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  disabled={filterDisabled}
                                  label="Year"
                                  views={["year"]}
                                  maxDate={maxYear}
                                  minDate={minYear}
                                  value={year}
                                  onChange={(newYear) => {
                                    //@ts-ignore
                                    const Year = newYear
                                      ? new Date(newYear).getFullYear()
                                      : new Date().getFullYear();
                                    setSelectedYear(Year.toString());
                                    //@ts-ignore
                                    setYear(newYear);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} error={false} />
                                  )}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </Grid>
                        </>
                      )}

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2}
                        sx={{
                          display: {
                            md: "flex",
                          },
                        }}
                        justifyContent={"space-between"}
                      >
                        <Button
                          variant="contained"
                          sx={{ height: 40 }}
                          onClick={() => {
                            setMobileUserList([]);
                            applyFilter();
                          }}
                        >
                          Filter
                        </Button>
                        <Button variant="text" onClick={resetFilter}>
                          <img
                            src="/images/reset.png"
                            style={{ height: 25, width: 25 }}
                            alt="reset"
                          />
                        </Button>

                        <IconButton
                          sx={{
                            display: {
                              xs: "none",
                              md: "flex",
                            },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setAnchorEditColumnEl(e.currentTarget);
                            setMobileUserList([]);
                          }}
                        >
                          <GridMoreVertIcon />
                        </IconButton>
                      </Grid>
                    </Grid>

                    {isApprovalListLoading ? (
                      skeleton
                    ) : isMobile ? (
                      <Box sx={{ mt: 2 }}>
                        <ApprovalsListMobile
                          listData={approvalRow(mobileUserList)}
                          totalPageCount={2}
                          onLoadmore={loadList}
                          currentPage={1}
                          isLoading={false}
                        />
                      </Box>
                    ) : (
                      <DataGrid
                        header={Columns}
                        row={approvalRow(approvalList)}
                        getDetails={(id) => {
                          navigate(`/leave/leave-details/${id}`, {
                            state: {
                              filterItems: {
                                load: 0,
                                leaveTypeList: leaveType,
                                leaveStatusList: leaveStatus,
                                month: selectedMonth,
                                period: leavePeriod,
                                year: selectedYear,
                                quarter: quarter,
                                from,
                                to,
                                searchValue: searched,
                              },
                            },
                          });
                        }}
                        showPaging={isPaging}
                        page={page}
                        defaultRowPerPageCount={approvalPageSize}
                        currentPage={approvalCurrentPage}
                        rowPerPage={(row) => {
                          const fDate = new Date(from);
                          const tDate = new Date(to);

                          const payload = {
                            load: 0,
                            leaveTypeList: leaveType,
                            leaveStatusList: leaveStatus,
                            month: selectedMonth,
                            period: leavePeriod,
                            year: selectedYear,
                            quarter: quarter,
                            from: from
                              ? Moment(fDate).format("YYYY-MM-DD")
                              : "",
                            to: to ? Moment(tDate).format("YYYY-MM-DD") : "",
                            pageSize: row,
                            searchValue: searched,
                          };
                          getApporovalList(dispatch, payload);
                        }}
                        getPageList={(page) => {
                          const fDate = new Date(from);
                          const tDate = new Date(to);

                          const payload = {
                            load: 0,
                            pageNumber: page,
                            leaveTypeList: leaveType,
                            leaveStatusList: leaveStatus,
                            month: selectedMonth,
                            period: leavePeriod,
                            year: selectedYear,
                            quarter: quarter,
                            from: from
                              ? Moment(fDate).format("YYYY-MM-DD")
                              : "",
                            to: to ? Moment(tDate).format("YYYY-MM-DD") : "",
                            pageSize: approvalPageSize,
                            searchValue: searched,
                          };
                          getApporovalList(dispatch, payload);
                          setMobileUserList([]);
                        }}
                        sortTable={(sort, key) => {
                          const fDate = new Date(from);
                          const tDate = new Date(to);

                          const payload = {
                            isDesc: sort,
                            columnName: key,
                            leaveTypeList: leaveType,
                            leaveStatusList: leaveStatus,
                            month: selectedMonth,
                            period: leavePeriod,
                            year: selectedYear,
                            quarter: quarter,
                            from: from
                              ? Moment(fDate).format("YYYY-MM-DD")
                              : "",
                            to: to ? Moment(tDate).format("YYYY-MM-DD") : "",
                            pageSize: approvalPageSize,
                            searchValue: searched,
                          };
                          getApporovalList(dispatch, payload);
                          setMobileUserList([]);
                        }}
                      />
                    )}
                  </CardContent>
                </Card>
              </Box>
            </>
          ) : (
            <>
              <CalendarView
                leaveTypeList={leaveType}
                leaveStatusList={leaveStatus}
                updateLeaveType={updateLeaveType}
                updateLeaveStatus={updateLeaveStatus}
                setAnchorEditColumnEl={setAnchorEditColumnEl}
              />
            </>
          )}
          <Menu
            id="menu"
            anchorEl={anchorEditColumnEl}
            keepMounted
            open={openEditCoulmn}
            onClose={handleEditColumnClose}
            onClick={handleEditColumnClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              disabled={currentView === "calendar"}
              onClick={() => {
                setCurrentView("calendar");
              }}
            >
              Calendar View
            </MenuItem>
            <MenuItem
              disabled={currentView === "list"}
              onClick={() => {
                setCurrentView("list");
                getApporovalList(dispatch, {
                  load: 0,
                  leaveTypeList: leaveType,
                  leaveStatusList: leaveStatus,
                });
              }}
            >
              List View
            </MenuItem>
            <MenuItem
              onClick={() => {
                copyToClicpBoard(alert);
              }}
            >
              Share Calendar
            </MenuItem>
          </Menu>
        </Container>
      </Grid>
    </>
  );
};

export default Approvals;
