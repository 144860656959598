import { createSlice } from "@reduxjs/toolkit";
import { string } from "yup";
import { rowPerPage } from "../../Constants";

const initialState = {
  id: "",
  isClientAdded: false,
  totalClients: 0,
  isPaging: false,
  page: 0,
  clientDataLoading: false,
  clientAdminLoading: false,
  clientAdmins: [],
  error: "",
  clients: [],
  clientCode: 0,
  clientName: "",
  role: "",
  pageSize: rowPerPage,
  currentPage: 0,
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setClientCode: (state, { payload }) => {
      state.clientCode = payload;
    },
    clearClientCode: (state) => {
      state.clientCode = 0;
    },
    setClientRole: (state, { payload }) => {
      state.role = payload;
    },
    clearClientRole: (state) => {
      state.role = "";
    },
    setClientName: (state, { payload }) => {
      state.clientName = payload;
    },
    clearClientName: (state) => {
      state.clientName = "";
    },
    editClient: (state, { payload }) => {
      state.id = payload;
    },
    clientAdded: (state, { payload }) => {
      state.isClientAdded = payload;
    },
    clientCount: (state, { payload }) => {
      state.totalClients = payload;
    },
    paging: (state, { payload }) => {
      state.isPaging = payload.isPaging;
      state.page = payload.page;
      state.pageSize = payload.pageSize;
      state.currentPage = payload.currentPage;
    },
    getClientsPending: (state) => {
      state.clientDataLoading = true;
    },
    getClientsSuccess: (state, { payload }) => {
      state.clientDataLoading = false;
      state.clients = payload.clients;
      state.error = "";
    },
    getClientsFail: (state, { payload }) => {
      state.clientDataLoading = false;
      state.error = payload;
    },
    getClientAdminPending: (state) => {
      state.clientAdminLoading = true;
    },
    getClientAdminSuccess: (state, { payload }) => {
      state.clientAdminLoading = false;
      state.clientAdmins = payload;
    },
    getClientAdminFail: (state, { payload }) => {
      state.clientAdminLoading = false;
    },
  },
});

const { reducer, actions } = clientSlice;

export const {
  getClientAdminFail,
  getClientAdminSuccess,
  getClientAdminPending,
  editClient,
  getClientsFail,
  getClientsSuccess,
  getClientsPending,
  paging,
  setClientCode,
  clearClientCode,
  setClientName,
  clearClientName,
  setClientRole,
  clearClientRole,
} = actions;

export default reducer;
