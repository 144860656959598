import _ from "lodash";
import { all, delay, put, takeLatest } from "redux-saga/effects";
import { Status } from "../../services/ApiStatus";
import {
  addingTimeLogs,
  addingTimeSheet,
  clientsNameList,
  fetchTimeLogDetail,
  monthlyTimesheetDetails,
  projectsNameList,
  taskList,
  teamMemberTimesheetById,
  timesheetActionService,
  timesheetById,
  timesheets,
  weeklyTimesheetDetails,
} from "../../services/Timesheets";
import { sagaActions } from "../sagaActions";
import {
  addTimeLogMessageSuccess,
  addTimeSheetMessageSuccess,
  getClientNameListFail,
  getClientNameListPending,
  getClientNameListSuccess,
  getProjectNameListFail,
  getProjectNameListPending,
  getProjectNameListSuccess,
  getTaskNameListFail,
  getTaskNameListPending,
  getTaskNameListSuccess,
  getTimeLogDetailFail,
  getTimeLogDetailPending,
  getTimeLogDetailSuccess,
  getTimesheetDetailFail,
  getTimesheetDetailListPending,
  getTimesheetDetailPending,
  getTimesheetDetailSuccess,
  getTimesheetListFail,
  getTimesheetListPending,
  getTimesheetListSuccess,
  getTimesheetMonthlyListFail,
  getTimesheetMonthlyListSuccess,
  getTimesheetWeeklyListFail,
  getTimesheetWeeklyListSuccess,
  paging,
  timesheetActionFail,
  timesheetActionPending,
  timesheetActionSuccess,
} from "./timesheetSlice";

function* fetchTimesheetList(payload: any = "") {
  try {
    if (!_.isUndefined(payload?.payload?.load)) {
      yield put(getTimesheetListPending());
    }
    const response = yield timesheets(payload.payload);

    if (!!response && response?.status === Status.Success) {
      const { paginationFilter } = response.data;
      const page = Math.ceil(
        paginationFilter.count / paginationFilter.pageSize
      );
      const isPaging = page > 1 ? true : false;
      yield put(
        paging({
          page: page,
          isPaging: isPaging,
          pageSize: paginationFilter.pageSize,
          currentPage: paginationFilter.pageNumber,
        })
      );
      yield put(getTimesheetListSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(fetchTimesheetList(response?.message));
        yield put(paging({ page: 0, isPaging: false }));
      }
    }
  } catch (error: any) {
    yield put(getTimesheetListFail(error?.message));
    yield put(paging({ page: 0, isPaging: false }));
  }
}

function* fetchTimesheetDetailById(payload: any = "") {
  try {
    yield put(timesheetActionSuccess(""));
    yield put(getTimesheetDetailPending());

    const response = yield timesheetById(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getTimesheetDetailSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getTimesheetDetailFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(getTimesheetDetailFail(error?.message));
  }
}

function* fetchTimesheetWeeklyList(payload: any = "") {
  try {
    yield put(getTimesheetDetailListPending());

    const response = yield weeklyTimesheetDetails(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getTimesheetWeeklyListSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getTimesheetWeeklyListFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(getTimesheetWeeklyListFail(error?.message));
  }
}
function* fetchTimesheetMonthlyList(payload: any = "") {
  try {
    yield put(getTimesheetDetailListPending());

    const response = yield monthlyTimesheetDetails(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getTimesheetMonthlyListSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getTimesheetMonthlyListFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(getTimesheetMonthlyListFail(error?.message));
  }
}

function* timesheetAction(timesheetActionpayload: any = "") {
  try {
    yield put(timesheetActionPending());

    const response = yield timesheetActionService(
      timesheetActionpayload.payload
    );
    if (!!response && response?.status === Status.Success) {
      yield delay(5000);
      const request = {
        payload: { timesheetRecordId: timesheetActionpayload.payload.id },
      };
      yield fetchTimesheetDetailById(request);
      yield put(timesheetActionSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(timesheetActionFail());
      }
    }
  } catch (error: any) {
    yield put(timesheetActionFail());
  }
}

function* fetchTeamMemberTimesheetDetailById(payload: any = "") {
  try {
    yield put(timesheetActionSuccess(""));
    yield put(getTimesheetDetailPending());

    const response = yield teamMemberTimesheetById(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getTimesheetDetailSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getTimesheetDetailFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(getTimesheetDetailFail(error?.message));
  }
}

function* fetchClientList(payload: any = "") {
  try {
    yield put(getClientNameListPending());
    const response = yield clientsNameList();
    if (!!response && response?.status === Status.Success) {
      yield put(getClientNameListSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getClientNameListFail());
      }
    }
  } catch (error: any) {
    yield put(getClientNameListFail());
  }
}

function* fetchProjectList(payload: any = "") {
  try {
    yield put(getProjectNameListPending());
    const response = yield projectsNameList(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getProjectNameListSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getProjectNameListFail());
      }
    }
  } catch (error: any) {
    yield put(getProjectNameListFail());
  }
}

function* fetchTaskList(payload: any = "") {
  try {
    yield put(getTaskNameListPending());
    const response = yield taskList(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getTaskNameListSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getTaskNameListFail());
      }
    }
  } catch (error: any) {
    yield put(getTaskNameListFail());
  }
}

function* addTimeLogs(payload: any = "") {
  try {
    const response = yield addingTimeLogs(payload.payload);
    if (
      !!response &&
      response?.status === Status.Success &&
      !response?.data?.succeeded
    ) {
      yield put(addTimeLogMessageSuccess(response?.data?.errors[0]));
    } else {
      if (
        !!response &&
        response?.status === Status.Success &&
        response?.data?.succeeded
      ) {
        yield put(addTimeLogMessageSuccess("Time log created successfully !"));
      }
    }
  } catch (error: any) {
    console.log("addTimeLogs error", error);
  }
}

function* createTimeSheet(payload: any = "") {
  try {
    const response = yield addingTimeSheet(payload.payload);
    if (
      !!response &&
      response?.status === Status.Success &&
      !response?.data?.succeeded
    ) {
      yield put(addTimeSheetMessageSuccess(response?.data?.errors[0]));
    } else {
      if (
        !!response &&
        response?.status === Status.Success &&
        response?.data?.succeeded
      ) {
        yield put(
          addTimeSheetMessageSuccess("Time log created successfully !")
        );
      }
    }
  } catch (error: any) {
    console.log("addingTimeSheet error", error);
  }
}

function* fetchTimeLogsDetails(payload: any = "") {
  try {
    yield put(getTimeLogDetailPending());
    const response = yield fetchTimeLogDetail(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getTimeLogDetailSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getTimeLogDetailFail());
      }
    }
  } catch (error: any) {
    yield put(getTimeLogDetailFail());
  }
}

export default function* watcherSaga() {
  yield all([
    yield takeLatest(sagaActions.GET_TIMESHEETS_LIST, fetchTimesheetList),
    yield takeLatest(
      sagaActions.GET_TIMESHEETS_DETAIL,
      fetchTimesheetDetailById
    ),
    yield takeLatest(
      sagaActions.GET_TIMESHEETS_WEEKLY_DETAIL,
      fetchTimesheetWeeklyList
    ),
    yield takeLatest(
      sagaActions.GET_TIMESHEETS_MONTHLY_DETAIL,
      fetchTimesheetMonthlyList
    ),
    yield takeLatest(sagaActions.TIMESHEET_ACTION, timesheetAction),
    yield takeLatest(
      sagaActions.GET_TEAM_MEMBER_TIMESHEET,
      fetchTeamMemberTimesheetDetailById
    ),
    yield takeLatest(sagaActions.GET_CLIENT_LIST, fetchClientList),
    yield takeLatest(sagaActions.GET_PROJECT_LIST, fetchProjectList),
    yield takeLatest(sagaActions.GET_TASK_LIST, fetchTaskList),
    yield takeLatest(sagaActions.ADD_TIME_LOG, addTimeLogs),
    yield takeLatest(sagaActions.GET_WEEKLY_TIME_LOG, fetchTimeLogsDetails),
    yield takeLatest(sagaActions.ADD_TIME_SHEET, createTimeSheet),
  ]);
}
