import { CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { Card } from "react-bootstrap";
import { useStyles } from "./styles";
import {
  BereavementLeaveIcon,
  CasualLeaveIcon,
  EarnedLeaveIcon,
  LeaveWithoutPayIcon,
  MeternityLeaveIcon,
  OptionalLeaveIcon,
  SickLeaveIcon,
} from "../Icons";

const leaveSummaryIcons = {
  "Casual Leave": <CasualLeaveIcon />,
  "Bereavement Leave": <BereavementLeaveIcon />,
  "Earned Leave": <EarnedLeaveIcon />,
  "Leave Without Pay": <LeaveWithoutPayIcon />,
  "Paternity Leave": <MeternityLeaveIcon />,
  "Maternity Leave": <MeternityLeaveIcon />,
  "Sick Leave": <SickLeaveIcon />,
  "Optional Leave":<OptionalLeaveIcon/>
};

const DetailCard: React.FunctionComponent<any> = ({ item }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={6} key={item.index}>
      <Card className={classes.display_card}>
        <CardContent>
          <Grid container spacing={2} sx={{ mt: -3, mb: 3 }}>
            <Grid item xs={9}>
              <Typography variant="subtitle1">{item.name}</Typography>
            </Grid>
            <Grid item xs={3} justifyContent={"flex-end"} display={"flex"}>
              <div
                style={{
                  background: item.color,
                  height: 32,
                  width: 32,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {leaveSummaryIcons[item.name]}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="body2">Credited Balance</Typography>
            </Grid>
            <Grid item xs={6} justifyContent={"flex-end"} display={"flex"}>
              <Typography>{item.creditedBalance}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Leave taken</Typography>
            </Grid>
            <Grid item xs={6} justifyContent={"flex-end"} display={"flex"}>
              <Typography>{item.leavesTaken}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Closing balance</Typography>
            </Grid>
            <Grid item xs={6} justifyContent={"flex-end"} display={"flex"}>
              <Typography>{item.closingBalance}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Currently booked</Typography>
            </Grid>
            <Grid item xs={6} justifyContent={"flex-end"} display={"flex"}>
              <Typography>{item.currentlyBooked}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default DetailCard;
