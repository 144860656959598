import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  headerContainer: {
    height: 100,
    backgroundColor: "#CCD5FC",
    borderRadius: 1,
  },
  headerGrid: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignSelf: "center",
  },
  headerTypo: {
    fontSize: 24,
    fontWeight: "400",
    align: "center",
  },
  company_panel: {
    backgroundColor: "#CCD5FC",
    borderRadius: "10px",
    padding: "20px 40px",
    color: "#000000",
  },
  back_icon: {
    cursor: "pointer",
    marginRight: "15px",
    color: "#000000",
  },
  adminUsersIcons: {
    height: 60,
    width: 60,
    borderRadius: 30,
    display: "flex",
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  headerUsers: {
    mt: 1,
    mb: 1,
    mr: 2,
    ml: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export { styles };
