import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Box,
  Card,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Moment from "moment";
import React from "react";
import { dateFormat } from "../../../Constants";
import { imageReturnFromZoho } from "../../../modules/People/People.helper";
import { userAccess } from "../../../utils/CommonFunction";
import { styles } from "./GridStyle";

type TableProps = {
  row?: Array<any>;
  getDetails?: (number, boolean?, details?) => void;
  deleteMember?: (number) => void;
  actionList?: Array<any>;
  moveToTeamMember?: (number) => void;
  disableTeamMember?: (number) => void;
  enableTeamMember?: (number) => void;
  showPaging?: boolean;
  page?: number;
  getPageList?: (number) => void;
  enableDisableRow?: (boolean) => void;
  sortTable?: (boolean, string) => void;
};

const MobileDataGrid: React.FunctionComponent<TableProps> = (props) => {
  const classes = styles();
  const defaultRowCell = [1, 2, 3];

  const {
    row = defaultRowCell,
    getDetails = () => null,
    deleteMember = () => null,
    moveToTeamMember = () => null,
    disableTeamMember = () => null,
    enableTeamMember = () => null,
    actionList = [],
    showPaging = false,
    page = 0,
    getPageList = () => null,
    enableDisableRow = () => null,
    sortTable = () => null,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [actionPeopleId, setActionPeopleId] = React.useState<number>();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const rowAction = (action) => {
    switch (action) {
      case "getDetails":
        getDetails(actionPeopleId, true);
        break;
      case "deleteMember":
        deleteMember(actionPeopleId);
        break;
      case "moveToTeamMember":
        moveToTeamMember(actionPeopleId);
        break;
      case "disableTeamMember":
        disableTeamMember(actionPeopleId);
        break;
      case "enableTeamMember":
        enableTeamMember(actionPeopleId);
        break;
    }
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const responsiveCard = () => {
    return (
      <Box className="responsive-tbl">
        {row?.map((value) => (
          <Card
            key={value.id}
            className="tbl-card"
            sx={{ minWidth: 275, marginBottom: 3 }}
            onClick={() => getDetails(value.teamMemberId, false, value)}>
            <Grid
              container
              spacing={2}
              className={
                value?.isDisabled ? "tbl-header-disable" : "tbl-header"
              }>
              <Grid item xs={10}>
                <Box sx={{ display: "flex" }}>
                  <Avatar
                    alt={value?.firstName}
                    src={
                      value?.profilePhotoURL
                        ? imageReturnFromZoho(value?.profilePhotoURL)
                        : ""
                    }
                    sx={{ marginRight: 1 }}
                  />
                  <Box>
                    <Typography variant="h6" component="div">
                      {value.firstName
                        ? `${value.firstName} ${value.lastName} `
                        : "-"}
                      <span className={classes.headerTypo}>
                        {value?.jobTitle ? `(${value?.jobTitle})` : ""}
                      </span>
                    </Typography>
                    <Typography variant="caption" component="div">
                      {`${value.emailAddress} `}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end">
                  {userAccess().client && value?.isPatonaTeamMember ? (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                      }}>
                      <MoreVertIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClick(e);
                        setActionPeopleId(value?.teamMemberId);
                        enableDisableRow(value?.isDisabled);
                      }}>
                      <MoreVertIcon />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Box
              className={value?.isDisabled ? "tbl-body-disable" : "tbl-body"}>
              <Grid container spacing={2}>
                <Grid item xs={6} spacing={2}>
                  <Box padding={1}>
                    <Typography variant="caption" component="div">
                      {`Assigned to - `} {value.companyName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} spacing={2}>
                  <Box textAlign={"right"} padding={1}>
                    <Typography variant="caption" component="div">
                      {`Started date`}
                    </Typography>
                    <Typography variant="caption" component="div">
                      {`${
                        value.joiningDate
                          ? Moment(value.joiningDate).format(dateFormat)
                          : ""
                      }`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Card>
        ))}
      </Box>
    );
  };

  return (
    <Stack sx={{ flexGrow: 1 }} mt={2}>
      {responsiveCard()}
      {row.length === 0 && (
        <Typography variant="subtitle1" py={3} align={"center"}>
          {"No Record found"}
        </Typography>
      )}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        {!!actionList &&
          actionList?.length > 0 &&
          actionList.map((action) => {
            return (
              action.display && (
                <MenuItem onClick={() => rowAction(action.action)}>
                  {action.name}
                </MenuItem>
              )
            );
          })}
      </Menu>
    </Stack>
  );
};

export default MobileDataGrid;
