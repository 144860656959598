import { rowPerPage } from "../Constants";
import api from "../services/BaseApi";
import { userAccess } from "../utils/CommonFunction";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const getFilteredLeaveApprovalData = BASE_URL + "getFilteredLeaveApprovalData";
const getLeaveApprovalDataById = BASE_URL + "getLeaveApprovalDataById";
const getLeaveSummaryDataByEmail =
  BASE_URL + "getLeaveSummaryDataByEmailAndZohoId";
const getFilteredLeaveApprovalCalendarData =
  BASE_URL + "getFilteredLeaveApprovalCalendarData";
const getLeaveRequestsShortInfo = BASE_URL + "getLeaveRequestsShortInfo";
const approveLeaveRequestURL = BASE_URL + "approveLeaveRequest";
const getTeamMembersPastAndUpcomingLeaves =
  BASE_URL + "getTeamMembersPastAndUpcomingLeaves";
const getTeamMembersLeavesShortInfo =
  BASE_URL + "getTeamMembersLeavesShortInfo";
const getLeaveTypesToApplyLeaves = BASE_URL + "getLeaveTypesToApplyLeaves";
const applyLeaveForTeammembers = BASE_URL + "applyLeaveForTeammembers";
const getLeaveApproverDetails=BASE_URL+"getLeaveApproverDetails"

export const calenderApprovals = async (payload) => {
  try {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    let request: any = {
      clientId: 0,
      searchValue: "",
      year: year,
      month: month,
      leaveTypeList: [],
      leaveStatusList: [],
    };

    if (payload) {
      request = { ...request, ...payload };
    }
    request.clientId = userAccess().clientCode;
    const res = await api.post(getFilteredLeaveApprovalCalendarData, request);
    return res;
  } catch (error) {
    console.log(error, "===== approvals calender list API Error");
    //return error;
  }
};

export const approvals = async (payload) => {
  try {
    let request: any = {
      pageNumber: 1,
      pageSize: rowPerPage,
      clientId: 0,
      searchValue: "",
      isDesc: true,
      columnName: "",
      role: "Patona_Admin",
      from: "",
      to: "",
      quarter: "",
      year: "",
      month: 0,
      leaveTypeList: [],
      leaveStatusList: [],
      period: "Custom",
    };

    if (payload) {
      request = { ...request, ...payload };
    }
    request.clientId = userAccess().clientCode;
    request.role = userAccess().role;
    const res = await api.post(getFilteredLeaveApprovalData, request);
    return res;
  } catch (error) {
    console.log(error, "===== approvals list API Error");
    //return error;
  }
};

export const approvalById = async (payload) => {
  try {
    let request: any = {
      id: 0,
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(getLeaveApprovalDataById, request);
    return res;
  } catch (error) {
    console.log(error, "===== approval By Id API Error");
    // return error;
  }
};

export const approvalLeaveSummary = async (payload) => {
  try {
    let request: any = {
      email: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(getLeaveSummaryDataByEmail, request);
    return res;
  } catch (error) {
    console.log(error, "===== approval leave summary API Error");
    //return error;
  }
};

export const getLeaveShortInfo = async (payload) => {
  try {
    let request: any = {
      date: new Date(),
      status: "approved",
    };

    if (payload) {
      request = { ...request, ...payload };
    }
    request.clientId = userAccess().clientCode;
    const res = await api.post(getLeaveRequestsShortInfo, request);
    return res;
  } catch (error) {
    console.log(error, "===== approval leave summary API Error");
    //return error;
  }
};

export const getTeamMemberLeaveInfo = async (payload) => {
  try {
    let request: any = {
      isForPast: false,
      email: "",
      zohoId: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(getTeamMembersPastAndUpcomingLeaves, payload);
    return res;
  } catch (error) {
    console.log(error, "===== approval leave past and upcoming API Error");
    //return error;
  }
};

export const getTeamMemberShortLeaveInfo = async (payload) => {
  try {
    let request = {
      leaveRecordId: "",
    };
    if (payload) {
      request.leaveRecordId = payload;
    }
    const res = await api.post(getTeamMembersLeavesShortInfo, request);
    return res;
  } catch (error) {
    console.log(error, "===== team member leave short info API Error");
    //return error;
  }
};

export const getApproverDetail = async (payload:any) => {
  try {
    let request = {
      leaveRecordID: "",
    };
    if (payload) {
      request.leaveRecordID = payload;
    }
    const res = await api.post(getLeaveApproverDetails, request);
    return res;
  } catch (error) {
    console.log(error, "===== team member leave short info API Error");
    //return error;
  }
};

export const leaveActionService = async (payload) => {
  try {
    let request: any = {
      leaveRecordID: "",
      role: "",
      firstName: "",
      lastName: "",
      email: "",
      status: "0",
      remark: "",
    };

    if (payload) {
      request = { ...request, ...payload };
    }
    request.role = userAccess().role;
    request.firstName = userAccess().firstName;
    request.lastName = userAccess().lastName;
    request.email = userAccess().email;
    const res = await api.post(approveLeaveRequestURL, request);
    return res;
  } catch (error) {
    console.log(error, "===== approval leave action API Error");
    //return error;
  }
};

export const getLeaveTypesToApply = async (payload) => {
  try {
    let request = {
      email: "",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(getLeaveTypesToApplyLeaves, request);
    return res;
  } catch (error) {
    console.log(error, "===== get leave type API Error");
    //return error;
  }
};

export const getApprovalTeammember = async (payload) => {
  try {
    let request: any = {
      zohoId: "",
      leaveId: "",
      dateFrom: "",
      dateTo: "",
      reasonForLeave: "",
      days: [
        {
          date: "",
          leaveCount: "",
          session: "",
        },
      ],
    };
    request = { ...request, ...payload };
    const res = await api.post(applyLeaveForTeammembers, request);
    return res;
  } catch (error) {
    console.log(error, "===== apply leave for team member API Error");
    //return error;
  }
};
