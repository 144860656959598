import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
  useMediaQuery
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import { Box } from "@mui/system";
import { GridMoreVertIcon, GridSearchIcon } from "@mui/x-data-grid";
import _ from "lodash";
import Moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { leaveStatusArray, leaveTypesArray } from "../../Constants";
import { STRINGS } from "../../Strings";
import Search from "../../shared/components/Search/Search";
import TableSkeleton from "../../shared/components/Skeletons/TableSkeleton";
import { userAccess } from "../../utils/CommonFunction";
import {
  getCalenderApporovalList,
  getLeaveRequestsShortInfo,
  leaveTypeColor,
} from "./Approvals.helper";
import "./CalendarView.scss";
import { useStyles } from "./style";

const CalendarView: any = ({
  leaveTypeList,
  leaveStatusList,
  updateLeaveType,
  updateLeaveStatus,
  setAnchorEditColumnEl,
}) => {
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "70%" : "50%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    p: 4,
    boxShadow: "1px 4px 20px rgba(132, 132, 132, 0.2)",
    borderRadius: "10px",
  };
  const classes = useStyles();
  const navigagte = useNavigate();
  const calRef = useRef<any>();
  const dispatch = useDispatch();
  const [calViewData, setCalViewData] = useState<any>({});
  const [calenderData, setCalenderData] = useState<any>([]);
  const [showDetails, setShowDetails] = useState(false);
  const [year, setYear] = useState<number>();
  const [infoStatus, setInfoStatus] = useState<string>("");
  const [searched, setSearched] = useState<string>("");
  const [month, setMonth] = useState<number>();
  const [defaultLeaveType, setDefaultLeaveType] = useState([]);
  const [defaultLeaveStatus, setDefaultLeaveStatus] = useState([]);
  const [leaveType, setLeaveType] = useState<string[]>([]);
  const [leaveStatus, setLeaveStatus] = useState<string[]>([]);
  const [leaveInfoListState, setLeaveInfoListState] = useState<any>({});

  const { calenderApprovalList, leaveInfoList, isLeaveInfoLoading } =
    useSelector(
      //@ts-ignore
      (state) => state.approval
    );

  useEffect(() => {
    const grouped = _.groupBy(leaveInfoList, (info) => info.leaveType);
    setLeaveInfoListState(grouped);
  }, [leaveInfoList]);

  useEffect(() => {
    const calenderList = calenderApprovalList?.map((data) => {
      return {
        ...data,
        date: Moment(data.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
    });

    setCalenderData(calenderList);
  }, [calenderApprovalList]);

  const updateCalendar = () => {
    setCalViewData(calRef.current?.getApi().currentData);
    const date = new Date(calRef.current?.getApi().currentData?.currentDate);
    const updatedYear = date.getFullYear();
    const updatedMonth = date.getMonth() + 1;
    setYear(updatedYear);
    setMonth(updatedMonth);

    let checkedStatus = [];
    defaultLeaveStatus?.map((check) => {
      //@ts-ignore
      if (check.checked) {
        //@ts-ignore
        checkedStatus.push(check.value);
      }
    });

    let checkedType = [];
    console.log(defaultLeaveType);
    defaultLeaveType?.map((check) => {
      //@ts-ignore
      if (check.checked) {
        //@ts-ignore
        checkedType.push(check.value);
      }
    });

    const payload = {
      year: updatedYear,
      month: updatedMonth,
      leaveTypeList: checkedType,
      leaveStatusList: checkedStatus,
      searchValue: searched,
    };
    getCalenderApporovalList(dispatch, payload);
  };

  useEffect(() => {
    let types: any = [];
    let status: any = [];

    leaveTypesArray?.map((data) => {
      leaveType.push(data);
      types.push({ checked: leaveTypeList.indexOf(data) > -1, value: data });
    });
    setDefaultLeaveType(types);
    leaveStatusArray?.map((data) => {
      leaveStatus.push(data);
      status.push({ checked: leaveStatusList.indexOf(data) > -1, value: data });
    });
    setDefaultLeaveStatus(status);
    setCalViewData(calRef.current?.getApi().currentData);
    const date = new Date(calRef.current?.getApi().currentData?.currentDate);
    const updatedYear = date.getFullYear();
    const updatedMonth = date.getMonth() + 1;
    setYear(updatedYear);
    setMonth(updatedMonth);

    const payload = {
      year: updatedYear,
      month: updatedMonth,
      leaveTypeList: leaveTypeList,
      leaveStatusList: leaveStatusList,
      searchValue: searched,
    };
    getCalenderApporovalList(dispatch, payload);

    return () => {
      setLeaveType([]);
      setLeaveStatus([]);
    };
  }, []);

  const handlePrev = () => {
    calRef.current?.getApi()?.prev();
    updateCalendar();
  };

  const handleNext = () => {
    calRef.current?.getApi()?.next();
    updateCalendar();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value) => {
    const updateCheck = defaultLeaveType?.map((data) => {
      //@ts-ignore
      if (data.value === value) {
        //@ts-ignore
        data.checked = event.target.checked;
      }
      return data;
    });
    //@ts-ignore
    setDefaultLeaveType(updateCheck);

    let checkedLeave = [];
    updateCheck?.map((check) => {
      //@ts-ignore
      if (check.checked) {
        //@ts-ignore
        checkedLeave.push(check.value);
      }
    });

    let checkedStatus = [];
    defaultLeaveStatus?.map((check) => {
      //@ts-ignore
      if (check.checked) {
        //@ts-ignore
        checkedStatus.push(check.value);
      }
    });

    const payload = {
      year: year,
      month: month,
      leaveTypeList: checkedLeave,
      leaveStatusList: checkedStatus,
      searchValue: searched,
    };
    updateLeaveType(checkedLeave);
    getCalenderApporovalList(dispatch, payload);
  };

  const handleStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value
  ) => {
    const updateCheck = defaultLeaveStatus?.map((data) => {
      //@ts-ignore
      if (data.value === value) {
        //@ts-ignore
        data.checked = event.target.checked;
      }
      return data;
    });
    //@ts-ignore
    setDefaultLeaveStatus(updateCheck);
    let checkedStatus = [];
    updateCheck?.map((check) => {
      //@ts-ignore
      if (check.checked) {
        //@ts-ignore
        checkedStatus.push(check.value);
      }
    });

    let checkedType = [];
    defaultLeaveType?.map((check) => {
      //@ts-ignore
      if (check.checked) {
        //@ts-ignore
        checkedType.push(check.value);
      }
    });

    const payload = {
      year: year,
      month: month,
      leaveTypeList: checkedType,
      leaveStatusList: checkedStatus,
      searchValue: searched,
    };
    updateLeaveStatus(checkedStatus);
    getCalenderApporovalList(dispatch, payload);
  };

  return (
    <>
      <Box
        mt={1}
        mb={1}
        className={
          userAccess().client ? "page-header-pink" : "page-header-blue"
        }>
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{
            flexDirection: {
              xs: "row-reverse",
              md: "row",
            },
          }}>
          <Grid item xs={8} md={6}>
            <Grid
              item
              xs={12}
              sm={12}
              md={10}
              xl={10}
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "flex-end",
                  md: "flex-start",
                },
              }}>
              <IconButton
                sx={{
                  display: {
                    xs: "flex",
                    md: "none",
                  },
                }}>
                <GridSearchIcon />
              </IconButton>
              <Search
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                }}
                placeHolder={"Search by name,  leave type, status or period"}
                getSearch={(input) => {
                  let checkedStatus = [];
                  defaultLeaveStatus?.map((check) => {
                    //@ts-ignore
                    if (check.checked) {
                      //@ts-ignore
                      checkedStatus.push(check.value);
                    }
                  });

                  let checkedType = [];
                  defaultLeaveType?.map((check) => {
                    //@ts-ignore
                    if (check.checked) {
                      //@ts-ignore
                      checkedType.push(check.value);
                    }
                  });
                  const payload = {
                    searchValue: input,
                    year: year,
                    month: month,
                    leaveTypeList: checkedType,
                    leaveStatusList: checkedStatus,
                  };
                  setSearched(input);
                  getCalenderApporovalList(dispatch, payload);
                }}
              />
              <IconButton
                sx={{
                  display: {
                    xs: "flex",
                    md: "none",
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setAnchorEditColumnEl(e.currentTarget);
                }}>
                <GridMoreVertIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            md={6}
            sx={{
              display: "flex",
              justifyContent: {
                xs: "flex-start",
                md: "flex-end",
              },
            }}
            justifyContent="flex-end">
            <Button variant="contained" disabled>
              Apply Leave
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={1}>
        <Grid item md={3}>
          <Box mt={1} mb={1}>
            <Card>
              <CardContent sx={{ p: 0 }}>
                <List
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      Leave type
                    </ListSubheader>
                  }>
                  <ListItem>
                    <FormGroup>
                      {defaultLeaveType?.map((leavetype) => {
                        return (
                          <FormControlLabel
                            //@ts-ignore
                            control={<Checkbox checked={leavetype.checked} />}
                            //@ts-ignore
                            label={leavetype.value}
                            //@ts-ignore
                            onChange={(e) => handleChange(e, leavetype.value)}
                          />
                        );
                      })}
                    </FormGroup>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Box>
          <Box mt={1} mb={1}>
            <Card>
              <CardContent sx={{ p: 0 }}>
                <List
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      Leave status
                    </ListSubheader>
                  }>
                  <ListItem>
                    <FormGroup>
                      {defaultLeaveStatus?.map((leavetype) => {
                        return (
                          <FormControlLabel
                            //@ts-ignore
                            control={<Checkbox checked={leavetype.checked} />}
                            //@ts-ignore
                            label={leavetype.value}
                            //@ts-ignore
                            onChange={(e) =>
                              //@ts-ignore
                              handleStatusChange(e, leavetype.value)
                            }
                          />
                        );
                      })}
                    </FormGroup>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item md={9}>
          <Box mt={1} mb={1}>
            <Card sx={{ backgroundColor: "#FFFAEA" }}>
              <CardContent>
                <Grid container alignItems="center">
                  <Grid item xs={8} display={"flex"}>
                    <IconButton onClick={handlePrev}>
                      <ArrowBackIosIcon />
                    </IconButton>
                    <Typography variant="h6" mx={5} p={1} component="span">
                      {" "}
                      {calViewData?.viewTitle}{" "}
                    </Typography>
                    <IconButton onClick={handleNext}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex" }}
                    justifyContent={"flex-end"}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setAnchorEditColumnEl(e.currentTarget);
                      }}>
                      <GridMoreVertIcon />
                    </IconButton>
                  </Grid>
                </Grid>

                <Grid container alignItems="center">
                  <Grid item xs={12} sx={{ backgroundColor: "#FFF", mt: 2 }}>
                    <FullCalendar
                      ref={calRef}
                      headerToolbar={{
                        left: "",
                        center: "",
                        right: "",
                      }}
                      eventClick={(e) => {
                        let checkedType = [];
                        defaultLeaveType?.map((check) => {
                          //@ts-ignore
                          if (check.checked) {
                            //@ts-ignore
                            checkedType.push(check.value);
                          }
                        });
                        let payload: any = {
                          date: Moment(e.event.start).format("YYYY-MM-DD"),
                          status: e.event.extendedProps.status,
                          leaveTypeList: checkedType,
                          searchValue: searched,
                        };
                        setInfoStatus(e.event.extendedProps.status);
                        getLeaveRequestsShortInfo(dispatch, payload);
                        setShowDetails(true);
                      }}
                      events={calenderData}
                      themeSystem="Simplex"
                      plugins={[dayGridPlugin]}
                      initialView="dayGridMonth"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={showDetails}
        onClose={() => {
          setShowDetails(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            className={classes.m_title}
            variant="h6"
            component="h2"
            mb={2}>
            {infoStatus} Requests
          </Typography>
          {isLeaveInfoLoading ? (
            <TableSkeleton headerCount={[1]} rowCount={[1]} />
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {!_.isEmpty(leaveInfoList) ? (
                    <List
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 400,
                        "& ul": { padding: 0 },
                      }}
                      subheader={<li />}>
                      {Object.keys(leaveInfoListState)?.map((info) => (
                        <li key={`section-${info}`}>
                          <ul>
                            <ListSubheader
                              sx={{
                                color: leaveTypeColor(info),
                              }}>{`${info}`}</ListSubheader>
                            {leaveInfoListState[info]?.map((item) => (
                              <>
                                <ListItem
                                  key={info}
                                  onClick={() =>
                                    navigagte(`/leave/leave-details/${item.id}`)
                                  }>
                                  <Grid
                                    container
                                    spacing={2}
                                    alignItems="center">
                                    <Grid item xs={4}>
                                      <Box
                                        display={"flex"}
                                        alignItems={"center"}>
                                        <Avatar
                                          alt={item.employeeName}
                                          src={item.employeeProfilePhoto}
                                          sx={{ mr: 1 }}
                                        />
                                        <Typography variant="subtitle1">
                                          {item.employeeName}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Typography variant="subtitle2">
                                        {`${
                                          item.daysTaken >= 1
                                            ? "Full Day"
                                            : "Half Day"
                                        }`}{" "}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography variant="subtitle2">
                                        {item.reason}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </ListItem>
                                <Divider />
                              </>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </List>
                  ) : (
                    <Typography
                      id="modal-modal-title"
                      className={classes.m_title}
                      variant="subtitle2"
                      component="h2"
                      mb={2}>
                      {STRINGS.NoRecords}
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default CalendarView;
