import { useState } from "react";

import {
  Box,
  Button,
  FormHelperText,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { sagaActions } from "../../../../../redux/sagaActions";
import { userAccess } from "../../../../../utils/CommonFunction";
import { getDateTime } from "../../../../../utils/dateFormatter";
import { useStyles } from "../styles";
import SearchableEmployeeField from "./SearchableEmployeeField";

const ImmediateActionModal = ({
  onSubmit,
  onCancel,
  noClient = false,
  searchable = false,
  showModal,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const empId = useParams().id;

  const [reason, setReason] = useState("");

  const [selectedEmployeeDetails, setSelectedEmployeeDetails] = useState({
    id: null,
    clientId: null,
    lastScore: null,
    isLastMonthFeedbackSubmitted: false,
  });

  const {
    isLoading,
    employeeName,
    lastScore,
    clientId,
    submitFeedbackInfo,
    isLastMonthFeedbackSubmitted,
  } = useSelector((state: any) => state.performance.employee);

  const validateForm = () => {
    if (!empId && selectedEmployeeDetails?.id === null) {
      return false;
    }

    if (reason.trim().length === 0) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    const { userId, firstName, lastName } = userAccess();

    let payload = {
      employeeId: searchable ? selectedEmployeeDetails?.id : empId,
      feedBackType: 3,
      clientId: searchable ? selectedEmployeeDetails?.clientId : clientId,
      userId: userId,
      from: firstName + " " + lastName,
      date: getDateTime(),
      immediateActionReason: reason,
    };

    dispatch({ type: sagaActions.ADD_FEEDBACK, payload });
    onSubmit();
  };

  return (
    <Modal open={showModal} onClose={() => {}}>
      <Box className={classes.modal}>
        <Typography
          variant="h4"
          textAlign="center"
          fontWeight={400}
          p={2}
          mb={2}
        >
          Immediate Action
        </Typography>

        {searchable && (
          <SearchableEmployeeField
            onSelect={(employee) => setSelectedEmployeeDetails(employee)}
          />
        )}

        <Typography variant="body1" fontWeight={500} mt={2}>
          Reason for your immediate feedback
        </Typography>

        <TextField
          label="Mention reason"
          name="reason"
          value={reason}
          onChange={(event) => setReason(event.target.value)}
          margin="normal"
          fullWidth
          multiline
          rows={4}
          onBlur={validateForm}
        />

        {noClient && (
          <FormHelperText error={true} sx={{ textAlign: "right" }}>
            Feedback cannot be given as this team member is not currently linked
            to a client.
          </FormHelperText>
        )}
        <Box className={classes.actions}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!validateForm()}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ImmediateActionModal;
