import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Country } from "country-state-city";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useLocation } from "react-router-dom";
import { userSchema } from "../../../schemas/user";
import { useStyles } from "../CompanyDetails/styles";

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { STRINGS } from "../../../Strings";
import { UserData } from "../../../Types/Users/User";
import { userUpdatedDone } from "../../../redux/User/userSlice";
import { AdminRoleArray, userAccess } from "../../../utils/CommonFunction";
import {
  getTimeZone,
  imageUpload,
  resetUpdateProfileError,
  updateProfile
} from "./User.helper";

let initialValues = {
  firstName: "",
  lastName: "",
  timeZone: "",
  country: "",
  phoneNumber: "",
  address: "",
  designation: "",
  role: "",
  userId: "",
  isProfilePicUpdated: false,
  profilePictureFile: "",
  profilePicture: "",
  uId: "",
};

const style = {
  overflowY: "scroll",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

type EditUserProps = {
  cancel?: () => void;
  user: UserData;
  isLoading: false;
  showCancel?: boolean;
  pressNext?: () => void;
  submitLabel?: string;
};

const EditUser: React.FunctionComponent<EditUserProps> = (props) => {
  const {
    cancel = () => null,
    user,
    isLoading = false,
    showCancel = true,
    pressNext = () => null,
    submitLabel = STRINGS.Update,
  } = props;
  const classes = useStyles();
  const location: any = useLocation();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = React.useState<any>("");
  const [selectedTimeZone, setSelectedTimeZone] = React.useState<any>("");
  const [selectedCountry, setSelectedCountry] = React.useState("");
  const [fileName, setFileName] = React.useState<any>("");
  const [selectFile, setFile] = React.useState<any>("");
  const [isPicUpdated, setIsPicUpdated] = React.useState<boolean>(false);
  const [uploadfile, setUploadfile] = React.useState<any>("");
  const [formAction, setFormAction] = React.useState<any>();
  const [availableCountries, setAvailableCountries] = React.useState<any>([]);

  const loadCountries = () => {
    setAvailableCountries(Country.getAllCountries());
  };

  useEffect(() => {
    getTimeZone(dispatch);
    loadCountries();
    return () => {
      resetUpdateProfileError(dispatch);
    };
  }, []);

  const { timeZone, updateLoading, isUserUpdated, userUpdateError } =
    useSelector(
      //@ts-ignore
      (state) => state.user
    );
  useEffect(() => {
    if (isUserUpdated && !_.isEmpty(formAction)) {
      alert.success("Profile has been updated successfully");
      formAction.resetForm();
      dispatch(userUpdatedDone());
      cancel();
      pressNext();
    }
  }, [isUserUpdated]);

  useEffect(() => {
    if (!_.isNull(user)) {
      initialValues = {
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: _.isNull(user.phoneNumber) ? "" : user.phoneNumber,
        address: _.isNull(user.address) ? "" : user.address,
        country: _.isNull(user.country) ? "" : user.country,
        timeZone: _.isNull(user.timeZone) ? "" : user.timeZone,
        designation: _.isNull(user.designation) ? "" : user.designation,
        role: _.isNull(user.role) ? "" : user.role,
        userId: user.userId,
        isProfilePicUpdated: false,
        profilePictureFile: "",
        profilePicture: "",
        uId: userAccess()?.userId,
      };
      const country = _.isNull(user.country) ? "" : user.country;
      setSelectedCountry(country);
      setSelectedRole(user.role);
      setSelectedTimeZone(user.timeZone);
      setFileName(user.profilePicture);
      setUploadfile(user.profilePictureFile);
      setIsPicUpdated(false);
    }
  }, [user]);

  useEffect(() => {
    if (isPicUpdated) {
      setFieldValue("isProfilePicUpdated", isPicUpdated);
    }
  }, [isPicUpdated]);

  useEffect(() => {
    if (userUpdateError) {
      alert.error(userUpdateError);
    }
  }, [userUpdateError]);

  const {
    values,
    errors,
    dirty,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: userSchema,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      values.isProfilePicUpdated = isPicUpdated;
      values.profilePicture = fileName;
      values.profilePictureFile = uploadfile;
      values.phoneNumber = values?.phoneNumber?.toString();
      updateProfile(values, dispatch);
      setFormAction(action);
    },
  });

  return (
    <>
      <Grid container component="main">
        <Container maxWidth="xl">
          <>
            <Box
              sx={style}
              justifyContent={"center"}
              display={"block"}
              alignContent={"center"}
              alignItems={"center"}
              alignSelf={"center"}>
              <Box
                component="form"
                noValidate
                sx={{ pointerEvents: updateLoading ? "none" : "auto" }}
                onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box display="flex" justifyContent="flex-start">
                      {fileName ? (
                        <img
                          className={classes.user_pic}
                          src={fileName}
                          alt={fileName}
                        />
                      ) : (
                        <Avatar
                          alt={`${user.firstName} ${user.lastName}`}
                          src={user.profilePicture}
                          sx={{ width: 174, height: 174 }}
                        />
                      )}
                      <Grid ml={2}>
                        <Typography variant="h6">
                          {STRINGS.ProfilePicture}
                        </Typography>
                        <Typography variant="caption">
                          ({STRINGS.ImageSize})
                        </Typography>
                        <Box
                          display={"flex"}
                          sx={{ mt: 6 }}
                          className="upload-btn-wrapper">
                          <button className="profile_btn">
                            <FileUploadOutlinedIcon
                              sx={{ mr: 1 }}></FileUploadOutlinedIcon>
                            <Typography variant="caption">
                              {STRINGS.Upload}
                            </Typography>
                          </button>
                          <input
                            type="file"
                            name="myfile"
                            onChange={(e) =>
                              imageUpload(
                                e,
                                setFileName,
                                setFile,
                                alert,
                                setIsPicUpdated,
                                setUploadfile
                              )
                            }
                            disabled={updateLoading}
                          />
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      margin="normal"
                      fullWidth
                      id="firstName"
                      label={STRINGS.OwnerFirstName}
                      name="firstName"
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 25 }}
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={updateLoading}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      margin="normal"
                      fullWidth
                      id="lastName"
                      label={STRINGS.OwnerLastName}
                      name="lastName"
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 25 }}
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={updateLoading}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                      type="number"
                      margin="normal"
                      fullWidth
                      id="phoneNumber"
                      label={STRINGS.PhoneNumber}
                      name="phoneNumber"
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 25 }}
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={updateLoading}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                      margin="normal"
                      fullWidth
                      id="address"
                      label={STRINGS.Address}
                      name="address"
                      variant="outlined"
                      autoComplete="off"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={updateLoading}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 0.5 }}>
                  <Grid item xs={12} sm={6} md={6} mb={1}>
                    <FormControl fullWidth>
                      <InputLabel>Country</InputLabel>
                      <Select
                        id="country"
                        name="country"
                        label={STRINGS.Country}
                        value={selectedCountry}
                        disabled={updateLoading}
                        onChange={(e) => {
                          setSelectedCountry(e.target.value);
                          setFieldValue("country", e.target.value);
                        }}>
                        {availableCountries?.map((value, key) => {
                          return (
                            <MenuItem value={value.isoCode} key={key}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 5,
                          color: "#D14343",
                        }}>
                        {touched.country && errors.country
                          ? errors.country
                          : null}
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>{STRINGS.TimeZone}</InputLabel>
                      <Select
                        error={Boolean(touched.timeZone && errors.timeZone)}
                        id="timeZone"
                        name="timeZone"
                        label={STRINGS.TimeZone}
                        value={selectedTimeZone}
                        disabled={updateLoading}
                        onChange={(e) => {
                          setSelectedTimeZone(e.target.value);
                          setFieldValue("timeZone", e.target.value);
                        }}>
                        {!!timeZone &&
                          timeZone.length > 0 &&
                          timeZone?.map((e, key) => {
                            return (
                              <MenuItem value={e.label} key={key}>
                                {e.label}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 5,
                          color: "#D14343",
                        }}>
                        {touched.timeZone && errors.timeZone
                          ? errors.timeZone
                          : null}
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
                  <Grid item xs={12} sm={6} md={6} sx={{ marginTop: -2 }}>
                    <TextField
                      error={Boolean(touched.designation && errors.designation)}
                      helperText={touched.designation && errors.designation}
                      margin="normal"
                      type={"text"}
                      fullWidth
                      id="designation"
                      label={STRINGS.Designation}
                      name="designation"
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 50 }}
                      value={values.designation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={updateLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>Role</InputLabel>
                      <Select
                        disabled={true}
                        id="role"
                        name="role"
                        label={STRINGS.Role}
                        value={selectedRole}
                        onChange={(e) => {
                          setSelectedRole(e.target.value);
                        }}>
                        {AdminRoleArray?.map((e, key) => {
                          return (
                            <MenuItem value={e.id} key={key}>
                              {e.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Box display="flex" justifyContent="flex-end">
                  {showCancel && (
                    <Button onClick={cancel} variant="text" sx={{ mt: 2 }}>
                      {STRINGS.Cancel}
                    </Button>
                  )}

                  <Button
                    type="submit"
                    disabled={showCancel ? !dirty : false}
                    variant="contained"
                    sx={{ mt: 2 }}>
                    {updateLoading && (
                      <CircularProgress size={18} color="warning" />
                    )}
                    {!updateLoading && submitLabel}
                  </Button>
                </Box>
              </Box>
            </Box>
          </>
        </Container>
      </Grid>
    </>
  );
};

export default EditUser;
