import { CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { getTaskById } from "../../services/Tasks";
import TaskComment from "./Components/TaskComment";


const TaskCommentListModal = ({ showModal, closeModal }) => {
    const [isLoading ,setIsLoading] = useState(true);
    const [taskInfo, setTaskInfo] = useState<any>({})
    const alert = useAlert()

    const getTask =async () => {
        setIsLoading(true)
       
        try {
            const result = await getTaskById(showModal)
            setTaskInfo(result.data)
        } catch (e) {
            alert.error('Error fetching comments ' + e)
        }
        setIsLoading(false)

    }


    useEffect(() => {
        if(showModal){
            getTask()
        }
    },[showModal])

    return (
        <Dialog open={showModal} onClose={closeModal} maxWidth='md' sx={{

        }}>
            <DialogTitle variant="h4" fontWeight={400} fontSize={'32px'} justifyContent={'center'} display={'flex'} alignItems={'center'} flexDirection={'column'}>
                <Typography fontSize={'24px'} lineHeight={'32px'} fontWeight={700}>Comments</Typography>
                <DialogContent sx={{
                py: '2rem',
                width: '30vw',
               
            }}>
                <Grid container item gap={1} py={1}>
                   {
                    isLoading && <Grid container item alignItems={'center'} justifyContent={'center'}><CircularProgress /></Grid>
                   }
                   {
                    !isLoading && taskInfo.comments.length === 0 ? <Grid container item alignItems={'center'} justifyContent={'center'}>
                        <Typography variant="body1">No comments Added</Typography>
                    </Grid>: null
                   }
                   {
                    !isLoading && taskInfo.comments.length >0? taskInfo.comments.map((comment,index) => (
                        <TaskComment taskComment={comment} last={index === taskInfo.comments.length-1}></TaskComment>
                    )): null
                   }
                   </Grid>
                </DialogContent>
            </DialogTitle>
        </Dialog>
    )
}

export default memo(TaskCommentListModal)