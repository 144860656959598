const AttendanceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.5652 3.80435H14.2391V3.47826C14.2391 2.92391 13.8152 2.5 13.2609 2.5C12.7065 2.5 12.2826 2.92391 12.2826 3.47826V3.80435H7.06522V3.47826C7.06522 2.92391 6.6413 2.5 6.08696 2.5C5.53261 2.5 5.1087 2.92391 5.1087 3.47826V3.80435H4.78261C3.51087 3.80435 2.5 4.81522 2.5 6.08696V15.2174C2.5 16.4891 3.51087 17.5 4.78261 17.5H14.5652C15.837 17.5 16.8478 16.4891 16.8478 15.2174V6.08696C16.8478 4.81522 15.837 3.80435 14.5652 3.80435ZM12.9348 3.47826C12.9348 3.28261 13.0652 3.15217 13.2609 3.15217C13.4565 3.15217 13.587 3.28261 13.587 3.47826V4.78261C13.587 4.97826 13.4565 5.1087 13.2609 5.1087C13.0652 5.1087 12.9348 4.97826 12.9348 4.78261V3.47826ZM6.08696 3.15217C6.28261 3.15217 6.41304 3.28261 6.41304 3.47826V4.78261C6.41304 4.97826 6.28261 5.1087 6.08696 5.1087C5.8913 5.1087 5.76087 4.97826 5.76087 4.78261V4.13043V3.47826C5.76087 3.28261 5.8913 3.15217 6.08696 3.15217ZM4.78261 4.45652H5.1087V4.78261C5.1087 5.33696 5.53261 5.76087 6.08696 5.76087C6.6413 5.76087 7.06522 5.33696 7.06522 4.78261V4.45652H12.2826V4.78261C12.2826 5.33696 12.7065 5.76087 13.2609 5.76087C13.8152 5.76087 14.2391 5.33696 14.2391 4.78261V4.45652H14.5652C15.4783 4.45652 16.1957 5.17391 16.1957 6.08696V7.06522H3.15217V6.08696C3.15217 5.17391 3.86957 4.45652 4.78261 4.45652ZM14.5652 16.8478H4.78261C3.86957 16.8478 3.15217 16.1304 3.15217 15.2174V7.71739H16.1957V15.2174C16.1957 16.1304 15.4783 16.8478 14.5652 16.8478Z"
        fill="none"
        stroke="black"
        stroke-width="0.3"
        className="fill-color"
      />
      <path
        d="M13.0326 10.1957L12.7065 9.90224C12.413 9.60876 11.9239 9.60876 11.6304 9.90224L9.05431 12.4457L7.71735 11.1414C7.42387 10.8479 6.93474 10.8479 6.64126 11.1414L6.31518 11.4348C6.18474 11.5653 6.08691 11.7609 6.08691 11.9892C6.08691 12.2175 6.15213 12.3805 6.31518 12.5435L8.49996 14.6957C8.663 14.8262 8.85865 14.924 9.05431 14.924C9.24996 14.924 9.44561 14.8588 9.60865 14.6957L13.0652 11.3044C13.1956 11.174 13.2934 10.9783 13.2934 10.7501C13.2934 10.5218 13.1956 10.3588 13.0326 10.1957ZM12.576 10.8153L9.11952 14.2066C9.08691 14.2392 8.98909 14.2392 8.95648 14.2066L6.7717 12.0544C6.73909 12.0218 6.73909 11.9892 6.73909 11.9892C6.73909 11.9892 6.73909 11.924 6.7717 11.924L7.09778 11.6305C7.13039 11.5979 7.163 11.5979 7.19561 11.5979C7.22822 11.5979 7.26083 11.5979 7.29344 11.6305L8.85865 13.1631C8.98909 13.2935 9.18474 13.2935 9.31517 13.1631L12.1195 10.3914C12.1521 10.3588 12.25 10.3588 12.2826 10.3914L12.6087 10.6848C12.6413 10.7175 12.6413 10.7501 12.6413 10.7501C12.6413 10.7501 12.6087 10.7827 12.576 10.8153Z"
        fill="none"
        stroke="black"
        stroke-width="0.3"
        className="fill-color"
      />
    </svg>
  );
};

export default AttendanceIcon;
