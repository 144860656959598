import Box from "@mui/material/Box";

const PerformanceTabPanel = (props) => {
  const { children, tabValue, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={tabValue !== index}
      id={`performance-tabpanel-${index}`}
      aria-labelledby={`performance-tab-${index}`}
      sx={{
        height: "100%",
      }}
      {...other}
    >
      {tabValue === index && <>{children}</>}
    </Box>
  );
};

export default PerformanceTabPanel;
