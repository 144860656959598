import { memo, useCallback, useEffect, useState } from "react"
import Layout from "../../shared/components/LayoutV2"
import TasksHeader from "./TasksHeader"
import TasksList from "./TasksList"
import { getTasks } from "../../services/Tasks"
import { useAlert } from "react-alert"




const TasksContainer = () => {
    const [taskList, setTasksList] = useState<any>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const alert = useAlert()

    const fetchTasks = useCallback(async () => {
        setIsLoading(true);
        try {
            const list: any = await getTasks();
            setTasksList(list.data)
        } catch (e) {
            alert.error('Error Fetching Task List' + e)
        }
        setIsLoading(false)
    }, [alert])

    useEffect(() => {
        fetchTasks()
    }, [fetchTasks])

   
    const handlePageChange = () => {

    }
    const handlePageSizeChange = () => {

    }

    const handlePriorityClick = () => {

    }

    const handleReporterClick = () => {

    }

    return <Layout
        header={<TasksHeader addTaskSuccess={fetchTasks}/>}
        body={
            <TasksList
                totalCount={totalCount}
                pageSize={pageSize}
                isDataLoading={isLoading}
                taskList={taskList}
                pageNumber={pageNumber}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
                handlePriorityClick={handlePriorityClick}
                handleReporterClick={handleReporterClick}
                refetchTasks={fetchTasks}
            />
        }
    />



}


export default memo(TasksContainer)