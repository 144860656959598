import { Box, Paper, Rating, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { FEEDBACK_TYPES, REWARD_TYPES } from "../../../Constants";
import { sagaActions } from "../../../redux/sagaActions";
import CustomizedDataGrid from "../../../shared/components/CustomizedDataGrid";
import IOSSwitch from "../../../shared/components/Switch/IOSSwitch";
import { formatDate } from "../../../utils/dateFormatter";
import FeedbackViewModal from "../common/modal/FeedbackViewModal";
import PerformanceFilter from "./PerformanceFilter";
import PerformanceListMobile from "./PerformanceListMobile";

const columns = [
  {
    field: "feedBackType",
    headerName: "Feedback type",
    width: 150,
    renderCell: (params: any) => {
      return (
        <div>
          <div>{FEEDBACK_TYPES[params.value]}</div>
          <Typography variant="caption" color="textSecondary">
            {formatDate(params.row.date)}
          </Typography>
        </div>
      );
    },
  },
  { field: "from", headerName: "From", width: 200 },
  {
    field: "score",
    headerName: "Rating",
    width: 180,
    renderCell: (params: any) => {
      if (params.row.feedBackType === "Monthly") {
        return (
          <Rating
            name="read-only"
            value={params.value || 0}
            readOnly
            emptyIcon={<></>}
          />
        );
      }

      if (params.row.feedBackType === "Reward") {
        return REWARD_TYPES[params.row.awardAppreciation];
      }

      return "";
    },
  },
  {
    field: "comment",
    headerName: "",
    flex: 1,
    renderCell: (params: any) => {
      if (params.row.feedBackType === "Monthly") {
        return params.value;
      }

      if (params.row.feedBackType === "Reward") {
        return params.row.achievement;
      }

      if (params.row.feedBackType === "ImmedicateAction") {
        return params.row.immediateActionReason;
      }

      return "";
    },
  },
];

const PerformanceList = () => {
  const dispatch = useDispatch();
  const navigate: any = useNavigate();
  const location: any = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [annaulFeedbackFlag, setAnnualFeedbcakFlag] = useState(false);
  const empId = useParams().id;

  const handleSwithControl = (e: any) => {
    setAnnualFeedbcakFlag(e.target.checked);
  };
  const switchColumn: any = {
    field: "view",
    headerName: "",
    sortable: false,
    disableColumnMenu: true,
    width: 300,
    align: "center",
    renderHeader: () => {
      return (
        <Box className="filter-switch-performance-details">
          <IOSSwitch
            checked={annaulFeedbackFlag}
            label="View Annual Feedback"
            onChange={handleSwithControl}
            labelPlacement="start"
          />
        </Box>
      );
    },
  };

  const [tableColumns, setTableColumns] = useState([...columns, switchColumn]);

  const isMobile = useMediaQuery("(max-width: 600px)");

  const {
    feedBackList = [],
    isLoading,
    selectedFeedbackDetails,
  } = useSelector((state: any) => state.performance.employee);

  const showPerformanceDetail = (params: any) => {
    if (annaulFeedbackFlag) {
      navigate(`/performance/${empId}/annual/${new Date().getFullYear() - 1}`, {
        state: { item: params.row, prevRoute: location },
      });
    } else {
      setShowModal(true);
      dispatch({
        type: sagaActions.FETCH_EMPLOYEE_PERFORMANCE_FEEDBACK,
        payload: {
          feedbackId: params.row.id,
        },
      });
    }
  };
  const showPerformanceDetailMobile = (item: any) => {
    if (annaulFeedbackFlag) {
      navigate(
        `/performance/${item.employeeId}/annual/${
          new Date().getFullYear() - 1
        }`,
        {
          state: { item: item, prevRoute: location },
        }
      );
    } else {
      setShowModal(true);
      dispatch({
        type: sagaActions.FETCH_EMPLOYEE_PERFORMANCE_FEEDBACK,
        payload: {
          feedbackId: item.id,
        },
      });
    }
  };

  useEffect(() => {
    if (annaulFeedbackFlag) {
      columns[0]["headerName"] = "Date";
      columns[2]["hide"] = true;
    } else {
      columns[0]["headerName"] = "Feedback type";
      columns[2]["hide"] = false;
    }
    setTableColumns([...columns, switchColumn]);
  }, [annaulFeedbackFlag]);

  return (
    <Box
      sx={{
        height: "calc(100% - 300px)",
        backgroundColor: "#FBFBFD",
        borderRadius: "10px 10px 0 0",
      }}
    >
      <PerformanceFilter
        annaulFeedbackFlag={annaulFeedbackFlag}
        handleSwithControl={handleSwithControl}
      />

      {isMobile && (
        <PerformanceListMobile
          annaulFeedbackFlag={annaulFeedbackFlag}
          showPerformanceDetailMobile={showPerformanceDetailMobile}
        />
      )}

      {!isMobile && (
        <Paper sx={{ height: "400px", minHeight: 400 }}>
          <CustomizedDataGrid
            rows={feedBackList}
            columns={tableColumns}
            isDataLoading={isLoading}
            handleRowClick={showPerformanceDetail}
            pagination={false}
            rowHeight={64}
          />
        </Paper>
      )}

      <FeedbackViewModal
        showModal={showModal}
        setShowModal={setShowModal}
        details={selectedFeedbackDetails}
      />
    </Box>
  );
};

export default PerformanceList;
