import { useRef, useState } from "react";

import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import { Box, Button, Modal, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikInput from "../../../shared/components/FormikInput";

export const addQuestionFormSchema = Yup.object({
  questionList: Yup.array().of(
    Yup.object().shape({
      subject: Yup.string().required("Please enter subject"),
      question: Yup.string().required("Please enter question"),
    })
  ),
});

const AddQuestionModal = ({ show, setModel, onFormSubmitAction }) => {
  const modelRef = useRef<any>();
  const [modalClientHeight, setModalClientHeight] = useState(100);

  return (
    <>
      <Modal open={show}>
        <Box
          className="custom-modal question-add-modal-popup"
          ref={modelRef}
          style={{
            height:
              modalClientHeight < window.innerHeight
                ? "auto"
                : window.innerHeight,
            overflow: "auto",
          }}
        >
          <Box>
            <Typography variant="h2" textAlign="center" fontWeight={400} p={2}>
              Add question
            </Typography>
            <Formik
              initialValues={{
                questionList: [
                  {
                    subject: "",
                    question: "",
                  },
                ],
              }}
              enableReinitialize
              validationSchema={addQuestionFormSchema}
              onSubmit={(data) => {
                onFormSubmitAction(data);
              }}
            >
              {(formikProps) => {
                return (
                  <Form>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <h3
                        style={{
                          marginTop: "4px",
                          marginBottom: "0px",
                          fontSize: "16px",
                        }}
                      >
                        Add new question
                      </h3>

                      <Button
                        onClick={() => {
                          // @ts-ignor
                          formikProps.setFieldValue("questionList", [
                            ...formikProps.values.questionList,
                            {
                              subject: "",
                              question: "",
                            },
                          ]);
                          setTimeout(() => {
                            // @ts-ignor
                            setModalClientHeight(
                              modelRef?.current?.clientHeight
                            );
                          }, 500);
                        }}
                      >
                        <img
                          src="/images/add_input.png"
                          style={{ height: 32, width: 32 }}
                          alt="reset"
                        />
                      </Button>
                    </Box>

                    {formikProps.values.questionList?.map((item, index) => (
                      <Box key={"queston-list" + index}>
                        <FormikInput
                          label="Subject"
                          name={`questionList.${index}.subject`}
                          options={undefined}
                          formikProps={formikProps}
                          fullWidth
                        />
                        <FormikInput
                          label="Question"
                          name={`questionList.${index}.question`}
                          options={undefined}
                          formikProps={formikProps}
                          fullWidth
                        />

                        {index ? (
                          <Box
                            style={{
                              position: "relative",
                              width: "100%",
                              display: "inline-block",
                            }}
                          >
                            <Button
                              style={{
                                float: "right",
                              }}
                              onClick={() => {
                                console.log(
                                  "remove ref client",
                                  modelRef?.current?.height
                                );

                                setModalClientHeight(
                                  formikProps.values.questionList.length * 200
                                );

                                const questionListArrays = [
                                  ...formikProps.values.questionList,
                                ];
                                questionListArrays.splice(index, 1);

                                formikProps.setFieldValue(
                                  "questionList",
                                  questionListArrays
                                );
                              }}
                            >
                              <RemoveCircleRoundedIcon
                                sx={{
                                  height: 35,
                                  width: 35,
                                  color: "#002DFF",
                                }}
                              ></RemoveCircleRoundedIcon>
                            </Button>
                          </Box>
                        ) : null}
                      </Box>
                    ))}

                    <Box textAlign="right" mt={2}>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => setModel(false)}
                        sx={{ height: 40, marginRight: "10px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={{ height: 40 }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddQuestionModal;
