import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    "& .present-title": {
      position: "absolute",
      bottom: "60px",
      left: "30px",
      fontSize: "13px",
      width: "110px" ,
      whiteSpace: "normal" ,
      overflow: "visible" ,
      wordWrap: " break-word",
    },
    "& .duration": {
      color: "#707D84",
      marginBottom: "8px",
    },
    "& .fc-daygrid-day-top": {
      width: "70%",
      marginTop: "8px",
      marginLeft: "8px",
      borderRadius: "0px 6.35px 6.35px 0px",
      fontSize: "larger",
    },
    "& .fc .fc-daygrid-day.fc-day-today": {
      backgroundColor: "#fff !important",
    },
    "& .title-main": {
      position: "absolute",
      bottom: -12,
      left: 5,
    },
    "& .present-day .fc-daygrid-day-top": {
      backgroundColor: "#A0F5D5 !important",
    },
    "& .weekend-day .fc-daygrid-day-top": {
      backgroundColor: "#FFE9AA !important",
    },
    "& .default-day .fc-daygrid-day-top": {
      backgroundColor: "#fff !important",
    },
    "& .leave-day .fc-daygrid-day-top": {
      backgroundColor: "#FDE0D0 !important",
    },
    "& .holiday-day": {
      backgroundColor: "#A0F5D5 !important",
    },
    "& .holiday-day .present-title":{
      position:'relative',
      bottom:30,
      fontSize:'14px'
    },
    "& .fc-h-event": {
      backgroundColor: "transparent !important",
      borderColor: "transparent !important",
    },
  },
  calenderRadio: {
    "& .MuiRadio-root": {
      color: "#000000",
    },
    "& .Mui-checked": {
      color: "#A16AE8",
    },
  },
}));

export default useStyles;
