import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { TabPanel } from "../../shared/components/TabPanel/TabPanel";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";

const requirementData = [
  "Proven work experience as a Front-end developer",
  "experience with JavaScript, CSS and jQuery",
  "Familiarity with browser testing and debugging",
  "understanding of the entire web development process (design, development and deployment)",
  "Familiarity with software like Adobe Suite, Photoshop and content",
  "Understanding of layout aesthetics",
  "Familiarity with software like Adobe Suite, Photoshop and content",
  "Knowledge of SEO principles",
  "Familiarity with software like Adobe Suite, Photoshop and content management systems",
  "An ability to perform well in a fast-paced environment",
];

const roleData = [
  "Use markup languages like HTML to create user-friendly web pages",
  "Maintain and improve website",
  "Optimize applications for maximum speed",
  "Design mobile-based features",
  "Collaborate with back-end developers and web designers to improve usability",
  "Get feedback from, and build solutions for, users and customers",
  "Write functional requirement documents and guides",
  "Create quality mockups and prototypes",
  "Help back-end developers with coding and troubleshooting",
  "Ensure high quality graphic standards and brand consistency",
  "Stay up-to-date on emerging technologies",
];

const headerData = {
  "Client Name": "Thirday",
  "Contract period": "4 months",
  "Expected joining date": "12-11-2024",
  "Posted on": "21-08-2023",
};

const tabsStyle = {
  "&.MuiTab-root": {
    fontSize: "18px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: "400",
    borderBottom: "2px solid #CCD3D5",
  },
};

const listHeaderStyle = {
  fontWeight: 700,
  lineHeight: "24px",
  fontSize: "16px",
  color: "black",
  backgroundColor: "inherit",
  ml: -4,
};

const OpportunityDetails = () => {
  const [tabValue, setValue] = useState(0);
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid>
      <Box m={3}>
        <Box height="40px" display="flex" alignItems="center" mb={1}>
          <KeyboardArrowLeftIcon onClick={() => navigate("/opportunities")} />
          <Typography>Frontend devloper (mid leavel)</Typography>
        </Box>
        <Tabs
          value={tabValue}
          variant="fullWidth"
          onChange={handleChange}
          sx={{ mb: 1, backgroundColor: "#FFFFFF" }}
        >
          <Tab sx={tabsStyle} label="Requirement details" />{" "}
          <Tab sx={tabsStyle} label="Applied stage" />
        </Tabs>
      </Box>
      <Box m={1}>
        <TabPanel value={tabValue} index={0}>
          <Grid
            display="flex"
            justifyContent="space-between"
            bgcolor="white"
            p={2}
          >
            {Object.keys(headerData).map((item) => (
              <Box>
                <Typography lineHeight={2} fontWeight={900}>
                  {item}
                </Typography>
                <Typography lineHeight={2}>{headerData[item]}</Typography>
              </Box>
            ))}
          </Grid>

          <Grid container spacing={1} pt={1}>
            <Grid item xs={6} md={6}>
              <Box bgcolor={"white"} minHeight={380}>
                <List sx={{ listStyleType: "disc", ml: 4 }}>
                  <ListSubheader sx={listHeaderStyle}>
                    Requirement and skills
                  </ListSubheader>
                  {requirementData.map((items) => (
                    <ListItem disablePadding sx={{ display: "list-item" }}>
                      <ListItemText primary={items} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box bgcolor={"white"} minHeight={380}>
                <List sx={{ listStyleType: "disc", ml: 4 }}>
                  <ListSubheader sx={listHeaderStyle}>
                    Roles and responsibility
                  </ListSubheader>
                  {roleData.map((items) => (
                    <ListItem disablePadding sx={{ display: "list-item" }}>
                      <ListItemText primary={items} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Grid>
  );
};
export default OpportunityDetails;
