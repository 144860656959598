import StarIcon from "@mui/icons-material/Star";
import { MenuItem, Rating, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-outlined": {
    padding: "0 !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const CustomRatingHeader = ({ setBulkRating, bulkRating, selected }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <span>Ratings</span>

      {selected.length > 0 && (
        <CustomSelect
          value={bulkRating || 1}
          onChange={(event) => setBulkRating(event.target.value)}
          renderValue={(value) => <StarIcon sx={{ color: "#faaf00" }} />}
          sx={{ width: 50, border: "none", ml: 2 }}
          onBlur={(event) => {
            event.stopPropagation(); // Fix for "Failed to execute 'contains' on 'Node': parameter 1 is not of type 'Node'."
          }}
        >
          {[5, 4, 3, 2, 1].map((rating) => (
            <MenuItem key={rating} value={rating}>
              <Rating value={rating} readOnly precision={1} emptyIcon={<></>} />
            </MenuItem>
          ))}
        </CustomSelect>
      )}
    </Box>
  );
};

export default CustomRatingHeader;
