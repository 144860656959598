import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Button, CircularProgress, Grid, IconButton, Paper, Typography } from "@mui/material";
import { Country } from "country-state-city";
import { FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reactQuillFormats, reactQuillModules } from "../../../Constants";
import FormikInput from "../../../shared/components/FormikInput";
import { AdminRole, userAccess } from "../../../utils/CommonFunction";
import { generateChatGptJobDescription, postJob } from "../../Hire/Hire.helper";
import GenerateJobDescriptionModal from "./GenerateJobDescriptionModal";

import { PlusOneRounded } from "@mui/icons-material";
import _ from "lodash";
import { useAlert } from "react-alert";
import * as yup from "yup";

const validationSchema = yup.object({
  client: (userAccess().role === AdminRole.PatonaAdmin || userAccess().role === AdminRole.TMFAdmin) ? yup.string().required("Client is required") : yup.string().notRequired(),
  title: yup.string().required("Job title is required"),
  experienceLevel: yup.string().required("Experience level is required"),
  employeeType: yup.string().required("Employment type is required"),
  expectedSalaryFrom: yup
    .number()
    .required("Expected salary from is required")
    .positive("Must be a positive number"),
  expectedSalaryTo: yup
    .number()
    .required("Expected salary to is required")
    .positive("Must be a positive number"),
  expectedJoiningDate: yup
    .date()
    .nullable()
    .required("Expected joining date is required"),
  aboutTheRole: yup.string().required("Description is required"),
  location: yup.string().required("Location is required"),
});

const NewJobForm = ({
  jobDetails
}) => {
  const [showAiGenerateJob, setShowAiGenerateJob] = useState(false);

  const alert = useAlert()
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allWorkableClients, isLoading, isJobAdded, jobAddUpdateError } = useSelector(
    //@ts-ignore
    (state) => state.hire
  );

  const [initialValues, setInitialValues] = useState({
    clientId: userAccess().ClientId,
    client: "",
    title: "",
    experienceLevel: "",
    employeeType: "",
    expectedSalaryFrom: "",
    expectedSalaryTo: "",
    expectedJoiningDate: null,
    location: "",
    aboutTheRole: "",
    otherRequirement: "",
    jobInterviewQuestions: [{
      interviewQuestionId: 0,
      question: "",
      hint: "",
      jobRequestRefId: 0
    }]
  });

  useEffect(() => {
    if (isLoading) {
      navigate("/hire");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if(isJobAdded){
      setLoading(false)
      alert.success('Job Added Successfully')
    }
  },[isJobAdded])

  useEffect(() => {
    if(jobAddUpdateError){
      setLoading(false)
      alert.error('Error adding Job')
    }
  },[jobAddUpdateError])

  useEffect(() => {
    if (!_.isEmpty(jobDetails)) {
      setInitialValues({
        clientId: jobDetails?.clientId,
        client: "",
        title: jobDetails?.title,
        experienceLevel: jobDetails?.experienceLevel,
        employeeType: jobDetails?.employeeType,
        expectedSalaryFrom: jobDetails?.expectedSalaryFrom,
        expectedSalaryTo: jobDetails?.expectedSalaryTo,
        expectedJoiningDate: jobDetails?.expectedJoiningDate,
        location: jobDetails?.location,
        aboutTheRole: jobDetails?.description,
        otherRequirement: jobDetails?.otherRequirement,
        jobInterviewQuestions: jobDetails?.jobInterviewQuestions
      });
    }
  }, []);

  const jobPostFormSubmit = (data) => {
    setLoading(true)
    postJob(data, dispatch);
  };



  return (
    <Paper style={{ padding: 16 }}>
      <Box sx={{ mt: 1 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(data) => jobPostFormSubmit(data)}
        >
          {(formikProps) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  {/* Client Field */}
                  {(userAccess().role === AdminRole.PatonaAdmin || userAccess().role === AdminRole.TMFAdmin) && <Grid item xs={12} sm={6}>
                    <FormikInput
                      label="Client*"
                      name="client"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={allWorkableClients.map((item) => {
                        return { name: item.name, value: item.id };
                      })}
                      schemaChange
                      schemaOnChangeFun={(value) => {
                        formikProps.setFieldValue("clientId", value);
                      }}
                      inputType="select"
                      fullWidth
                    />
                  </Grid>}
                  <Grid item xs={12} sm={6}>
                    <FormikInput
                      label="Job title*"
                      name="title"
                      formikProps={formikProps}
                      options={undefined}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikInput
                      label="Experience level*"
                      name="experienceLevel"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={[
                        { name: "Associate", value: 1 },
                        { name: "Mid level senior", value: 2 },
                        { name: "Senior level", value: 3 },
                      ]}
                      inputType="select"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikInput
                      label="Employment type*"
                      name="employeeType"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={[
                        { name: "On Contract", value: "On Contract" },
                        { name: "Permanent", value: "Permanent" },
                        { name: "Probation", value: "Probation" },
                      ]}
                      inputType="select"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikInput
                      name="expectedSalaryFrom"
                      label="Expected salary from*"
                      type="number"
                      formikProps={formikProps}
                      InputProps={{
                        startAdornment: formikProps.values.expectedSalaryFrom
                          ? "$"
                          : "",
                      }}
                      options={undefined}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikInput
                      name="expectedSalaryTo"
                      label="Expected salary to*"
                      type="number"
                      InputProps={{
                        startAdornment: formikProps.values.expectedSalaryTo
                          ? "$"
                          : "",
                      }}
                      formikProps={formikProps}
                      options={undefined}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikInput
                      inputType="date-picker"
                      label="Expected Joining date"
                      inputFormat="MM/DD/YYYY"
                      formikProps={formikProps}
                      name="expectedJoiningDate"
                      options={undefined}
                      disablePast={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikInput
                      label="Location*"
                      name="location"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={Country.getAllCountries()?.map((item) => {
                        return { name: item.name, value: item.isoCode };
                      })}
                      inputType="select"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      disabled={
                        !!(
                          formikProps?.values?.title === "" ||
                          formikProps?.values?.client === ""
                        )
                      }
                      onClick={() => {
                        const payload = {
                          jobTitle: formikProps?.values?.title,
                          clientId: formikProps?.values?.clientId,
                          expLevel: formikProps?.values?.experienceLevel,
                          employeeType: formikProps?.values?.employeeType,
                          salaryFrom: formikProps?.values?.expectedSalaryFrom,
                          salaryTo: formikProps?.values?.expectedSalaryTo,
                          location: formikProps?.values?.location,
                          noticePeriod: "",
                        };
                        generateChatGptJobDescription(payload, dispatch);
                        setShowAiGenerateJob(true);
                      }}
                      variant="contained"
                      sx={{ mt: 2 }}
                    >
                      {"AI Generated job description"}
                    </Button>
                    <Box
                      sx={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <InfoOutlinedIcon sx={{ ml: 1 }} />{" "}
                      <Typography
                        variant="caption"
                        sx={{ pl: 1, color: "#aeaeae" }}
                      >
                        {!userAccess().client
                          ? `Client and Job title is required to generate AI job description`
                          : `Job title is required to generate AI job description`}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <ReactQuill
                      theme="snow"
                      id="aboutTheRole"
                      modules={reactQuillModules}
                      formats={reactQuillFormats}
                      placeholder={`Description`}
                      value={formikProps.values.aboutTheRole}
                      onChange={(e) => {
                        formikProps.setFieldValue("aboutTheRole", e);
                      }}
                      style={{
                        height: 250,
                        marginBottom: 50,
                        marginTop: 20,
                      }}
                    />
                    <Box className="formik-input-div">
                      {formikProps.errors.aboutTheRole && (
                        <span className="error">
                          {formikProps.errors.aboutTheRole}
                        </span>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormikInput
                      label="Other requirement*"
                      name="otherRequirement"
                      formikProps={formikProps}
                      options={undefined}
                      multiline
                      fullWidth
                      rows="5"
                    />
                  </Grid>
                </Grid>


                {
                  <FieldArray name="jobInterviewQuestions" render={(arrayHelpers) => {
                    const questions = formikProps.values.jobInterviewQuestions
                    return <Grid>
                      <Grid container justifyContent={'space-between'} mb={2}>
                        <Typography variant="h6">Interview Questions</Typography>
                        <IconButton onClick={() => {
                          arrayHelpers.push({
                            interviewQuestionId: 0,
                            question: "",
                            hint: "",
                            jobRequestRefId: 0
                          })
                        }}><PlusOneRounded></PlusOneRounded></IconButton>
                      </Grid>
                      {questions.map((q, index) => (
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <FormikInput
                              label="Required Question"
                              name={`jobInterviewQuestions.${index}.question`}
                              formikProps={formikProps}
                              options={undefined}
                              fullWidth

                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormikInput
                              label="Hint for the question"
                              name={`jobInterviewQuestions.${index}.hint`}
                              formikProps={formikProps}
                              options={undefined}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>

                  }}></FieldArray>
                }



                <GenerateJobDescriptionModal
                  formikProps={formikProps}
                  showAiGenerateJob={showAiGenerateJob}
                  setShowAiGenerateJob={setShowAiGenerateJob}
                />

                <Box
                  display="flex"
                  justifyContent="flex-end"
                  sx={{ mt: 3, mb: 2 }}
                >
                  <Button
                    variant="text"
                    sx={{ mt: 2 }}
                    disabled={loading}
                    onClick={() => navigate("/hire")}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={loading}
                    variant="contained"
                    sx={{ mt: 2 }}
                  >
                    {loading ? <CircularProgress color="inherit" size={20}></CircularProgress>: 'Submit'}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Paper>
  );
};

export default NewJobForm;
