import api from "../services/BaseApi";
import { userAccess } from "../utils/CommonFunction";
import { formatDate } from "../utils/dateFormatter";
const BASE_URL_RECRUITLY = process.env.REACT_APP_RECRUITLY_URL;
const BASE_URL_WORKABLE = process.env.REACT_APP_DASHBOARD_URL;
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const getJobRequestTypesURL = BASE_URL_RECRUITLY + "getJobStatusList";
export const getJobRequestTypes = async () => {
  try {
    const response = await api.get(getJobRequestTypesURL);
    return response.data;
  } catch (error) {
    console.log(error, "Error fetching getJobRequestTypesURL");
  }
};

const getJobRequestListURL = BASE_URL_RECRUITLY + "getFilteredJobRequets ";
export const getJobRequestList = async ({
  requestType,
  pageNumber = 1,
  pageSize = 10,
  searchTerm = "",
}) => {
  try {
    const clientCode = userAccess().clientCode;
    const clientName = userAccess().clientName;
    const payload = {
      RequestType: requestType,
      Client: clientName,
      ClientId: clientCode,
      PageNumber: pageNumber,
      PageSize: pageSize,
      SearchValue: searchTerm,
    };
    const { data } = await api.post(getJobRequestListURL, payload);
    const tempJobRequestList = data.filteredJobs.map((jobRequest: any) => {
      return {
        ...jobRequest,
        createdAt: formatDate(jobRequest.createdAt, "DD/MM/YYYY HH:mm:ss"),
      };
    });

    return {
      filteredJobs: tempJobRequestList,
      paginationFilter: data.paginationFilter,
    };
  } catch (error) {
    return {
      filteredJobs: [],
      paginationFilter: {},
    };
  }
};

const getJobRequestListFromPatonaURL =
  BASE_URL_WORKABLE + "getJobRequestsByRequestType";
export const getJobRequestListFromPatona = async ({
  pageNumber = 1,
  pageSize = 10,
  searchTerm = "",
}) => {
  try {
    let payload: any = {
      requestType: "Open and Raised",
      client: userAccess().clientCode,
      ClientId: userAccess().clientCode,
      searchValue: searchTerm,
      isDesc: true,
      columnName: "",
      pageNumber,
      pageSize,
    };
    const { data } = await api.post(getJobRequestListFromPatonaURL, payload);
    const tempJobRequestList = data?.reqTypeFilteredData.map(
      (jobRequest: any) => {
        const workableJobResponse =
          jobRequest.reqTypeData[0].workableJobResponse;

        const stages = jobRequest.reqTypeData[0].stages.map((stage: any) => {
          return {
            code: stage.current_Stage_Name.toUpperCase(),
            count: stage.stage_Count,
          };
        });

        return {
          job_Id: workableJobResponse.jobRequestId,
          job_Title: workableJobResponse.title,
          company_Name: workableJobResponse.client || "",
          exceptedJoiningDate: formatDate(
            workableJobResponse.expectedJoiningDate
          ),
          salaryRange: workableJobResponse.expectedSalary || "",
          createdAt: formatDate(workableJobResponse.createdDate),
          stages,
        };
      }
    );

    return {
      reqTypeFilteredData: tempJobRequestList,
      paginationFilter: data?.paginationFilter,
    };
  } catch (error) {
    console.log(error);
    return {
      reqTypeFilteredData: [],
      paginationFilter: {},
    };
  }
};

const getJobRequestDetailsURL = BASE_URL_RECRUITLY + "getJobRequestDetails";
export const getJobRequestDetails = async (jobId) => {
  try {
    const payload = {
      Id: jobId,
    };
    const { data } = await api.post(getJobRequestDetailsURL, payload);
    return {
      clientId: "",
      client: "",
      title: data.title,
      experienceLevel: data?.experience?.name || "",
      employeeType: data?.employmentType?.lookupType?.name || "",
      expectedSalaryFrom: data?.pay?.minPay || "",
      expectedSalaryTo: data?.pay?.maxPay || "",
      expectedJoiningDate: data?.startDate,
      location: "",
      description: data?.description || "",
      otherRequirement: "",
    };
  } catch (error) {
    console.log(error, "Error fetching getJobRequestDetails");
  }
};

const getPatonaJobRequestDetailsURL = BASE_URL + "getJobRequestById";
export const getPatonaJobRequestDetails = async (jobId) => {
  try {
    const payload = {
      stage: "Applied",
      ClientId: 0,
      JobRequestId: jobId,
    };
    const { data } = await api.post(getPatonaJobRequestDetailsURL, payload);
    return {
      clientId: "",
      client: "",
      title: data.title,
      experienceLevel: data.experienceLevel,
      employeeType: data.employmentType,
      expectedSalaryFrom: data.expectedSalaryFrom,
      expectedSalaryTo: data.expectedSalaryTo,
      expectedJoiningDate: data.expectedJoiningDate,
      location: data.location,
      description: data.aboutTheRole,
      otherRequirement: data.additionalRequirements,
    };
  } catch (error) {
    console.log(error, "Error fetching getPatonaJobRequestDetails");
  }
};
