// AllPerformanceTableMobile.tsx
import { Box, Button, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import SkeletonLoaderMobile from "../common/loader/SkeletonLoaderMobile";

const AnnaulFeedbackTableMobile = ({
  loadMoreData,
  pageNumber,
  setFeedback,
  feedback,
}) => {
  const navigate: any = useNavigate();

  const { data = [], isLoading } = useSelector(
    //@ts-ignore
    (state) => state.performance.allEmployees
  );

  const handleRedirectFeedbackDetails = (id, item) => {
    if(item.clientId === 0) return;
    navigate(`/performance/${id}/annual/${new Date().getFullYear() - 1}`, {
      state: { item },
    });
  };

  if (isLoading && pageNumber === 1) {
    return <SkeletonLoaderMobile />;
  }

  return (
    <>
      <Box className="allPerformanceTableMobile" pb={12}>
        {data?.map((item, index) => (
          <Box
            onClick={() => handleRedirectFeedbackDetails(item.id, item)}
            className="allPerformanceTableMobile-link"
            key={index}
          >
            <Paper
              key={index}
              elevation={1}
              className="allPerformanceTableMobile-paper annaul-feedback-mobile-table"
              sx={{ p: 2, mb: 2 }}
            >
              <div className="allPerformanceTableMobile-item">
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="performance-table-avatar-name">
                    <span className="row-names">{item.name}</span>
                  </div>
                  <Typography variant="subtitle1">{item.client}</Typography>
                </Box>
                {item.clientId != 0 && item.isLastYearFeedbackSubmitted && (
                  <Typography
                    variant="caption"
                    color="#00E08C"
                    sx={{ cursor: "pointer", fontSize: "14px" }}
                  >
                    Annual feedback submitted
                  </Typography>
                )} 
                
                {item.clientId != 0 && !item.isLastYearFeedbackSubmitted && (
                  <Typography
                    variant="caption"
                    color="#002DFF"
                    sx={{ cursor: "pointer", fontSize: "14px" }}
                  >
                    Give annual feedback
                  </Typography>
                )}
              </div>
            </Paper>
          </Box>
        ))}

        <Box display="flex" justifyContent="center">
          {isLoading && (
            <PulseLoader color="#002DFF" loading={true} size={15} />
          )}
          {!isLoading && (
            <Button variant="outlined" onClick={loadMoreData}>
              Load More
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AnnaulFeedbackTableMobile;
