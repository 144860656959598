import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { PulseLoader } from "react-spinners";
import { useStyles } from "../common/modal/styles";

const SubmissionModal = ({
  showModal,
  onSuccessClose,
  onCancel,
  submitBulkFeedbackInfo,
}: any) => {
  const classes = useStyles();

  return (
    <Modal open={showModal}>
      <Box className={classes.modal}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {submitBulkFeedbackInfo?.isLoading && (
            <>
              <Typography
                variant="h4"
                textAlign="center"
                fontWeight={400}
                p={2}
              >
                Submitting your feedback...
              </Typography>
              <PulseLoader color="#000" loading={true} size={15} />
            </>
          )}

          {submitBulkFeedbackInfo?.submitted && (
            <>
              <img
                src="/images/feedback-submitted.png"
                alt="Feedback submitted"
                width={150}
                height={100}
              />
              <Typography
                variant="h4"
                textAlign="center"
                fontWeight={400}
                p={2}
              >
                Your feedback has been sent
              </Typography>
              <Box textAlign="right" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ height: 40 }}
                  onClick={() => {
                    if (onSuccessClose) {
                      onSuccessClose();
                    } else {
                      window.location.href = "/performance";
                    }
                  }}
                >
                  Close
                </Button>
              </Box>
            </>
          )}

          {submitBulkFeedbackInfo.error && (
            <>
              <img
                src="/images/Disqualify@3x.png"
                alt="Feedback submitted"
                width={72}
                height={72}
              />
              <Typography
                variant="h6"
                textAlign="center"
                fontWeight={400}
                p={2}
              >
                {submitBulkFeedbackInfo?.error || "Something went wrong"}
              </Typography>
              <Box textAlign="right" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ height: 40 }}
                  onClick={() => {
                    if (onCancel) {
                      onCancel();
                    } else {
                      window.location.reload();
                    }
                  }}
                >
                  Close
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default SubmissionModal;
