import StarIcon from "@mui/icons-material/Star";
import { Paper, Rating, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { FEEDBACK_TYPES, REWARD_TYPES } from "../../../Constants";
import { formatDate } from "../../../utils/dateFormatter";
import SkeletonLoaderMobile from "../common/loader/SkeletonLoaderMobile";

const PerformanceListMobile = ({
  showPerformanceDetailMobile,
  annaulFeedbackFlag,
}) => {
  const {
    feedBackList = [],
    isLoading,
    selectedFeedbackDetails,
  } = useSelector((state: any) => state.performance.employee);

  if (isLoading) {
    return (
      <Box mt={2}>
        <SkeletonLoaderMobile rows={3} />
      </Box>
    );
  }

  if (annaulFeedbackFlag) {
    return (
      <Box mt={2}>
        {feedBackList?.map((item, index) => (
          <Paper
            key={index}
            elevation={1}
            sx={{ p: 2, mb: 2 }}
            onClick={() => showPerformanceDetailMobile(item)}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex" }}>
                <Typography variant="subtitle1">Annually</Typography>
                <Typography variant="caption" ml={1}>
                  ({formatDate(item.date)})
                </Typography>
              </Box>

              <Rating
                value={item.avgScore}
                readOnly
                emptyIcon={<StarIcon fontSize="medium" />}
              />
            </Box>
            <Typography variant="body2" mt={2}>
              {item.from}
            </Typography>
          </Paper>
        ))}
      </Box>
    );
  }

  return (
    <Box mt={2}>
      {feedBackList?.map((item, index) => (
        <Paper
          key={index}
          elevation={1}
          sx={{ p: 2, mb: 2 }}
          onClick={() => showPerformanceDetailMobile(item)}
        >
          <div className="allPerformanceTableMobile-item">
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex" }}>
                <Typography variant="subtitle1">
                  {FEEDBACK_TYPES[item.feedBackType]}
                </Typography>
                <Typography variant="caption" ml={1}>
                  ({formatDate(item.date)})
                </Typography>
              </Box>
              {item.feedBackType === "Monthly" && (
                <Rating
                  value={item.score}
                  readOnly
                  emptyIcon={<StarIcon fontSize="medium" />}
                />
              )}

              {item.feedBackType === "Reward" && (
                <Typography variant="caption">
                  {REWARD_TYPES[item.awardAppreciation]}
                </Typography>
              )}
            </Box>

            {item.feedBackType === "Monthly" && (
              <Typography variant="body2" mt={2}>
                {item.comment}
              </Typography>
            )}

            {item.feedBackType === "Reward" && (
              <Typography variant="body2" mt={2}>
                {item.achievement}
              </Typography>
            )}

            {item.feedBackType === "ImmedicateAction" && (
              <Typography variant="body2" mt={2}>
                {item.immediateActionReason}
              </Typography>
            )}
          </div>
        </Paper>
      ))}
    </Box>
  );
};

export default PerformanceListMobile;
