import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

function SkeletonLoader() {
  return (
    <Box width="100%" role="tabpanel">
      {Array.from(new Array(3)).map((_, index) => (
        <Paper key={index} sx={{ display: "flex", flexDirection: 'column', gap: 1, mb: 2, p: 2, borderRadius: 0 }}>
          <Skeleton variant="text" width="40%" />
          <Skeleton variant="text" width="60%" />
          <Box display="flex" alignItems="center" gap={1}>
            <Skeleton variant="rectangular" width={40} height={40} />
            <Skeleton variant="rectangular" width={40} height={40} />
            <Skeleton variant="rectangular" width={40} height={40} />
            <Skeleton variant="rectangular" width={40} height={40} />
            <Skeleton variant="rectangular" width={40} height={40} />
          </Box>
          <Skeleton variant="rounded" width="100%" height={80} />
        </Paper>
      ))}
    </Box>
  );
}

export default SkeletonLoader;
