import React from "react";
import { useStyles } from "../CompanyDetails/styles";
import { Link } from "react-router-dom";
import { Country, City } from "country-state-city";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { UserData } from "../../../Types/Users/User";
import { Avatar } from "@mui/material";
import { getBase64File } from "./User.helper";
import TextSkeleton from "../../../shared/components/Skeletons/TextSkeleton";
import { STRINGS } from "../../../Strings";

type UserDetailsProps = {
  enableEdit?: () => void;
  enablePassword?: () => void;
  user: UserData;
  isLoading: false;
};

const UserDetails: React.FunctionComponent<UserDetailsProps> = (props) => {
  const {
    enableEdit = () => null,
    enablePassword = () => null,
    user,
    isLoading = false,
  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.display_card}>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Box display="flex" justifyContent="center">
            {user.profilePicture ? (
              <img
                className={classes.user_pic}
                src={user.profilePicture}
                alt={user.profilePicture}
              />
            ) : (
              <Avatar
                alt={`${user.firstName} ${user.lastName}`}
                src={user.profilePicture}
                sx={{ width: 174, height: 174 }}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={6}>
              <Typography className={classes.company_title_text}>
                {STRINGS.Name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <TextSkeleton />
              ) : (
                <Typography variant="body2">
                  {`${user.firstName} `}
                  {`${user.lastName}`}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={6}>
              <Typography className={classes.company_title_text}>
                {STRINGS.Phone}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <TextSkeleton />
              ) : (
                <Typography variant="body2"> {user.phoneNumber}</Typography>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={6}>
              <Typography className={classes.company_title_text}>
                {STRINGS.Address}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <TextSkeleton />
              ) : (
                <Typography variant="body2"> {user.address}</Typography>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={6}>
              <Typography className={classes.company_title_text}>
                {STRINGS.Country}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <TextSkeleton />
              ) : (
                <Typography variant="body2">
                  {user.country
                    ? Country.getCountryByCode(user.country)?.name
                    : ""}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={6}>
              <Typography className={classes.company_title_text}>
                {STRINGS.TimeZone}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <TextSkeleton />
              ) : (
                <Typography variant="body2">{user.timeZone}</Typography>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={6}>
              <Typography className={classes.company_title_text}>
                {STRINGS.Designation}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <TextSkeleton />
              ) : (
                <Typography variant="body2"> {user.designation} </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={6}>
              <Typography className={classes.company_title_text}>
                {STRINGS.Role}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <TextSkeleton />
              ) : (
                <Typography variant="body2"> {user.role} </Typography>
              )}
            </Grid>
          </Grid>
          <Box sx={{ textAlign: "right" }}>
            <Button onClick={enablePassword} variant="text" sx={{ mt: 2 }}>
              {STRINGS.ChangePassword}
            </Button>
            <Button onClick={enableEdit} variant="text" sx={{ mt: 2 }}>
              {STRINGS.Edit}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDetails;
